<div class="row">
    <div class="education-card col-lg-4 col-md-6 col-sm-12" *ngFor="let card of cardsData">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">{{ card.degreeTitle || card.title || card.skillTitle || card.role || card.subject
                    }}</h5>
                <p class="card-text">
                    <span *ngIf="card.institute"><strong>Institute Name:</strong> {{ card.institute }}</span><br>
                    <span *ngIf="card.startDate && card.endDate"><strong>Duration:</strong> {{ card.startDate | date }}
                        - {{ card.endDate | date }}</span><br>
                    <span *ngIf="card.cgpa"><strong>CGPA:</strong> {{ card.cgpa }}</span><br>
                    <span *ngIf="card.total && card.obtained">
                        <strong>Total:</strong> {{ card.total }} <strong>Obtained:</strong> {{ card.obtained
                        }}</span><br>
                    <span *ngIf="card.percentage"><strong>Percentage:</strong> {{ card.percentage }}%</span><br>
                    <span *ngIf="card.fee"><strong>Fee:</strong> {{ card.fee }}</span><br>
                    <span *ngIf="card.experience"><strong>Experience:</strong> {{ card.experience }}</span><br>
                    <span *ngIf="card.description"><strong>Description:</strong> {{ card.description }}</span><br>
                    <span *ngIf="card.company"><strong>Company:</strong> {{ card.company }}</span><br>
                    <span *ngIf="card.facebook">
                        <strong>Facebook:</strong>
                        <a [href]="card.facebook" target="_blank" aria-label="clickable-text">{{ card.facebook }}</a>
                    </span><br>
                    <span *ngIf="card.twitter">
                        <strong>Twitter:</strong>
                        <a [href]="card.twitter" target="_blank" aria-label="clickable-text">{{ card.twitter }}</a>
                    </span><br>
                    <span *ngIf="card.linkedin">
                        <strong>LinkedIn:</strong>
                        <a [href]="card.linkedin" target="_blank" aria-label="clickable-text">{{ card.linkedin }}</a>
                    </span><br>
                    <span *ngIf="card.instagram">
                        <strong>Instagram:</strong>
                        <a [href]="card.instagram" target="_blank" aria-label="clickable-text">{{ card.instagram }}</a>
                    </span>
                </p>
                <div class="button-container">
                    <button class="btn btn-outline-danger btn-sm me-2" aria-label="Delete">
                        <app-feather-icons [icon]="'delete'"></app-feather-icons> Delete
                    </button>
                    <button class="btn btn-outline-primary btn-sm " aria-label="Edit">
                        <app-feather-icons [icon]="'edit'"></app-feather-icons> Edit
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>