import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cv4',
  templateUrl: './cv4.component.html',
  styleUrl: './cv4.component.scss'
})
export class Cv4Component {
  @Input() userData: any = {};

}
