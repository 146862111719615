<section class="feature-section" [ngClass]="sectionClass">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-title [titleClass]="'title-1'" [tag]="tag" [heading]="heading" [textWhite]="true" [type]="'simple'" [tagClass]="tagClass"></app-title>
            </div>
        </div>
          <div class="feature-1 arrow-light">
              <app-featured-property [type]="'simple'" [featuredProperty]="featuredProperty" [tagClass]="tagClass" ></app-featured-property>
          </div>
    </div>
</section>
