import { Component, inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxsOnInit } from '@ngxs/store';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { UserCentralizedDataService } from 'src/app/shared/services/user-centralized-data.service';

@Component({
  selector: 'app-cv-edits',
  templateUrl: './cv-edits.component.html',
  styleUrl: './cv-edits.component.scss'
})
export class CvEditsComponent implements OnInit {
  public bgImage = 'assets/images/banner/banner.jpg';
  public Title = 'CV Templates';
  public parent = 'CV Builder';
  public child = 'CV Templates';
  @Input() tagClass: string;
  @Input() sectionClass: string;
  private isNavigating = false; // Prevent multiple navigations
  cvId: number = 1; // Current CV ID
  cvData: any = {};
  // public cvId: number;
  public userData: any = {};
  private __utilsService = inject(CommonUtilsServiceService);
  constructor(private userCentralizedDataService: UserCentralizedDataService, private router: Router, private route: ActivatedRoute
  ) { }
  ngOnInit(): void {
    this.cvById();
    this.userData = this.userCentralizedDataService.getCompleteCvData();
  }

  cvById() {
    this.route.params.subscribe(params => {
      this.cvId = +params['templateId'];
      if (!this.cvId) {
        console.error('No valid templateId found!');
      }
    });
  }

  navigateToCvData() {
    if (!this.isNavigating) {
      this.isNavigating = true;

      // Pass templateId as a query parameter while navigating
      this.router.navigate(['/cv-builder/cv-data'], {
        queryParams: { templateId: this.cvId }
      }).then(() => {
        this.isNavigating = false;
      });
    }
  }


  navigateToCvTemplates() {
    if (!this.isNavigating) {
      this.isNavigating = true;
      this.router.navigate(['/cv-builder/cv-templates']).then(() => {
        this.isNavigating = false;
      });
    }
  }

  // Method to handle CV download
  downloadCV() {
    const cvContainer = document.getElementById('cvContainer');

    if (cvContainer) {
      const printWindow = window.open('', '_blank', 'width=800,height=600');
      const clonedCV = cvContainer.cloneNode(true) as HTMLElement;

      if (printWindow) {
        printWindow.document.body.appendChild(clonedCV);
        printWindow.document.close();

        const styleTags = Array.from(document.querySelectorAll('style, link[rel="stylesheet"]'));
        styleTags.forEach((tag) => {
          const clonedTag = tag.cloneNode(true) as HTMLElement;
          printWindow.document.head.appendChild(clonedTag);
        });

        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    } else {
      console.error('CV container not found!');
    }
  }



}
