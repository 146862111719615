<div class="advance-card">
  <h6>Contact Info</h6>
  <div class="category-property">
    <ul>
      <li>
        <app-feather-icons [icon]="'map-pin'" class="me-2"></app-feather-icons>
        A-32, Albany, Newyork.
      </li>
      <li>
        <app-feather-icons [icon]="'phone-call'" class="me-2"></app-feather-icons>
        (+066) 518 - 457 - 5181
      </li>
      <li>
        <app-feather-icons [icon]="'mail'" class="me-2"></app-feather-icons>
        Contact&#64;gmail.com
      </li>
    </ul>
  </div>
</div>
