<!-- Tags  -->
<ul class="product-filter-tags">
    <li class="me-1" >
      @for(tag of paramsTag; track tag){
        <a href="javascript:void(0)" class="filter_tag">
          {{ tag }} <app-feather-icons [icon]="'x'" (click)="removeTag(tag)"></app-feather-icons>
        </a>
      }
      @if(price.minPrice.length || price.maxPrice.length){
        <a href="javascript:void(0)" class="filter_tag">
            Price : {{ price.minPrice | currencySymbol: propertyService.Currency }} - {{ price.maxPrice | currencySymbol: propertyService.Currency }} <app-feather-icons [icon]="'x'" (click)="removePrice()"></app-feather-icons>
        </a>
      }

      @if(area.minArea.length || area.maxArea.length){
        <a href="javascript:void(0)" class="filter_tag">
            Area : {{ area.minArea }} - {{ area.maxArea}} <app-feather-icons [icon]="'x'" (click)="removeArea()"></app-feather-icons>
        </a>
      }
    </li>
</ul>

<div class="property-2 row column-sm zoom-gallery property-label property-grid" [class.list-view]="listView">
  @for(data of latestForRentData; track data){
    <div [class.col-md-6]="col_md_6" [class.col-lg-6]="col_lg_6"  [class.col-lg-4]="col_lg_4" [class.col-xl-4]="col_xl_4" [class.col-xxl-3]="col_xxl_3" [class.col-xl-12]="col_xl_12" [class.col-xl-6]="col_xl_6">
      <app-property-box [latestForRentData]="data"  [type]="'basic'" [tagClass]="'color-2'" [thumbnail]="thumbnail" [thumbnail_video]="thumbnail_video" [gridImages]="gridImages" ></app-property-box>
    </div>
  }
</div>

<app-pagination [products]="latestForRentData" [paginate]="paginate" (setPage)="setPage($event)">
</app-pagination>
