import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, map } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-scholarship-detail',
  templateUrl: './scholarship-detail.component.html',
  styleUrl: './scholarship-detail.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush

})
export class ScholarshipDetailComponent {
  scholarshipsID: any;
  scholarshipsData: any;
  relatedScholarshipData: any[] = [];
  public isLoading = true;
  public isRelatedScholarshipsLoading = true;
  @Input() heading = "Related Scholarships";

  constructor(private route: ActivatedRoute) {
  }

  private _utils = inject(CommonUtilsServiceService);
  private _apiCall = inject(ApiCallService);
  private _propertyService = inject(PropertyService);
  private readonly _spinner = inject(NgxSpinnerService);

  ngOnInit(): void {


    this.scholarshipsID = this.route.snapshot.queryParamMap.get('scholarshipid');

    this.route.queryParamMap.subscribe((params) => {
      this.scholarshipsID = params.get('scholarshipid');
      if (this.scholarshipsID) {
        this.getScholarshipsDetails();
      }
      window.scrollTo(0, 0);
    });
  }

  getScholarshipsDetails() {
    this._spinner.show();

    this._apiCall.PostCallWithoutToken(null, `Scholarship/GetScholarshipById?ScholarshipId=${this.scholarshipsID}`).subscribe(
      (response) => {
        this.scholarshipsData = {
          moduleType: "scholarships",
          images: response.data.images.map((image: any) => ({
            id: image.id,
            imageBase64: image.imageBase64
          })),
          instituteName: response.data?.scholarshipName,
          country: response.data?.country,
          state: response.data?.state,
          city: response.data?.city,
          address: response.data?.address,
          startDate: response.data?.startDate,
          endDate: response.data?.endDate,
          description: response.data?.description,
          applyDescription: response.data?.howToApply,
          departments: response.data?.documents.map((department: any) => ({
            id: department.id,
            departmentName: department.documentName,
          })),
          courses: response.data?.courses.map((course: any) => ({
            id: course.id,
            courseName: course.courseName,
          })),
          skillVMs: response.data?.coverages.map((document: any) => ({
            id: document.id,
            skillTitle: document.coverageName,
          })),
          locationSrc: response.data?.locationSrc,
          locationTitle: response.data?.locationTitle,
          email: response.data?.email,
          websiteLink: response.data?.applicationLink,
          salary: response.data?.amount,
          contactNumber: response.data?.contactNumber,
          applicationFee: response.data?.applicationFee,
          duration: response.data?.duration,
          minimumCGPA: response.data?.minimumCGPA,
        };

        // Initialize Blob URL array
        this.scholarshipsData.blobUrls = [];

        // Convert each image to Blob URL if `images` exists
        if (Array.isArray(response.data.images)) {
          response.data.images.forEach((image: { imageBase64: string }) => {
            if (image.imageBase64 && image.imageBase64.includes('base64,')) {
              const base64Data = image.imageBase64.split('base64,')[1];
              const blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
              this.scholarshipsData.blobUrls.push(blobUrl);
              console.log("Scholarship Blob URL:", blobUrl);
            } else {
              this.scholarshipsData.blobUrls.push('path/to/default/image.png'); // Fallback URL
            }
          });
        }
        this.isLoading = false;
        this._spinner.hide();
        this.getRelatedScholarships();
      },
      (error) => {
        this.isLoading = false;
        this._spinner.hide();
        console.error('Error fetching scholarship details:', error);
      }
    );
  }


  // Get related Scholarships
  getRelatedScholarships() {
    this.isRelatedScholarshipsLoading = true;
    this._propertyService.getRelatedScholarships(this.scholarshipsID).subscribe(
      (response) => {
        this.relatedScholarshipData = response;
        this.isRelatedScholarshipsLoading = false;

        // Convert each related scholarship's image URL to a Blob URL
        this.relatedScholarshipData.forEach((scholarship) => {
          if (scholarship && scholarship.img && scholarship.img.url && scholarship.img.url.includes('base64,')) {
            const base64Data = scholarship.img.url.split('base64,')[1]; // Extract base64 data
            scholarship.blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            // console.log("Related Scholarship Blob URL:", scholarship.blobUrl);
          } else {
            // Set a default image if the image data is not in the expected format
            scholarship.blobUrl = 'path/to/default/image.png';
            console.warn("Image data is not in the expected base64 format:", scholarship.img ? scholarship.img.url : "No img property");
          }
        });

      },
      (error) => {
        this.isRelatedScholarshipsLoading = false;
        console.error("Error fetching related scholarships:", error);
      }
    );
  }



}
