@if(happyClientsData){
  <owl-carousel-o [options]="Options">
    @for(data of happyClientsData; track data){
      <ng-template carouselSlide>
          <div>
              <div class="row our-client">
                  <div class="col-md-6">
                      <div class="client-left">
                          <img [src]="data.images" class="img-fluid" alt="user-image">
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="client-right">
                          <p class="font-roboto"> {{ data.desc }} </p>
                          <ul class="client-rating">
                              <li><i class="fas fa-star"></i></li>
                              <li><i class="fas fa-star"></i></li>
                              <li><i class="fas fa-star"></i></li>
                              <li><i class="fas fa-star"></i></li>
                              <li><i class="fas fa-star"></i></li>
                          </ul>
                          <h3>{{ data.name }}</h3>
                          <h6>{{ data.title }}</h6>
                          <span class="quote-img">
                              <img src="assets/images/testimonial/quote-2.png" alt="quotes-icon">
                          </span>
                      </div>
                  </div>
              </div>
          </div>
      </ng-template>
    }
  </owl-carousel-o>
}
