import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { faq } from '../../../../shared/interface/property';
import { PropertyService } from '../../../../shared/services/property.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  //  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqComponent {
  @Input() titleClass: string;
  public tag: string = 'Questions';

  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/inner-background.jpg';
  public title = 'Faq';
  public parent = 'Home';
  public child = 'Faq';

  public faqData: faq[];
  FAQsForm: FormGroup;

  public theme_default3 = '#ff5c41';
  public theme_default4 = '#ff8c41';

  private _apicall=inject(ApiCallService);
  private _toaster=inject(ToastrService);

  constructor(private propertyService: PropertyService,private fb: FormBuilder) { 
    this.FAQsForm = this.fb.group({
      
      faq_question: ['', [Validators.required]],
    })
  }

  ngOnInit() {
    document.documentElement.style.setProperty('--theme-default', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default3', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default4', this.theme_default4);

    this.propertyService.faqData().subscribe((response) => {
      this.faqData = response.faq;
    });
  }

  ngOnDestroy(): void {
    document.documentElement.style.removeProperty('--theme-default');
    document.documentElement.style.removeProperty('--theme-default3');
    document.documentElement.style.removeProperty('--theme-default4');
  }

  replaceEmails(text: string): string {
    const emailPattern = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
    return text.replace(emailPattern, '<a aria-label="clickable-text" href="mailto:$1" style="color: blue; text-decoration: none;">$1</a>');
  }

  sendFaqQuestion() {
    
    if (this.FAQsForm.invalid) {
      this.FAQsForm.markAllAsTouched();
      this._toaster.error('Invalid Form data');
      return;
    }
    else{
        this._apicall.PostCallWithoutToken(this.createApiPayload(),'AdmissionLeloVisitor/SaveQuestionAndSendEmail').subscribe((res)=>{
          if(res.responseCode==200)
          {
            this._toaster.success(res.errorMessage);
            this.FAQsForm.reset();
          }
          else
          {
            this._toaster.error(res.errorMessage);
            this.FAQsForm.reset();
          }
        })
    }
  }
  createApiPayload(){
    return {
      question:this.FAQsForm.get('faq_question')?.value,
      
    }
  }
}
