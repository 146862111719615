<div class="main">
    <div class="contanier">
        <div class="left-side">
            <div class="profile">
                <div class="profile-image">
                    <img [src]="userData?.userDetails.profileBase64 || 'https://default-image-url.com/default-profile.png'"
                        class="img-fluid bg-img" alt="profile-image" />

                    <h2>{{userData?.userDetails.firstName + " " + userData?.userDetails.lastName||"Muhammad ALI"}} <br>
                        <span>{{userData?.userDetails.gender||"WEB-DEVELOPER"}}</span>
                    </h2>
                </div>
            </div>
            <div class="contact">
                <h3 class="title">Contact Info</h3>
                <ul>
                    <li>
                        <span class="icon"><i class="fa-regular fa-phone"></i></span>
                        <span class="text">{{userData?.userDetails.phoneNumber ||"+92 300 1234567"}}</span>
                    </li>
                    <li>
                        <span class="icon"><i class="fa-solid fa-envelope"></i></span>
                        <span class="text-gmail">{{userData?.userDetails.email||"myemail&commat;gmail.com"}}</span>
                    </li>
                    <li>
                        <span class="icon"><i class="fa-solid fa-location-dot"></i></span>
                        <span class="text">{{userData?.userDetails.state + " " + userData?.userDetails.country||"Punjab Pakistan"}}</span>
                    </li>
                </ul>
            </div>
            @if(userData?.educationDetails?.length > 0){

                <div class="education">
                    <h3 class="title">Education</h3>
                    <ul>
                        @for(education of userData?.educationDetails; track education){
                        <li>
                            <span class="icon"><i class="fa-solid fa-circle"></i></span>
                            <span class="text">{{ education?.degreeTitle || "Bachelor of Science in Computer Science"
                                }}</span>
                        </li>
                        }
    
                    </ul>
                </div>
            }
            @if(userData?.languageDetails?.length > 0){

                <div class="languages">
                    <h3 class="title">languages</h3>
                    <ul>
                        <div *ngFor="let language of userData?.languageDetails">
    
                            <li>
                                <span class="icon"><i class="fa-solid fa-circle"></i></span>
                                <span class="text">{{language?.language||"English"}}</span>
                            </li>
                        </div>
    
    
                    </ul>
                </div>
            }
        </div>
        <div class="right-side">
            <div class="about">
                <h2 class="title2">Profile</h2>
                <p>{{userData?.userDetails.description||"Dynamic Computer Science student with expertise in software development and
                    proficiency in HTML and
                    CSS. Eager to contribute to innovative projects and collaborate effectively within
                    teams.Proven problem-solving skills and a commitment to continuous learning. Seeking
                    opportunities to apply technical prowess in a dynamic environment, combining passion for innovation
                    with a drive for excellence."}}


                </p>
            </div>
            @if(userData?.educationDetails?.length > 0){

                <div class="ab-education">
                    <h2 class="title3">Education</h2>
                    @for(education of userData?.educationDetails; track education){
                    <div class="matric">
                        <h3 class="matric">{{education?.degreeTitle||"Matriculation"}}</h3>
                        <span class="ed-text">Session :</span>
                        <span class="years"> {{education?.startDate?.substring(0,4) +" - " +
                            education?.endDate?.substring(0,4)}}</span><br>
                        <span class="ed-text">institute :</span>
                        <span class="years"> {{education?.institute || "UNIVERSITY OF EDUCATION"}}
                        </span>
                    </div>
                    }
    
    
                </div>
            }
            @if(userData?.skillsDetails?.length > 0){

                <div class="skills">
                    <h2 class="title4">Skills</h2>
                    <div class="name-of-skills">
                        <div *ngFor="let skill of userData?.skillsDetails">
                            <ul>
                                <li class="sname"><span> {{ skill?.skillTitle + " - " + skill?.percentage + "%" }}
                                    </span></li>
                            </ul>
                        </div>
    
                    </div>
    
                </div>
            }
            @if(userData?.certificatesDetails?.length > 0){

                <div class="hobbies">
                    <h2 class="title4">Certificates</h2>
                    <div class="name-of-hobbies">
                        <div *ngFor="let certificate of userData?.certificatesDetails">
    
                            <ul>
                                <li class="sname"><span>{{certificate?.title + " - " + certificate?.institute}}</span></li>
                            </ul>
                        </div>
                        
    
                    </div>
                </div>
            }
        </div>
    </div>
</div>