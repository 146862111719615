import { Component } from '@angular/core';

@Component({
  selector: 'app-modern-home-section',
  templateUrl: './modern-home-section.component.html',
  styleUrls: ['./modern-home-section.component.scss']
})
export class ModernHomeSectionComponent {

}
