import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdmissionListComponent } from './admission-list/admission-list.component';
import { AdmissionDetailsComponent } from './admission-details/admission-details.component';

const routes: Routes = [
  {
    path: 'all-admissions',
    component: AdmissionListComponent
  },
  {
    path : 'admission-details',
    component : AdmissionDetailsComponent
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdmissionsRoutingModule { }
