<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<div class="mainContainer">
    <div class="headers">
        <h6>
            Awards
        </h6>
        <div>
        </div>
        <div class="addHeadings">
            <app-feather-icons (click)="addCard(addAwards)" class="color"
                [icon]="'plus'"></app-feather-icons>

            <span>
                Award
            </span>
        </div>
    </div>
    <div >
        @if(isLoading){
        <div class="item">
            <ngx-skeleton-loader count="6" [theme]="{
                    height: '40px',
                    width: '150px',
                    'border-radius': '10px',
                    'margin-right': '1rem'
                    }" />
        </div>
        }
        @else if(AwardsDetails?.length > 0){
        <!-- @for(Awards of AwardsDetails;track Awards){
        <div class="tag">
            <div class="tagContent">
                {{Awards?.position}}
                <span class="icons">
                    <i class="fas fa-edit edit-icon" (click)="editCard(addAwards,Awards)"></i>
                    <i class="fas fa-trash delete-icon" (click)="deleteCard(deleteModal,Awards?.id)"></i>
                </span>
            </div>
        </div>
        } -->

        <div class="row">
            <div class="col-md-12">
                <div class="educational-quality">
                    @for(Awards of AwardsDetails;track Awards){
                    <div class="detail-tags">
                        <div>
                            <div class="m-circle clr">A</div>
                            <div class="wrapper mb40">
                                @if(!Awards?.isPresent){
                                <span class="tag">{{Awards?.startDate?.substring(0,4) +" - " +
                                    Awards?.endDate?.substring(0,4)}}</span>
                                }@else{
                                <span class="tag">{{Awards?.startDate?.substring(0,4) }} - Present</span>
                                }
                                <h5 class="mt15"> {{Awards?.position}}</h5>
                                <h4 class="clr2">{{Awards?.company}}</h4>
                                <p>{{Awards?.description?.substring(0,60)}}
                                    @if(Awards?.description?.length > 60){
                                    ...
                                    }

                                </p>
                            </div>
                        </div>

                        <div class="control-buttons">
                            <i class="fas fa-edit edit-icon" (click)="editCard(addAwards,Awards)"></i>
                            <i class="fas fa-trash delete-icon" (click)="deleteCard(deleteModal,Awards?.id)"></i>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
        }

    </div>
</div>

<ng-template #addAwards>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "Add"){
                Add Awards
                }@else{
                Update Awards
                }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Dismiss">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="row gx-2 gx-sm-3" [formGroup]="AwardsForm">

                <div class="form-group col-sm-6">
                    <label>Position<span class="text-danger">*</span></label>
                    <input type="text" class=" form-control glow-input" formControlName="position" placeholder="Enter position">
                    @if(AwardsForm.get('position')?.hasError('required') &&
                    AwardsForm.get('position')?.touched){
                    <small class="text-danger">position is Required</small>
                    }
                </div>

                <div class="form-group col-sm-6">
                    <label>Company<span class="text-danger">*</span></label>
                    <input type="text" class="form-control glow-input" formControlName="company" placeholder="Enter company name">
                    @if(AwardsForm.get('company')?.hasError('required') &&
                    AwardsForm.get('company')?.touched){
                    <small class="text-danger">Company name is Required</small>
                    }
                </div>

                <div class="form-group col-sm-6">
                    <label>Start Date<span class="text-danger">*</span></label>
                    <input type="date" class="form-control" formControlName="startDate"  max="{{ getMaxDate() }}" (change)="getMinDate()">
                    @if(AwardsForm.get('startDate')?.hasError('required') &&
                    AwardsForm.get('startDate')?.touched){
                    <small class="text-danger">Start date is Required</small>
                    }
                </div>

                <div class="form-group col-sm-6">
                    <div class="last-date">
                        <label>End Date* </label>
                        <label>
                            <input class="checkbox_animated color-4" type="checkbox" [(ngModel)]="isPresent"
                                [ngModelOptions]="{standalone: true}" (change)="getCheckboxValue()" />
                            Present
                        </label>
                    </div>
                    <input type="date" class="form-control" formControlName="endDate" [min]="minmumDate">
                    @if(AwardsForm.get('endDate')?.hasError('required') &&
                    AwardsForm.get('endDate')?.touched){
                    <small class="text-danger">End date is Required</small>
                    }
                </div>

                <div class="form-group col-sm-12">
                    <label>Description<span class="text-danger">*</span></label>
                    <textarea class="form-control" formControlName="description" placeholder="Enter job description"
                        rows="7"></textarea>
                    @if(AwardsForm.get('description')?.hasError('required') &&
                    AwardsForm.get('description')?.touched){
                    <small class="text-danger">Description is Required</small>
                    }
                </div>

            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()" aria-label="Cancel">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="saveAwardsDetails()" aria-label="Add">
                @if(FormName == "Add"){
                Add
                }@else{
                Update
                }
            </button>
        </div>
    </div>
</ng-template>


<ng-template #deleteModal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Dismiss">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text">
                Are You Sure you want to delete this Award ?
            </div>
            <div class="deleteButtons">
                <button type="button" class="btn btn-dashed color-2 btn-pill" aria-label="Cancel"
                    (click)="modal.dismissAll()">Cancel</button>
                <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteAwards()" aria-label="Delete">
                    Delete
                </button>
            </div>
        </div>
    </div>
</ng-template>