import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScholarshipListComponent } from './scholarship-list/scholarship-list.component';
import { ScholarshipDetailComponent } from './scholarship-detail/scholarship-detail.component';

const routes: Routes = [
  {
    path: 'all-scholarships',
    component: ScholarshipListComponent
  },
  {
    path: 'scholarship-details',
    component: ScholarshipDetailComponent  
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScholarshipsRoutingModule { }
