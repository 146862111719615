<div class="mainContainer" [ngClass]="{'collapsed': !isExpanded}">
    <div class="headers" >
        <h6>
            Educations
        </h6>
        <div class="addHeadings" (click)="addCard(EducationsForm)">
            <app-feather-icons  class="color"
                [icon]="'plus'"></app-feather-icons>
            <span>
                Education
            </span>
            <i class="fas fa-chevron-down color" [ngClass]="{'collapsed': isExpanded}" (click)="toggleForm($event)"></i>

        </div>
    </div>
    @defer {
    <div>
        @if(isLoading){
        <div class="item">
            <ngx-skeleton-loader count="6" [theme]="{
                    height: '40px',
                    width: '150px',
                    'border-radius': '10px',
                    'margin-right': '1rem'
                    }" />
        </div>
        }
        @else if(educationDetails?.length > 0){
        <!-- @for(education of educationDetails;track education){ -->
        <!-- <div class="tag">
            <div class="tagContent">
                {{education?.degreeTitle}}
                <span class="icons">
                    <i class="fas fa-edit edit-icon" (click)="editCard(EducationsForm,education)"></i>
                    <i class="fas fa-trash delete-icon" (click)="deleteCard(deleteModal,education?.id)"></i>
                </span>
            </div>
        </div> -->
        <div class="row">
            <div class="col-md-12">
                <div class="educational-quality">
                    @for(education of educationDetails;track education){
                    <div class="detail-tags">
                        <div>
                            <div class="m-circle clr">E</div>
                            <div class="wrapper mb40">
                                @if(!education?.isPresent){
                                <span class="tag">{{education?.startDate?.substring(0,4) +" - " +
                                    education?.endDate?.substring(0,4)}}</span>
                                }@else{
                                <span class="tag">{{education?.startDate?.substring(0,4) }} - Present</span>
                                }
                                <h5 class="mt15">{{education?.degreeTitle}}</h5>
                                <h4 class="clr2">{{education?.institute}}</h4>
                                <p>CGPA : {{education?.cgpa}}</p>
                            </div>
                        </div>

                        <div class="control-buttons">
                            <i class="fas fa-edit edit-icon" (click)="editCard(EducationsForm,education)"></i>
                            <!-- <i class="fas fa-trash delete-icon" (click)="deleteCard(deleteModal,education?.id)"></i> -->
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>

        }

        <!-- <div class="tag">
            <div class="tagContent">
                Bs Political Science
                <span class="icons">
                    <i class="fas fa-edit edit-icon"></i>
                    <i class="fas fa-trash delete-icon"></i>
                </span>
            </div>
        </div> -->
    </div>
}
</div>
<ng-template #EducationsForm>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "Add"){
                Add Education
                }@else{
                Update Education
                }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="row gx-2 gx-sm-3 " [formGroup]="educationForm">
                <div class="form-group col-sm-6">
                    <label>Degree Title*</label>
                    <app-common-dropdowns [dataList]="degreeList" [defaultText]="'Select Degree Title'"
                        (optionSelected)="onDegreeSelected($event)" [selectedOption]="degree" [clearThevalue]="degree"
                        (sendTheValue)="onDegreeValueChange($event)">
                    </app-common-dropdowns>
                    @if(degree == "" && degreeError){
                    <small class="text-danger">Degree Title is Required</small>
                    }
                </div>

                <div class="form-group col-sm-6">
                    <label>CGPA*</label>
                    <input type="number" class="form-control" formControlName="cgpa" placeholder="Enter CGPA"
                        step="0.01" min="0">
                    @if(educationForm.get('cgpa')?.hasError('required') && educationForm.get('cgpa')?.touched){
                    <small class="text-danger">CGPA is required.</small>
                    }
                    @if(educationForm.get('cgpa')?.hasError('pattern') && educationForm.get('cgpa')?.touched){
                    <small class="text-danger">CGPA must be a number between 0 and 4.</small>
                    }
                </div>

                <div class="form-group col-sm-6">
                    <label>Start Date*</label>
                    <input type="date" class="form-control" id="intialDate" formControlName="startDate"
                        [ngClass]="{'empty': educationForm.get('startDate')?.value === '', 'highlight': educationForm.get('startDate')?.value}"
                        max="{{ getMaxDate() }}" (change)="getMinDate()">
                    @if(educationForm.get('startDate')?.hasError('required') &&
                    educationForm.get('startDate')?.touched){
                    <small class="text-danger">Start Date is Required</small>
                    }
                </div>

                <div class="form-group col-sm-6">
                    <div class="last-date">
                        <label>End Date* </label>
                        <!-- <label>
                            <input class="checkbox_animated color-4" type="checkbox" [(ngModel)]="isPresent"
                                [ngModelOptions]="{standalone: true}" (change)="getCheckboxValue()" />
                            Present
                        </label> -->
                    </div>
                    <input type="date" class="form-control" id="endingdate" formControlName="endDate"
                        [ngClass]="{'empty': educationForm.get('endDate')?.value === '', 'highlight': educationForm.get('endDate')?.value}"
                        [min]="minmumDate">
                    @if(educationForm.get('endDate')?.hasError('required') && educationForm.get('endDate')?.touched){
                    <small class="text-danger"> End Date is Required</small>
                    }
                </div>

                <div class="form-group col-sm-12">
                    <label>Institute*</label>
                    <input type="text" class="form-control" formControlName="institute"
                        placeholder="Enter Institute name">
                    @if(educationForm.get('institute')?.hasError('required') &&
                    educationForm.get('institute')?.touched){
                    <small class="text-danger">Institute is Required</small>
                    }
                </div>

                <!-- <div class="form-group col-sm-4">
                    <label>Total Marks</label>
                    <input type="number" class="form-control" formControlName="total" placeholder="Enter total marks"
                        min="0">
                    <div *ngIf="educationForm.get('total')?.hasError('required') && educationForm.get('total')?.touched"
                        class="text-danger">
                        Total Marks are Required
                    </div>
                </div>

                <div class="form-group col-sm-4">
                    <label>Obtained Marks</label>
                    <input type="number" class="form-control" formControlName="obtained"
                        placeholder="Enter obtained marks" min="0">
                    <div *ngIf="educationForm.get('obtained')?.hasError('required') && educationForm.get('obtained')?.touched"
                        class="text-danger">
                        Obtained Marks are Required
                    </div>
                </div> -->

                <!-- <div class="form-group col-sm-12 text-end">
                    <button type="submit" class="btn btn-gradient color-2 btn-pill"
                        (click)="saveEducationDetails()">Save Education
                    </button>
                </div> -->
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()" aria-label="Cancel">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="saveEducationDetails()" aria-label="Add">
                @if(FormName == "Add"){
                Add
                }@else{
                Update
                }
            </button>
        </div>
    </div>
</ng-template>





<!-- <ng-template #deleteModal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text">
                Are You Sure you want to delete this education ?
            </div>
            <div class="deleteButtons">
                <button type="button" class="btn btn-dashed color-2 btn-pill" aria-label="Cancel"
                    (click)="modal.dismissAll()">Cancel</button>
                <button type="button" class="btn btn-gradient color-2 btn-pill" aria-label="Delete" (click)="deleteEducation()">
                    Delete
                </button>
            </div>
        </div>
    </div>
</ng-template> -->