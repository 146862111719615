import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { UserCentralizedDataService } from 'src/app/shared/services/user-centralized-data.service';

@Component({
  selector: 'app-cv-skill-details',
  templateUrl: './cv-skill-details.component.html',
  styleUrl: './cv-skill-details.component.scss'
})
export class CvSkillDetailsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  skillForm: FormGroup;
  public FormName: string = "Add";
  isLoading: boolean = true;
  public isExpanded: boolean = false;
  hasBeenOpened: boolean = false;
  skillsDetails: any = [
    // {
    //   skillTitle: 'Angular',
    //   percentage: '80'
    // },
    // {
    //   skillTitle: 'React',
    //   percentage: '70'
    // },
    // {
    //   skillTitle: '.Net Core',
    //   percentage: '60'
    // }
  ];
  delId: number;
  editId: number = 0;
  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  private readonly _userCentralizedDataService = inject(UserCentralizedDataService);

  constructor(private fb: FormBuilder, public modal: NgbModal, private cdr: ChangeDetectorRef) {
    this.skillForm = this.fb.group({
      skillTitle: ['', Validators.required],
      percentage: ['', [Validators.required, Validators.min(0), Validators.max(100)]]
    });
  }

  ngOnInit(): void {
    this.getSKillsDetails();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  addCard(content: TemplateRef<any>) {
    this.skillForm.reset();
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
  }

  editCard(content: TemplateRef<any>, tag: any) {
    this.skillForm.reset();
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.editId = tag?.id;
    this.skillForm.get("skillTitle")?.setValue(tag?.skillTitle);
    this.skillForm.get("percentage")?.setValue(tag?.percentage);

  }
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }


  checkSkillExists(): boolean {
    const payload = this.createApiPayload();
    // If there are skills in the list, check for duplication
    if (this.skillsDetails?.length > 0) {
      // If we're adding a new skill
      if (this.FormName === "Add") {
        const isExist = this.skillsDetails.some((tag: any) => tag.skillTitle === payload.skillTitle);
        if (isExist) {
          this._toaster.error("This Skill Already Exists");
          return true; // Return true if duplicate skill exists
        }
      } else {
        // If we're editing an existing skill
        let updatedSkills = this.skillsDetails.filter((item: any) => item.id !== this.editId); // Exclude the current skill being edited
        const isExist = updatedSkills.some((tag: any) => tag.skillTitle === payload.skillTitle);
        if (isExist) {
          this._toaster.error("This Skill Already Exists");
          return true; // Return true if duplicate skill exists
        }
      }
      return false; // No duplicate skill found
    }
    return false; // If there are no skills, return false (meaning no duplicate)
  }

  public async saveSkillDetails(): Promise<void> {
    this.skillForm.markAllAsTouched();

    const isAlreadyExists = this.checkSkillExists();
    if (!isAlreadyExists) {
      const payload = this.createApiPayload();

      // Check if the form is valid
      if (this.skillForm.invalid) {
        this._toaster.error("Invalid Form");
      } else {
        this._userCentralizedDataService.saveSkillsDetails(payload);
        this.getSKillsDetails();
        this.modal.dismissAll();

      }
    }
  }

  createApiPayload() {
    // Set editId to 0 when adding a new skill, else keep the existing editId
    this.editId = this.FormName === "Add" ? 0 : this.editId;

    // Construct the payload object to send in the API call
    return {
      id: this.editId,
      skillTitle: this.skillForm.get('skillTitle')?.value,
      percentage: this.skillForm.get('percentage')?.value
    };
  }


  // public async deleteSkill(): Promise<void> {
  //   this._spinner.show();
  //   try {
  //     const res = await this._apiCall.PostCallWithToken(null, `AdmissionLeloUsers/DeleteTeacherSkill?SkillId=${this.delId}`).pipe(takeUntil(this.destroy$))
  //       .toPromise();
  //     if (res.responseCode == 200) {
  //       this._toaster.success(res.responseMessage);
  //       this.getSKillsDetails();
  //       this._spinner.hide();
  //       this.modal.dismissAll();
  //     } else {
  //       this.errorHandlingService.handleResponseError(res);
  //     }
  //   } catch (error) {
  //     this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
  //   } finally {
  //     this._spinner.hide();
  //     this.cdr.detectChanges();
  //   }
  // }


  // FUNCTION TO GET Experience DETAILS

  public async getSKillsDetails(): Promise<void> {
    this.isLoading = true;;
    try {
      this.skillsDetails = this._userCentralizedDataService.getSkillsDetails();

      if (!this.skillsDetails || this.skillsDetails.length === 0) {
        this.skillsDetails = [];
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this._spinner.hide();
      this.isLoading = false;
      this.cdr.detectChanges();
    }
  }



  // collapsed the tab
  toggleForm(event: MouseEvent) {
    event.stopPropagation();  // Stop click event propagation to prevent parent click
    this.isExpanded = !this.isExpanded;  // Toggle expanded state for the arrow
  }


}
