<section class="layout-home2 banner-section p-0">
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-lg-12 p-0">
        <video class="video-bg" id="block" style="width: 100%; " poster="" playsinline="" autoplay="" muted="" loop="">
          <source src="assets/video/video2.mp4" type="video/mp4">
        </video>
        <div class="home-main feature-section new-property video-bg">
          <div class="container">
            <div class="feature-content video-details text-start p-0 vertical-search mt-0">
              <span class="label-cross">
                New !
              </span>
              <div class="left-sidebar">
                <a href="javascript:void(0)" aria-label="clickable-text">
                  <h2> Sick about moving out? </h2>
                </a>
                <app-filter-box [buttonClass]="'color-4'"></app-filter-box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
