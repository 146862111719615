import { ChangeDetectionStrategy, Component, HostListener, OnInit, inject } from '@angular/core';
import { privacyPolicy } from 'src/app/shared/interface/property';
import { MetaService } from 'src/app/shared/services/meta.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyComponent implements OnInit {
  private readonly metaService: MetaService = inject(MetaService);
  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Privacy Policy';
  public parent = 'Home';
  public child = 'Privacy Policy';
  public activeClass = 'information';

  private readonly headerOffset: number = 100;
  private sectionOffsets: { [key: string]: number } = {};
  private isScrollingManually = false;  
  public privacyPolicyData: privacyPolicy[];

  constructor(private propertyService: PropertyService) {}

  ngOnInit() {
    this.propertyService.privacyPolicyData().subscribe((response) => {
      this.privacyPolicyData = response.privacy;
      setTimeout(() => {
        this.calculateSectionOffsets();
      }, 0);
    });

    this.metaService.updateTitle('Privacy Policy | AdmissionLylo');
    this.metaService.updateMetaTags([
      { name: 'description', content: 'Your page description here' },
      { name: 'keywords', content: 'angular, seo, meta tags' },
      { name: 'author', content: 'Your Name' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.metaService.setCanonicalURL();
    this.metaService.initializeMetaData();
  }


  setPage(value: string): void {
    this.isScrollingManually = true;  

    const element = document.getElementById(value);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - this.headerOffset;
      this.activeClass = value;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });

      setTimeout(() => {
        this.isScrollingManually = false;
      }, 200);  
    }
  }

  calculateSectionOffsets(): void {
    this.privacyPolicyData.forEach((data) => {
      const element = document.getElementById(data.value);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        this.sectionOffsets[data.value] = elementPosition - this.headerOffset;
      }
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (this.isScrollingManually) {
      return;
    }

    const scrollPosition = window.pageYOffset;

    for (const [key, value] of Object.entries(this.sectionOffsets)) {
      const element = document.getElementById(key);
      if (element && scrollPosition >= value && scrollPosition < value + element.offsetHeight) {
        this.activeClass = key;
        break;
      }
    }
  }
}
