<section class="layout-home4 p-0">
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-lg-12 p-0">
        <div class="sync-slider">
          <div class="home-slider-4 arrow-image">
            <app-home-section-slider [homeSectionSliderData]="homeSectionSliderData" [type]="'classic'" ></app-home-section-slider>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
