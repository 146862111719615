<app-map-h-search-home-section></app-map-h-search-home-section>

<app-search-property></app-search-property>

<app-classic-latest-property [latestPropertyData]="latestPropertyData"></app-classic-latest-property>

<app-classic-featured-property></app-classic-featured-property>

<app-classic-property-service></app-classic-property-service>

<app-classic-property-listing [latestPropertyData]="latestPropertyData" [title]="'classic'"></app-classic-property-listing>

<app-classic-video></app-classic-video>

<app-classic-agents></app-classic-agents>

<app-classic-banner></app-classic-banner>

<app-classic-happy-client></app-classic-happy-client>

<app-classic-brand></app-classic-brand>
