<app-loader-two [isLoading]="isDataLoading"></app-loader-two>
<app-header-one></app-header-one>

<section class="user-dashboard small-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="sidebar-user sticky-cls">
                    <div class="user-profile">
                        <app-user-info></app-user-info>
                    </div>
                    <div class="dashboard-list">
                        <app-user-panel-side-menu [activeTab]="'teacher-test'"></app-user-panel-side-menu>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-content">
                    <div class="table-container">
                        <div class="table-header">
                            <h2>All Tests</h2>
                            <div class="attempted-test" (click)="navigateToTestSummary()">
                                <span> Attempted Test </span>
                                <app-feather-icons class="color" [icon]="'external-link'"></app-feather-icons>
                               
                            </div>
                        </div>
                        @if (isDataLoading) {<div>
                            <ngx-skeleton-loader count="10" appearance="circle" [theme]="{
                             width: '100%',
                             height: '3rem',
                             'border-radius': '10px'
                           }" />
                        </div>
                        }@else {

                        <table class="test-table">
                            <thead>
                                <tr>
                                    <th>Sr.</th>
                                    <th>Test</th>
                                    <th>Level</th>
                                    <th>Description</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                @for (test of testTable; track $index) {
                                <tr>
                                    <td>{{ $index + 1 }}</td>
                                    <td>{{ test.category + " - " + test.subCategory }} </td>
                                    <td>{{ test.level}} </td>
                                    <td>{{ test.description.substring(0, 40) +"..." }}</td>
                                    <td>
                                            <span class="label label-shadow  btn-sm" (click)="startTest(test)">Start</span>
                                           
                                        </td>
                                </tr>
                                }

                            </tbody>
                        </table>
                        }
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>
<app-adly-footer></app-adly-footer>