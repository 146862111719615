<div class="media">
  <div class="change-pic">
    <img [src]="userDetails?.profileBase64" class="img-fluid update_img" alt="user-image">
    <!-- <div class="change-hover">
      <button type="button" class="btn"><app-feather-icons [icon]="'camera'"></app-feather-icons></button>
      <input class="updateimg" type="file" name="img">
    </div> -->
  </div>
  <div class="media-body">
    <h5>{{userDetails?.firstName | na  }}{{ " "}} {{userDetails?.lastName  | na }}</h5>
    <h6 class="font-roboto">{{userDetails?.email}}</h6>
    <h6 class="font-roboto mb-0">{{userDetails?.phoneNumber}}</h6>
  </div>
</div>
<!-- <div class="connected-social">
  <h6>Connect with</h6>
  <ul class="agent-social">
    <li><a href="https://www.facebook.com/" class="facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
    <li><a href="https://twitter.com/" class="twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
    <li><a href="https://account.google.com" class="google" target="_blank"><i class="fab fa-google"></i></a></li>
    <li><a href="https://www.linkedin.com/" class="linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
  </ul>
</div> -->

@if(userDetails?.socialLinks){
  <div class="connected-social" >
    <h6>Connect with</h6>
    <ul class="agent-social">
      @if(userDetails?.socialLinks?.facebook){
        <li >
          <a [href]="userDetails.socialLinks.facebook" class="facebook" target="_blank" aria-label="clickable-text">
            <i class="fab fa-facebook-f"></i>
          </a>
        </li>
      }
      @if(userDetails?.socialLinks?.twitter){
        <li >
          <a [href]="userDetails.socialLinks.twitter" class="twitter" target="_blank" aria-label="clickable-text">
            <i class="fab fa-twitter"></i>
          </a>
        </li>
      }
      @if(userDetails?.socialLinks?.instagram
      ){
        <li >
          <a [href]="userDetails.socialLinks.instagram" class="google" target="_blank" aria-label="clickable-text">
            <i class="fab fa-instagram"></i>
          </a>
        </li>
      }
      @if(userDetails?.socialLinks?.linkedin){
        <li >
          <a [href]="userDetails.socialLinks.linkedin" class="linkedin" target="_blank" aria-label="clickable-text">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </li>
      }
    </ul>
  </div>
}


