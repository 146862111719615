
<div class="mt-90">
  <app-title
    [isLabelAdded]="true"
    [isLineAdded]="true"
    [tag]="tag"
    [titleClass]="'title-1'"
    [heading]="'Frequently ask question'"
    [type]="'simple'"
  ></app-title>
</div>

<section class="faq-section log-in">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 order-lg-1">
        <div class="faq-image text-center">
          <img
            src="assets/images/inner-pages/3.svg"
            class="img-fluid"
            alt="image"
          />
          <h3>Have Any Questions ?</h3>
          <p class="font-roboto">You can ask anything you want to know</p>
        </div>
        <form  [formGroup]="FAQsForm">
          <div class="form-group mb-0">
            <label>Let us know</label>
            <input
              type="text"
              class="form-control glow-input"
              placeholder="Ask a question"
              formControlName="faq_question"
              required
            />
            @if ( FAQsForm.get('faq_question')?.touched &&
            FAQsForm.get('faq_question')?.hasError('required')) {
            <small class="text-danger">Question is required</small>
            }
            <div class="send-button">
              <app-center-button
                class="place"
                [buttonText]="'Send'"
                (click)="sendFaqQuestion()"
              ></app-center-button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-6">
        <div class="faq-questions">
          <!-- <div class="title-3 text-start">
            <h2>Frequently ask question</h2>
          </div> -->
          <div class="accordion" ngbAccordion [closeOthers]="true" role="region" aria-labelledby="faq-header" aria-expanded="false">
            @for (data of faqData; track data; let i = $index) {
            <div
              class="card"
              ngbAccordionItem
              [collapsed]="data.panelNumber !== 'First'"
              role="tabpanel" aria-labelledby=`faq-header-$(i)`
            >
              <div class="card-header" ngbAccordionHeader id=`faq-header-$(i)`>
                <a
                  class="card-link" aria-label="clickable-text"
                  href="javascript:void(0)"
                  ngbAccordionToggle
                  aria-controls=`faq-body-$(i)` aria-expanded="false"
                >
                  {{ data.title }}
                </a>
              </div>
              <div ngbAccordionCollapse id="faq-body-1" aria-labelledby=`faq-header-$(i)`>
                <div class="card-body" ngbAccordionBody>
                  <span [innerHTML]="replaceEmails(data.description)"></span>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
