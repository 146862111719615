<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="var(--mainColor)" type="ball-spin-clockwise"
    [fullScreen]="true">
    <p style="font: size 20px; color: var(--mainColor)">Loading...</p>
</ngx-spinner>

<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Change password</h5>
    <button aria-label="Button" type="button" class="btn-close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="chnage_Password">
      <div class="row gx-3">
        <div class="form-group col-12">
          <label for="p-o">Current password</label>
          <i class="fa" [ngClass]="oldPassword === 'password' ? 'fa-eye-slash' : 'fa-eye'"
            (click)="showAndHidePassword('oldPassword')"></i>
          <input type="password" class="form-control" id="p-o" formControlName="old_password"
            placeholder="Old Password" [type]="oldPassword">
            @if(chnage_Password.get('old_password')?.hasError('required') &&
            chnage_Password.get('old_password')?.touched){
              <div class="error-message">Old Password is Required</div>
            }
            
        </div>
        <div class="form-group col-12">
          <label for="p-n">Enter new password</label>
          <div>
            <i class="fa" [ngClass]="signUpPassword === 'password' ? 'fa-eye-slash' : 'fa-eye'"
              (click)="showAndHidePassword('signUpPassword')"></i>
          </div>
          <input class="form-control" id="p-o" type="text" placeholder="New Password" formControlName="new_password"
            [type]="signUpPassword" #pwd>
          @if(chnage_Password.get('new_password')?.hasError('required') &&
          chnage_Password.get('new_password')?.touched){
          <div class="error-message">New Password is Required</div>
          }
        </div>
        <div class="password-error-message">
          @if(!(passMinlength && passUpperCase && passLowerCase && passNumber &&
          passSpecialChar) && chnage_Password.get("new_password")?.value?.length > 0){
          <div class="passwordErrorMessages">
            <div class="message" [ngClass]="passMinlength? 'validPassword' : 'inValidPassWord' ">
              <i [ngClass]="passMinlength? 'fa fa-check-circle' : 'fa fa-times-circle' "></i>
              At least 8 characters long.
            </div>

            <div class="message" [ngClass]="passUpperCase? 'validPassword' : 'inValidPassWord' ">
              <i [ngClass]="passUpperCase? 'fa fa-check-circle' : 'fa fa-times-circle' "></i>
              At least one uppercase letter.
            </div>
            <div class="message" [ngClass]="passLowerCase? 'validPassword' : 'inValidPassWord' ">
              <i [ngClass]="passLowerCase? 'fa fa-check-circle' : 'fa fa-times-circle' "></i>
              At least one lowercase letter.
            </div>
            <div class="message" [ngClass]="passNumber? 'validPassword' : 'inValidPassWord' ">
              <i [ngClass]="passNumber? 'fa fa-check-circle' : 'fa fa-times-circle' "></i>
              At least one number
            </div>
            <div class="message" [ngClass]="passSpecialChar? 'validPassword' : 'inValidPassWord' ">
              <i [ngClass]="passSpecialChar? 'fa fa-check-circle' : 'fa fa-times-circle' "></i>
              At least one special character (&#64;,%,$,#, etc.)
            </div>
          </div>
          }
        </div>
        <div class="form-group col-12">
          <label for="p-c">Confirm your password</label>
          <!-- <input type="password" class="form-control" id="p-c" formControlName="confirm_password"> -->
          <i class="fa" [ngClass]="signUpConfirmPassword === 'password' ? 'fa-eye-slash' : 'fa-eye'"
            (click)="showAndHidePassword('signUpConfirmPassword')"></i>
          <input class="form-control" id="p-c" placeholder="Confirm Password" [type]="signUpConfirmPassword"
            formControlName="confirm_password" #cpsw (keyup)='checkPasswords(pwd.value, cpsw.value)'>
          @if(chnage_Password.get('confirm_password')?.hasError('required') &&
          chnage_Password.get('confirm_password')?.touched){
          <div class="error-message">Confirm Password is Required</div>
          }
          <div class="inValidPassWord" *ngIf='!passwordsMatching && cpsw.value && isConfirmPasswordDirty'>
            Passwords did not match
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button aria-label="Button" type="button" class="btn btn-dashed color-2 btn-pill" (click)="closeModal()">Cancel</button>
    <button aria-label="Button" type="button" class="btn btn-gradient color-2 btn-pill" (click)="ChangePassword()">Save changes</button>
  </div>
</div>