<div class="row">
  <div class="col-lg-6">
    <div class="download-wrap">
      <h3>Download Our Apps</h3>
      <p class="font-roboto">
        Created for convenience and ease to make it's memorable
      </p>
      <ul>
        <li>
          <a href="https://appstoreconnect.apple.com" target="_blank" aria-label="clickable-text"
            ><img
              src="assets/images/inner-pages/app.png"
              class="img-fluid"
              alt="app-store-logo"
          /></a>
        </li>
        <li>
          <a href="https://play.google.com/" target="_blank" aria-label="clickable-text"
            ><img
              src="assets/images/inner-pages/googleplay.png"
              class="img-fluid"
              alt="play-store-logo"
          /></a>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-lg-6">
    <form class="app-right">
      <div class="input-app">
        <div class="form-group">
          <select>
            <option>+ 62</option>
            <option>+ 91</option>
            <option>+ 61</option>
          </select>
          <input
            class="form-control"
            placeholder="Enter your phone number"
            required
          />
        </div>
        <button type="submit" class="btn btn-gradient color-2 btn-flat" aria-label="Send me">
          Send me
        </button>
      </div>
      <p class="font-roboto mb-0">Standard SMS rates may apply</p>
    </form>
  </div>
</div>
