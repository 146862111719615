import { Component, Input, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
interface DropdownItem {
  id: number;
  value: string; 
}
@Component({
  selector: 'app-edit-user-details-modal',
  templateUrl: './edit-user-details-modal.component.html',
  styleUrls: ['./edit-user-details-modal.component.scss']
})
export class EditUserDetailsModalComponent {
  @Input() userDetails: any;

  editProfileForm: FormGroup;

  Country: string = '';
  City: string = '';
  State: string = '';


  private _apiCall = inject(ApiCallService);

  constructor(
    public modal: NgbModal,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.getCountry();
    this.editProfileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      userType: ['', Validators.required],
      gender: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      dateOfBirth: ['', Validators.required],
      address: ['', Validators.required],
      description: [''],
      city: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      username: ['', Validators.required],
    });


    if (this.userDetails) {
      const formattedDate = this.formatDate(this.userDetails.dateOfBirth);

      this.editProfileForm.patchValue({
        ...this.userDetails,
        dateOfBirth: formattedDate
      });

      // Set previous Country, State, and City
      this.Country = this.userDetails.country;
      this.State = this.userDetails.state;
      this.City = this.userDetails.city;

      
      this.getStateList(this.userDetails.countryId); 
      this.getCityList(this.userDetails.stateId);   
    }


  }


  private formatDate(dateString: string): string {
    if (!dateString) return '';
    return dateString.substring(0, 10);

  }



  ////////////////////////////////////////////////////
  /////////  Country drop down  //////////
  ///////////////////////////////////////////////////// 
  CountrysearchTerm: string = "";
  CountryList: DropdownItem[] = [];
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    this._apiCall
      .GetCallWithoutToken('DropDown/GetCountryDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
          if (this.userDetails && this.Country) {
            const selectedCountry = this.CountryList.find(country => country.value === this.Country);
            if (selectedCountry) {
              this.onCountrySelected(selectedCountry);
            }
          }
        }
      })

  }

  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = '';
      this.City = '';
    }
    this.Country = option.value;
    this.editProfileForm.patchValue({
      country: option.value
    });
    this.getStateList(option.id);

  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue
    if (this.Country === '') {
      this.State = '';
      this.City = ''
    }
  }

  ////////////////////////////////////////////////////
  /////////  State drop down data //////////
  ////////////////////////////////////////////////////

  StatesearchTerm: string = "";

  StateList: DropdownItem[] = [];

  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }

  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = '';
    }
    this.State = option.value;
    this.editProfileForm.patchValue({
      state: option.value
    });
    this.getCityList(option.id);

  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue
    if (this.State === '') {
      this.City = ''
    }
  }

  getStateList(countryId: number) {
    this._apiCall
      .GetCallWithoutToken('DropDown/GetStateDropDown?CountryId=' + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
          if (this.userDetails && this.State) {
            const selectedState = this.StateList.find(state => state.value === this.State);
            if (selectedState) {
              this.onStateSelected(selectedState); // Automatically trigger selection
            }
          }
        }
      });
  }


  ////////////////////////////////////////////////////
  /////////  City drop down data //////////
  ////////////////////////////////////////////////////


  CityList: DropdownItem[] = [];
  onCitySelected(option: any): void {
    this.City = option.value;
    this.editProfileForm.patchValue({
      city: option.value
    });
  }

  getCityList(stateId: number) {
    this._apiCall.GetCallWithoutToken('DropDown/GetCityDropDown?StateId=' + stateId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
          if (this.userDetails && this.City) {
            const selectedCity = this.CityList.find(city => city.value === this.City);
            if (selectedCity) {
              this.onCitySelected(selectedCity); // Automatically trigger selection
            }
          }
        }
      }
    )
  }

  onEditDetails() {
    // if (this.editProfileForm.valid) {
    const updatedData = this.editProfileForm.value;
    this._apiCall
      .PostCallWithToken(updatedData, 'AdmissionLeloUsers/SavePersonalDetails')
      .subscribe(
        (response: any) => {
          // console.log('Profile updated successfully', response);
          this.activeModal.close();
        },
        (error: any) => {
          // console.error('Error updating profile', error);

        }
      );
  }


}
