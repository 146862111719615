import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-jobs-details',
  templateUrl: './jobs-details.component.html',
  styleUrl: './jobs-details.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsDetailsComponent {
  @Input() heading = "Related Jobs";

  public isLoading = true;
  public isRelatedJobsLoading = true;
  relatedJobsData: any[] = [];

  private _utils = inject(CommonUtilsServiceService);

  private readonly _spinner = inject(NgxSpinnerService);
  private _propertyService = inject(PropertyService);
  private _apiCall = inject(ApiCallService);

  constructor(private route: ActivatedRoute) {

  }

  jobsID: any;
  jobsData: any;
  ngOnInit(): void {

    this.jobsID = this.route.snapshot.queryParamMap.get('jobid');


    this.route.queryParamMap.subscribe((params) => {
      this.jobsID = params.get('jobid');
      if (this.jobsID) {
        this.getJobsDetails();
      }
      window.scrollTo(0, 0);
    });

  }


  getJobsDetails() {
    this._spinner.show();
    
    this._apiCall.PostCallWithoutToken(null, `Jobs/GetJobById?JobId=${this.jobsID}`).subscribe(
      (response) => {
  
        this.jobsData = {
          moduleType: "jobs",
          images: response.data.imageVMs.map((image: any) => ({
            id: image.id,
            imageBase64: image.imageBase64
          })),
          instituteName: response.data?.jobName,
          country: response.data?.countryName,
          state: response.data?.stateName,
          city: response.data?.cityName,
          address: response.data?.location,
          description: response.data?.description,
          departments: response.data?.educationVMs.map((education: any) => ({
            id: education.id,
            departmentName: education.educationTitle
          })),
          courses: response.data?.experienceVMs.map((experience: any) => ({
            id: experience.id,
            courseName: experience.experienceTitle,
          })),
          locationSrc: response.data?.locationSrc,
          locationTitle: response.data?.locationTitle,
          email: response.data?.websiteLink,
          salary: response.data?.salary,
          deadline: response.data?.deadline,
          applyDescription: response.data?.applyDescription,
          jobType: response.data?.jobType,
          contactNumber: response.data?.contactNumber,
          responsibilitiesDescription: response.data?.responsibilitiesDescription,
          additionalDescription: response.data?.additionalDescription,
          skillVMs: response.data?.skillVMs,
          websiteLink: response.data?.websiteLink,
        };
  
        // Initialize Blob URL array
        this.jobsData.blobUrls = [];
  
        // Convert each image to Blob URL if `imageVMs` exists
        if (Array.isArray(response.data.imageVMs)) {
          response.data.imageVMs.forEach((image: { imageBase64: string }) => {
            if (image.imageBase64 && image.imageBase64.includes('base64,')) {
              const base64Data = image.imageBase64.split('base64,')[1];
              const blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
              this.jobsData.blobUrls.push(blobUrl);
              // console.log("Job Blob URL:", blobUrl);
            } else {
              this.jobsData.blobUrls.push('path/to/default/image.png'); // Fallback URL
            }
          });
        }
        this.isLoading = false;
        this._spinner.hide();
        this.getRelatedJobs();
      },
      (error) => {
        this._spinner.hide();
        this.isLoading = false;
        console.error('Error fetching job details:', error);
      }
    );
  }
  

  // Get Related Jobs
  getRelatedJobs() {
    this.isRelatedJobsLoading = true;
    this._propertyService.getRelatedJobs(this.jobsID).subscribe(
      (response) => {
        this.relatedJobsData = response;
        this.isRelatedJobsLoading = false;

        // Convert each related job's image URL to a Blob URL
        this.relatedJobsData.forEach((job) => {
          if (job && job.img && job.img.url && job.img.url.includes('base64,')) {
            const base64Data = job.img.url.split('base64,')[1]; // Extract base64 data
            job.blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            // console.log("Related Job Blob URL:", job.blobUrl);
          } else {
            // Set a default image if the image data is not in the expected format
            job.blobUrl = 'path/to/default/image.png';
            console.warn("Image data is not in the expected base64 format:", job.img ? job.img.url : "No img property");
          }
        });

      },
      (error) => {
        this.isRelatedJobsLoading = false;
        console.error("Error fetching related jobs:", error);
      }
    );
  }



}
