<div class="blog-sidebar modal-form" style="z-index: 2;">
  <div class="filter-cards">
    <div class="advance-card">
      <h6>Request exploration</h6>
      <div class="category-property">
        <form>
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Your Name" required>
          </div>
          <div class="form-group">
            <input type="email" class="form-control" placeholder="Email Address" required>
          </div>
          <div class="form-group">
            <input placeholder="phone number" class="form-control" name="mobnumber" id="tbNumbers" type="tel"  maxlength="9" required="">
          </div>
          <div class="form-group">
            <textarea placeholder="Message" class="form-control" rows="3"></textarea>
          </div>
          <button aria-label="Button" type="button" class="btn btn-gradient color-2 btn-block btn-pill" [routerLink]="['/page/user/my-listing']">Submit Request</button>
        </form>
      </div>
    </div>
  </div>
</div>
