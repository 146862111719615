import { Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-scholarshps-form',
  templateUrl: './scholarshps-form.component.html',
  styleUrl: './scholarshps-form.component.scss'
})
export class ScholarshpsFormComponent {
  @ViewChild('addScholarship') addScholarship!: TemplateRef<any>;
  public __apiCall = inject(ApiCallService);
  public _toastr = inject(ToastrService);
  private popUP$ = new Subscription;
  scholarshipsData: any;
  public isSpinnerShown = false;
  addScholarshipForm: FormGroup;
  scholarshipDescriptionCharCount: number = 0;
  howToApplyCharCount: number = 0;




  ngOnInit() {
    this.getCountry();
    this.getScholarshipTypes();
  }



  constructor(public modal: NgbModal, private fb: FormBuilder, private popUp: DataShareService) {
    this.addScholarshipForm = this.fb.group({
      scholarship_name: ["", Validators.required],
      institute_name: ["", Validators.required],
      scholarship_address: ["", Validators.required],
      scholarship_addressTitle: ["", Validators.required],
      scholarship_addressLink: ["", [Validators.required, Validators.pattern('https://maps.google.com/maps.+')]],
      application_Link: ["", [Validators.required, Validators.pattern('https://.+')]],
      email_address: ["", [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      scholarship_phone: ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10,18}$"), Validators.minLength(10), Validators.maxLength(18)]],
      start_date: ["", [Validators.required,]],
      end_date: ["", Validators.required],
      minimum_cgpa: ["", [Validators.required, this.cgpaValidator]],
      duration: ["", Validators.required],
      scholarship_amount: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      scholarship_fee: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      courses_name: ["", Validators.required],
      required_documents: ["", Validators.required],
      coverage_Tags: ["", Validators.required],
      scholarship_description: ["", [Validators.required, this.minWordsValidator(400)]],
      how_to_apply: ["", [Validators.required, this.minWordsValidator(400)]],
    });



    this.popUP$ = this.popUp.currentPopup$.subscribe((popUpName) => {
      if (popUpName == 'scholarships') {
        if (this.addScholarship) {
          this.modal.open(this.addScholarship, { centered: true, size: 'xl' });
        } else {
          setTimeout(() => {
            if (this.addScholarship) {
              this.modal.open(this.addScholarship, { centered: true, size: 'xl' });
            }
          }, 100);
        }
        this.addScholarshipForm.reset();
        this.coursestags = [];
        this.requiredDocuments = [];
        this.coverageTags = [];
        this.Duration = "";
        this.ScholarshipType = "";
        this.City = "";
        this.Country = "";
        this.State = "";
        this.scholarshipTypeError = false;
        this.countryTypeError = false;
        this.stateError = false;
        this.cityError = false;
        this.imageError = false;

      }
    })
  }



  files: File[] = [];
  images: { imageBase64: string }[] = [];

  onSelect(event: NgxDropzoneChangeEvent) {
    const newFiles = event.addedFiles;
    this.files.push(...newFiles);
    // console.log("files", this.files)

    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const sizeInKB = Math.round(file.size / 1024);
        const istoDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!istoDel) {
          const base64String = e.target.result as string;
          this.images.push({ imageBase64: base64String });
        }
      };
      reader.readAsDataURL(file);
    });

  }

  onRemove(event: File) {
    const index = this.files.indexOf(event);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.images.splice(index, 1);
    }
  }
  imageSizeError: boolean = false
  checkImgeSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.imageSizeError = true;
      this.onRemove(file)
      return true;

    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }


  /////////////////////////////////////////////////////
  //////////// image update case //////////////////////
  //////////////////////////////////////////////////




  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for ScholarshipType Starts Here
  //////////////////////////////////////////////
  ScholarshipType: string = ""
  ScholarshipTypeList = [];

  getScholarshipTypes() {
    this.__apiCall
      .GetCallWithoutToken("DropDown/GetScholarshipDropDown")
      .subscribe((response) => {
        this.ScholarshipTypeList = response?.data;
      });
  }

  onScholarshipTypeSelected(option: any) {
    this.ScholarshipType = option.value;
  }
  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for ScholarshipType Ends Here
  //////////////////////////////////////////////

  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for  Duration Starts Here
  //////////////////////////////////////////////

  Duration: string = "";

  DurationTimeList = [
    { id: 1, value: "One Year" },
    { id: 2, value: "Two Year" },
    { id: 3, value: "Full Degree" },

  ];

  onDurationSelected(option: any) {
    this.Duration = option.value;

  }

  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for  Duration Ends Here
  //////////////////////////////////////////////

  ////////////////////////////////////////////////////
  /////////  Country drop down  //////////
  ///////////////////////////////////////////////////// 
  CountrysearchTerm: string = "";
  Country: string = "";
  State: string = '';
  City: string = '';
  CountryList = []
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetCountryDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      })

  }
  scholarshipTypeError: boolean = false;
  countryTypeError: boolean = false;
  stateError: boolean = false;
  cityError: boolean = false;
  imageError: boolean = false;

  ShowError() {
    this.scholarshipTypeError = this.ScholarshipType == "" ? true : false;
    this.countryTypeError = this.Country == "" ? true : false;
    this.stateError = this.State == "" ? true : false;
    this.cityError = this.City == "" ? true : false;
    this.imageError = this.images.length < 1 ? true : false
  }

  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = '';
      this.City = '';
    }
    this.Country = option.value;
    this.getStateList(option.id);

  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue
    if (this.Country === '') {
      this.State = '';
      this.City = ''
    }
  }

  ////////////////////////////////////////////////////
  /////////  State drop down data //////////
  ////////////////////////////////////////////////////

  StatesearchTerm: string = "";

  StateList = []
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }

  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = '';
    }
    this.State = option.value;
    this.getCityList(option.id);

  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue
    if (this.State === '') {
      this.City = ''
    }
  }

  getStateList(countryId: number) {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetStateDropDown?CountryId=' + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }


  ////////////////////////////////////////////////////
  /////////  City drop down data //////////
  ////////////////////////////////////////////////////


  CityList = []
  onCitySelected(option: any): void {
    this.City = option.value;
  }

  getCityList(stateId: number) {
    this.__apiCall.GetCallWithoutToken('DropDown/GetCityDropDown?StateId=' + stateId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      }
    )
  }

  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag functionality start here
  coursestags: any[] = [];

  addCourseTag(): void {
    const tagInput = this.addScholarshipForm.get("courses_name")?.value.trim();

    if (
      tagInput &&
      !this.coursestags.some((tag) => tag.courseName === tagInput)
    ) {
      const newTag = { courseName: tagInput };
      this.coursestags.push(newTag);
      this.addScholarshipForm.get("courses_name")?.setValue("");
    }
  }

  removeCourseTag(tag: any): void {
    this.coursestags = this.coursestags.filter((t) => t !== tag);
  }

  onEnterKeyPress(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addCourseTag();
    }
  }

  // Function to add COURSE tag functionality end here
  /////////////////////////////////////////////////////////////////////////


  ///////////////////////////////////////////////////////////
  // Function to add Required Documents tag functionality start here
  requiredDocuments: any[] = [];

  addDocumentsTags(): void {
    const tagInput = this.addScholarshipForm.get("required_documents")?.value.trim();

    if (tagInput && !this.requiredDocuments.some((tag) => tag.documentName === tagInput)) {
      const newTag = { documentName: tagInput };
      this.requiredDocuments.push(newTag);
      this.addScholarshipForm.get("required_documents")?.setValue("");
    }
  }

  removeDocumentsTags(tag: any): void {
    this.requiredDocuments = this.requiredDocuments.filter((t) => t !== tag);
  }

  onEnterDocuments(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addDocumentsTags();
    }
  }
  // Function to add Required Documents tag functionality end here
  /////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////
  // Function to add Coverage Tags functionality start here
  coverageTags: any[] = [];

  addCoverageTags(): void {

    const tagInput = this.addScholarshipForm.get("coverage_Tags")?.value.trim();

    if (
      tagInput &&
      !this.coverageTags.some((tag) => tag.coverageName === tagInput)
    ) {
      const newTag = { coverageName: tagInput };
      this.coverageTags.push(newTag);
      this.addScholarshipForm.get("coverage_Tags")?.setValue("");
    }
  }

  removeCoverageTags(tag: any): void {
    this.coverageTags = this.coverageTags.filter((t) => t !== tag);
  }

  onEnterCoverage(event: Event): void {

    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addCoverageTags();
    }
  }
  // Function to add  Coverage Tagsfunctionality end here
  /////////////////////////////////////////////////////////////////////////

  ////////////////////////futureDateValidator//////////////////////////////////////
  minEndDate: any;
  updateEndDateMin(): void {
    const startDate = this.addScholarshipForm.get('start_date')?.value;
    this.minEndDate = startDate ? startDate : null;
  }


  // Method for word count validation
  minWordsValidator(minWords: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value || '';
      const wordCount = value.trim().split(/\s+/).length;
      return wordCount < minWords ? { minWords: true } : null;
    };
  }


  // Method to update job description char count
  updateScholarshipDescriptionCharCount(): void {
    const scholarshipdescription = this.addScholarshipForm.get("scholarship_description")?.value;
    this.scholarshipDescriptionCharCount = scholarshipdescription?.length || 0;
  }

  // Method to update how to apply char count
  updateHowToApplyCharCount(): void {
    const howToApply = this.addScholarshipForm.get("how_to_apply")?.value;
    this.howToApplyCharCount = howToApply?.length || 0;
  }
  // Custom validator method
  cgpaValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    return value && (Number(value) > 4) ? { greaterThanFour: true } : null;
  }
  checkScholarshipFieldsValidity(): boolean {
    if (this.coursestags.length < 1 || this.requiredDocuments.length < 1 || this.coverageTags.length < 1) {
      return true;
    }
    return false;
  }
  addControls() {
    // Adding tag controls unconditionally
    if (!this.addScholarshipForm.contains('courses_name')) {
      this.addScholarshipForm.addControl('courses_name', new FormControl("", Validators.required));
    }

    if (!this.addScholarshipForm.contains('required_documents')) {
      this.addScholarshipForm.addControl('required_documents', new FormControl("", Validators.required));
    }

    if (!this.addScholarshipForm.contains('coverage_Tags')) {
      this.addScholarshipForm.addControl('coverage_Tags', new FormControl("", Validators.required));
    }

    // Update the form's validity
    this.addScholarshipForm.updateValueAndValidity();
  }

  onSubmit() {
    this.addScholarshipForm.markAllAsTouched();
    const tagsValidity = this.checkScholarshipFieldsValidity();
    this.ShowError();

    const description = this.addScholarshipForm.get("scholarship_description")?.value;

    if (
      this.Country === "" ||
      this.State === "" ||
      this.City === "" ||
      this.ScholarshipType === "" ||
      this.Duration === "" ||
      this.images.length < 1 ||
      tagsValidity ||
      description?.length < 1
    ) {
      const errorMessage = this.getFirstError();
      if (errorMessage) {
        this._toastr.error(errorMessage);
        this.addControls();
        return;
      }
      this.addControls();
    }
    else {
      const payload = this.createApiPayload();
      this.__apiCall
        .PostCallWithoutToken(payload, "Dashboard/SaveScholarship")
        .subscribe((response: any) => {
          this.addControls();
          if (response.responseCode == 200) {
            this._toastr.success(response.responseMessage);
            this.modal.dismissAll();
          } else {
            this._toastr.error(response.responseMessage);
          }
        })
    }


  }

  private getFirstError(): string {
    for (const key of Object.keys(this.addScholarshipForm.controls)) {
      const control = this.addScholarshipForm.get(key);
      if (control && control.invalid) {
        return `${this.getFriendlyFieldName(key)} is invalid.`;
      }
    }
  
    
    if (this.Country === "") return "Country is required.";
    if (this.State === "") return "State is required.";
    if (this.City === "") return "City is required.";
    if (this.ScholarshipType === "") return "Scholarship Type is required.";
    if (this.Duration === "") return "Duration is required.";
    if (this.images.length < 1) return "At least one image is required.";
    if (this.checkScholarshipFieldsValidity()) return "Tags are invalid.";
    
    const description = this.addScholarshipForm.get('scholarship_description')?.value;
    const howToApply = this.addScholarshipForm.get('how_to_apply')?.value;
  
    if ((description?.length ?? 0) < 400)
      return "Scholarship Description must be at least 400 characters long.";
    if ((howToApply?.length ?? 0) < 400)
      return "How to Apply section must be at least 400 characters long.";
  

    return "";
  }


  private getFriendlyFieldName(field: string): string {
    const fieldNames: { [key: string]: string } = {
      scholarship_name: "Scholarship Name",
      institute_name: "Institute Name",
      scholarship_address: "Scholarship Address",
      scholarship_addressTitle: "Scholarship Address Title",
      scholarship_addressLink: "Scholarship Google Maps Link",
      application_Link: "Application Link",
      email_address: "Email Address",
      scholarship_phone: "Scholarship Phone Number",
      start_date: "Start Date",
      end_date: "End Date",
      minimum_cgpa: "Minimum CGPA",
      duration: "Duration",
      scholarship_amount: "Scholarship Amount",
      scholarship_fee: "Scholarship Fee",
      courses_name: "Courses Name",
      required_documents: "Required Documents",
      coverage_Tags: "Coverage Tags",
      scholarship_description: "Scholarship Description",
      how_to_apply: "How to Apply",
      Country: "Country",
      State: "State",
      City: "City",
      ScholarshipType: "Scholarship Type",
      images: "Images",
      tags: "Tags",
    };
    

    return fieldNames[field] || field;
  }
  
  createApiPayload() {
    const payload = {
      scholarshipName: this.addScholarshipForm.get("scholarship_name")?.value,
      instituteName: this.addScholarshipForm.get("institute_name")?.value,
      address: this.addScholarshipForm.get("scholarship_address")?.value,
      locationTitle: this.addScholarshipForm.get("scholarship_addressTitle")?.value,
      locationSrc: this.addScholarshipForm.get("scholarship_addressLink")?.value,
      applicationLink: this.addScholarshipForm.get("application_Link")?.value,
      email: this.addScholarshipForm.get("email_address")?.value,
      contactNumber: this.addScholarshipForm.get("scholarship_phone")?.value,
      startDate: this.addScholarshipForm.get("start_date")?.value,
      endDate: this.addScholarshipForm.get("end_date")?.value,
      minimumCGPA: this.addScholarshipForm.get("minimum_cgpa")?.value,
      amount: this.addScholarshipForm.get("scholarship_amount")?.value,
      applicationFee: this.addScholarshipForm.get("scholarship_fee")?.value,
      description: this.addScholarshipForm.get("scholarship_description")?.value,
      howToApply: this.addScholarshipForm.get("how_to_apply")?.value,

      country: this.Country,
      state: this.State,
      city: this.City,
      duration: this.Duration,
      type: this.ScholarshipType,
      courses: this.coursestags,
      documents: this.requiredDocuments,
      coverages: this.coverageTags,
      images: this.images,
    };
    return payload;
  }

}
