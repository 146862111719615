import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from "@angular/core";
import {
  latestForRent,
  latestForRentData,
} from "../../../../shared/interface/property";
import { PropertyService } from "../../../../shared/services/property.service";
import { DataShareService } from "src/app/shared/services/data-share.service";
import { PropertyBoxGridService } from "src/app/shared/services/property-box-grid.service";

@Component({
  selector: "app-slider-filter-latest-rent",
  templateUrl: "./slider-filter-latest-rent.component.html",
  styleUrls: ["./slider-filter-latest-rent.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderFilterLatestRentComponent {

  private _dataSharing = inject(DataShareService);


  @Input() propertyClass: boolean;
  @Input() tagClass: string = "";
  @Input() total: number;
  @Input() title: string = "";
  @Input() listView: boolean = false;
  @Input() gridOption: boolean = true;
  @Input() isDescriptionShown: boolean = true;

  @Input() titleClass: string = "title-1";
  @Input() heading: string = "Latest For Sale";
  @Input() desc: string = "";
  @Input() showFilters: boolean = false;
  @Input() isTopTeachersModule: boolean = false;
  @Input() institutesData: any[] = [];
  @Output() filterButtonClick = new EventEmitter<string>(); // Event emitter for filter buttons

  public latestForRentData: latestForRent[] = [];
  public isOpenFilter: boolean = false;
  public isOpen: boolean = false;
  // public listView: boolean = false;
  public active: boolean = false;
  public listViewBox: boolean = false;
  public col_lg_6: boolean = false;
  public col_md_6: boolean = false;
  public col_lg_4: boolean = false;
  public col_xxl_3: boolean = false;
  public col_6: boolean = false;
  public col_xl_6: boolean = false;
  public col_md_12: boolean = false;
  public col_xl_12: boolean = false;
  public col_xl_4: boolean;
  public col_md_3: boolean;

  // @Input() agencyData: agencyAgent[];
  @Input() type: string;
  @Input() totalData: number;
  public noData: boolean = false;

  cards = [
    { title: 'Card 1', description: 'Description 1' },
  ];
  constructor(public propertyService: PropertyService,
    public propertyBoxGridService: PropertyBoxGridService) { }

  ngOnInit() {

  }




  // Method to emit filter event
  onFilterClick(type: string): void {
    this.filterButtonClick.emit(type);
  }

  getCardClasses() {
    if (this.propertyBoxGridService.listView) {
      return {
        'col-12': true, // Full width in list view
        'card-size': true
      };
    }
    else if (this.propertyBoxGridService.col_md_6) {
      return {
        'col-md-6': true, // 2 cards per row
        'card-size': true
      };
    }
    else if (this.propertyBoxGridService.col_xl_4) {
      return {
        'col-md-4': true, // 3 cards per row
        'card-size': true
      };
    }
    else if (this.propertyBoxGridService.col_lg_6) {
      return {
        'col-md-3': true, // 4 cards per row (override col-lg-6 if needed)
        'card-size': true
      };
    }
    else {
      // Default to 4 cards if none of the above conditions match
      return {
        'col-lg-3': true,
        'card-size': true
      };
    }
  }

}
