import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserCentralizedDataService {

  private userDetails: any = {};
  private educationDetails: any[] = [];
  private languageDetails: any[] = [];
  private experienceDetails: any[] = [];
  private skillsDetails: any[] = [];
  private awardsDetails: any[] = [];
  
  private userDetailsSubject = new BehaviorSubject<any>(this.getUserDetailsFromSession());
  public userDetails$ = this.userDetailsSubject.asObservable();

  constructor() { }

  // Centralized CV data
  getCompleteCvData(): any {
    return {
      userDetails: this.getUserDetails(),
      educationDetails: this.getEducationDetails(),
      languageDetails: this.getLanguageDetails(),
      experienceDetails: this.getExperienceDetails(),
      skillsDetails: this.getSkillsDetails(),
      awardsDetails: this.getAwardsDetails(),
      certificatesDetails: this.getCertificatesDetails(),
    };
  }


  // ----------------------------
  // User Details Management
  // ----------------------------

  private getUserDetailsFromSession(): any {
    const userDetailsString = sessionStorage.getItem('userDetails');
    return userDetailsString ? JSON.parse(userDetailsString) : {};
  }

  setUserDetails(details: any): void {
    sessionStorage.setItem('userDetails', JSON.stringify(details));
    this.userDetailsSubject.next(details); // Notify subscribers
  }

  getUserDetails(): any {
    return this.userDetailsSubject.value; // Reactive data
  }

  updateUserDetails(updatedDetails: any): void {
    this.setUserDetails(updatedDetails);
  }


  // ----------------------------
  // education Details Management
  // ----------------------------

  public getEducationDetails(): any[] {
    const storedDetails = sessionStorage.getItem('educationDetails');
    return storedDetails ? JSON.parse(storedDetails) : [];
  }

  public saveEducationDetails(education: any): void {
    let educationDetails = this.getEducationDetails();
    if (education.id === 0) {
      // Add new education record
      const newId = educationDetails.length > 0 ? Math.max(...educationDetails.map(item => item.id)) + 1 : 1;
      education.id = newId;
      educationDetails.push(education);
    } else {
      // Edit existing education record
      const index = educationDetails.findIndex(item => item.id === education.id);
      if (index !== -1) {
        educationDetails[index] = education;
      }
    }

    sessionStorage.setItem('educationDetails', JSON.stringify(educationDetails));
  }

  public deleteEducation(id: number): void {
    let educationDetails = this.getEducationDetails();
    const index = educationDetails.findIndex(item => item.id === id);
    if (index !== -1) {
      educationDetails.splice(index, 1);
    }

    sessionStorage.setItem('educationDetails', JSON.stringify(educationDetails));
  }

  public isDegreeExists(degreeTitle: string, editId?: number): boolean {
    const educationDetails = this.getEducationDetails();
    return educationDetails.some((item) => item.degreeTitle === degreeTitle && item.id !== editId);
  }

  // ----------------------------
  // language Details Management
  // ----------------------------



  public saveLanguageDetails(language: any): void {
    let languageDetails = this.getLanguageDetails();
    if (language.id === 0) {
      // Add new language record
      const newId = languageDetails.length > 0 ? Math.max(...languageDetails.map(item => item.id)) + 1 : 1;
      language.id = newId;
      languageDetails.push(language);
    } else {
      // Edit existing language record
      const index = languageDetails.findIndex(item => item.id === language.id);
      if (index !== -1) {
        languageDetails[index] = language;
      }
    }

    sessionStorage.setItem('languageDetails', JSON.stringify(languageDetails));
  }

  public getLanguageDetails(): any[] {
    const storedDetails = sessionStorage.getItem('languageDetails');
    return storedDetails ? JSON.parse(storedDetails) : [];
  }

  public deleteLanguage(id: number): void {
    let languageDetails = this.getLanguageDetails();
    const index = languageDetails.findIndex(item => item.id === id);
    if (index !== -1) {
      languageDetails.splice(index, 1);
    }

    sessionStorage.setItem('languageDetails', JSON.stringify(languageDetails));
  }

  public isLanguageExists(languageTitle: string, editId?: number): boolean {
    const languageDetails = this.getLanguageDetails();
    return languageDetails.some((item) => item.languageTitle === languageTitle && item.id !== editId);
  }

  // ----------------------------
  // experience Details Management
  // ----------------------------


  public getExperienceDetails(): any[] {
    const storedDetails = sessionStorage.getItem('experienceDetails');
    return storedDetails ? JSON.parse(storedDetails) : [];
  }

  public saveExperienceDetails(experience: any): void {
    let experienceDetails = this.getExperienceDetails();
    if (experience.id === 0) {
      // Add new experience record
      const newId = experienceDetails.length > 0 ? Math.max(...experienceDetails.map(item => item.id)) + 1 : 1;
      experience.id = newId;
      experienceDetails.push(experience);
    } else {
      // Edit existing experience record
      const index = experienceDetails.findIndex(item => item.id === experience.id);
      if (index !== -1) {
        experienceDetails[index] = experience;
      }
    }

    sessionStorage.setItem('experienceDetails', JSON.stringify(experienceDetails));
  }

  public deleteExperience(id: number): void {
    let experienceDetails = this.getExperienceDetails();
    const index = experienceDetails.findIndex(item => item.id === id);
    if (index !== -1) {
      experienceDetails.splice(index, 1);
    }

    sessionStorage.setItem('experienceDetails', JSON.stringify(experienceDetails));
  }

  public isExperienceExists(companyName: string, editId?: number): boolean {
    const experienceDetails = this.getExperienceDetails();
    return experienceDetails.some((item) => item.companyName === companyName && item.id !== editId);
  }
  // ----------------------------
  // skills Details Management
  // ----------------------------


  public saveSkillsDetails(skill: any): void {
    let skillsDetails = this.getSkillsDetails();
    if (skill.id === 0) {
      // Add new skill record
      const newId = skillsDetails.length > 0 ? Math.max(...skillsDetails.map(item => item.id)) + 1 : 1;
      skill.id = newId;
      skillsDetails.push(skill);
    } else {
      // Edit existing skill record
      const index = skillsDetails.findIndex(item => item.id === skill.id);
      if (index !== -1) {
        skillsDetails[index] = skill;
      }
    }

    sessionStorage.setItem('skillsDetails', JSON.stringify(skillsDetails));
  }

  public getSkillsDetails(): any[] {
    const storedDetails = sessionStorage.getItem('skillsDetails');
    return storedDetails ? JSON.parse(storedDetails) : [];
  }

  public deleteSkill(id: number): void {
    let skillsDetails = this.getSkillsDetails();
    const index = skillsDetails.findIndex(item => item.id === id);
    if (index !== -1) {
      skillsDetails.splice(index, 1);
    }

    sessionStorage.setItem('skillsDetails', JSON.stringify(skillsDetails));
  }

  public isSkillExists(skillTitle: string, editId?: number): boolean {
    const skillsDetails = this.getSkillsDetails();
    return skillsDetails.some((item) => item.skillTitle === skillTitle && item.id !== editId);
  }

  // ----------------------------
  // awards Details Management
  // ----------------------------


  public getAwardsDetails(): any[] {
    const storedDetails = sessionStorage.getItem('awardsDetails');
    return storedDetails ? JSON.parse(storedDetails) : [];
  }

  public saveAwardsDetails(award: any): void {
    let awardsDetails = this.getAwardsDetails();
    if (award.id === 0) {
      // Add new award record
      const newId = awardsDetails.length > 0 ? Math.max(...awardsDetails.map(item => item.id)) + 1 : 1;
      award.id = newId;
      awardsDetails.push(award);
    } else {
      // Edit existing award record
      const index = awardsDetails.findIndex(item => item.id === award.id);
      if (index !== -1) {
        awardsDetails[index] = award;
      }
    }

    sessionStorage.setItem('awardsDetails', JSON.stringify(awardsDetails));
  }

  public deleteAward(id: number): void {
    let awardsDetails = this.getAwardsDetails();
    const index = awardsDetails.findIndex(item => item.id === id);
    if (index !== -1) {
      awardsDetails.splice(index, 1);
    }

    sessionStorage.setItem('awardsDetails', JSON.stringify(awardsDetails));
  }

  public isAwardExists(awardTitle: string, editId?: number): boolean {
    const awardsDetails = this.getAwardsDetails();
    return awardsDetails.some((item) => item.awardTitle === awardTitle && item.id !== editId);
  }

  // ----------------------------
  // certificates Details Management
  // ----------------------------


  public getCertificatesDetails(): any[] {
    const storedDetails = sessionStorage.getItem('certificatesDetails');
    return storedDetails ? JSON.parse(storedDetails) : [];
  }

  public saveCertificatesDetails(certificate: any): void {
    let certificatesDetails = this.getCertificatesDetails();
    if (certificate.id === 0) {
      // Add new certificate record
      const newId = certificatesDetails.length > 0 ? Math.max(...certificatesDetails.map(item => item.id)) + 1 : 1;
      certificate.id = newId;
      certificatesDetails.push(certificate);
    } else {
      // Edit existing certificate record
      const index = certificatesDetails.findIndex(item => item.id === certificate.id);
      if (index !== -1) {
        certificatesDetails[index] = certificate;
      }
    }

    sessionStorage.setItem('certificatesDetails', JSON.stringify(certificatesDetails));
  }

  public deleteCertificate(id: number): void {
    let certificatesDetails = this.getCertificatesDetails();
    const index = certificatesDetails.findIndex(item => item.id === id);
    if (index !== -1) {
      certificatesDetails.splice(index, 1);
    }

    sessionStorage.setItem('certificatesDetails', JSON.stringify(certificatesDetails));
  }

  public isCertificateExists(certificateTitle: string, editId?: number): boolean {
    const certificatesDetails = this.getCertificatesDetails();
    return certificatesDetails.some((item) => item.certificateTitle === certificateTitle && item.id !== editId);
  }



}
