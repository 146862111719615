<section class="layout-home3 p-0">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-12 p-0">
                <div>
                    <div class="home-slider-3 arrow-image">
                        <app-home-section-slider [homeSectionSliderData]="homeSectionSliderData" [type]="'basic'"></app-home-section-slider>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
