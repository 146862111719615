import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import intlTelInput from 'intl-tel-input';
import { fa } from 'intl-tel-input/i18n';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { agency, baths, beds, category, propertyStatus, propertyType, rooms } from 'src/app/shared/data/advance-filter';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { UserCentralizedDataService } from 'src/app/shared/services/user-centralized-data.service';

interface DropdownItem {
  id: number;
  value: string;
}
@Component({
  selector: 'app-cv-profile-details',
  templateUrl: './cv-profile-details.component.html',
  styleUrl: './cv-profile-details.component.scss'
})
export class CvProfileDetailsComponent implements OnInit, OnDestroy {
  @ViewChild("fileInput") fileInput!: ElementRef;

  private destroy$ = new Subject<void>();
  private intlTelInputInstance: any;

  public isSpinnerShown = false;

  //VARIABLES
  userForm: FormGroup;
  Country: string = "";
  State: string = "";
  City: string = "";
  profileBase64: string = "";
  userPersonalData: any;
  imagePreview: string = "";
  contactNumber: string = '';
  email: string = "";

  //SERVICES
  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly _authService = inject(AuthService);
  private readonly commonUtilsService = inject(CommonUtilsServiceService);
  private readonly errorHandlerService = inject(ErrorHandlingService);
  private readonly _userCentralizedDataService = inject(UserCentralizedDataService);

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef, private renderer: Renderer2, private el: ElementRef) {
    this.userForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      phoneNumber: [
        "",
        [
          Validators.required,
          Validators.pattern("^((\\+91-?)|0)?[0-9]{10,18}$"),
          Validators.minLength(10),
          Validators.maxLength(18),
        ],
      ],
      dateOfBirth: ["", Validators.required],
      address: ["", Validators.required],
      // username: ['', Validators.required],
      // email: ['', [
      //   Validators.required, Validators.email, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
      // ]],
      description: ["", Validators.required],
    });

    // const userDetails = this._authService.getUserDetails();
    // this.profileBase64 = this._authService.generateFallbackImage(userDetails.firstName);
  }

  ngOnInit(): void {
    const inputElement = this.el.nativeElement.querySelector('#phoneNumber');
    if (inputElement) {
      this.intlTelInputInstance = intlTelInput(inputElement, {
        initialCountry: 'pk',
        separateDialCode: false,
        // utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
      });

      this.renderer.listen(inputElement, 'input', (event) => {
        this.formatPhoneNumber(event);
      });
    }


    this.getCountry();
    this.getPersonalDetails();

    // Auto-save implementation with validation check
    this.userForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.userForm.valid) {
        this.autoSavePersonalDetails();
      } else {
        // console.log("Personal Details Form contains errors. Auto-save skipped.");
      }
    });
  }



  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  formatPhoneNumber(event: any): void {
    let phoneNumber = event.target.value.replace(/\D/g, '');

    if (this.intlTelInputInstance) {
      const countryCode = this.intlTelInputInstance.getSelectedCountryData().dialCode;

      // Ensure the country code is added only once
      if (!phoneNumber.startsWith(countryCode)) {
        phoneNumber = `+${countryCode}${phoneNumber}`;
      }
    }

    event.target.value = phoneNumber;
    this.contactNumber = phoneNumber;

  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  // Function to get user details and populate the form
  private getPersonalDetails(): void {
    const userDetails = this._userCentralizedDataService.getUserDetails();
    if (userDetails) {
      this.userForm.patchValue({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        phoneNumber: userDetails.phoneNumber,
        dateOfBirth: userDetails.dateOfBirth?.substring(0, 10),
        address: userDetails.address,
        description: userDetails.description,
      });
      this.Country = userDetails.country;
      this.State = userDetails.state;
      this.City = userDetails.city;
      this.gender = userDetails.gender;
      this.email = userDetails.email;
      this.profileBase64 = userDetails.profileBase64 || '';
    }
  }

  // FUNCTION TO SAVE PERSONAL DETAILS IMAGE
  onFileSelect(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.profileBase64 = e.target.result;
        this.userForm.patchValue({ profileBase64: this.profileBase64 });

        // Save profile image to sessionStorage
        const userDetailsString = sessionStorage.getItem("userDetails");
        if (userDetailsString) {
          const userDetails = JSON.parse(userDetailsString);
          userDetails.profileBase64 = this.profileBase64;
          sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
        }

        // this._toaster.success('Profile Image Updated Successfully', 'Success');
        this.commonUtilsService.notifyUserDetailsUpdated();
      };
      reader.readAsDataURL(file);
    }
  }


  // Function to save user details
  private autoSavePersonalDetails(): void {
    const userDetails = {
      firstName: this.userForm.get("firstName")?.value,
      lastName: this.userForm.get("lastName")?.value,
      phoneNumber: this.userForm.get("phoneNumber")?.value,
      dateOfBirth: this.userForm.get("dateOfBirth")?.value,
      address: this.userForm.get("address")?.value,
      description: this.userForm.get("description")?.value,
      country: this.Country,
      state: this.State,
      city: this.City,
      gender: this.gender,
      profileBase64: this.profileBase64,
    };

    // Save data to sessionStorage or centralized service
    this._userCentralizedDataService.setUserDetails(userDetails);
    sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
    // console.log("Auto-saved userDetails:", userDetails);
  }


  // FUNCTION TO CREATE PAYLOAD
  createApiPayload() {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      const userDetails = JSON.parse(userDetailsString);
      return {
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        gender: userDetails.gender,
        phoneNumber: userDetails.phoneNumber,
        dateOfBirth: userDetails.dateOfBirth,
        address: userDetails.address,
        city: userDetails.city,
        state: userDetails.state,
        country: userDetails.country,
        description: userDetails.description,
        profileBase64: userDetails.profileBase64,
      };
    }
    return {}; // Return empty object if no details found
  }

  // FUNCTION TO CHECK DATA MATCH
  matchPayload() {
    const userDetailsString = sessionStorage.getItem("userDetails");
    const userDetails = userDetailsString ? JSON.parse(userDetailsString) : {};
    const newPayload = this.createApiPayload();

    // Match sessionStorage data with new form data
    return JSON.stringify(userDetails) === JSON.stringify(newPayload);
  }

  // DISPLAY ERROR MESSAGE OF COMMON DROPDOWNS
  genderError: boolean = false;
  countryTypeError: boolean = false;
  stateError: boolean = false;
  cityError: boolean = false;
  imageError: boolean = false;
  ShowError() {
    this.genderError = this.gender == "" ? true : false;
    this.countryTypeError = this.Country == "" ? true : false;
    this.stateError = this.State == "" ? true : false;
    this.cityError = this.City == "" ? true : false;
    this.imageError = this.profileBase64 == "" ? true : false;
  }


  // GENDER DROPDOWN DATA
  genderList = [
    { id: 1, value: "Male" },
    { id: 2, value: "Female" },
  ];
  gender: string = "";
  onGenderSelected(option: any) {
    this.gender = option.value;
  }

  // COUNTRY DROPDOWN DATA
  CountrysearchTerm: string = "";
  CountryList: DropdownItem[] = [];
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetCountryDropDown")
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      });
  }
  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = "";
      this.City = "";
    }
    this.Country = option.value;
    this.getStateList(option.id);
  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue;
    if (this.Country === "") {
      this.State = "";
      this.City = "";
    }
  }

  // STATE DROPDOWN DATA
  StatesearchTerm: string = "";
  StateList: DropdownItem[] = [];
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }
  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = "";
    }
    this.State = option.value;
    this.getCityList(option.id);
  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue;
    if (this.State === "") {
      this.City = "";
    }
  }

  getStateList(countryId: number) {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetStateDropDown?CountryId=" + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }

  // CITY DROPDOWN DATA
  CityList: DropdownItem[] = [];
  onCitySelected(option: any): void {
    this.City = option.value;
  }
  getCityList(stateId: number) {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetCityDropDown?StateId=" + stateId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      });
  }
}
