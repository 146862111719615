import { Component } from '@angular/core';

@Component({
  selector: 'app-home-section-property-box',
  templateUrl: './home-section-property-box.component.html',
  styleUrls: ['./home-section-property-box.component.scss']
})
export class HomeSectionPropertyBoxComponent {

}
