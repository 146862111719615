<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">add new card</h5>
    <button aria-label="Button" type="button" class="btn-close" (click)="modal.dismissAll()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="a-month">card type</label>
        <select id="a-month" class="form-control">
          <option selected>add new card...</option>
          <option>credit card</option>
          <option>debit card</option>
          <option>debit card with ATM pin</option>
        </select>
      </div>
      <div class="form-group">
        <label for="a-na">name on card</label>
        <input type="text" class="form-control" id="a-na">
      </div>
      <div class="form-group">
        <label for="a-n">card number</label>
        <input type="text" class="form-control" id="a-n">
      </div>
      <div class="row gx-3">
        <div class="form-group col-md-8">
          <label for="a-e">expiry date</label>
          <input type="text" class="form-control" id="a-e">
        </div>
        <div class="form-group col-md-4">
          <label for="a-c">cvv</label>
          <input type="password" maxlength="3" class="form-control" id="a-c">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button aria-label="Button" type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
    <button aria-label="Button" type="button" class="btn btn-gradient color-2 btn-pill" (click)="modal.dismissAll()">add card</button>
  </div>
</div>
