import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrl: './subject.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectComponent implements OnInit, OnDestroy {
  // private destroy$ = new Subject<void>();
  public isSpinnerShown: boolean = false
  public FormName: string = "Add";
  subjectForm: FormGroup;
  isLoading: boolean = true;
  subjectDeatils: any = [
    // {
    //   subject: "English",
    //   fee: 0,
    //   duration: "2 Hours"
    // },
    // {
    //   subject: "Hindi",
    //   fee: 0,
    //   duration: "2 Hours"
    // },
    // {
    //   subject: "Mathametics",
    //   fee: 0,
    //   duration: "2 Hours"
    // }
  ];
  delId: number;
  editId: number = 0;

  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);

  constructor(private fb: FormBuilder, public modal: NgbModal, private cdr: ChangeDetectorRef) {
    this.subjectForm = this.fb.group({
      fee: ['', [Validators.required, Validators.min(0)]]
    });
  }

  ngOnInit(): void {
    this.getSubjectList();
    this.getSubjectDetails();
  }

  ngOnDestroy(): void {
    // this.destroy$.next();
    // this.destroy$.complete();
  }



  addCard(content: TemplateRef<any>) {
    this.subjectForm.reset();
    this.subjectError = false;
    this.durationError = false;
    this.subject = '';
    this.timeSlot = '';
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
  }
  editCard(content: TemplateRef<any>, tag: any) {
    this.subjectForm.reset();
    this.subjectError = false;
    this.durationError = false;
    this.subject = '';
    this.timeSlot = '';
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.editId = tag?.id;
    this.subjectForm.get("fee")?.setValue(tag?.fee);
    this.timeSlot = tag?.duration;
    this.subject = tag?.subject;

  }
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }

  checksubjectExsists(): boolean {
    const payload = this.createApiPayload();
    if (this.subjectDeatils?.length > 0) {
      if (this.FormName == "Add") {
        const isExsist = this.subjectDeatils.some((tag: any) => tag.subject == payload.subject);
        if (isExsist) {
          this._toaster.error("This Subject Already Exist");
          return true;
        }
      } else {
        let newSubject = this.subjectDeatils;
        newSubject = newSubject.filter((item: any) => item.id != this.editId);
        const isExsist = newSubject.some((tag: any) => tag.subject == payload.subject && tag.id != this.editId);
        if (isExsist) {
          this._toaster.error("This Subject Already Exist");
          return true;
        }
      }

      return false;
    }
    return false;
  }
  public saveCourseDetails(): void {
    this.subjectForm.markAllAsTouched();
    this.ShowError();

    const isAlreadyExists = this.checksubjectExsists();
    if (isAlreadyExists) {
        return;
    }

    if (this.subjectForm.invalid || this.subject === "" || this.timeSlot === "") {
        this._toaster.error("Invalid Form");
        return;
    }

    const payload = this.createApiPayload();
    this.isSpinnerShown = true;

    this._apiCall.PostCallWithToken(payload, 'Teacher/SaveTeacherSubject')
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this._toaster.success(response.responseMessage);
            this.subjectForm.reset();
            this.editId = 0;
            this.subject = "";
            this.timeSlot = "";
            this.modal.dismissAll();
            this.getSubjectDetails();
          } else {
            this.errorHandlingService.handleResponseError(response);
          }
          this.isSpinnerShown = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
}

  createApiPayload() {
    this.editId = this.FormName == "Add" ? 0 : this.editId;
    return {
      id: this.editId,
      subject: this.subject,
      duration: this.timeSlot,
      fee: this.subjectForm.get('fee')?.value
    };
  }



  public deleteSubject(): void {
    this.isSpinnerShown = true;

    this._apiCall.PostCallWithToken(null, `Teacher/DeleteTeacherSubject?SubjectId=${this.delId}`)
      .subscribe(
        (res) => {
          if (res.responseCode === 200) {
            this._toaster.success(res.responseMessage);
            this.getSubjectDetails();
            this.modal.dismissAll();
          } else {
            this._toaster.error(res.responseMessage);
          }
          this.isSpinnerShown = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
}


  // FUNCTION TO GET EDUCATION DETAILS

  public getSubjectDetails(): void {
    this.isLoading = true;

    this._apiCall.GetCallWithToken("Teacher/GetTeacherSubject")
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this.subjectDeatils = response.data;
          } else {
            this.subjectDeatils = [];
            this.errorHandlingService.handleResponseError(response);
          }
          this.isLoading = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isLoading = false;
        }
      );
}

  /////////////////////////////////////////////////
  /////////////errors of dropdowns //////////////////
  ///////////////////////////////////////////////////
  subjectError: boolean = false;
  durationError: boolean = false;

  ShowError() {
    this.subjectError = this.subject == "" ? true : false;
    this.durationError = this.timeSlot == "" ? true : false;
  }


  ///////////////////////////////////////////////////////////////
  //////////  dropdown data /////////////// /////////
  ////////////////////////////////////////////////////////////

  ////  1

  subject: string = '';

  onSubjectSelected(option: any) {
    this.subject = option.value;;
  }

  onSubjectValueChange(searchValue: any) {
    this.subject = searchValue
  }




  subjectList = [];

  getSubjectList() {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetSubjectDropDown")
      .subscribe((response) => {
        this.subjectList = response?.data;
      });
  }

  /////  2

  timeSlot: string = '';

  onTimeSelcted(option: any) {
    this.timeSlot = option.value;;
  }



  timeList = [
    { value: 'Hourly' },
    { value: 'Weekly' },
    { value: 'Monthly' }
  ];

}
