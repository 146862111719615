import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-listing',
  templateUrl: './my-listing.component.html',
  styleUrls: ['./my-listing.component.scss'],
})
export class MyListingComponent implements OnInit {
  
  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Dashboard';
  public parent = 'Home';
  public child = 'My Services';

  public theme_default3 = '#ff5c41';
  public theme_default4 = '#ff8c41';

  serviceCardsData: any[] = []; // Initialize with an empty array

  ngOnInit() {
    document.documentElement.style.setProperty('--theme-default', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default3', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default4', this.theme_default4);

    this.loadDefaultData();
  }

  ngOnDestroy(): void {
    document.documentElement.style.removeProperty('--theme-default');
    document.documentElement.style.removeProperty('--theme-default3');
    document.documentElement.style.removeProperty('--theme-default4');
  }

  loadDefaultData() {
    // Load default or initial data if needed
    this.serviceCardsData = this.educationData;
  }

  // Method to handle tab changes
  onTabChange(tab: string) {
    
    switch (tab) {
      case 'education':
        this.serviceCardsData = this.educationData;
        break;
      case 'experience':
        this.serviceCardsData = this.experienceData;
        break;
      case 'subjects':
        this.serviceCardsData = this.subjectsData;
        break;
      case 'certificates':
        this.serviceCardsData = this.certificatesData;
        break;
      case 'skills':
        this.serviceCardsData = this.skillsData;
        break;
      case 'links':
        this.serviceCardsData = [this.socialLinksData];
        break;
      default:
        this.serviceCardsData = this.educationData;
        break;
    }
  }
  
  // Hardcoded data for each category
  educationData = [
    {
      degreeTitle: 'Bachelor of Science in Computer Science',
      institute: 'XYZ University',
      startDate: '2020-01-01',
      endDate: '2024-01-01',
      cgpa: 3.8,
      total: 120,
      obtained: 115,
    },
    {
      degreeTitle: 'High School Diploma',
      institute: 'ABC High School',
      startDate: '2016-01-01',
      endDate: '2020-01-01',
      cgpa: 3.7,
      total: 80,
      obtained: 76,
    },
  ];

  experienceData = [
    {
      role: 'Software Engineer',
      company: 'ABC Corp',
      startDate: '2021-01-01',
      endDate: '2024-01-01',
      description: 'Worked on various projects involving full-stack development.',
    },
    {
      role: 'Junior Developer',
      company: 'XYZ Ltd',
      startDate: '2019-06-01',
      endDate: '2021-01-01',
      description: 'Assisted in developing web applications and maintaining codebase.',
    },
  ];

  subjectsData = [
    {
      subject: 'Mathematics',
      duration: '6 months',
      fee: 300,
    },
    {
      subject: 'Physics',
      duration: '6 months',
      fee: 250,
    },
  ];

  certificatesData = [
    {
      title: 'Certified Java Developer',
      institute: 'Oracle',
      experience: 'Certified in Java SE 8 Programmer',
    },
    {
      title: 'AWS Certified Solutions Architect',
      institute: 'Amazon',
      experience: 'Associate Level Certification in AWS',
    },
  ];

  skillsData = [
    {
      skillTitle: 'JavaScript',
      percentage: 85,
    },
    {
      skillTitle: 'Python',
      percentage: 90,
    },
  ];

  socialLinksData = {
    facebook: 'https://facebook.com/johndoe',
    twitter: 'https://twitter.com/johndoe',
    linkedin: 'https://linkedin.com/in/johndoe',
    instagram: 'https://instagram.com/johndoe',
  };

  
}
