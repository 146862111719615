/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";

// Disable console.log in production mode
// if (window) {
//   window.console.log = function () {};
//   window.console.warn = function () {};
//   window.console.error = function () {};
//   window.console.info = function () {};
// }


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
