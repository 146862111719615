<div class="custom-dropdown2">

    <div (click)="toggleDropdown()">
      <input type="text" class="selected-option"  [value]="selectedOption || searchTerm || ''"
      placeholder="{{ clearThevalue || defaultText || selectedOption}}"
     (input)="filterOptions($event)"
     [readonly]="isDisabled"
     [ngClass]="{'empty': selectedOption === '', 'highlight': selectedOption !== ''}"
     [ngClass]="{'empty': clearThevalue === '', 'highlight': clearThevalue !== ''}"
     [(ngModel)]="clearThevalue"
     > 
      <!-- <i class="fa-solid fa-chevron-down dropdownIcon"></i> -->
      <app-feather-icons class="dropdownIcon" [icon]="'chevron-down'"></app-feather-icons>
    </div>
    @if (isOpen) {
        <div class="options">
            @for ( option of getDisplayList() ; track option) {
                <div class="singleOption" (click)="selectOption(option)">
                  {{ option.value }}
                </div>              
            }
        </div>     
    }
    </div>