<!-- <app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb> -->
<div class="mt-90" >
   <app-title [isLabelAdded]="true" [isLineAdded]="true" [tag]="tag" [titleClass]="'title-1'" [heading]="'Contact Us'" [type]="'simple'"></app-title>
</div>
<!-- <section class="small-section get-in-touch"> -->
   @if (isOnContactUsPage == true) {
      <div class="container">
         <div class="row">
            <div class="col-lg-6 contact-img">
               <img src="../../../../assets/images/contactus.png" class="img-fluid" alt="Contact-Us">
            </div>
            <div class="col-lg-6">
               <div class="log-in">
                  <app-contact-us-form></app-contact-us-form>
               </div>
            </div>
         </div>
      </div>
   } @else if (isOnContactUsPage == false) {
      <section class="small-section get-in-touch">
         <div class="container">
            <div class="row">
               <div class="col-lg-6 contact-img">
                  <img src="../../../../assets/images/contactus.png" class="img-fluid" alt="Contact-Us">
               </div>
               <div class="col-lg-6">
                  <div class="log-in">
                     <app-contact-us-form></app-contact-us-form>
                  </div>
               </div>
            </div>
         </div>
      </section>
   }

<!-- <section class="small-section contact_section pt-0 contact_bottom">
  <div class="container">
    <div class="row">
      @for(data of contactDetailsData; track data){
        <div class="col-lg-4 col-sm-6">
          <app-contact-details [contactDetailsData]="data"></app-contact-details>
        </div>
      }
    </div>
  </div>
</section> -->