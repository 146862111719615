<section class="service-section service-bg">
  <div class="container">
    <app-title [titleClass]="'title-3 text-start'" [heading]="'Property Services'" [desc]="desc" [type]="'basic'"></app-title>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="service-slider arrow-gradient arrow-right">
          <app-provided-services [providedServicesData]="providedServices" [type]="'classic'"></app-provided-services>
        </div>
      </div>
    </div>
  </div>
</section>
