<div class="page-section ratio_40" id="video">
  <h4 class="content-title">Video</h4>
  <div class="play-bg-image">
    <div class="bg-size">
      <iframe 
        [src]="vedioUrl" 
        title="YouTube video player" 
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
    </div>
    <div class="icon-video" *ngIf="!isPlaying">
      <a href="javascript:void(0)" (click)="playVideo()" aria-label="clickable-text">
        <i class="fas fa-play"></i>
      </a>
    </div>
  </div>
</div>
<!-- <div class="page-section ratio_40" id="video">
  <h4 class="content-title head">Video</h4>
  <div class="play-bg-image">
      <div class="bg-size">
          <iframe [src]="getInstituteData" title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
      </div>
      <div class="icon-video" *ngIf="!isPlaying">
          <a href="javascript:void(0)" class="a" (click)="playVideo()">
            <i class="fas fa-play"></i>
          </a>
      </div>
  </div>
</div> -->
