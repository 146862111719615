
<!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="var(--mainColor)" type="ball-spin-clockwise"
    [fullScreen]="true">
    <p style="font: size 20px; color: var(--mainColor)">Loading...</p>
</ngx-spinner> -->
<app-loader-two [isLoading]="isLoading"></app-loader-two>
<app-header-one></app-header-one>
@if(jobsData){
<app-sticky-tab [instituteData]="jobsData"></app-sticky-tab>

<!-- @if(relatedJobsData.length !== 0){ -->
    <app-related-property 
    [type]="'grid-3'" 
    [heading]="heading" 
    [totalData]="3" 
    [instituteData]="relatedJobsData"
    [isDescriptionShown]="true"
    [isSkeletonShown]="isRelatedJobsLoading"
    >
    </app-related-property>
    <!-- } -->
}

<app-adly-footer></app-adly-footer>
