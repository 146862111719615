export interface Founder {
    id: number;
    img: string;
    aboutImg: string;
    name: string;
    title: string;
    email: string;
    contactNumber: string;
    address: string;
    desc: string;
    experience: number;
    projects: number;
    companyExperience: number;
}

export const founderData: Founder[] = [

    {
        id: 1,
        img: "assets/images/portfolio/arslan-bg.png",
        aboutImg: "assets/images/portfolio/arslan.jpeg",
        name: "Arslan Yousaf",
        title: "Owner & Founder",
        email: "ia.atgsystems@gmail.com",
        desc: "Hi! I’m Arslan Yousaf, the Owner & Founder of our innovative software company based in Pakistan. I have a passion for creating impactful web applications that not only serve clients but also enhance user experiences. With over 6 years of experience in the industry, I've successfully led numerous projects, transforming ideas into functional products. My journey has been fueled by a constant desire to learn and adapt to new technologies. I thrive in collaborative environments, working closely with talented teams to tackle challenges and achieve our goals. Together, we prioritize effective communication, source control, and meticulous project management to deliver excellence.",
        experience: 6,
        projects:20,
        companyExperience: 1,
        contactNumber: "03236987643",
        address: "Lahore Pakistan",
      },
      {
        id: 2,
        img: "assets/images/portfolio/ateeq-bg.png",
        aboutImg: "assets/images/portfolio/ateeq.jpeg",
        name: "Atiq Ur Rehman",
        title: "Owner & Founder",
        email: "ia.atgsystems@gmail.com",
        desc: "Hello! I’m Atiq Ur Rehman, the Owner & Founder of our dynamic software firm. With 7 years of hands-on experience, I focus on guiding our team to manage daily operations efficiently while fostering a culture of productivity and innovation. My role involves not just overseeing projects but also mentoring our talented employees, ensuring that everyone is aligned with our vision. I take pride in my ability to adapt to changing circumstances and always strive to improve processes. I believe in the power of teamwork, regular stand-ups, and open communication to achieve our collective goals, and I'm dedicated to delivering exceptional results for our clients.",
        experience: 7,
        projects:23,
        companyExperience: 1,
        contactNumber: "03236987643",
        address: "Islambad Pakistan",
      },

]
