<!-- <app-loader-two [isLoading]="isDataLoading"></app-loader-two> -->
<app-header-one></app-header-one>

<section class="user-dashboard small-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="sidebar-user sticky-cls">
                    <div class="user-profile">
                        <app-user-info></app-user-info>
                    </div>
                    <div class="dashboard-list">
                        <app-user-panel-side-menu [activeTab]="'test-summary'"></app-user-panel-side-menu>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-content">
                    <div class="table-container">
                        <div class="table-header">
                            <h2>Attempted Tests</h2>
                        </div>
                        @if (isDataLoading) {<div>
                            <ngx-skeleton-loader count="10" appearance="circle" [theme]="{
                             width: '100%',
                             height: '3rem',
                             'border-radius': '10px'
                           }" />
                        </div>
                        }@else {
                            <div class="table-responsive">
                                <table class="test-table">
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>Test</th>
                                            <th>Level</th>
                                            <th>Description</th>
                                            <th>Status</th>
                                            <th>Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        @for (test of testSummary; track $index) {
                                        <tr>
                                            <td>{{ $index + 1 }}</td>
                                            <td>{{ test.title }} </td>
                                            <td>{{ test.difficultyType }} </td>
                                            <td>{{ test.testDescription.length > 30 ? test.testDescription.substring(0, 30) + '...' : test.testDescription }}</td>
                                            <td>
                                                <span class="label" [ngClass]="test.isPassed ? 'label-success' : 'label-danger'">
                                                    {{ test.isPassed ? 'Pass' : 'Fail' }}
                                                </span>
                                            </td>
                                            <td>
                                                <button class="btn btn-light" (click)="toggleDetails( $index)">
                                                    <i class="fas fa-bars"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr *ngIf="expandedRow === $index" class="details-row">
                                            <td colspan="7">
                                                <div class="details-content">
                                                    <table class="table table-borderless">
                                                        <thead>
                                                            <tr>
                                                                <th>Test</th>
                                                                <th>Date</th>
                                                                <th>Status</th>
                                                                <th>Attempts</th>
                                                                <th>Obtained Marks</th>
                                                                <th>Total Marks</th>
                                                                <th>Difficulty Level</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{{ test.title }}</td>
                                                                <td>{{ test.startTime | date: 'yyyy-MM-dd' }}</td>
                                                                <td>
                                                                    <span class="label" [ngClass]="test.isPassed ? 'label-success' : 'label-danger'">
                                                                        {{ test.isPassed ? 'Pass' : 'Fail' }}
                                                                    </span>
                                                                </td>
                                                                <td class="text-center">{{ test.attempts }}</td>
                                                                <td class="text-center">{{ test.obtainedMarks }}</td>
                                                                <td class="text-center">{{ test.totalMarks }}</td>
                                                                <td class="text-center">{{ test.difficultyType }}</td>
                                                            </tr>
                                                            <tr *ngIf="!test.isPassed">
                                                                <td colspan="7" class="text-end retry-label"> 
                                                                    <span class="label label-shadow  btn-sm" (click)="startTest(test)">Try Again</span>
                                                                </td>
                                                            </tr>
                                                          
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
        
                                        }
        
                                    </tbody>
                                </table>
                            </div>

                      
                        }
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>

<app-adly-footer></app-adly-footer>