<!-- Loader component, jo sirf tab dikhega jab isLoading true hoga -->
<app-loader-two *ngIf="isLoading" [isLoading]="isLoading"></app-loader-two>

<!-- Actual content, jo sirf tab dikhega jab isLoading false ho -->
<ng-container *ngIf="!isLoading">
<app-header-one></app-header-one>
<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="user-dashboard small-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="sidebar-user sticky-cls">
                    <div class="user-profile">
                        <app-user-info></app-user-info>
                    </div>
                    <div class="dashboard-list">
                        <app-user-panel-side-menu [activeTab]="'add-cv'"></app-user-panel-side-menu>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-content">
                    <div class="payment-user" id="payment">
                        <div class="common-card">
                            <!-- <div class="common-header">
                                <h5>CV</h5>
                            </div> -->
                            <div class="row card-payment">

                                <div class="col-lg-12">

                                    <div class="description-section">
                                        <div class="description-details">
                                            <div class="desc-box">
                                                <div *ngIf="cvId === 1">
                                                    @if(userData){
                                                    <app-cv1 [userData]="userData"></app-cv1>
                                                    }
                                                </div>
                                                <div *ngIf="cvId === 2">
                                                    @if(userData){

                                                    <app-cv2 [userData]="userData"></app-cv2>
                                                    }
                                                </div>
                                                <div *ngIf="cvId === 3">
                                                    @if(userData){
                                                    <app-cv3 [userData]="userData"></app-cv3>
                                                    }
                                                </div>
                                                <div *ngIf="cvId === 4">
                                                    @if(userData){

                                                    <app-cv4 [userData]="userData"></app-cv4>
                                                    }
                                                </div>
                                                <div *ngIf="cvId === 5">
                                                    @if(userData){

                                                    <app-cv5 [userData]="userData"></app-cv5>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-adly-footer></app-adly-footer>
</ng-container>