<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="agent-section property-section">
  <div class="container">
    <div class="row ratio2_3 agent-section property-section ">
      <div class="col-xl-9 col-lg-8 property-grid-3 agent-grids">
        <app-grid-panel [agency]="true" [isList]="true"></app-grid-panel>
        <div class="property-wrapper-grid list-view">
            <app-common-agency [agencyData]="agentsData" [type]="'list'" [totalData]="9"></app-common-agency>
        </div>
      </div>
      @if(propertyData){
        <div class="col-xl-3 col-lg-4">
          <app-advance-filter [contact]="false" [filter]="false" [mortgage]="false" [propertyFilterSliderData]="propertyData.propertyFilterSlider"></app-advance-filter>
        </div>
      }
    </div>
  </div>
</section>

