import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { propertyBrief } from '../../../../shared/interface/property';

@Component({
  selector: 'app-property-brief',
  templateUrl: './property-brief.component.html',
  styleUrls: ['./property-brief.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyBriefComponent implements OnInit {

  @Input() propertyBriefData: propertyBrief[] = [];
  @Input() instituteData: any;
  @Input() heading: string = "Description";

  public description: string = "";
  public items: string[] = [];
  public truncatedItems: string[] = [];
  public isContentToggled: boolean[] = [];
  public limit: number = 120; // Updated limit for 120 words
  public showToggleButton: boolean = false; // Flag to show/hide buttons

  ngOnInit() {
    this.description = this.instituteData;
    this.items = [this.description];
    this.truncatedItems = this.items.map(item => this.formatContent(item));
    this.isContentToggled = new Array(this.items.length).fill(false);

    // Check if description exceeds 120 words
    this.showToggleButton = this.countWords(this.description) > 120;
  }

  toggleContent(index: number, event: Event) {
    event.preventDefault();
    this.isContentToggled[index] = !this.isContentToggled[index];
  }

  formatContent(content: string) {
    const words = content.split(' ');
    if (words.length > this.limit) {
      return words.slice(0, this.limit).join(' ') + '...';
    }
    return content;
  }

  countWords(content: string): number {
    return content ? content.split(/\s+/).length : 0;
  }
}
