import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clamp',
})
export class ClampPipe implements PipeTransform {

  transform(value: string, lineLimit: number = 3): string {
    if (!value) return '';
    
    const maxCharsPerLine = 40;
    const maxChars = lineLimit * maxCharsPerLine;

    if (value.length > maxChars) {
      return value.substring(0, maxChars) + '...';
    } else {
      return value;
    }
  }

}
