import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-admission-details',
  templateUrl: './admission-details.component.html',
  styleUrl: './admission-details.component.scss',
  //  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdmissionDetailsComponent {
  private routeSub: Subscription;
  public isLoading = true;
  public isRelatedAdmissionLoading = true;
  relatedAdmissionData: any[] = [];
  private _utilsService = inject(CommonUtilsServiceService);
  @Input() heading = "Related Admissions";

  
  admissionid: any;
  admissionData: any;
  private _apiCall = inject(ApiCallService);
  private _propertyService = inject(PropertyService);
  private readonly _spinner = inject(NgxSpinnerService);


  constructor(private route: ActivatedRoute) { }
  
  
  ngOnInit(): void {

    this.admissionid = this.route.snapshot.queryParamMap.get('admissionid');

    this.route.queryParamMap.subscribe((params) => {
      this.admissionid = params.get('admissionid');
      if (this.admissionid) {
        this.getAdmissionDetails();
      }
      window.scrollTo(0, 0);
    });
  }



  getAdmissionDetails() {
    this.admissionid = this.route.snapshot.queryParamMap.get('admissionid');
    this._spinner.show();

    this._apiCall.PostCallWithoutToken(null, `Admission/GetAdmissionById?AdmissionId=${this.admissionid}`).subscribe(
      (response) => {
        this.isLoading = false;

        // Initialize the admission data with necessary fields
        this.admissionData = {
          moduleType: "admission",
          images: response.data.admissionImages.map((image: any) => ({
            id: image.id,
            imageBase64: image.imageBase64
          })),
          instituteName: response.data?.instituteName,
          country: response.data?.country,
          state: response.data?.state,
          city: response.data?.city,
          address: response.data?.address,
          startDate: response.data?.startDate,
          endDate: response.data?.endDate,
          description: response.data?.description,
          departments: response.data?.admissionDepartments.map((department: any) => ({
            id: department.id,
            departmentName: department.departmentName
          })),
          courses: response.data?.admissionCourses.map((course: any) => ({
            id: course.id,
            courseName: course.courseName,
          })),
          skillVMs: response.data?.admissionDocuments.map((document: any) => ({
            id: document.id,
            skillTitle: document.documentName
          })),
          classes: response.data?.admissionClasses.map((singleClass: any) => ({
            id: singleClass.id,
            classTitle: singleClass.className
          })),
          locationSrc: response.data?.locationSrc,
          locationTitle: response.data?.locationTitle,
          email: response.data?.websiteLink,
          jobType: response.data?.testType,
          contactNumber: response.data?.contactNumber,
          responsibilitiesDescription: response.data?.responsibilitiesDescription,
          additionalDescription: response.data?.additionalDescription,
          schlorships: response.data?.admissionScholarships,
          websiteLink: response.data?.websiteLink,
          salary: response.data?.applicationFee,
        };

        // Initialize Blob URL array
        this.admissionData.blobUrls = [];

        // Convert each image to Blob URL if `admissionImages` exists
        if (Array.isArray(response.data.admissionImages)) {
          response.data.admissionImages.forEach((image: { imageBase64: string }) => {
            if (image.imageBase64 && image.imageBase64.includes('base64,')) {
              const base64Data = image.imageBase64.split('base64,')[1];
              const blobUrl = this._utilsService.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
              this.admissionData.blobUrls.push(blobUrl);
            } else {
              this.admissionData.blobUrls.push('path/to/default/image.png'); // Fallback URL
            }
          });
        }
        this.isLoading = false;
        // this._spinner.hide();
        this.getRelatedAdmissions();
      },
      (error) => {
        this._spinner.hide();
      }
    );
  }


  // Get Related Admissions 
  getRelatedAdmissions() {
    this.isRelatedAdmissionLoading = true;
    this._propertyService.getRelatedAdmissions(this.admissionid).subscribe(
      (response) => {
        this.relatedAdmissionData = response;
        this.isRelatedAdmissionLoading = false;

        // Convert each related admission's image URL to a Blob URL
        this.relatedAdmissionData.forEach((admission) => {
          if (admission && admission.img && admission.img.url) {
            if (admission.img.url.includes('base64,')) {
              const base64Data = admission.img.url.split('base64,')[1];
              admission.blobUrl = this._utilsService.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            } else {
              admission.blobUrl = 'path/to/default/image.png';
            }
          } else {
            admission.blobUrl = 'path/to/default/image.png';
          }
        });
      },
      (error) => {
        this.isRelatedAdmissionLoading = false;
      }
    );
  }



}
