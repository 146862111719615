import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { detailsProperty, img } from '../../../../shared/interface/property';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent {

  @Input() galleryImagesData: detailsProperty[];
  @Input() requestForm: boolean = false;
  @Input() instituteData:any;

  public selectedImage: string;

  public Options = {
    items: 1,
    loop: true,
    nav: false,
    dots: false,
    autoplay:true,
    autoplayTimeout:2000,
    autoplayHoverPause:true,
  };

  public thumbnailCarouselOptions = {
    items: 5,
    margin: 10,
    center: true,
    loop: true,
    nav: false,
    dots: false,
  };

  changeImage(image: string) {
    this.selectedImage = image;
  }
}
