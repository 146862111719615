import { ChangeDetectionStrategy, Component, inject, input, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { agencyAgent } from 'src/app/shared/interface/property';
import { ApiCallService } from 'src/app/shared/services/api-call.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactUsComponent {
  @Input() banner: boolean = true;
  @Input() location: boolean = true;
  @Input() titleClass: string = 'title-1';
  @Input() type: string = 'simple';
  @Input() heading: string = 'Location';
  @Input() desc: string = '';
  @Input() isOnContactUsPage: boolean = true;

  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Contact Us';
  public parent = 'Home';
  public child = 'Contact Us';
  public tag = 'Our Location';
  public tag1 = 'Contact';
  private _toaster=inject(ToastrService);
  contactUsForm: FormGroup;
  private _apicall=inject(ApiCallService);
  constructor(
    private fb: FormBuilder
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required]],
      mobnumber: ['', [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10,18}$")
      ]],
      email: ['', [
        Validators.required, Validators.email, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
      ]],
      message: ['', [Validators.required]],
    })
  }

  ngOnInit(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onSubmit() {
    
    if (this.contactUsForm.invalid) {
      this.contactUsForm.markAllAsTouched();
      this._toaster.error('Invalid form data');
      return;
    }
    else{
        this._apicall.PostCallWithoutToken(this.createApiPayload(),'AdmissionLeloVisitor/ContactUs').subscribe((res)=>{
          if(res.responseCode==200)
          {
            this._toaster.success(res.responseMessage);
            this.contactUsForm.reset();
          }
          else
          {
            this._toaster.error(res.responseMessage);
            this.contactUsForm.reset();
          }
        })
    }
  }
  createApiPayload(){
    return {
      name:this.contactUsForm.get('name')?.value,
      email:this.contactUsForm.get('email')?.value,
      phoneNumber:this.contactUsForm.get('mobnumber')?.value,
      message:this.contactUsForm.get('message')?.value
    }
  }
}
