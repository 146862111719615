<div class="filter-panel">
  <div class="top-panel tab-icon">
    <div class="filters respon-filter-content filter-button-group">
      <ul>
        <li (click)="onTabClick('ecucation')" [ngClass]="{ 'active' : activeTab == 'ecucation' }">
          <!-- <lord-icon [src]="'assets/icon-json/home.json'" colors="primary:#121331,secondary:#121331">
          </lord-icon> -->
          <img [src]="'assets/images/icon/graduation-cap.png'" alt="education " class="icon">
          <span>Education</span>
        </li>
        <li (click)="onTabClick('experience')" [ngClass]="{ 'active' : activeTab == 'experience' }">
          <!-- <lord-icon [src]="'assets/icon-json/sale.json'" colors="primary:#121331,secondary:#121331">
          </lord-icon> -->
          <img [src]="'assets/images/icon/experience.png'" alt="experience " class="icon">
          <span>Experience</span>
        </li>
        <li (click)="onTabClick('subjects')" [ngClass]="{ 'active' : activeTab == 'subjects' }">
          <!-- <lord-icon [src]="'assets/icon-json/map.json'" colors="primary:#121331,secondary:#121331">
          </lord-icon> -->
          <img [src]="'assets/images/icon/book.png'" alt="subjects " class="icon">
          <span>Subjects</span>
        </li>
        <li (click)="onTabClick('skills')" [ngClass]="{ 'active' : activeTab == 'skills' }">
          <!-- <lord-icon [src]="'assets/icon-json/file.json'" colors="primary:#121331,secondary:#121331">
          </lord-icon> -->
          <img [src]="'assets/images/icon/skill-development.png'" alt="skills " class="icon">
          <span>Skills</span>
        </li>
        <li (click)="onTabClick('certificates')" [ngClass]="{ 'active' : activeTab == 'certificates' }">
          <!-- <lord-icon [src]="'assets/icon-json/file.json'" colors="primary:#121331,secondary:#121331">
          </lord-icon> -->
          <img [src]="'assets/images/icon/certificate.png'" alt="certificate " class="icon">
          <span>Certificates</span>
        </li>
        <li (click)="onTabClick('links')" [ngClass]="{ 'active' : activeTab == 'links' }">
          <!-- <lord-icon [src]="'assets/icon-json/file.json'" colors="primary:#121331,secondary:#121331">
          </lord-icon> -->
          <img [src]="'assets/images/icon/chain.png'" alt="social links " class="icon">
          <span>Social Links</span>
        </li>
      </ul>
    </div>
  </div>
</div>
