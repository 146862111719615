<section class="banner-section banner-4 new-property parallax-image">
  <div class="container">
    <div class="row ratio_landscape feature-section">
      <div class="col">
        <app-title [titleClass]="'title-2'" [heading]="'Property of the day'" [desc]="desc" [type]="'basic'" [textWhite]="true"></app-title>
        <div class="feature-wrap">
            <app-property-of-day [propertyOfDay]="propertyOfDay" ></app-property-of-day>
        </div>
      </div>
    </div>
  </div>
</section>
