import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LibraryRoutingModule } from './library-routing.module';
import { TitleComponent } from './components/title/title.component';
import { BooksComponent } from './components/books/books.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BooksCategoriesComponent } from './components/categories/categories.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AllBooksComponent } from './components/all-books/all-books.component';
import { BookDetailComponent } from './components/book-detail/book-detail.component';
import { PropertyModule } from '../../property/property.module';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [
    TitleComponent,
    BooksComponent,
    BooksCategoriesComponent,
    AllBooksComponent,
    BookDetailComponent
  ],
  imports: [
    CommonModule,
    LibraryRoutingModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    PropertyModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
  ],
  exports: [
    BooksComponent
  ] 
})
export class LibraryModule { }
