import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstitutedetailComponent } from './institutedetail/institutedetail.component';
import { InstitutesListComponent } from './institutes-list/institutes-list.component';

const routes: Routes = [

 
  {
    path: 'all-institutes',
    component: InstitutesListComponent
  },
  {
    path: 'institute-details',
    component : InstitutedetailComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class InstitutesRoutingModule { }
