import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ApiCallService } from "src/app/shared/services/api-call.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { CommonUtilsServiceService } from "src/app/shared/services/common-utils-service.service";

@Component({
  selector: "app-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInfoComponent implements OnInit, OnDestroy {
  private readonly _apiCall = inject(ApiCallService);
  private readonly _authService = inject(AuthService);

  public userDetails: any;


  private readonly _userDetailsService = inject(CommonUtilsServiceService);
  private subscription: Subscription;

  ngOnInit(): void {
    this.getLogedInUserDetails(); // Initial call
    

    this.subscription = this._userDetailsService.userDetailsUpdated$.subscribe(
      (updated) => {
        if (updated) {
          this.getLogedInUserDetails();
          // Emit the updated profile image to the header
          this.emitProfileImageUpdate();
          // Emit the updated social links to the dashboard sidebar
          this.emitSocialLinksUpdate();
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }



  getLogedInUserDetails() {
    const storedUserDetails = localStorage.getItem("userDetails");

    if (storedUserDetails) {
      this.userDetails = JSON.parse(storedUserDetails);

      if (
        !this.userDetails.profileBase64 ||
        this.userDetails.profileBase64.length < 20
      ) {
        this.userDetails.profileBase64 =
          this._authService.generateFallbackImage(this.userDetails.firstName);
      }

      // Emit the profile image update when details are loaded
      this.emitProfileImageUpdate();
      this.emitSocialLinksUpdate();
    } else {
      this.userDetails = null;
    }
  }

  private emitProfileImageUpdate() {
    if (this.userDetails && this.userDetails.profileBase64) {
      // You can create a new service for this or use an existing one
      // For this example, let's assume we've added a method to CommonUtilsServiceService
      this._userDetailsService.updateProfileImage(this.userDetails.profileBase64);
    }
  }

  private emitSocialLinksUpdate() {
    if (this.userDetails && this.userDetails.socialLinks) {
      this._userDetailsService.updateSocialLinks(this.userDetails.socialLinks);
    }
  }
}
