
@if(!userAccount){
<a href="javascript:void(0)" (click)="openLanguage()" (clickOutside)="clickOutside()">
  <app-feather-icons [icon]="'user'"></app-feather-icons>
</a>
}
@if(userAccount){
<ul class="nav-submenu" [ngClass]="{ open : languageOpen }">
  <li>
    <a href="javascript:void(0)">Log out <app-feather-icons class="log-out-icon" [icon]="'log-out'"></app-feather-icons>
    </a>
  </li>
</ul>
}

<!-- original code  -->
<!-- <a href="javascript:void(0)" (click)="openModal()">
  <app-feather-icons [icon]="'user'"></app-feather-icons>
</a> -->