import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from 'src/app/shared/services/api-call.service';

@Component({
  selector: 'app-test-summary',
  templateUrl: './test-summary.component.html',
  styleUrl: './test-summary.component.scss'
})
export class TestSummaryComponent {
  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Test Yourself';
  public parent = 'Teacher';
  public child = 'Test Summary';

  public _toastr = inject(ToastrService);
  public __apiCall = inject(ApiCallService);

  isDataLoading: boolean = false;
  expandedRow: number | null = null;
  testSummary: any;

  constructor(private router: Router) { }

  ngOnInit() {
    this.getTestSummary();
  }

  getTestSummary() {
    this.isDataLoading = true;
    this.__apiCall.GetCallWithToken("UserTest/GetUserTestDetail").subscribe((response) => {
      if (response.responseCode == 200) {
        this.testSummary = response.data;
        this.isDataLoading = false;
      } else {
        this.testSummary = [];
        this.isDataLoading = false;
      }
    }, (error) => {
      this._toastr.error('Error fetching tests data', error);
      this.isDataLoading = false;
    }
    )
  }

  trackByIndex(index: number): number {
    return index;
  }
 

  toggleDetails(index: number): void {
    this.expandedRow = this.expandedRow === index ? null : index;
  }

  startTest(test: any) {
    const [category, subCategory] = test.title.split(' - '); 
    const url = this.router.createUrlTree(['/question'], {
      queryParams: {
        category: category,
        subCategory: subCategory,
        level: test.difficultyType, 
        description: test.testDescription 
      }
    });
    window.open(url.toString(), '_blank');
  }
  

}
