import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-book-detail',
  templateUrl: './book-detail.component.html',
  styleUrl: './book-detail.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookDetailComponent {

  public isLoading = true;
  public isRelatedBooksLoading = true;
  relatedBookData: any[] = [];

  private _utilsService = inject(CommonUtilsServiceService);
  private readonly _spinner = inject(NgxSpinnerService);

  constructor(private route: ActivatedRoute) {

  }

  bookid: any;
  bookData: any;
  bookDetails: any;
  private _apiCall = inject(ApiCallService);
  ngOnInit(): void {

    this.bookid = this.route.snapshot.queryParamMap.get('bookid');


    this.route.queryParamMap.subscribe((params) => {
      this.bookid = params.get('bookid');
      if (this.bookid) {
        this.getBookDetail();
      }
      window.scrollTo(0, 0);
    });

  }


  getBookDetail() {
    this._spinner.show();
    this._apiCall.PostCallWithoutToken(null, `Book/GetBookById?Id=${this.bookid}`).subscribe(
      (response) => {
        this.bookData = response.data;

        this.bookDetails = {
          moduleType: "books",
          id: this.bookData?.id,
          instituteName: this.bookData?.title,
          country: "",
          state: "",
          city: "",
          edition: this.bookData?.edition,
          bookLanguage: this.bookData?.language,
          address: this.bookData?.publisherName,
          isbNumber: this.bookData?.isbNumber,
          publishedDate: this.bookData?.publishedDate?.substring(0, 10),
          category: this.bookData?.category,
          description: this.bookData?.bookDescription,
          fileBase64: this.bookData?.fileBase64,
          departments: response.data?.bookKeyWordVMs.map((keyword: any) => ({
            id: keyword.id,
            departmentName: keyword.keyWord
          })),
          courses: response.data?.bookAuthorVMs.map((author: any) => ({
            id: author.id,
            courseName: author.author,
          })),
          images: [
            {
              imageBase64: this.bookData?.imageBase64 // Keep base64 format for now
            }
          ],
        };

        // Initialize blobUrls array
        this.bookDetails.blobUrls = [];

        // Convert imageBase64 to Blob URL if it exists
        if (this.bookData?.imageBase64 && this.bookData.imageBase64.includes('base64,')) {
          const base64Data = this.bookData.imageBase64.split('base64,')[1];
          const blobUrl = this._utilsService.convertBase64ToBlobUrl(base64Data, 'image/jpeg');

          // Add the Blob URL to blobUrls array
          this.bookDetails.blobUrls.push(blobUrl);
          // console.log("Book Detail Blob URL:", blobUrl);
        } else {
          // Optional: handle case where imageBase64 doesn't exist
          console.warn("No valid imageBase64 found");
        }
        this.isLoading = false;
        this._spinner.hide();
        this.getRelatedBooks();
      },
      (error) => {
        this._spinner.hide();
        this.isLoading = false;
        console.error('Error fetching book details:', error);
      }
    );
  }


  // Get Related Books 
  getRelatedBooks() {
    this.isRelatedBooksLoading = true;

    this._apiCall.GetCallWithoutToken(`Book/GetRelatedBooks?Id=${this.bookid}`).subscribe(
      (response) => {
        this.relatedBookData = response.data.map((book: any) => {
          // Convert imageBase64 to Blob URL if it exists
          if (book.imageBase64 && book.imageBase64.includes('base64,')) {
            const base64Data = book.imageBase64.split('base64,')[1];
            book.imageBase64 = this._utilsService.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            // console.log("Related Book Blob URL:", book.imageBase64);
          } else {
            book.imageBase64 = 'path/to/default/image.png';
          }
          return book;
        });
        this.isRelatedBooksLoading = false;
      },
      (error) => {
        this.isRelatedBooksLoading = false;
        console.error('Error fetching related books:', error);
      }
    );
  }


  navigateToBookDetails(id: number): void {
    this._utilsService.navigateTo('/books/book-details', { bookid: id });
  }

}
