<div class="about page-section" id="about">
  <h4>{{heading}}</h4>
  <div class="row">
    <div class="col-sm-12">
      <div *ngFor="let item of items; let i = index">
        <p class="text mb30">
          {{ isContentToggled[i] ? item : truncatedItems[i] }}
          <a *ngIf="showToggleButton" href="#" (click)="toggleContent(i, $event)" aria-label="clickable-text" class="toggle-button color">
            {{ isContentToggled[i] ? "Read Less" : "Read More" }}
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
