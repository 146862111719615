<app-slider-filter-home-slider></app-slider-filter-home-slider>

<app-slider-filter-latest-sale [tagClass]="'color-6'"></app-slider-filter-latest-sale>

<app-slider-filter-featured-property [tagClass]="'color-6'" [title]="title" [sectionClass]="'banner-4'"></app-slider-filter-featured-property>

<app-slider-filter-latest-rent [propertyClass]="true" [tagClass]="'color-6'" [title]="title"></app-slider-filter-latest-rent>

<app-slider-filter-new-offer [tagClass]="'color-6'" [title]="title" [sectionClass]="'banner-4'"></app-slider-filter-new-offer>

<app-slider-filter-property-in-cities [tagClass]="'color-6'" [title]="title" [sectionClass]="'gallery-6'"></app-slider-filter-property-in-cities>

<app-slider-filter-banner [tagClass]="'color-6'" [title]="title"></app-slider-filter-banner>

<app-slider-filter-agents [tagClass]="'color-6'" [title]="title" [sectionClass]="'about-color6'"></app-slider-filter-agents>

<app-slider-filter-happy-client [tagClass]="'color-6'" [title]="title"></app-slider-filter-happy-client>

<app-slider-filter-brand></app-slider-filter-brand>
