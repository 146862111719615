<div class="common-card">
  <div [ngClass]="chartData.widgetClass">
    <div class="media">
      <div class="media-body">
        <p>{{ chartData.title }}</p>
        <h5>{{ chartData.number }}</h5>
      </div>
      <div class="small-bar">
        <div [ngClass]="chartData.chartClass">
          <x-chartist [configuration]="chartData"></x-chartist>
        </div>
      </div>
    </div>
  </div>
</div>
