import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-two',
  templateUrl: './loader-two.component.html',
  styleUrls: ['./loader-two.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderTwoComponent {
  @Input() isLoading: boolean = false;
  // public show: boolean = true;

  constructor() {
    // setTimeout(() => {
    //   this.show = false;
    // }, 3000);
  }
}
