<div class="loader-wrapper" *ngIf="isLoading">
  <div class="row loader-text">
    <div class="col-12">
      <img src="../../../../../../assets/images/icon/loader.gif" class="img-fluid" alt="loader" />
    </div>
    <div class="col-12">
      <div>
        <h3 class="mb-0">Admissions Lylo Loading...</h3>
      </div>
    </div>
  </div>
</div>