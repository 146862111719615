import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { sideMenu, userPanelSideMenu } from '../../../../../shared/data/user-panel';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-user-panel-side-menu',
  templateUrl: './user-panel-side-menu.component.html',
  styleUrls: ['./user-panel-side-menu.component.scss'],
})
export class UserPanelSideMenuComponent {

  @Input() activeTab: string | null;

  private _authService = inject(AuthService);
  public userPanelSideMenu = userPanelSideMenu;

  // Track active submenu
  public activeSubMenu: string | null = null;

  constructor(private router: Router) {
    this.checkActiveRoute();
   }

  getPage(data: any) {
    if (data.value === 'logout') {
      this.router.navigate(['']);
    } else {
      if (!data.subMenu) {
        this.activeTab = data.value;
        this.activeSubMenu = null;
        this.router.navigate([data.path]);
      }
    }
  }

  toggleSubMenu(menuValue: string) {
    if (this.activeSubMenu === menuValue) {
      this.activeSubMenu = null; 
    } else {
      this.activeSubMenu = menuValue; 
    }
  }

  checkActiveRoute() {
    const currentRoute = this.router.url; 
    let matched = false; 
  
    userPanelSideMenu.forEach(menu => {
      if (menu.subMenu) {
        const matchedSubMenu = menu.subMenu.find(sub => currentRoute === sub.path);
        if (matchedSubMenu) {
          this.activeSubMenu = menu.value; 
          this.activeTab = matchedSubMenu.value; 
          matched = true; 
        }
      } else if (menu.path && currentRoute === menu.path) {
        this.activeTab = menu.value;
        matched = true;
      }
    });
  
    
    if (!matched) {
      this.activeSubMenu = null;
      this.activeTab = null;
    }
  }
  
  
  
  

  // Define trackBy function to optimize rendering
  trackData(index: number, item: sideMenu): string {
    return item.value;  
  }
}
