import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { latestForRent } from '../../../../../../shared/interface/property';
import { DataShareService } from 'src/app/shared/services/data-share.service';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageSliderComponent {

  @Input() latestForRentData: latestForRent;
  @Input() tagClass: string;
  @Input() listView: boolean = false;
  @Input() thumbnail: boolean = false;
  @Input() thumbnail_video: boolean = false;
  @Input() gridImages: boolean = false;

  public selectedImage: string;

  public Options = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,         
    autoplayTimeout: 2000,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    responsive: {
      0: { items: 1 },   // 1 item for small screens (mobile)
      600: { items: 1 },  // 1 item for medium screens (tablet)
      1000: { items: 1 }, // 1 item for large screens (desktop)
    },
  
  };

  public thumbnailCarouselOptions = {
    items: 4,
    margin: 10,
    center: true,
    loop: true,
    nav: false,
    dots: false,
  };

  constructor(private dataShare: DataShareService, private cdRef: ChangeDetectorRef) {
    // Subscribe to format changes and update the carousel options dynamically
    // this.dataShare.imageFormatChanged$.subscribe((value) => {
    //   if (value) {
    //     this.updateCarouselOptions(value);  // Update options dynamically
    //   }
    // });
  }

  changeImage(image: string) {
    this.selectedImage = image;
  }

  // updateCarouselOptions(value: any) {
  //   // Example logic to change the number of items based on the 'value' received
  //   if (value === 4) {
  //     this.Options.items = 1; // Change to 3 items per row
  //   } else if (value ===  3) {
  //     this.Options.items = 1; // Change to 2 items per row
  //   } else {
  //     this.Options.items = 1; // Default to 1 item per row
  //   }
  // }
}
