<app-modern-home-section></app-modern-home-section>

<app-enterprise-latest-property [latestForRentData]="latestForRentData" [tagClass]="'color-2'"></app-enterprise-latest-property>

<app-enterprise-looking-for [latestForRentData]="latestForRentData" [title]="'modern'" [tagClass]="'color-2'"></app-enterprise-looking-for>

<app-enterprise-property-of-day [title]="title"></app-enterprise-property-of-day>

<app-enterprise-property-service [title]="title"></app-enterprise-property-service>

<app-enterprise-featured-city [title]="title"></app-enterprise-featured-city>

<app-enterprise-banner></app-enterprise-banner>

<app-enterprise-people-say></app-enterprise-people-say>

<app-enterprise-latest-blog></app-enterprise-latest-blog>

<app-enterprise-brand></app-enterprise-brand>
