import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HostelListComponent } from './hostel-list/hostel-list.component';
import { HostelDetailComponent } from './hostel-detail/hostel-detail.component';

const routes: Routes = [
  {
    path: 'all-hostels',
    component: HostelListComponent
  },
  {
    path : 'hostel-details',
    component : HostelDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HostelsRoutingModule { }
