<section class="my-gallery" >
    <!-- [ngClass]="sectionClass" -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-10">
                <div class="col">
                    <app-title [titleClass]="'title-1'" [tag]="'city'" [heading]="'Our Services'" [textWhite]="false" [type]="'simple'" [tagClass]="tagClass"></app-title>
                    <div class="row">
                      @for(data of propertyInCity; track data){
                        <div class="wow fadeInUp col-xl-3 col-lg-4 col-md-5 col">
                            <app-property-in-cities [propertyInCity]="data" [type]="'simple'"></app-property-in-cities>
                        </div>
                      }
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <app-title [titleClass]="'title-1'" [tag]="'city'" [heading]="'Advertisement'" [textWhite]="false" [type]="'simple'" [tagClass]="tagClass"></app-title>
                <div class="main-ads-section">
                </div>
              </div>
        </div>
    </div>
</section>
