<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="property-wizard">
  <div class="container">
    <div class="row wizard-box">
      <div class="col-sm-12">
        <div class="wizard-step-container row">
          <div class="col-xxl-3 col-lg-4">
            <app-add-property-steps [addPropertyStepsData]="addPropertyStepsData" [activeSteps]="activeSteps"></app-add-property-steps>
          </div>
          <div class="wizard-form-details col-xxl-9 col-lg-8">
            <div class="theme-card my-3">
              <div class="wizard-step-1" [ngClass]="activeSteps == 1 ? 'd-block' : 'd-none'">
                <app-property-general-details (activeSteps)="receiveChildData($event)"></app-property-general-details>
              </div>
              <div class="wizard-step-2" [ngClass]="activeSteps == 2 ? 'd-block' : 'd-none'">
                <app-property-address-details (activeSteps)="receiveChildData($event)"></app-property-address-details>
              </div>
              <div class="wizard-step-3" [ngClass]="activeSteps == 3 ? 'd-block' : 'd-none'">
                <app-property-gallery (activeSteps)="receiveChildData($event)"></app-property-gallery>
              </div>
              <div class="wizard-step-4" [ngClass]="activeSteps == 4 ? 'd-block' : 'd-none'">
                <app-property-confirmation></app-property-confirmation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
