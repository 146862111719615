<div class="find-cities position-relative bg-size">
    <div class="our-services">
        <div class="icon-img">
            <img [src]=" propertyInCity.img " alt="Admission Icon">
        </div>
        <h3> {{ propertyInCity.city }} </h3>
    </div>
    <div class="citi-overlay position-relative">
        <div >
            <h6 class="font-roboto">{{ propertyInCity.text }}</h6>
            <!-- <a class="btn" [routerLink]="[propertyInCity]">View Details</a> -->
            <button aria-label="Button" class="btn btn-link"[routerLink]="[propertyInCity.routeLink]">Details<i
                class="fas fa-arrow-right ms-1"></i></button>
        </div>
    </div>
</div>
