@if (isContentShown === true) {
  <app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>
<app-common-banner-1 [imagePath]="bannerImage" [heading1]="'Books'" [heading2]="'for Knowlwdge, Education, and Success'"
  (childEvent)="handleChildEvent($event)" (sendTheSearchValue)="onSearchValueReceived($event)"
  (callAPionEmptyFields)="onReset()" [isAdvanceSearchTovisible]="false" (sendTheSearchValue)="handleSearchData($event)"
  [totalRecords]="totalRecords"
  [popUpButton]="{ text: 'Add Book', isShown: true, componentName: 'books' }"
  ></app-common-banner-1>

}

<!-- Main container for the books component -->
<div class="container-fluid" [ngClass]="[isContentShown ? 'mt-0' : 'mt-90']">
  <div class="row">
    <div class="col-lg-10" [ngClass]="[isContentShown ? 'col-lg-12' : 'col-lg-10']"
      [ngClass]="{'col-lg-12': adImages.length == 0 || isContentShown}">
      @if (isContentShown === true) {
      <!-- <app-title
        [heading]="'All Books'"
        [isLabelAdded]="false"
        [isLineAdded]="true"
        [tag]="tag"
        [textWhite]="false"
        [type]="'simple'"
      ></app-title> -->
      <app-grid-panel [heading]="'All Books'" [gridOption]="false" [currentPage]="currentPage"
        [totalRecords]="totalRecords" [itemsPerPage]="itemsPerPage"></app-grid-panel>
      }@else {
      <app-title [heading]="'Top Books'" [isLabelAdded]="true" [isLineAdded]="true" [tag]="tag" [textWhite]="false"
        [type]="'simple'"></app-title>
      }

      <div class="wrapper ovh">
        <div class="body_content">
          <!-- Loading skeleton while data is being fetched -->
          <div *ngIf="isDataLoading; else Books">
            @if (isDataLoading === true) {
            <div class="item">
              <ngx-skeleton-loader count="8" [theme]="{
                    height: '22.5rem',
                    width: '23%',
                    'border-radius': '10px',
                    'margin-right': '0.5rem',
                    'margin-left': '0.5rem'
                  }" />
            </div>
            }
            <!-- <div class="skeleton-loader-wrapper">
            </div> -->
          </div>
          <!-- Template for displaying books when data is loaded -->
          <ng-template #Books>
            <section class="wrapper">
              <div class="container">
                <!-- Display message when no books are found -->
                @if (noRecordsFound) {
                <div>
                  <div class="no-record">
                    <img src="../../../../assets/images/svg/Nodata.gif" alt="No records found" />
                    <p>No Book Found</p>
                  </div>
                </div>
                }
                <!-- Display error message if an error occurred -->
                @if(isErrorOccurred){
                <div>
                  <div class="no-record error-svg">
                    <img src="../../../../../../assets/images/svg/error.gif" alt="Error Ocuurred">
                    <p>An Error Occurred,Try refreshing Page</p>
                  </div>
                </div>
                }
                <!-- Grid to display books -->
                <div class="row">
                  <div id="card-area">
                    <div class="bookWrapper">
                      <div class="box-area">
                        <!-- Loop through books and display each one -->
                        @for ( books of bookDataByCategory; track books) {
                        <div class="box">
                          <img alt="Book-Image" [src]="books.imageBase64" />
                          <div class="overlay">
                            <h3 class="line-clamp1">{{ books.title }}</h3>
                            <p class="desLineClamp">
                              {{ books.bookDescription }}
                            </p>
                            <a (click)="navigateToBookDetails(books.id)" aria-label="clickable-text"
                              class="see-details">See Details</a>
                          </div>

                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ng-template>

        </div>
      </div>
      @if (isContentShown === false) { @if ( bookDataByCategory.length > 0) {
      <div class="all-jobs-btn">
        <div class="col-lg-12">
          <app-center-button [buttonText]="'All Books'" [route]="'/books/categories'"></app-center-button>
        </div>

      </div>
      } }
    </div>

    <!-- Sidebar column for Advertisements -->
    @if (isContentShown === false) {
    <div class=" advertisement ads-section" [ngClass]="{
      'col-lg-2': !isScreenSmall, 
      'hideAdvertsment': adImages.length == 0, 
      'col-lg-12': isScreenSmall 
    }">
      @if(!isAdLoading){
      <app-title [heading]="''" [textWhite]="false" [type]="'simple'"></app-title>
      <div class="main-ads-section" (mouseenter)="pauseScroll()" (mouseleave)="startScroll()">
        <div class="ad-images-wrapper">
          @for(ad of adImages; track ad) {
          @if(ad?.imageUrl?.length > 20) {
          <a [href]="ad?.redirectLink" aria-label="clickable-text" target="_blank">
            <img [src]="ad?.imageUrl" class="ad-image" alt="Advertisement" />
          </a>
          }
          }
          @for(ad of adImages;track ad){ @if(ad?.imageUrl?.length > 20){
          <a [href]="ad?.redirectLink" target="_blank" aria-label="clickable-text"><img [src]="ad?.imageUrl"
              class="ad-image" alt="Advertisement" />
          </a>
          } }
        </div>
      </div>
      }@else{
      <!-- <ng-template #advertismnets> -->
      <div class="item add-container">
        <ngx-skeleton-loader count="1" [theme]="{
              height: addSectionCss.height,
              width: addSectionCss.width,
              'border-radius': '10px',
              'margin-right': '0.5rem',
              'margin-left': '0.5rem'
            }"></ngx-skeleton-loader>
      </div>
      }
    </div>
    }
  </div>




  <!-- pagination starts here -->
  @if (isContentShown === true) {
  @if(bookDataByCategory.length !== 0) {
  <div class="row pagination-container">
    <div class="mt30 d-flex justify-content-center">
      <ngb-pagination [collectionSize]="totalRecords" [(page)]="currentPage" [pageSize]="itemsPerPage"
        (pageChange)="onPageChanged($event)" [maxSize]="maxVisiblePages" [ellipses]="false" [boundaryLinks]="true">
      </ngb-pagination>
    </div>
    <div class="tRecord text-center">
      {{ getDisplayRange() }} of {{ totalRecords }}
    </div>
  </div>
  }
  }
  <!-- pagination ends here -->
</div>

@if (isContentShown === true) {
<app-adly-footer></app-adly-footer>
}