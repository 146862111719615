<div class="modal-content">
  <button aria-label="Button" type="button" class="btn-close" (click)="modalClose()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-3 col-lg-5">
          <div class="left-sidebar">
            <div class="filter-cards">
              <div class="advance-card">
                <h6>filter</h6>
                <app-filter-box></app-filter-box>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-9 col-lg-7 map-section mt-0">
            @if(data == 'google-map'){
              <google-map width="100%" height="75vh" [options]="mapOptions"></google-map>
            }
            @if(data == 'leaflet-map'){
              <app-common-leaflet-map></app-common-leaflet-map>
            }
        </div>
      </div>
    </div>
  </div>
</div>
