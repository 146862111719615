@if(type == 'grid-3'){
<section class="property-section pt-0">
  <div class="container">
    <div class="title-3 text-start inner-title">
      <h2>{{ heading }}</h2>
    </div>
    @if(isSkeletonShown){
    <div class="item">
      <ngx-skeleton-loader count="3" [theme]="{
                        height: '25rem',
                        width: '25%',
                        'border-radius': '10px',
                        'margin-right': '0.5rem',
                        'margin-left': '0.5rem',
                        }" />
    </div>
    }@else{
    <div class="row ratio_65">
      <div class="col-12 property-grid-3">
        <div class="property-2 row column-sm zoom-gallery property-label property-grid">
          @for(data of instituteData; track data){
          <div class="col-xl-4 col-md-6">
            <app-property-box [type]="'basic'" [textColor]="true" [tagClass]="'color-2'" [data]="totalData"
              [institutes]="data" [isDescriptionShown]="isDescriptionShown"></app-property-box>
          </div>
          }
        </div>
      </div>
    </div>
    }
  </div>
</section>
}
@else {
<div class="property-section p-t-40">
  <div class="title-3 text-start inner-title">
    <h2>{{ heading }}</h2>
  </div>
  <div class="row ratio_65">
    <div class="col-12 property-grid-2">
      <div class="property-2 row column-sm zoom-gallery property-label property-grid">
        @for(data of instituteData; track data){
        <div class="col-xl-4 col-md-6">
          <app-property-box [type]="'basic'" [textColor]="true" [tagClass]="'color-2'" [data]="totalData"
            [institutes]="data" [isDescriptionShown]="isDescriptionShown"></app-property-box>
        </div>
        }
      </div>
    </div>
  </div>
</div>
}