<section class="property-section" [ngClass]="{
    'property-color-6': propertyClass == true,
    'property-list-view': listView == true
  }">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12">
        <div class="row ratio_55">
          <div class="col-xl-3">

            <app-title [titleClass]="titleClass" [heading]="heading" [textWhite]="false" [type]="type"
              [desc]="desc"></app-title>
          </div>


        </div>
      </div>
    </div>


    <div class="property-2 row column-space ">
      <div class="col-xl-12 top-institutes-card ">
        <div class="row">

          <div class="main-cards ">

            <div class="row column-sm property-label" [class.list-view]="listView"
              [ngClass]="{ 'property-2 property-grid' : type != 'profile' }">
              <div *ngFor="let institute of institutesData | slice: 0 : total; let i = index; "
                [ngClass]="getCardClasses()">

                <app-property-box [isDescriptionShown]="isDescriptionShown" [institutes]="institute" [type]="'basic'"
                  [textColor]="true" [tagClass]="tagClass" [data]="total" [listView]="listView"></app-property-box>

              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</section>