import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-technology-partner',
  templateUrl: './technology-partner.component.html',
  styleUrl: './technology-partner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechnologyPartnerComponent {
  @Input() showTitle: boolean = true;

  @Input() titleClass: string = 'title-1';
  @Input() type: string = 'simple';
  @Input() heading: string = 'Technology Partners';
  @Input() desc: string = '';

  public tag: string = 'Partners';
  feedbackSlides3: OwlOptions = {
    nav: false,
    margin: 25,
    loop: true,
    dots: true,
    autoplay: true,
    smartSpeed: 2000,
    autoplayHoverPause: true,
    navText: [
      '<i class="flaticon-011-chevron-1"></i>',
      '<i class="flaticon-010-chevron"></i>'
    ],
    responsive: {
      0: {
        items: 1
      },
      515: {
        items: 2
      },
      695: {
        items: 3
      },
      935: {
        items: 4
      },
      1200: {
        items: 5
      }
    }
  };
  partners = [
    { src: '../../../../../assets/images/icon/wordsense.jpeg', alt: 'WordSense', intrinsicWidth: 264, intrinsicHeight: 191 },
    { src: '../../../../../assets/images/icon/bilnow.png', alt: 'Bilnow', intrinsicWidth: 250, intrinsicHeight: 150 },
    { src: '../../../../../assets/images/icon/wordsense2.0.jpg', alt: 'Wordsense-2.0', intrinsicWidth: 1237, intrinsicHeight: 639 }
    // { src: '../../../../../assets/images/icon/sdh.png', alt: 'Social Casino' },
    // { src: '../../../../../assets/images/icon/netsol.png', alt: 'Logo' }
  ];

  ngOnInit() {
    this.updateCarouselOptions();
  }

  updateCarouselOptions() {
    const shouldSlide = this.partners.length >= 5;

    this.feedbackSlides3 = {
      nav: false,
      margin: 25,
      loop: shouldSlide,
      dots: true,
      autoplay: shouldSlide,
      smartSpeed: 2000,
      autoplayHoverPause: true,
      navText: [
        '<i class="flaticon-011-chevron-1"></i>',
        '<i class="flaticon-010-chevron"></i>'
      ],
      responsive: {
        0: {
          items: 1
        },
        515: {
          items: 2
        },
        695: {
          items: 3
        },
        935: {
          items: 4
        },
        1200: {
          items: 5
        }
      }
    };
  }

}
