import {  Input } from '@angular/core';
import { agency } from '../../../../shared/interface/property';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { PropertyService } from 'src/app/shared/services/property.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';

@Component({
  selector: 'app-agent-profile-details',
  templateUrl: './agent-profile-details.component.html',
  styleUrls: ['./agent-profile-details.component.scss'],
})
export class AgentProfileDetailsComponent {

  TeacherID: any;
  TeacherData: any;

  private _apiCall = inject(ApiCallService);
  private _dataShare = inject(DataShareService);
  constructor(
    private route: ActivatedRoute
  ) {
  }
  

  ngOnInit(): void {
    
    this.TeacherID = this.route.snapshot.queryParamMap.get('id');
    // console.log("instiyute id", this.TeacherID);
    this.getTeacherDetails();
    
  }


  getTeacherDetails (){
    this._apiCall.PostCallWithoutToken(null, `AdmissionLeloUsers/GetTeacherById?TeacherId=${this.TeacherID}`).subscribe(
      (response) => {
        this.TeacherData = response.data;
        this._dataShare.setTeacherDetails({
          id: response.data.id,
          contactNumber: response.data.phoneNumber,
        });

      }
    )
  }

  


  @Input() agentsDetails: agency; 
  @Input() showTeachearDetails: boolean = false; 
  @Input() showagnecyDescription: boolean = true; 

  
skills=[
  {
    name:'Java script',
    percentage: 90
  },
  {
    name:'type script',
    percentage: 45
  },
]

}
