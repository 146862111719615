<nav>
  <div class="main-navbar">
    <div id="mainnav">
      <div class="toggle-nav" (click)="openSideMenu()">
        <i class="fa fa-bars sidebar-bar"></i>
      </div>
      <ul class="nav-menu" [ngClass]="{ 'open': isOpenMenu }">
        <li class="back-btn">
          <div class="mobile-back text-end" (click)="closeSideMenu()">
            <span>Back</span>
            <i aria-hidden="true" class="fa fa-angle-right ps-2"></i>
          </div>
        </li>

        @for(menuItem of menuItems; track menuItem) {
        @if(!menuItem.megaMenu) {

        <!-- Only add 'dropdown' class if the menuItem has children -->
        <li [ngClass]="menuItem.children ? 'dropdown' : ''"
          [ngClass]="{ 'dropdown-right' : menuItem.right && menuRight }"
          [ngClass]="{ 'active': isActive(menuItem?.path) }"
          >

          <!-- Handle menuItem with no children (simple link) -->
          @if(!menuItem.children || menuItem.children.length === 0) {
          <a [routerLink]="menuItem.path" class="nav-link menu-title"
            [ngClass]="menuItem.active && isOpenMenu ? 'active' : ''">
            @if(menuItem.icon) {
            <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
            }
            {{ menuItem?.title ?? '' | translate }}
          </a>
          } @else {
          <!-- Dropdown logic for items with children -->
          <a href="javascript:void(0)" class="nav-link menu-title"
            [ngClass]="menuItem.active && isOpenMenu ? 'active' : ''" (click)="openMenu(menuItem)">
            @if(menuItem.icon) {
            <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
            }
            @if(!menuItem.active && isOpenMenu) {
            <app-feather-icons [icon]="'plus'" [class]="'according-menu'"></app-feather-icons>
            } @if(menuItem.active && isOpenMenu) {
            <app-feather-icons [icon]="'minus'" [class]="'according-menu'"></app-feather-icons>
            }
            {{ menuItem?.title ?? '' | translate }}
          </a>

          <ul class="nav-submenu menu-content"
            [ngClass]="{ 'd-block': menuItem.active == true && isOpenMenu , 'd-none d-md-block': menuItem.active == false && !isOpenMenu}">
            <div *ngTemplateOutlet="recursiveMenu; context: { $implicit: menuItem.children }"></div>
            <ng-template #recursiveMenu let-menuItem>
              @for(item of menuItem; track item) {
              <li>
                <a [routerLink]="item.path" [class.menu-title-level1]="item.children && item.level == 2"
                  [ngClass]="{ 'submenu-title': item.children && item.level == 3, 'active': item.active && isOpenMenu}"
                  (click)="openMenu(item)">
                  @if(!item.active && isOpenMenu && item.children) {
                  <app-feather-icons [icon]="'plus'" [class]="'according-menu'"></app-feather-icons>
                  } @if(item.active && isOpenMenu && item.children) {
                  <app-feather-icons [icon]="'minus'" [class]="'according-menu'"></app-feather-icons>
                  }
                  {{ item.title | translate }}
                  @if(item.badge) {
                  <span class="label">{{ item.badgeText }}</span>
                  }
                  @if(item.badgeIcon) {
                  <span class="icon-trend label"><i class="fas fa-bolt"></i></span>
                  }
                </a>
                <ul *ngIf="item.children" [class.nav-sub-childmenu]="item.children"
                  [ngClass]="{ 'submenu-content level2': item.level == 3, 'level1': item.level == 2, 'd-block': item.active == true && isOpenMenu}">
                  <div *ngTemplateOutlet="recursiveMenu; context: { $implicit: item.children }"></div>
                </ul>
              </li>
              }
            </ng-template>
          </ul>
          }
        </li>
        } @else {
        <!-- Mega menu structure remains the same -->
        <li class="mega-menu">
          <a href="javascript:void(0)" class="nav-link menu-title"
            [ngClass]="menuItem.active && isOpenMenu ? 'active' : ''" (click)="openMenu(menuItem)">
            @if(menuItem.icon) {
            <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
            }
            @if(!menuItem.active && isOpenMenu) {
            <app-feather-icons [icon]="'plus'" [class]="'according-menu'"></app-feather-icons>
            } @if(menuItem.active && isOpenMenu) {
            <app-feather-icons [icon]="'minus'" [class]="'according-menu'"></app-feather-icons>
            }
            {{ menuItem.title }}
          </a>
          <div class="mega-menu-container menu-content"
            [ngClass]="{ 'd-block': menuItem.active == true && isOpenMenu , 'd-none d-md-block': menuItem.active == false && !isOpenMenu}">
            <div class="container">
              <div class="row">
                <div *ngTemplateOutlet="recursiveMenu1; context: { $implicit: menuItem.children }"></div>
                <ng-template #recursiveMenu1 let-menuItem>
                  @for(item of menuItem; track item) {
                  <div class="col mega-box">
                    @if(!item.section) {
                    <div class="link-section">
                      <div class="submenu-title">
                        <h5 [ngClass]="item.active && isOpenMenu ? 'active' : ''" (click)="openMenu(item)">
                          {{ item.title }}
                        </h5>
                        @if(!item.active && isOpenMenu) {
                        <app-feather-icons [icon]="'plus'" [class]="'according-menu'"></app-feather-icons>
                        } @if(item.active && isOpenMenu) {
                        <app-feather-icons [icon]="'minus'" [class]="'according-menu'"></app-feather-icons>
                        }
                      </div>
                      <div class="submenu-content opensubmegamenu"
                        [ngClass]="{ 'd-block': item.active == true && isOpenMenu , 'd-none d-md-block': item.active == false && !isOpenMenu}">
                        <ul class="list">
                          @for(data of item.children; track data) {
                          <li>
                            <a [routerLink]="data.path">
                              {{ data.title }}
                              @if(data.badge) {
                              <span class="label">{{ data.badgeText }}</span>
                              }
                              @if(data.badgeIcon) {
                              <span class="icon-trend label"><i class="fas fa-bolt"></i></span>
                              }
                            </a>
                          </li>
                          }
                        </ul>
                      </div>
                    </div>
                    } @else {
                    <!-- Handling sections -->
                    @for(child of item.section; track child) {
                    <div class="link-section">
                      <div class="submenu-title">
                        <h5 [ngClass]="child.active && isOpenMenu ? 'active' : ''" (click)="openMenu(child)">
                          {{ child.title }}
                        </h5>
                        @if(!child.active && isOpenMenu) {
                        <app-feather-icons [icon]="'plus'" [class]="'according-menu'"></app-feather-icons>
                        } @if(child.active && isOpenMenu) {
                        <app-feather-icons [icon]="'minus'" [class]="'according-menu'"></app-feather-icons>
                        }
                      </div>
                      <div class="submenu-content opensubmegamenu"
                        [ngClass]="{ 'd-block': child.active == true && isOpenMenu , 'd-none d-md-block': child.active == false && !isOpenMenu}">
                        <ul class="list">
                          @for(data of child.children; track data) {
                          <li>
                            <a [routerLink]="data.path">
                              {{ data.title }}
                              @if(data.badge) {
                              <span class="label">{{ data.badgeText }}</span>
                              }
                              @if(data.badgeIcon) {
                              <span class="icon-trend label"><i class="fas fa-bolt"></i></span>
                              }
                            </a>
                          </li>
                          }
                        </ul>
                      </div>
                    </div>
                    }
                    }
                  </div>
                  }
                </ng-template>
              </div>
            </div>
          </div>
        </li>
        }
        }

        @if(userAccount){
        <div class="showandHide" >
          <div class="user-details" (click)="openLogOutpOpUP()">
            <div class="user-account">
              {{userDetails?.firstName + " " + userDetails?.lastName}}
            </div>
            <div class="user-image">
              <img [src]="userDetails?.profileBase64" alt="user-profile">
            </div>

          </div>
          <ul class="nav-submenu2" [ngClass]="{ open : closePopUp }">
            <li (click)="logoutUser()">
              <a href="javascript:void(0)">Log out <app-feather-icons class="log-out-icon"
                  [icon]="'log-out'"></app-feather-icons> </a>
            </li>
          </ul>
        </div>
        }@else{
          <div class="showandHide">
            <div class="user-details user-to-logIN">
              <a href="javascript:void(0)" (click)="openBackDropCustomClass(signInPage)">
                <app-feather-icons [icon]="'user'"></app-feather-icons>
              </a>
              </div>
            </div>
        }
      </ul>
    </div>
  </div>
</nav>


<ng-template #signInPage>
  <div class="modal-content">
    <!-- <app-sign-in (userLoggedIn)="onUserLoggedIn()"></app-sign-in> -->
    <app-log-in-user (userLoggedIn)="onUserLoggedIn()"></app-log-in-user>
  </div>
</ng-template>