import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cv1',
  templateUrl: './cv1.component.html',
  styleUrl: './cv1.component.scss'
})
export class Cv1Component {
  @Input() userData: any = {};




}
