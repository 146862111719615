<!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="var(--mainColor)" type="ball-spin-clockwise"
    [fullScreen]="true">
    <p style="font: size 20px; color: var(--mainColor)">Loading...</p>
</ngx-spinner> -->
<!-- <app-loader-two [isLoading]="isSpinnerShown"></app-loader-two> -->
<app-header-one></app-header-one>
<app-common-banner-1
  [imagePath]="bannerImage"
  [heading1]="'Library'"
  [heading2]="'for Innovation, Education, and Success'"
  (childEvent)="handleChildEvent($event)"
  (callAPionEmptyFields)="onReset()"
  (sendTheSearchValue)="handleSearchData($event)"
  [showSearch]="true"
  [showCountryDropdown]="false"
  [showStateDropdown]="false"
  [showCityDropdown]="false"
  [showRangeSlider]="false"
  [showDegreeDropdown]="false"
  [showJobTypeDropdown]="false"
  [showScholarshipTypeDropdown]="false"
  [totalRecords]="totalRecords"
  [isAdvanceSearchTovisible]="false"
  [popUpButton]="{ text: 'Add Book', isShown: true, componentName: 'books' }"
></app-common-banner-1>

<div class="wrapper ovh " id="content-start">
  <div class="body_content">
    <section class="wrapper">
      <div class="container">
        <div *ngIf="isDataLoading; else libraryBooks">
          <div class="item">
            <ngx-skeleton-loader
              count="9"
              [theme]="{
                height: '25.5rem',
                width: '30%',
                'border-radius': '10px',
                'margin-right': '0.5rem',
                'margin-left': '0.5rem'
              }"
            />
          </div>
        </div>
        @if (noRecordsFound) {
          <div>
            <div class="no-record">
              <img
                src="../../../../../../assets/images/svg/Nodata.gif"
                alt="No records found"
              />
              <p>No Books Found</p>
            </div>
          </div>
          } @if(isErrorOccurred){
            <div>
              <div class="no-record">
                <img src="../../../../../../assets/images/svg/error.gif" alt="Error Ocuurred">
                <p>An Error Occurred,Try refreshing Page</p>
              </div>
            </div>
          }

        <ng-template #libraryBooks>
         
          <div class="row">
            @for ( books of booksData; track books) {
            <div class="col-sm-12 col-md-6 col-lg-4 mb-4">
              <div
                class="card text-dark card-has-bg click-col bgImage"
                [style.background-image]="'url(' + books.imageBase64 + ')'"
                (click)="showBooksByCategory(books.categoryName)"
              >
                <div class="card-img-overlay d-flex flex-column">
                  <div class="card-body">
                    <h1 class="card-title mt-0">
                      <a style="font-weight: 500" aria-label="clickable-text">{{ books.categoryName }}</a>
                    </h1>
                    <small class="line-clamp2" style="font-size: large">{{
                      books.description
                    }}</small>
                  </div>
                  <div class="card-footer">
                    <div class="media">
                      <div class="media-body">
                        <h5 class="my-0 text-dark d-block">Total Books</h5>
                        <small style="font-size: large">{{
                          books.totalBooks
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            }
          </div>
        </ng-template>
      </div>
    </section>
  </div>

  <!-- pagination starts here -->
  @if (isContentShown === true) {
    @if(booksData.length !== 0) {
      <div class="row pagination-container w-100">
        <div class="mt30 d-flex justify-content-center">
          <ngb-pagination 
            [collectionSize]="totalRecords" 
            [(page)]="currentPage" 
            [pageSize]="itemsPerPage"
            (pageChange)="onPageChanged($event)"
            [maxSize]="maxVisiblePages" 
            [ellipses]="false"
            [boundaryLinks]="true">
          </ngb-pagination>
        </div>
        <div class="tRecord text-center">
          {{ getDisplayRange() }} of {{ totalRecords }}
        </div>
      </div>
    }
  }
  <!-- pagination ends here -->
</div>

<!-- Footer Component -->
<app-adly-footer></app-adly-footer>
