<div class="common-card property-overview">
  <div class="common-header">
    <h5>Listings Overview</h5>
  </div>
  <div class="table-responsive">
    <table class="table table-bordernone">
      <thead>
        <tr>
          <th>Type</th>
          <th>Total</th>
          <th>Verified</th>
          <th>Declined</th>
          <th>Pending</th>
        </tr>
      </thead>
      <tbody>
        @for(data of transformedData; track data){
          <tr>
            <td>
              <div class="d-flex">
                <!-- <img [src]="data.img" class="img-fluid" alt="property-image"> -->
                
                <h6>{{ data.category }}</h6>
              </div>
            </td>
            <td>{{ data.total }}</td>
            <td><span class="label label-light color-3">{{ data.verified }}</span></td>
            <td>{{ data.declined }}</td>
            <td>{{ data.pending }}</td>
            <!-- <td>
              <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </td> -->
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
