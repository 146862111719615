import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { featuresData } from '../../../../shared/interface/property';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturesComponent {

  @Input() featuresData: featuresData[];
  @Input() instituteData : any;
  
}
