import { Component, Input, Output, EventEmitter, OnInit, output, ChangeDetectionStrategy, inject, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { CommonRightButtonComponent } from '../common-right-button/common-right-button.component';
import { CommonDropdownsComponent } from '../common-dropdowns/common-dropdowns.component';
import { Router } from '@angular/router';
import { RangSliderComponent } from '../rang-slider/rang-slider.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServicesPopUp } from 'src/app/shared/interface/property';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-common-banner-1',
  templateUrl: './common-banner-1.component.html',
  styleUrl: './common-banner-1.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonBanner1Component implements OnInit,OnDestroy {
  @ViewChild('signInPage') SignInPopUp!: TemplateRef<any>;
  @ViewChild('signInPage2') SignInPopUp2!: TemplateRef<any>;

  private readonly _authService = inject(AuthService);
  @Input() popUpButton: ServicesPopUp = {
    text: '',
    isShown: false,
    componentName: ''
  };
  @Input() bannerText: string = 'Universities List';
  @Input() heading1: string = '';
  @Input() heading2: string = '';
  @Input() imagePath: string = '';
  @Input() AdvanceSearch: boolean = true;
  @Input() isAdvanceSearchTovisible: boolean = true;

  @Output() resetPaginationEvent = new EventEmitter<void>();
  @Output() childEvent = new EventEmitter<any>();
  @Output() sendTheSearchValue = new EventEmitter<any>();
  @Output() callAPionEmptyFields = new EventEmitter<any>();
  isAdvanceBTnVisible: boolean = false;
  isDropdownVisible: boolean = false;
  isNavigateToDashboard: boolean = false;


  public currentPageURL: string = '';
  routeUrl!: string;

  @Input() showSearch: boolean = true;
  @Input() showTitle: boolean = true;
  @Input() showCountryDropdown: boolean = true;
  @Input() showStateDropdown: boolean = true;
  @Input() showCityDropdown: boolean = true;
  @Input() showDegreeDropdown: boolean = true;
  @Input() showJobTypeDropdown: boolean = true;
  @Input() showScholarshipTypeDropdown: boolean = true;
  @Input() showLanguageDropDown: boolean = false;
  @Input() showRangeSlider: boolean = true;
  @Input() showCategoryDropDown: boolean = false;
  @Input() showLocationDropDown: boolean = false;
  @Input() showSubjectDropDown: boolean = false;
  @Input() showInstituteTypeDropdown: boolean = false;
  @Input() totalRecords: number = 0;


  private modalService = inject(NgbModal);


  //subscription

  private PopUpOpeningSub = new Subscription;

  constructor(
    // private commonDataService: CommonDataSharingService,
    private router: Router,
    private apiCall: ApiCallService,
    private dataShare: DataShareService,
  ) {
    this.PopUpOpeningSub = this.dataShare.openLoginPopUp$.subscribe((res) => {
      if (res) {
        if (res == true) {
          this.openSpecificPopUp();
        }
      }
    })

  }
  ngOnDestroy(): void {
    this.PopUpOpeningSub.unsubscribe();
  }

  ngOnInit(): void {
    if (this.showCountryDropdown) {
      this.getCountry();
    }
    if (this.showScholarshipTypeDropdown) {
      this.getScholarshipType();
    }
    if (this.showJobTypeDropdown) {
      this.getJobType();
    }
    if (this.showSubjectDropDown) {
      this.getSubjectDropDown();
    }
    if (this.showDegreeDropdown) {
      this.getDegreeDropDown();
    }
    if (this.showCategoryDropDown) {
      this.getCategoryDropDown();
    }
  }

  componentNameToOpen: string = ''
  openSpecificPopUp(): void {
    debugger
    const token = this._authService.getToken();
    this.componentNameToOpen = this.popUpButton.componentName;
    if (!token || token == null) {
      this.componentNameToOpen = '';
      setTimeout(() => {
        if (this.SignInPopUp2) {
          this.dataShare.openSignInModal(this.SignInPopUp2);
        };
      }, 100);
    } else {
      if (this.componentNameToOpen == 'teachear') {
        this.openSigninPopUp();
      } else {
        this.dataShare.openServicesPopup(this.componentNameToOpen);
      }
    }

  }

  onUserLoggedIn() {
    this.modalService.dismissAll();
  }

  openSigninPopUp() {
    const token = this._authService.getToken();
    if (!token) {
      setTimeout(() => {
        if (this.SignInPopUp) {
          this.dataShare.openSignInModal(this.SignInPopUp);
        };
      }, 100);
    } else {
      this.router.navigate(['/user/profile'])
    }
  }





  // Function to receive slider values from RangeSliderComponent
  receiveSliderValues(event: { min: string; max: string }) {
    this.minValue = event.min;
    this.maxValue = event.max;
  }
  // Functions to toggle Advance Search Button and Dropdown

  toggleAdvanceBtn() {

    this.isAdvanceBTnVisible = !this.isAdvanceBTnVisible;
    this.closedsearch();

  }
  closedsearch() {
    if (!this.isAdvanceBTnVisible) {
      this.AdvanceSearch = false;
    } else {
      this.AdvanceSearch = true;
    }
  }

  toggleDropdown() {
    if (this.isAdvanceBTnVisible) {
      this.isDropdownVisible = !this.isDropdownVisible;
    } else {
      this.isDropdownVisible = true;
    }
  }

  Country: string = '';
  City: string = '';
  State: string = '';
  searchText: string = '';
  degree: string = '';
  jobType: string = '';
  scholarshipType: string = '';
  location: string = '';
  Subject: string = '';

  minValue: string = '0';
  maxValue: string = '0';
  searchInputData: string = ''
  onSearchselected(): void {
    // this.searchText = event.target.value;
    this.searchText = this.searchInputData;

  }

  onSalarySelected(option: any): void {
    this.minValue = option.value;
    this.maxValue = option.value;
  }

  onLocationSlected(option: any): void {
    this.location = option.value;
  }

  Locations = [
    { id: 1, value: 'New York' },
    { id: 2, value: 'Los Angeles' },
    { id: 3, value: 'Chicago' },
    { id: 4, value: 'Houston' },
    { id: 5, value: 'Phoenix' },
  ];

  ////////////////////////////////////////////////////
  /////////  Country drop down  //////////
  ///////////////////////////////////////////////////// 
  CountrysearchTerm: string = "";
  CountryList = []
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    if (!this.showCountryDropdown) {
      return;
    }
    this.apiCall
      .GetCallWithoutToken('DropDown/GetCountryDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      })

  }

  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = '';
      this.City = '';
    }
    this.Country = option.value;
    this.getStateList(option.id);

  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue
    if (this.Country === '') {
      this.State = '';
      this.City = ''
    }
  }

  ////////////////////////////////////////////////////
  /////////  State drop down data //////////
  ////////////////////////////////////////////////////

  StatesearchTerm: string = "";

  StateList = []
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }

  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = '';
    }
    this.State = option.value;
    this.getCityList(option.id);

  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue
    if (this.State === '') {
      this.City = ''
    }
  }

  getStateList(countryId: number) {
    if (!this.showStateDropdown) {
      return;
    }
    this.apiCall
      .GetCallWithoutToken('DropDown/GetStateDropDown?CountryId=' + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }


  ////////////////////////////////////////////////////
  /////////  City drop down data //////////
  ////////////////////////////////////////////////////


  CityList = []
  onCitySelected(option: any): void {
    this.City = option.value;
  }

  getCityList(stateId: number) {
    if (!this.showCityDropdown) {
      return;
    }
    this.apiCall.GetCallWithoutToken('DropDown/GetCityDropDown?StateId=' + stateId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      }
    )
  }




  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Language
  //////////////////////////////////////////////

  languageList = [
    { id: 1, value: 'Arabic' },
    { id: 2, value: 'Bengali' },
    { id: 3, value: 'Chinese' },
    { id: 4, value: 'English' },
    { id: 5, value: 'French' },
    { id: 6, value: 'German' },
    { id: 7, value: 'Hindi' },
    { id: 8, value: 'Italian' },
    { id: 9, value: 'Japanese' },
    { id: 10, value: 'Korean' },
    { id: 11, value: 'Portuguese' },
    { id: 12, value: 'Russian' },
    { id: 13, value: 'Spanish' },
    { id: 14, value: 'Urdu' },
  ];
  languageType: string = '';
  Language: string = '';
  onLanguageSelected(option: any) {
    this.Language = option.value;
  }
  SearchTermLanguage(searchTerm: any) {
    this.Language = searchTerm;
  }


  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Scholarship Type
  //////////////////////////////////////////////
  scholarshipSearchTerm: string = '';
  ScholarshipList = [];
  onScholarshipSelected(option: any) {
    this.scholarshipType = option.value;
  }
  SearchTermType(searchTerm: any) {
    this.scholarshipType = searchTerm;
  }
  getScholarshipType() {
    if (!this.showScholarshipTypeDropdown) {
      return;
    }
    this.apiCall
      .GetCallWithoutToken('DropDown/GetScholarshipDropDown')
      .subscribe((response) => {
        this.ScholarshipList = response?.data;
      });
  }



  ////////////////////////////////////////////
  // code for Custom InstituteType Dropdown
  //////////////////////////////////////////////
  instituteType: string = '';
  InstituteTypeList = [
    { id: 1, value: "University" },
    { id: 2, value: "College" },
    { id: 3, value: "School" },
    { id: 4, value: "Academy" }
  ];
  onInstiuteTypeSelected(option: any) {
    this.instituteType = option.value;
  }
 
  
  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Job 
  //////////////////////////////////////////////
  jobTypeSearchTerm: string = '';
  JobTypeList = [];
  onJobTypeSelected(option: any) {
    this.jobType = option.value;
  }
  SearchTermJob(searchTerm: any) {
    this.jobType = searchTerm;
  }
  getJobType() {
    if (!this.showJobTypeDropdown) {
      return;
    }
    this.apiCall
      .GetCallWithoutToken('DropDown/GetJobDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.JobTypeList = response?.data;
        }
      });
  }

  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Subject 
  //////////////////////////////////////////////
  subjectSearchTerm: string = '';
  SubjectList = [];
  onSubjectSelected(option: any): void {
    this.Subject = option.value;
  }
  SearchTermSubject(searchTerm: any) {
    this.Subject = searchTerm;
  }
  getSubjectDropDown() {
    if (!this.showSubjectDropDown) {
      return;
    }
    this.apiCall
      .GetCallWithoutToken('DropDown/GetSubjectDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.SubjectList = response?.data;
        }
      });
  }


  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Degree
  //////////////////////////////////////////////
  degreeSearchTerm: string = '';
  DegreeList = [];
  onDegreesSelected(option: any) {
    this.degree = option.value;
  }
  SearchTermDegree(searchTerm: any) {
    this.degree = searchTerm;
  }
  getDegreeDropDown() {
    if (!this.showDegreeDropdown) {
      return;
    }
    this.apiCall
      .GetCallWithoutToken('DropDown/GetDegreeDropDown')
      .subscribe((response) => {
        this.DegreeList = response?.data;
      });
  }

  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Book category
  //////////////////////////////////////////////
  bookCategory: string = '';
  categorySearchTerm: string = '';
  CategoryList = [];
  onCategorySelected(option: any) {
    this.bookCategory = option.value;
  }
  SearchTermCategory(searchTerm: any) {
    this.bookCategory = searchTerm;
  }
  getCategoryDropDown() {
    if (!this.showCategoryDropDown) {
      return;
    }
    this.apiCall
      .GetCallWithoutToken('DropDown/GetBookDropDown')
      .subscribe((response) => {
        this.CategoryList = response?.data;
      });
  }

  inputSearchData(event: any) {
    const val = event.target.value
    // if (val != "") {
    this.sendTheSearchValue.emit(val);
    // }

  }



  passData() {
    const data = {
      country: this.Country,
      state: this.State,
      city: this.City,
      search: this.searchInputData,
      degree: this.degree,
      jobType: this.jobType,
      scholarshipType: this.scholarshipType,
      language: this.Language,
      category: this.bookCategory,
      subject: this.Subject,
      location: this.location,
      minValue: this.minValue,
      maxValue: this.maxValue,
      instituteType : this.instituteType
    };
    this.childEvent.emit(data);
  }


  clearAllFiels() {
    const data = {
      country: '',
      state: '',
      city: '',
      search: '',
      degree: '',
      jobType: '',
      scholarshipType: '',
      instituteType : '',
      language: '',
      category: '',
      subject: '',
      location: '',
      minValue: '',
      maxValue: '',
    };

    this.Country = '';
    this.State = '';
    this.City = '';
    this.degree = '';
    this.jobType = '';
    this.scholarshipType = '';
    this.bookCategory = '';
    this.Subject = '';
    this.location = '';
    this.minValue = '0';
    this.maxValue = '0';
    this.instituteType = '';
    this.searchInputData = '';

    this.resetSlider();
    this.childEvent.emit(data);
    this.callAPionEmptyFields.emit()
    this.resetPaginationEvent.emit();

  }

  resetSlider() {
    // this.commonDataService.sliderReset(true);
  }
  // onResetButtonClick(): void {
  //   
  //   this.resetPaginationEvent.emit();
  // }
}
