@if (isButtonCenter === false) {
<div class="col-lg-12 py-4">
  <div class="text-right">
    <a class="btn btn-gradient color-2 btn-pill" (click)="handleClick()" (click)="onButtonClick()">
      <ng-container *ngIf="icon">
        <i [class]="icon"></i>
      </ng-container>{{ buttonText }}
    </a>
  </div>
</div>
} @else if (isButtonCenter === true) {
<div class="col-lg-12 py-4">
  <div class="text-center">
    <a class="btn btn-gradient color-2 btn-pill" (click)="handleClick()" (click)="onButtonClick()">
      <ng-container *ngIf="icon">
        <i [class]="icon"></i>
      </ng-container>{{ buttonText }}
    </a>
  </div>
</div>
}