<section class="layout-home8 bg-img-2 ratio_landscape">
  <div class="container p-0">
    <div class="row m-0">
      <div class="col-xl-7 col-lg-8">
        <div class="home-left-content">
          <div class="home-content">
            <h1 class="mt-0">
              You're local Real estate
              <br />
              professionals
            </h1>
            <h6 class="font-roboto mb-0">Residences can be classified by and connected to residences. Different types of housing can be use same physical type.</h6>
          </div>
          <div class="search-with-tab">
            <ul class="nav nav-tabs" id="home-tab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" aria-label="clickable-text" role="tab" [ngClass]="{'active show': openTab == 'sell'}" (click)="tabbed('sell')" style="cursor: pointer;">For Sell</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-label="clickable-text" role="tab" [ngClass]="{'active show': openTab == 'rent'}" (click)="tabbed('rent')" style="cursor: pointer;">For Rent</a>
              </li>
            </ul>
            <div class="tab-content" id="home-tabContent">
              <div class="tab-pane fade show active active">
                  <app-filter-box-two></app-filter-box-two>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-5 col-lg-4">
        <div class="home-right-image">
          <img src="assets/images/others/building.jpg" alt="background-image" class="bg-img">
        </div>
      </div>
    </div>
    <div class="bg-dots" style="z-index: 1;">
      <app-animation-dots></app-animation-dots>
    </div>
  </div>
</section>
