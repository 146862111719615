<div class="page-section" id="review">
  <div class="headingsReview row">
    <h4 class="content-title col-6">Reviews</h4>
    <div class="col-6 ">
      <h6 class="total-reviews">Total Reviews :246</h6>
    </div>
  </div>
  <div class="review" [ngClass]="{'scroll-section' : reviews.length>3}" id="scrollableDiv">
    @if(reviews.length < 0){ No Review Found }@else{ @for(review of reviews; track review){ <div class="review-box"
      [ngClass]="{ 'review-child' :  false }" id="index">
      <div class="media">
        @if(review?.ratingImageBase64?.length > 20){
        <img [src]="review?.ratingImageBase64" class="img-70" alt="user-image" />
        }@else {
        <span class="review?.ratingImageBase64">{{ review.name.charAt(0) | uppercase }}</span>
        }
        <!-- <img src="../../../../../assets/images/1.jpg" class="img-60" alt="user-image"> -->
        <div class="media-body">
          <div class="row">
            <div class="col-lg-8 col-md-6 col-sm-12 heading-section">
              <h6>{{review?.name}}</h6>
              <p>{{review?.ratingDate?.substring(0,10)}}</p>
            </div>

            <div class="rating-starts col-lg-4 col-md-6 col-sm-12">

              <i class="fas fa-star" *ngFor="let _ of [].constructor(review.numberOfStars); let i = index"></i>

              <!-- Empty stars -->
              <i class="far fa-star" *ngFor="let _ of [].constructor(5 - review.numberOfStars); let i = index"></i>

            </div>
          </div>
          {{ review?.comment | slice:0:review.showFullComment ? review?.comment.length : 200 }}
          @if(review?.comment.length > 200){
          <span>
            ... <a (click)="toggleComment(review, $event)" aria-label="clickable-text">{{ review.showFullComment ? 'Read Less' : 'Read More' }}</a>
          </span>
          }

        </div>

      </div>
  </div>
  }
  <div class="show-more-reviews">
    <!-- <button>Show More Reviews</button> -->
    <p>Show More Reviews</p>
  </div>
  }
</div>
<hr />
<div class="all-rating">
  <h4 class="content-title">Write a Review</h4>
  <div class="rating-given-by-user">
    <div class="rating-by-user">
      <!-- Star ratings -->
      <input value="5" name="rating" id="star5" type="radio" (change)="onRatingChange(5)"
        [checked]="selectedRating === 5">
      <label for="star5"></label>
      <input value="4" name="rating" id="star4" type="radio" (change)="onRatingChange(4)"
        [checked]="selectedRating === 4">
      <label for="star4"></label>
      <input value="3" name="rating" id="star3" type="radio" (change)="onRatingChange(3)"
        [checked]="selectedRating === 3">
      <label for="star3"></label>
      <input value="2" name="rating" id="star2" type="radio" (change)="onRatingChange(2)"
        [checked]="selectedRating === 2">
      <label for="star2"></label>
      <input value="1" name="rating" id="star1" type="radio" (change)="onRatingChange(1)"
        [checked]="selectedRating === 1">
      <label for="star1"></label>
    </div>
  </div>

</div>

<form class="review-form" [formGroup]="reviewForm">
  <div class="row">
    <div class="form-group col-md-6">
      <input type="text" class="form-control" placeholder="Name" formControlName="name" readonly>
    </div>
    <div class="form-group col-md-6">
      <input type="email" class="form-control" placeholder="Email" formControlName="email" readonly>
    </div>
  </div>
  <div class="form-group">
    <textarea rows="4" cols="40" class="form-control" placeholder="Comment" formControlName="review"></textarea>
  </div>
  <!-- <button type="submit" class="btn btn-gradient color-2 btn-pill" (click)="SubmitReview()">Submit</button> -->
  <app-center-button [buttonText]="'Submit'" (click)="SubmitReview(signInPage)"></app-center-button>
</form>
</div>



<ng-template #signInPage>
  <div class="modal-content">
    <app-log-in-user (userLoggedIn)="closePopUp()"></app-log-in-user>
  </div>
</ng-template>