import { ChangeDetectionStrategy, Component, inject, Input, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { Gallery, ImageSize, ThumbnailsPosition } from "ng-gallery";
import { Lightbox } from "ng-gallery/lightbox";
import { latestForRent } from "../../../../../shared/interface/property";
import { PropertyBoxGridService } from "../../../../../shared/services/property-box-grid.service";
import { PropertyService } from "../../../../../shared/services/property.service";
import { addCompareItem } from "../../../../../shared/store/actions/compare.action";
import { addWishlistItem } from "../../../../store/actions/wishlist.action";
import { CommonUtilsServiceService } from "src/app/shared/services/common-utils-service.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-property-box-two",
  templateUrl: "./property-box-two.component.html",
  styleUrls: ["./property-box-two.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyBoxTwoComponent {
  @Input() title: string = "";
  @Input() institutesData: latestForRent;
  @Input() latestForRentData: latestForRent;
  @Input() textColor: boolean = false;
  @Input() tagClass: string;
  @Input() data: number;
  @Input() listView: boolean = false;
  @Input() thumbnail: boolean = false;
  @Input() thumbnail_video: boolean = false;
  @Input() gridImages: boolean = false;
  @Input() isDescriptionShown: boolean = false;

  @ViewChild("readMoreModal") readMoreModal: any;

  public listViewBox: boolean = false;
  public col_lg_6: boolean = true;
  public col_md_6: boolean = true;
  public col_lg_4: boolean = false;
  public col_xxl_3: boolean = false;
  public col_6: boolean = false;
  public col_xl_12: boolean = false;
  public col_md_12: boolean = false;

  isAdmissionsRoute: boolean = false;

  public favouriteData: latestForRent[] = [];
  private _utils = inject(CommonUtilsServiceService);
  constructor(
    public gallery: Gallery,
    public lightbox: Lightbox,
    private propertyBoxGridService: PropertyBoxGridService,
    public propertyService: PropertyService,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) {
    // console.log("Institutes Data: ", this.institutesData);
    ;
    const currentRoute = this.router.url;
    // console.log("Current Route: ", currentRoute);

    if (
      currentRoute === "/admissions/all-admissions"
      || currentRoute === "/institutes/all-institutes"
      || currentRoute === "/scholarships/all-scholarships"
      || currentRoute === "/jobs/all-jobs"
      || currentRoute === "/hostels/all-hostels"
    ) {
      this.isAdmissionsRoute = true;
    }
  }

  ngOnInit() {
    const lightboxRef = this.gallery.ref("lightbox");

    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,
    });


    // console.log("tagclass is", this.tagClass)
  }

  ngDoCheck() {
    this.col_lg_6 = this.propertyBoxGridService.col_lg_6;
    this.col_md_6 = this.propertyBoxGridService.col_md_6;
    this.col_lg_4 = this.propertyBoxGridService.col_lg_4;
    this.col_xxl_3 = this.propertyBoxGridService.col_xxl_3;
    this.col_6 = this.propertyBoxGridService.col_6;
    this.col_xl_12 = this.propertyBoxGridService.col_xl_12;
  }

  addWishlist(data: latestForRent) {
    this.store.dispatch(new addWishlistItem(data));
  }

  addCompare(data: latestForRent) {
    this.store.dispatch(new addCompareItem(data));
  }

  getDetails(id: number) {
    this.router.navigate(["/property/image-slider"], {
      relativeTo: this.route,
      queryParams: { id: id },
      queryParamsHandling: "merge", // preserve the existing query params in the route
      skipLocationChange: false, // do trigger navigation
    });
  }

  openReadMoreModal() {
    this.modalService.open(this.readMoreModal, { centered: true });
  }

  email: string = "example@example.com"; // Dummy email data
  contact: string = "+1234567890"; // Dummy contact data

  getInstitutebyId(id: number, moduleType: any) {
    if (moduleType == "institute") {
      this._utils.navigateTo("/institutes/institute-details", {
        instituteid: id,
      });
    } else if (moduleType == "admission") {
      this._utils.navigateTo("/admissions/admission-details", {
        admissionid: id,
      });
    } else if (moduleType == "jobs") {
      this._utils.navigateTo("/jobs/job-details", { jobid: id });
    } else if (moduleType == "scholarships") {
      this._utils.navigateTo("/scholarships/scholarship-details", {
        scholarshipid: id,
      });
    } else if (moduleType == "books") {
      this._utils.navigateTo("/books/book-details", { bookid: id });
    } else if (moduleType == "hostel"){
      this._utils.navigateTo('/hostels/hostel-details', { hostelid: id });
    }
  }

  get truncatedEmail(): string {
    return this.institutesData?.email 
      ? (this.institutesData.email.length > 60 
         ? `${this.institutesData.email.substring(0, 60)}...` 
         : this.institutesData.email)
      : '';
  }




  /// code for copy to clipboard


  //   copyIcon.addEventListener('click', ()=>{
  //     if(passBox.value != "" || passBox.value.length >=1){
  //         navigator.clipboard.writeText(passBox.value);
  //         copyIcon.innerText = "check";
  //         copyIcon.title = "Password Copied";

  //         setTimeout(()=>{
  //             copyIcon.innerHTML = "content_copy";
  //             copyIcon.title = "";
  //         }, 3000)
  //     }
  // });


  toolTipText:string = "Copy";
  copyToClipBoard(valueToCopy: any) {
    const tooltip = document.querySelectorAll(".tooltip");
    if (valueToCopy != "" || valueToCopy?.length >= 1) {
      navigator.clipboard.writeText(valueToCopy);
        this.toolTipText = "Copied";
      
    }
  }


  tooltipVisible = false;

  Tooltip(res : boolean) {
    this.tooltipVisible = res;
    if(res == false){
      this.toolTipText = "Copy"
    }
  }
}
