<section class="feature-section ratio_landscape">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-title [titleClass]="'title-3'" [tag]="'Featured'" [heading]="'Property'" [desc]="desc" [svgIcon]="true" [type]="'simple'" [svgClass]="svgClass"></app-title>
            </div>
            <div class="feature-3 classic-feature arrow-image zoom-gallery" >
              <app-featured-property [featuredProperty]="featuredProperty" [type]="'basic'" [tagClass]="tagClass" ></app-featured-property>
            </div>
        </div>
    </div>
</section>
