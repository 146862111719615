import { ChangeDetectionStrategy, Component, inject, input, Input } from '@angular/core';
import { latestForRent } from '../../../../shared/interface/property';
import { PropertyService } from '../../../../shared/services/property.service';
import { ApiCallService } from 'src/app/shared/services/api-call.service';

@Component({
  selector: 'app-related-property',
  templateUrl: './related-property.component.html',
  styleUrls: ['./related-property.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedPropertyComponent {

  @Input() type: string;
  @Input() totalData: number;
  @Input() instituteData: any;
  @Input() heading: any;
  @Input() isDescriptionShown:boolean = false;
  @Input() isSkeletonShown:boolean = true;
  relatedCardsData: any;

  private _apiCall = inject(ApiCallService);

  public latestForRentData: latestForRent[] = [];

  constructor(private propertyService: PropertyService) { }

  ngOnInit() {
    // this.checkandCallforRelatedCards();
  }


  checkandCallforRelatedCards() {

    if (this.instituteData?.moduleType === "institute") {
      const payload = {
        specialization: this.instituteData?.jobType
      }
      this._apiCall.PostCallWithoutToken(payload, "Jobs/GetRelatedJobs").subscribe((res) => {
        this.relatedCardsData = res.data;
      })
    } else {

      this.propertyService.getLatestJobs().subscribe((response) => {
        this.relatedCardsData = response;
        this.relatedCardsData = this.relatedCardsData.slice(0, 3);
      })
      // this.propertyService.latestForRentData().subscribe((response) => {
      //   this.latestForRentData = response.latestForRent.filter((item) =>
      //     item.type.includes('slider_filter_search')
      //   );
      // });
    }
  }
}
