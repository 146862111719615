<!-- <div class="mt-90">
</div> -->
<section class="testimonial-bg testimonial-layout6 mt-90">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="mt-90">
                    <app-title [isLabelAdded]="true" [isLineAdded]="true" [tag]="tag" [titleClass]="'title-1'" [heading]="'Happy Clients'" [type]="'simple'" [textWhite]="true" ></app-title>
                </div>
                <div class="testimonial-2 arrow-light">
                    <app-happy-clients [happyClientsData]="happyClientsData" [type]="'simple'" [tagClass]="tagClass"></app-happy-clients>
                </div>
            </div>
        </div>
    </div>
</section>
