<div class="common-card available-property">
  <div class="common-header">
    <h5>Available property</h5>
  </div>
  <div class="radial-property">
    <div id="radial">
      <apx-chart [chart]="availablePropertyData.chart" [plotOptions]="availablePropertyData.plotOptions" [series]="availablePropertyData.series"
                 [labels]="availablePropertyData.labels" [colors]="availablePropertyData.colors" [stroke]="availablePropertyData.stroke">
      </apx-chart>
    </div>
  </div>
</div>
