import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cv2',
  templateUrl: './cv2.component.html',
  styleUrl: './cv2.component.scss'
})
export class Cv2Component implements OnInit {

  templateId: number | null = null;
  @Input() userData: any = {};

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id'); 
      this.templateId = id ? +id : null;
    });
    // console.log('User Data in cv2 :', this.userData);

  }

}
