import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cv8',
  templateUrl: './cv8.component.html',
  styleUrl: './cv8.component.scss'
})
export class Cv8Component {
  templateId: number | null = null;
  @Input() userData: any = {};

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id'); 
      this.templateId = id ? +id : null;
    });
    // console.log('User Data in cv4 :', this.userData);

  }
}
