<section class="property-section slick-between slick-shadow property-color-6">
    <div class="container">
        <div class="row ratio_landscape">
            <div class="col">
              @if(titleType == 'basic'){
                <app-title [titleClass]="'title-3 text-start'" [heading]="'Latest Property'" [desc]="desc" [type]="'basic'"></app-title>
              }@else {
                <app-title [titleClass]="'title-1'" [tag]="tag" [heading]="heading" [textWhite]="false" [type]="'simple'" [tagClass]="tagClass"></app-title>
              }
                <div class="listing-hover-property row">
                  @for(data of propertyData; track data){
                    <div class="col-xl-4 col-md-6 wow fadeInUp">
                        <app-property-box [propertyData]="data" [type]="'simple'" [tagClass]="tagClass" ></app-property-box>
                    </div>
                  }
                </div>
            </div>
        </div>
    </div>
</section>
