import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cv2',
  templateUrl: './cv2.component.html',
  styleUrl: './cv2.component.scss'
})
export class Cv2Component {

  @Input() userData: any = {};

}
