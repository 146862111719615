<app-parallax-image-home-section></app-parallax-image-home-section>

<app-corporate-property-listing [data]="6" [class]="'col-xl-4 col-lg-6 wow fadeInUp'" [svgClass]="true" [tagClass]="'color-7'" [containerClass]="false"></app-corporate-property-listing>

<div class="parallax-image-slider">
    <app-corporate-featured-property [svgClass]="true" [tagClass]="'color-7'"></app-corporate-featured-property>
</div>

<app-corporate-provided-services [svgClass]="true" [tagClass]="'color-7'"></app-corporate-provided-services>

<app-corporate-property-listing [data]="8" [class]="'col-xxl-3 col-xl-4 col-md-6 wow fadeInUp'" [svgClass]="true" [tagClass]="'color-7'" [containerClass]="true"></app-corporate-property-listing>

<app-corporate-pricing-plan [svgClass]="true" [tagClass]="'color-7'"></app-corporate-pricing-plan>

<app-corporate-banner [svgClass]="true" [tagClass]="'color-7'"></app-corporate-banner>

<app-corporate-agents [svgClass]="true" [tagClass]="'color-7'"></app-corporate-agents>

<app-corporate-clients [svgClass]="true" [tagClass]="'color-7'"></app-corporate-clients>

<app-corporate-latest-blog [svgClass]="true" [tagClass]="'color-7'" ></app-corporate-latest-blog>
