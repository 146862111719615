import { Component, inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { MetaService } from 'src/app/shared/services/meta.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-tutorial-list',
  templateUrl: './tutorial-list.component.html',
  styleUrl: './tutorial-list.component.scss'
})
export class TutorialListComponent {
  public noRecordFound = false;
  public isDataLoading = true;
  isErrorOccurred : boolean = false;
  public isSpinnerShown = false;
  public title = '';
  bannerImage : string = '';
  recievedData : any;
  private _spinner = inject(NgxSpinnerService)
  private _utils = inject(CommonUtilsServiceService)
  tutorialData : any[] = [];

  private readonly metaService : MetaService = inject(MetaService);

  constructor(public propertyService : PropertyService) {}

  ngOnInit(){
    this.fetchTutorialsData(this.currentPage);

    this.metaService.updateTitle('All Tutorials | AdmissionLylo');
    this.metaService.updateMetaTags([
      { name: 'description', content: 'Your page description here' },
      { name: 'keywords', content: 'angular, seo, meta tags' },
      { name: 'author', content: 'Your Name' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.metaService.setCanonicalURL();
    this.metaService.initializeMetaData();

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onReset() {

    this.currentPage = 1
    this.isSpinnerShown = true;
    this._spinner.show();
    this.fetchTutorialsData(this.currentPage);
  }

  handleChildEvent(data: any) {
    this.recievedData = data;
    // console.log("rec", this.receivedData)
    this.CheckAndCallAPI();
  }


  handleSearchData(event: any) {

    if (event === '') {
      this.isSpinnerShown = true;
      this._spinner.show();
      this.recievedData.search = '';

      this.fetchTutorialsData(1)
    }
  }

///////////////////   Pagination  //////////////////////////////
pages: (number | string)[] = [];
currentPage: number = 1;
totalRecords: number = 0;
itemsPerPage: number = 8;
maxVisiblePages: number = 5;
displayedItems: any[] = [];

fetchTutorialsData(pageNumber : number):void {
this.propertyService.getAllTutorials(this.recievedData || {}, pageNumber, this.itemsPerPage).subscribe(
  (response)=> {
    this.isDataLoading = false;
    this.isErrorOccurred = false;
    this.tutorialData = response.data;
    this.totalRecords = this.tutorialData[0]?.totalRecords;
    this.calculatePages();
    console.log("Tutorials Data", this.tutorialData);

    if (this.tutorialData == null || this.tutorialData?.length <= 0) {
      this.noRecordFound = true;
      this.tutorialData = [];
      this.totalRecords = 0;
    } else {
      this.noRecordFound = false;

          this.tutorialData.forEach((tutorial) => {
            tutorial.blobUrls = [];  
            if (Array.isArray(tutorial.img)) {
              tutorial.img.forEach((image: { url: string; }) => {
                if (image.url && image.url.includes('base64,')) {
                  const base64Data = image.url.split('base64,')[1];
                  const blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
                  tutorial.blobUrls.push(blobUrl);
                  console.log("Tutorial Blob URL:", blobUrl);
                } else {
                  tutorial.blobUrls.push('path/to/default/image.png'); 
                }
              });
            }
          });
        }
        this.isSpinnerShown = false;
        this._spinner.hide();
  },
  (error) => {
    console.error('Error fetching Tutorials data', error);
    this.isDataLoading = false;
    this.isErrorOccurred = true;
    this.isSpinnerShown = false;
    this._spinner.hide();
    }
  );
}

onPageChanged(event: any): void {
  this.currentPage = event;
  this.fetchTutorialsData(this.currentPage);
  this.isDataLoading = true;
  this.scrollToContentStart();
}
calculatePages(): void {
  const totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
  this.pages = [];
  if (totalPages <= this.maxVisiblePages) {
    this.pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  } else {
    const startPage = Math.max(
      this.currentPage - Math.floor(this.maxVisiblePages / 2),
      1
    );
    const endPage = Math.min(startPage + this.maxVisiblePages - 1, totalPages);

    if (startPage > 1) {
      this.pages.push(1);
      if (startPage > 2) {
        this.pages.push('...');
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      this.pages.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        this.pages.push('...');
      }
      this.pages.push(totalPages);
    }
  }
}

navigateToPage(page: any): void {
  if (page >= 1 && page <= this.pages.length) {
    this.currentPage = page;
    this.fetchTutorialsData(this.currentPage);
    this.scrollToContentStart();
  }
}

navigatePage(direction: 'prev' | 'next'): void {
  if (direction === 'prev' && this.currentPage > 1) {
    this.currentPage--;
  } else if (
    direction === 'next' &&
    this.currentPage < Math.ceil(this.totalRecords / this.itemsPerPage)
  ) {
    this.currentPage++;
  }
  this.fetchTutorialsData(this.currentPage);
  this.scrollToContentStart();
}

getDisplayRange(): string {
  const start = (this.currentPage - 1) * this.itemsPerPage + 1;
  const end = Math.min(start + this.itemsPerPage - 1, this.totalRecords);
  return `${start} – ${end}`;
}

scrollToContentStart(): void {
  const contentStart = document.getElementById('content-start');
  if (contentStart) {
    const topOffset = contentStart.getBoundingClientRect().top + window.pageYOffset - 95;
    window.scrollTo({ top: topOffset, behavior: 'smooth' });
  }
}

resetPagination(): void {
  this.currentPage = 1; // Reset current page to 1
  this.calculatePages(); // Recalculate pages
  this.fetchTutorialsData(this.currentPage);
  this.scrollToContentStart(); // Scroll to content start
}

onResetPagination(): void {
  this.resetPagination();
}

CheckAndCallAPI() {

  console.log("da", this.recievedData)
  const filters = {
    search: this.recievedData?.search || "",
    courseType: this.recievedData?.courseType || "",
    courseLevel: this.recievedData?.courseLevel || "",
    coursePrice: this.recievedData?.coursePrice || "",
  };

  if (filters.courseType !== "" ||
    filters.courseLevel !== "" ||
    filters.coursePrice !== "" ||
    filters.search !== "" ) {

    this.currentPage = 1;
    this.isSpinnerShown = true;
    this._spinner.show();
    this.propertyService.getAllTutorials(filters, this.currentPage).subscribe(
      (response) => {
        this.tutorialData = response.data;
        // this.isLoading = false;
        this.totalRecords = response.totalRecords;
        this.calculatePages();
        if (this.tutorialData == null || this.tutorialData?.length <= 0) {
          this.noRecordFound = true;
        } else {
          this.noRecordFound = false;
        }
        this.isSpinnerShown = false;
        this._spinner.hide();

        // console.log('Mapped Data: ', this.jobsData);
      },
      (error) => {
        console.error('Error fetching jobs data', error);
        this.isDataLoading = false;
        this.isSpinnerShown = false;
        this._spinner.hide();


      }
    );
  }
}

}
