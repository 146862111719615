<app-modern-video-home-section></app-modern-video-home-section>

<app-slider-filter-latest-rent [propertyClass]="false" [tagClass]="'color-2'" [title]="title" [listView]="true" [titleClass]="'title-2'" [type]="'basic'" [heading]="heading" [desc]="desc" [total]="6"></app-slider-filter-latest-rent>

<app-enterprise-looking-for [latestForRentData]="latestForRentData" [title]="title" [tagClass]="'color-2'"></app-enterprise-looking-for>

<app-enterprise-property-of-day [title]="title"></app-enterprise-property-of-day>

<app-enterprise-property-service [title]="title"></app-enterprise-property-service>

<app-enterprise-featured-city [title]="title"></app-enterprise-featured-city>

<app-enterprise-banner></app-enterprise-banner>

<app-enterprise-people-say></app-enterprise-people-say>

<app-enterprise-latest-blog></app-enterprise-latest-blog>

<app-enterprise-brand></app-enterprise-brand>

