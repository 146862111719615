import { Component, inject, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserCentralizedDataService } from 'src/app/shared/services/user-centralized-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cv-templates',
  templateUrl: './cv-templates.component.html',
  styleUrl: './cv-templates.component.scss'
})
export class CvTemplatesComponent {
  bannerImage: string = 'assets/images/banner/all-institute-banner.jpg';
  public noRecordFound = false;
  public isDataLoading = true;
  isErrorOccurred: boolean = false;
  public isSpinnerShown = false;
  gridType: string = 'grid-3';
  cvData: any = {}; // Centralized CV Data
  @Input() tagClass: string;
  @Input() sectionClass: string;
  private readonly _toaster = inject(ToastrService);
  public tag: string = 'CV';
  selectedCvId: string;
  isAllDataValid: boolean = false;

  // Templates array
  templates = [
    { templateId: 1, image_url: "../../../../../../assets/images/cv-images/cv1.png", isPaid: false },
    { templateId: 2, image_url: "../../../../../../../assets/images/cv-images/cv2.png", isPaid: false },
    { templateId: 3, image_url: "../../../../../../../assets/images/cv-images/cv3.png", isPaid: false },
    { templateId: 4, image_url: "../../../../../../../assets/images/cv-images/cv4.jpg", isPaid: false },
    { templateId: 5, image_url: "../../../../../../../assets/images/cv-images/cv5.png", isPaid: false },
    { templateId: 6, image_url: "../../../../../../../assets/images/cv-images/cv6.png", isPaid: false },
    { templateId: 7, image_url: "../../../../../../../assets/images/cv-images/cv7.png", isPaid: false },
    { templateId: 8, image_url: "../../../../../../../assets/images/cv-images/cv8.png", isPaid: false },
    { templateId: 9, image_url: "../../../../../../../assets/images/cv-images/cv9.png", isPaid: false },
    { templateId: 10, image_url: "../../../../../../../assets/images/cv-images/cv10.png", isPaid: false },
    // Add more templates here
  ];

  // Pagination variables
  displayedTemplates: any[] = []; // Current page data
  currentPage: number = 1; // Current page number
  itemsPerPage: number = 8; // Number of items per page
  totalPages: number = 0; // Total number of pages

  constructor(private sanitizer: DomSanitizer, private router: Router, private userCentralizedDataService: UserCentralizedDataService) { }

  ngOnInit() {
    this.cvData = this.userCentralizedDataService.getCompleteCvData();
    this.checkDataValidity();
    this.updatePagination(); // Initialize pagination
  }

  sanitizeImageUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  selectCv(cvId: string) {
    this.selectedCvId = cvId;
  }
  trackByTemplateId(index: number, template: any): number {
    return template.templateId;
  }


  checkDataValidity() {
    this.isAllDataValid = this.cvData.userDetails && Object.keys(this.cvData.userDetails).length > 0;
  }

  useTemplate(templateId: number) {
    const isUserDetailsValid = this.cvData.userDetails && Object.keys(this.cvData.userDetails).length > 0;

    if (isUserDetailsValid) {
      this.router.navigate([`/cv-builder/cv-template/cv/${templateId}`]);
    } else {
      this.router.navigate(['cv-builder/cv-data'], {
        queryParams: { templateId: templateId }
      });
    }
  }

  // Pagination logic
  updatePagination(): void {
    // Start and end indices for current page
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;

    // Slice the array to display only the templates of the current page
    this.displayedTemplates = this.templates.slice(startIndex, endIndex);

    // Update total pages
    this.totalPages = Math.ceil(this.templates.length / this.itemsPerPage);
  }

  onPageChanged(page: number): void {
    this.currentPage = page; // Update current page
    this.updatePagination(); // Update displayed templates
  }

  navigatePage(direction: 'prev' | 'next'): void {
    if (direction === 'prev' && this.currentPage > 1) {
      this.currentPage--;
    } else if (direction === 'next' && this.currentPage < this.totalPages) {
      this.currentPage++;
    }
    this.updatePagination();
  }

  getDisplayRange(): string {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(start + this.itemsPerPage - 1, this.templates.length);
    return `${start} – ${end}`;
  }
}
