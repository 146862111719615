<div class="mb-4 text-start">
  <h2>Let's Get In Touch</h2>
</div>
<form [formGroup]="contactForm" class="row gx-3" [ngClass]="rowClass">
  <div class="form-group col-md-12">
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <app-feather-icons [icon]="'user'"></app-feather-icons>
        </div>
      </div>
      <input type="text" class="form-control" placeholder="Enter your name" formControlName="name">
    </div>
  </div>
  <div class="form-group col-md-6">
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <app-feather-icons [icon]="'phone'"></app-feather-icons>
        </div>
      </div>
      <input placeholder="phone number" class="form-control" name="mobnumber" id="tbNumbers" formControlName="contact">
    </div>
  </div>
  <div class="form-group col-md-6">
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <app-feather-icons [icon]="'mail'"></app-feather-icons>
        </div>
      </div>
      <input type="email" class="form-control" placeholder="email address" formControlName="email">
    </div>
  </div>
  <div class="form-group col-md-12">
    <textarea class="form-control" id="exampleFormControlTextarea1"formControlName="message" rows="6">Write here something</textarea>
  </div>

  @if(captcha){
  <div class="submit-btn with-captcha">
    <div class="captcha">
      <div class="spinner">
        <label>
          <input type="checkbox">
          <span class="checkmark"><span>&nbsp;</span></span>
        </label>
      </div>
      <div class="text">
        I'm not a robot
      </div>
      <div class="logo">
        <img src="assets/images/inner-pages/recaptcha.png" alt="capture-image" />
        <p>reCAPTCHA</p>
        <small>Privacy - Terms</small>
      </div>
    </div>
    <div>
    </div>
    <!-- <button class="btn btn-gradient color-2 btn-flat" type="submit">Send Your Message</button> -->
    <app-center-button></app-center-button>
  </div>
  }

  @if(!captcha){
  <div class="col-md-12 submit-btn">
    <!-- <button class="btn btn-gradient color-2 btn-pill" type="submit">Send Your Message</button> -->
    <app-center-button [buttonText]="'Send Your Message'"></app-center-button>

  </div>
  }
</form>