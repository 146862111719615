<section class="testimonial-client">
  <div class="container">
    <div class="row">
      <div class="col">
        <app-title [titleClass]="'title-3 text-start'" [heading]="'Our Happy Client'" [desc]="desc" [type]="'basic'"></app-title>
        <div class="testimonial-4">
          <app-happy-clients [happyClientsData]="happyClientsData" [type]="'classic'"></app-happy-clients>
        </div>
      </div>
    </div>
  </div>
</section>
