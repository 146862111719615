import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-common-dropdowns',
  templateUrl: './common-dropdowns.component.html',
  styleUrl: './common-dropdowns.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonDropdownsComponent {


  @Input() clearThevalue:any = ''
  @Input() dataList: any[] = [];
  @Input() defaultText: string = '';
  @Input() SetValue: any;
  @Input() mySelectedValue: any;

  @Input() isDisabled: boolean = false;

  @Output() optionSelected = new EventEmitter<any>();
  @Output() sendTheValue = new EventEmitter<any>();

  isOpen = false;
  @Input() selectedOption!: string;
  @Input() searchTerm: string = '';
  private showDropDown!: Subscription;


  constructor(
    private elementRef: ElementRef
  ) {

  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // Click occurred outside the component, so close the dropdown
      this.isOpen = false;
    }
  }
  ngOnInit() {
  }
  
  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  isvaluePresent = false;


  selectOption(option: any) {
    this.selectedOption = option.value;
    this.isvaluePresent = true;
    this.optionSelected.emit(option);
    this.isOpen = false;
  }

  filterOptions(event: any) {
    this.sendTheValue.emit(event.target.value);
    this.searchTerm = event.target.value.toLowerCase();

  }
 

  getDisplayList() {
    return this.searchTerm ? this.dataList.filter(item => item.value.toLowerCase().includes(this.searchTerm)) : this.dataList;
  }
}
