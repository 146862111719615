<form>
  <div class="form-group">
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <app-feather-icons [icon]="'user'"></app-feather-icons>
        </div>
      </div>
      <input type="text" class="form-control" placeholder="Enter Email" required />
    </div>
  </div>
  <div class="form-group">
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <app-feather-icons [icon]="'lock'"></app-feather-icons>
        </div>
      </div>
      <input [type]="inputType" id="pwd-input" class="form-control" placeholder="Password" maxlength="8" required />
      <div class="input-group-apend">
        <div class="input-group-text" (click)="showPassword()">
          <i id="pwd-icon" class="far " [ngClass]="isShow == true ? 'fa-eye' : 'fa-eye-slash'"></i>
        </div>
      </div>
    </div>
    <div class="important-note">
      password should be a minimum of 8 characters and should contains letters and numbers
    </div>
  </div>
  <div class="d-flex">
    <label class="d-block mb-0" for="chk-ani">
      <input class="checkbox_animated color-2" id="chk-ani" type="checkbox" />
      <span>Remember me</span>
    </label>
    <a [routerLink]="['/page/other-pages/forgot-password']" aria-label="clickable-text" class="font-rubik text-color-2">Forgot password ?</a>
  </div>
  <div>
    <button type="submit" class="btn btn-gradient btn-pill color-2 me-sm-3 me-2" aria-label="Login">
      Log in
    </button>
    <a [routerLink]="['/page/other-pages/sign-up']" aria-label="clickable-text" class="btn btn-dashed btn-pill color-2">Create Account</a>
  </div>
  <div class="divider">
    <h6>or</h6>
  </div>
  <div>
    <h6>Log in with</h6>
    <div class="row social-connect">
      <div class="col-6">
        <a href="https://www.facebook.com/" aria-label="clickable-text" class="btn btn-social btn-flat facebook p-0" target="_blank">
          <i class="fab fa-facebook-f"></i>
          <span>Facebook</span>
        </a>
      </div>
      <div class="col-6">
        <a href="https://twitter.com/" aria-label="clickable-text" class="btn btn-social btn-flat twitter p-0" target="_blank">
          <i class="fab fa-twitter"></i>
          <span>Twitter</span>
        </a>
      </div>
      <div class="col-6">
        <a href="https://account.google.com" aria-label="clickable-text" class="btn btn-social btn-flat google p-0" target="_blank">
          <i class="fab fa-google"></i>
          <span>Google</span>
        </a>
      </div>
      <div class="col-6">
        <a href="https://www.linkedin.com/" aria-label="clickable-text" class="btn btn-social btn-flat linkedin p-0" target="_blank">
          <i class="fab fa-linkedin-in"></i>
          <span>Linkedin</span>
        </a>
      </div>
    </div>
  </div>
</form>
