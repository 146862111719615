<section class="breadcumb-section pt-0 position-relative">
  <!-- <div class="cta-service-v6 cta-banner position-relative" style="background-image: url('{{ imagePath }}');"> -->
  <div class="cta-service-v6 cta-banner position-relative" [style.background-image]="'url(' + imagePath + ')'">
    <div class="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50"></div>
    <!-- <img class="left-top-img wow zoomIn" src="images/vector-img/left-top.png" alt="image" />
    <img class="right-bottom-img wow zoomIn" src="images/vector-img/right-bottom.png" alt="image" />
    <img class="service-v1-vector d-none d-lg-block zi1" src="images/about/about-11.png" alt="image" />
    <img class="vector-v12 bounce-y d-none d-lg-block" src="images/about/element-12.png" alt="image" /> -->
    <div class="container overflow-visible">
      <div class="row wow fadeInUp">
        <div class="col-xl-7 headingsArea">
          <div class="position-relative">
            <div class="banner">
              <h1 class="heading1">{{ heading1 }}</h1>
              <h1 class="heading2">{{ heading2 }}</h1>
            </div>
            <!-- SearchBar with Dropdowns Starts Here -->
            <div class="wrapper">
              @if(showSearch){

              <div class="container-wrapper">
                <input checked="" class="checkbox" type="checkbox" (click)="toggleAdvanceBtn(); passData()" />
                <div class="mainbox">
                  <div class="iconContainer">
                    <div class="">
                      <svg viewBox="0 0 512 512" height="1em" xmlns="http://www.w3.org/2000/svg" class="search_icon">
                        <path
                          d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
                        </path>
                      </svg>
                    </div>
                  </div>
                  <input class="search_input" placeholder="Search.." type="text" [(ngModel)]="searchInputData"
                    (keyup.enter)="onSearchselected(); passData()" (input)="inputSearchData($event)" />
                </div>
              </div>
              }
              @if(isAdvanceSearchTovisible){
              @if(AdvanceSearch)
              {
              @if (isAdvanceBTnVisible) {
              <a class="advance-search-link" (click)="toggleDropdown()">Advance Search</a>
              }
              @if (isDropdownVisible) {

              <div class="card">
                <!-- Dropdown card Starts here -->
                <!-- <div class="card-header">Advanced Search</div> -->
                <div class="card-content">
                  <form>
                    <div class="dropdown-row">

                      <div class="first-row">
                        @if(showLanguageDropDown){
                        <app-common-dropdowns class="app-commondropdown-one dropspace" [defaultText]="'Language'"
                          [dataList]="languageList" (optionSelected)="onLanguageSelected($event)"
                          (searchTermChanged)="SearchTermLanguage($event)" [selectedOption]="Language"
                          [clearThevalue]="Language">
                        </app-common-dropdowns>
                        } @if(showLocationDropDown){
                        <app-common-dropdowns class="app-commondropdown-two dropspace" [defaultText]="'Location'"
                          [dataList]="Locations" (optionSelected)="onLocationSlected($event)">
                        </app-common-dropdowns>
                        } @if(showCountryDropdown){
                        <app-common-dropdowns class="app-commondropdown-three space dropspace dropDownCountry"
                          [defaultText]="'Country'" [dataList]="CountryList"
                          (optionSelected)="onCountrySelected($event)" [selectedOption]="Country"
                          [clearThevalue]="Country" (sendTheValue)="onCountryValueChange($event)">
                        </app-common-dropdowns>
                        } @if(showStateDropdown){
                        <app-common-dropdowns class="app-commondropdown-four space dropspace dropDownState"
                          [defaultText]="'State'" [dataList]="StateList" (optionSelected)="onStateSelected($event)"
                          [selectedOption]="State" [clearThevalue]="State" (sendTheValue)="onStateValueChange($event)">
                        </app-common-dropdowns>
                        } @if(showCategoryDropDown){
                        <app-common-dropdowns class="app-commondropdown-five dropspace" [defaultText]="'Book Category'"
                          [dataList]="CategoryList" (optionSelected)="onCategorySelected($event)"
                          (searchTermChanged)="SearchTermCategory($event)" [selectedOption]="bookCategory"
                          [clearThevalue]="bookCategory">
                        </app-common-dropdowns>
                        } @if(showCityDropdown){
                        <app-common-dropdowns class="app-commondropdown-six space dropspace dropDownCity"
                          [defaultText]="'City'" [dataList]="CityList" (optionSelected)="onCitySelected($event)"
                          [selectedOption]="City" [clearThevalue]="City">
                        </app-common-dropdowns>
                        }
                        @if(showSubjectDropDown){
                        <app-common-dropdowns class="app-commondropdown-seven dropspace subjectReset"
                          [defaultText]="'Subject'" [dataList]="SubjectList"
                          (optionSelected)="onSubjectSelected($event)" (searchTermChanged)="SearchTermSubject($event)"
                          [selectedOption]="Subject" [clearThevalue]="Subject">
                        </app-common-dropdowns>
                        }
                        @if(showDegreeDropdown){
                        <app-common-dropdowns class="app-commondropdown-eight dropspace" [defaultText]="'Degrees'"
                          [dataList]="DegreeList" (optionSelected)="onDegreesSelected($event)"
                          (searchTermChanged)="SearchTermDegree($event)" [selectedOption]="degree"
                          [clearThevalue]="degree">
                        </app-common-dropdowns>
                        } @if(showJobTypeDropdown){
                        <app-common-dropdowns class="app-commondropdown-nine dropspace dropDownJobType"
                          [defaultText]="'Job-Type'" [dataList]="JobTypeList"
                          (optionSelected)="onJobTypeSelected($event)" (searchTermChanged)="SearchTermJob($event)"
                          [selectedOption]="jobType" [clearThevalue]="jobType">
                        </app-common-dropdowns>
                        } @if(showScholarshipTypeDropdown){
                        <app-common-dropdowns class="app-commondropdown-ten dropspace"
                          [defaultText]="'Scholarship-Type'" [dataList]="ScholarshipList"
                          (optionSelected)="onScholarshipSelected($event)" (searchTermChanged)="SearchTermType($event)"
                          [selectedOption]="scholarshipType" [clearThevalue]="scholarshipType">
                        </app-common-dropdowns>
                        }@if(showInstituteTypeDropdown){
                          <app-common-dropdowns class="app-commondropdown-ten dropspace"
                            [defaultText]="'Institute Type'" [dataList]="InstituteTypeList"
                            (optionSelected)="onInstiuteTypeSelected($event)"
                            [selectedOption]="instituteType" [clearThevalue]="instituteType">
                          </app-common-dropdowns>
                          }

                        <!-- salary-range-slider  -->
                        @if(showRangeSlider){
                        <div class="slider-row">
                          <div class="sliderArea">
                            <app-rang-slider class="app-commondropdown-slider commonSlider"
                              (sliderValues)="receiveSliderValues($event)" [minValue]="minValue" [maxValue]="maxValue">
                            </app-rang-slider>
                          </div>
                        </div>
                        }
                        <!-- salary-range-slider  -->
                      </div>


                    </div>
                  </form>
                  <div class="card-footer">
                    <div class="dialogue-box">
                      @if(totalRecords > 0){

                      <p class="results">{{totalRecords}} Results Found</p>
                      }
                      @else{
                      <p class="results">No Results Found</p>
                      }
                      <!-- <div class="restDrops" (click)="clearAllFiels()">
                          <i class="fa-solid fa-rotate-right"></i>
                        </div> -->
                    </div>

                    <div class="buttons">
                      <app-center-button [buttonText]="'Reset'" class="search-btn"
                        (click)="clearAllFiels()"></app-center-button>
                      <app-center-button [buttonText]="'Search'" class="search-btn"
                        (click)="passData()"></app-center-button>
                    </div>

                  </div>
                </div>
                <!-- Dropdown card Ends here -->
              </div>
              } }

              }
            </div>
          </div>

        </div>
        @if(popUpButton.isShown){
        <div class="popUpButton">
          <a href="javascript:void(0)" class="fnc-slide__action-btn" aria-label="clickable-text"
            (click)="openSpecificPopUp()">
            <span>{{popUpButton?.text}}</span>
            <svg width="13px" height="10px" viewBox="0 0 13 10">
              <path d="M1,5 L11,5"></path>
              <polyline points="8 1 12 5 8 9"></polyline>
            </svg>
          </a>
        </div>
        }
      </div>
    </div>
  </div>
</section>


@if(componentNameToOpen == 'institute'){
<app-institute-form />
}@else if (componentNameToOpen == 'admission'){
<app-admission-form />
}@else if (componentNameToOpen == 'scholarships'){
<app-scholarshps-form />
}@else if (componentNameToOpen == 'job'){
<app-job-form />
}@else if (componentNameToOpen == 'books'){
<app-library-form />
}@else if(componentNameToOpen == 'hostel'){
  <app-hostel-form />
}@else if ( componentNameToOpen == 'teachear'){
<ng-template #signInPage>
  <div class="modal-content">
    <app-log-in-user (userLoggedIn)="onUserLoggedIn()"></app-log-in-user>
  </div>
</ng-template>
}
<ng-template #signInPage2>
  <div class="modal-content">
    <app-log-in-user (userLoggedIn)="onUserLoggedIn()" [navigateToDashboard]="isNavigateToDashboard"></app-log-in-user>
  </div>
</ng-template>