<ul class="nav nav-tabs right-line-tab">
  <li class="nav-item" *ngFor="let data of userPanelSideMenu; trackBy: trackData">
    <!-- Main menu item -->
    <a 
      class="nav-link" 
      href="javascript:void(0)" 
      [ngClass]="{ 'active': activeTab == data.value && !data.subMenu }" 
      aria-label="clickable-text"
      (click)="getPage(data)"
      (click)="toggleSubMenu(data.value)"
    >
      {{ data.title }}
      <span *ngIf="data.subMenu" class="submenu-icon">
        <i class="fas" [ngClass]="{ 'fa-chevron-down': activeSubMenu === data.value, 'fa-chevron-right': activeSubMenu !== data.value }"></i>
      </span>
    </a>

    <!-- Submenu -->
    <ul *ngIf="data.subMenu" class="sub-menu" [ngClass]="{ 'show': activeSubMenu === data.value }">
      <li class="nav-item" *ngFor="let subItem of data.subMenu">
        <a 
          class="nav-link small-navigation-link" 
          [ngClass]="{ 'active': activeTab == subItem.value }" 
          (click)="getPage(subItem)" 
          aria-label="clickable-text"
        >
        <i class="fas fa-chevron-right"></i>  {{ subItem.title }}
        </a>
      </li>
    </ul>
  </li>
</ul>
