<section class="p-0 ratio3_2">
  @if(propertyData){
    <div class="single-property-slider zoom-image-box zoom-gallery-multiple">
      <app-property-image-slider-home-section [propertyImageSliderHome]="propertyData.propertyImageSliderHome" [propertyDetails]="propertyDetails"></app-property-image-slider-home-section>
    </div>
  }
</section>

<section class="without-top property-main small-section">
  <app-home-details [propertyDetails]="propertyDetails"></app-home-details>
</section>

<section class="single-property mt-0 pt-0">
  <div class="container">
    <div class="row ratio_55">
      <div class="col-xl-9 col-lg-8">
        <div class="description-section tab-description">
          <div class="description-details">
            <app-property [propertyData]="propertyData" [propertyDetailsData]="propertyDetailsData" [type]="'basic'"></app-property>
          </div>
        </div>
        <app-related-property [type]="'grid-2'" [totalData]="4"></app-related-property>
      </div>
      @if(propertyData){
        <div class="col-xl-3 col-lg-4">
          <app-advance-filter [propertyFilterSliderData]="propertyData.propertyFilterSlider"></app-advance-filter>
        </div>
      }
    </div>
  </div>
</section>

