import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';

@Component({
  selector: 'app-teacher-test',
  templateUrl: './teacher-test.component.html',
  styleUrl: './teacher-test.component.scss'
})
export class TeacherTestComponent {
  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Test Yourself';
  public parent = 'Teacher';
  public child = 'Submit Test';

  isDataLoading: boolean = false;
  testTable: any;

  public _toastr = inject(ToastrService);
  public __apiCall = inject(ApiCallService);
  private __utilsService = inject(CommonUtilsServiceService);

  constructor(private router: Router) { }

  ngOnInit() {
    this.getTests();
  }

  startTest(test: any) {
    const url = this.router.createUrlTree(['/question'], {
      queryParams: {
        category: test.category,
        subCategory: test.subCategory,
        level: test.level,
        description: test.description
      }
    });
    this.navigateToTestSummary();
    window.open(url.toString(), '_blank');
  }



  getTests() {
    this.isDataLoading = true;
    this.__apiCall.GetCallWithToken("UserTest/UserScreenTest").subscribe((response) => {
      if (response.responseCode == 200) {
        this.testTable = response.data;
        this.isDataLoading = false;
      } else {
        this.testTable = [];
        this.isDataLoading = false;
      }
    }, (error) => {
      this._toastr.error('Error fetching tests data', error);
      this.isDataLoading = false;
    }
    )
  }

  navigateToTestSummary(): void {
    this.__utilsService.navigateTo('/user/test-summary');
  }


}
