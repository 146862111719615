<div class="feature-content">
  <span class="label-cross">
    New !
  </span>
  <div class="details">
    <h3><a href="javascript:void(0)" aria-label="clickable-text"><span>Home in</span> California Avenue </a></h3>
    <span>Merrick Way,&nbsp;Miami, FL United Kingdom</span>
  </div>
  <ul class="detail-list">
    <li>
      <div class="d-flex">
        <span class="label label-light color-2 label-lg">
          <img src="assets/images/icon/bed.png" class="img-fluid img-icon" alt="bed-icon">
        </span>
        <h6>Bedroom</h6>
      </div>
    </li>
    <li>
      <div class="d-flex">
        <span class="label label-light color-2 label-lg">
          <img src="assets/images/icon/bathroom.png" class="img-fluid img-icon" alt="bath-icon">
        </span>
        <h6>Bathroom</h6>
      </div>
    </li>
    <li>
      <span class="label label-light color-2 label-lg">9532 Sq Ft</span>
    </li>
  </ul>
  <ul class="feature-price">
    <li>
      <h3>$48,596.00</h3>
      <h6>Home For Sale</h6>
    </li>
    <li>
      <a class="btn btn-gradient btn-pill color-2 btn-lg" tabindex="0" [routerLink]="['/agent/submit-property']" aria-label="clickable-text">submit property</a>
    </li>
  </ul>
</div>
