<app-corporate-home-slider></app-corporate-home-slider>

<app-corporate-property-listing [data]="8" [class]="'col-xxl-3 col-xl-4 col-md-6 wow fadeInUp'" [tagClass]="'color-3'" [containerClass]="true"></app-corporate-property-listing>

<app-corporate-provided-services [tagClass]="'color-3'"></app-corporate-provided-services>

<app-corporate-property-listing [data]="6" [class]="'col-xl-4 col-lg-6 wow fadeInUp'" [tagClass]="'color-3'" [containerClass]="false"></app-corporate-property-listing>

<app-corporate-pricing-plan [tagClass]="'color-3'"></app-corporate-pricing-plan>

<app-corporate-featured-property [tagClass]="'color-3'"></app-corporate-featured-property>

<app-corporate-banner [tagClass]="'color-3'"></app-corporate-banner>

<app-corporate-agents [tagClass]="'color-3'"></app-corporate-agents>

<app-corporate-clients [tagClass]="'color-3'"></app-corporate-clients>

<app-corporate-latest-blog></app-corporate-latest-blog>
