<div class="row">
  <div class="col-xl-7 col-lg-5">
    <div class="about-image">
      <div class="img-box side-left">
        <img src="assets/images/about/6.jpg" class="img-fluid" alt="image">
        <div class="side-effect"></div>
      </div>
      <div class="img-box img-abs side-right">
        <img src="assets/images/about/5.jpg" class="img-fluid" alt="image">
        <div class="side-effect"></div>
      </div>
    </div>
  </div>
  <div class="col-xl-5 col-lg-7">
    <div class="about-content">
      <h3>We are the expert of team communication</h3>
      <p class="font-roboto">Residences can be classified by and how they are connected to neighbouring residences and land.
        Different types of housing tenure can be used for the same physical type </p>
    </div>
    <div class="about-listing">
      <ul>
        <li>
          <h4>15,801</h4>
          <p>Total property</p>
        </li>
        <li>
          <h4>5792</h4>
          <p>Agents</p>
        </li>
        <li>
          <h4>3871</h4>
          <p>Agency</p>
        </li>
        <li>
          <h4>4791+</h4>
          <p>Sold out property</p>
        </li>
      </ul>
    </div>
  </div>
</div>
