<section class="small-section bg-light-blue arrow-gradient">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="slide-3 brand-slider">
          <app-brand [brandData]="brandData" [type]="'classic'"></app-brand>
        </div>
      </div>
    </div>
  </div>
</section>
