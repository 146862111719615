import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  constructor() { }
  private buttonClickSubject = new Subject<void>();

  buttonClick$ = this.buttonClickSubject.asObservable();
  private sharedData: any;

  setData(data: any) {
    this.sharedData = data;
    // this.sharedDataSubject.next(data);
  }
  buttonClicked() {
    this.buttonClickSubject.next();
  }

  getData() {
    return this.sharedData;
  }


  private dataSubject = new BehaviorSubject<any>(null);
  statusSubject$: Observable<any> = this.dataSubject.asObservable();


  setCategoryData(Data: any) {
    this.dataSubject.next(Data);
  }
  CategoryData: any
  shareData(Data: any) {
    this.CategoryData = Data
  }
  recievedata() {
    return this.CategoryData;
  }



  PersonalData: any;
  setPersonalData(data: any) {
    this.PersonalData = data;
  }
  getPersonalData() {
    return this.PersonalData;
  }


  private setDropDownValue = new BehaviorSubject<any>(null);
  dropDownSubject$: Observable<any> = this.setDropDownValue.asObservable();

  setDropDownData(Data: any) {
    this.setDropDownValue.next(Data);
  }
  dropDownValue: any;
  shareDropDownData(data: any) {
    this.dropDownValue = data;
  }

  getDropDown() {
    return this.dropDownValue;
  }




  // code for range slider reset 

  private slider = new BehaviorSubject<any>(null);
  resetSlider$: Observable<any> = this.slider.asObservable();


  sliderReset(Data: any) {
    this.slider.next(Data);
  }


  userPersonalData: any
  setUserPersonalDetails(data: any) {
    this.userPersonalData = data;
  }

  getUserPersonalDetails() {
    return this.userPersonalData;
  }

  private hasNavigated: boolean = false;

  setHasNavigated(value: boolean): void {
    this.hasNavigated = value;
  }

  getHasNavigated(): boolean {
    return this.hasNavigated;
  }
}
