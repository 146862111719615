import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-advance-filter-contact',
  templateUrl: './advance-filter-contact.component.html',
  styleUrls: ['./advance-filter-contact.component.scss']
})
export class AdvanceFilterContactComponent {
  @Input() instituteData :any;

}
