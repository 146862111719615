<!-- <app-navbar></app-navbar> -->
<app-header-one></app-header-one>
<app-breadcrumb [bgImage]="bgImage" [title]="Title" [parent]="parent" [child]="child"></app-breadcrumb>
<!-- About us -->
<section class="about-main ratio_36">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-title [titleClass]="'title-2'" [type]="'basic'" [heading]="'About Us'" [desc]="aboutDesc"
                    [descClass]="'font-roboto'"></app-title>
                <div class="user-about">
                    <div class="row">
                        <div class="col-xl-5 col-lg-7">
                            <div class="about-content">
                                <h3>Empowering <br />students Through Education</h3>
                                <p class="font-roboto">At our core, we are committed to providing students with a comprehensive platform that supports every aspect of their educational journey. From finding the right institutes and admissions to securing scholarships and discovering job opportunities, we are dedicated to empowering students with the resources and guidance they need to succeed. Our platform is designed to be a trusted partner in your pursuit of academic excellence.</p>
                            </div>
                            <div class="about-listing">
                                <ul>
                                    <li>
                                        <h4>{{totalRecords?.institutes}}</h4>
                                        <p>Institutes</p>
                                    </li>
                                    <li>
                                      <h4>{{totalRecords?.teachers}}</h4>
                                      <p>Teachers</p>
                                  </li>
                                    <li>
                                        <h4>{{totalRecords?.admissions}}</h4>
                                        <p>Admissions</p>
                                    </li>
                                    <li>
                                      <h4>{{totalRecords?.scholarships}}</h4>
                                      <p>Scholarships</p>
                                  </li>
                                    <li>
                                        <h4>{{totalRecords?.jobs}}</h4>
                                        <p>Jobs</p>
                                    </li>
                                    <li>
                                      <h4>{{totalRecords?.books}}</h4>
                                      <p>Books</p>
                                  </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-7 map-image col-lg-5 bg-size"
                            [style.background-image]="'url(../../../../assets/images/about/map1.png)'" style="width: 55%;">
                            <img src="../../../../assets/images/about/map1.png" class="img-fluid bg-img" alt="map-image">
                            <div class="marker-icons">
                                <ul>
                                    <li><img class="img-fluid marker-1" src="../../../../../assets/images/about/books.png"
                                            alt="home-icon"></li>
                                    <li><img class="img-fluid marker-2" src="../../../../../assets/images/about/hostel1.png"
                                            alt="home-icon"></li>
                                    <li><img class="img-fluid marker-3" src="../../../../../assets/images/about/school1.png"
                                            alt="home-icon"></li>
                                    <!-- <li><img class="img-fluid marker-4" src="assets/images/leaflet/marker-icon.png"
                                            alt="home-icon"></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- Founders -->
<section class="about-section slick-between" [ngClass]="sectionClass">
  <div class="container">
    <div class="row">
      <div class="col">
        <app-title  [titleClass]="'title-2'"  [heading]="'Meet our Founders'" [textWhite]="false" [type]="'simple'" ></app-title>
        <div class="about-1 about-wrap arrow-white color-6">
          <owl-carousel-o [options]="founderOptions">
            @for(data of foundersData; track data){
              <ng-template carouselSlide>
                <div>
                  <div class="about-content row">
                    <div class="col-xl-6">
                      <div class="about-image">
                        <img [src]="data.img" class="img-fluid" alt="user-image" />
                        <div class="about-overlay"></div>
                        <div class="overlay-content">
                          <ul>
                            <li>
                              <a href="https://accounts.google.com/" target="_blank">
                                <img src="assets/images/about/icon-1.png" alt="google-icon" />
                              </a>
                            </li>
                            <li>
                              <a href="https://twitter.com/" target="_blank">
                                <img src="assets/images/about/icon-2.png" alt="twitter-icon" />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.facebook.com/" target="_blank">
                                <img src="assets/images/about/icon-3.png" alt="facebook-icon" />
                              </a>
                            </li>
                          </ul>
                          <span>Connect</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="our-details">
                        <a href="javascript:void(0)" [routerLink]="['/agent/agent-profile']">
                          <h6 class="d-flex"> {{ data.name }}
                            <span class="label-heart ms-2" [ngClass]="tagClass">
                              <app-feather-icons [icon]="'heart'"></app-feather-icons>
                            </span>
                          </h6>
                        </a>
                        <!-- <h3>{{ data.title }}</h3> -->
                        <span class="font-roboto">
                          <app-feather-icons [icon]="'mail'" class="me-1" style="color: var(--mainColor);"></app-feather-icons>{{ data.email }}</span>
                        <p class="font-roboto justify-text">
                          {{ data.desc }}
                        </p>
                        <div class="col-lg-9 py-4">
                          <div class="text-right" >
                            <a class="btn btn-gradient color-2 btn-pill" [attr.href]="'/portfolio/' + data.id" target="_blank" rel="noopener noreferrer" ><app-feather-icons [icon]="'eye'"></app-feather-icons>View Portfolio 
                              </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            }
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Choose Us -->
<section class="why-choose ratio_40 service-section service-1 bg-light">
    <div class="container">
      <div class="row">
        <div class="col">
          <app-title [titleClass]="'title-2'" [type]="'basic'" [heading]="'Why choose us'" [desc]="chooseUsDesc" [descClass]="'font-roboto'"></app-title>
          <div class="row property-service column-space">
            @for(data of whyChooseUsData; track data){
              <div class="col-xl-4 col-md-6">
                <div class="service-box">
                    <div class="icon-round">
                      <app-feather-icons [icon]="data.icon"></app-feather-icons>
                    </div>
                    <h3><a href="javascript:void(0)">{{ data.title }}</a></h3>
                    <p>{{ data.description }}</p>
                  </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </section>
<!-- Happy Clients -->
  <section class="about-testimonial testimonial-client">
    <div class="container">
      <div class="row">
        <div class="col">
          <app-title [titleClass]="'title-2'" [heading]="'Our Happy Client'" [desc]="happyClientDesc" [type]="'basic'" [descClass]="'font-roboto'"></app-title>
          <div class="testimonial-4">
            <owl-carousel-o [options]="clientOptions">
                @for(data of happyClientsData; track data){
                  <ng-template carouselSlide>
                    <div class="modern-client row">
                      <div class="col-lg-6">
                        <div class="img-testimonial">
                          <div>
                            <div class="img-left">
                              <img [src]="data.images" alt="user-image" width="636" height="424" class="img-fluid" loading="lazy">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-6 col-md-9 col-sm-10">
                        <div class="right-height">
                          <div class="comment-right">
                            <div>
                              <div class="media">
                                <div class="media-body">
                                  <a href="javascript:void(0)">
                                    <h3 class="d-flex">{{ data.name }} <span class="label-heart color-4 ms-2"><i class="fas fa-heart"></i></span></h3>
                                  </a>
                                </div>
                                <ul class="client-rating">
                                  <li><i class="fas fa-star"></i></li>
                                  <li><i class="fas fa-star"></i></li>
                                  <li><i class="fas fa-star"></i></li>
                                  <li><i class="fas fa-star"></i></li>
                                  <li><i class="fas fa-star"></i></li>
                                </ul>
                              </div>
                              <h6>{{ data.title }}</h6>
                              <p class="font-roboto">{{ data.desc }}</p>
                              <!-- <span class="font-roboto">{{ data.email }}</span> -->
                              <span class="font-roboto">
                                <app-feather-icons [icon]="'mail'" class="me-1" style="color: var(--mainColor);"></app-feather-icons>{{ data.email }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                }
              </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
  </section>
<!-- Latest Blog -->
<section class="ratio2_1 bg-light blog-inner">
    <div class="container">
      <div class="row">
        <div class="col">
          <app-title [titleClass]="'title-2'" [type]="'basic'" [heading]="'Latest Blog'" [desc]="blogDesc" [descClass]="'font-roboto'"></app-title>
          <div class="blog-2 blog-grid">
            <owl-carousel-o [options]="blogOptions">
                @for(data of latestBlogData; track data){
                  <ng-template carouselSlide>
                      <div style="margin: 0 10px;">
                          <div class="blog-wrap wow fadeInUp" [ngClass]="tagClass">
                              <div class="blog-image">
                                  <div class="bg-size" [style.background-image]="'url('+data.img+')'">
                                      <img src="data.img" [width]="data.width" 
                                      [height]="data.height" [alt]="data.title"  class="img-fluid bg-img" alt="property-image" style="display: none;" loading="lazy">
                                  </div>
                                  <div class="blog-label">
                                      <div>
                                          <h3>{{ data.date }}</h3>
                                          <span>{{ data.month }}</span>
                                      </div>
                                  </div>
                              </div>
                              <div class="blog-details">
                                  <span>
                                      <app-feather-icons [icon]="'map-pin'"></app-feather-icons> {{ data.city }}
                                  </span>
                                  <h3>
                                      <a href="javascript:void(0)" >{{ data.title }}</a>
                                  </h3>
                                  <p class="font-roboto">{{ data.desc }} </p>
                                  <a href="javascript:void(0)" class="read-more" >read more</a>
                              </div>
                          </div>
                      </div>
                  </ng-template>
                }
          </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
  </section>
<!-- Partner Section  -->
<app-technology-partner [showTitle]="true"></app-technology-partner>

  
<app-adly-footer></app-adly-footer>