@if(type == 'simple'){
  @if(svgIcon == true){
    <div [class]="titleClass" class="title">
        <svg class="title-svg" [ngClass]="{ 'color-7' : svgClass }">
            <use
                xlink:href="assets/svg/icons.svg#title-line"></use>
        </svg>
        <h2>{{ tag }} <span>{{ heading }}</span></h2>
        <p class="font-roboto">{{ desc }}</p>
    </div>
  }@else {
    <div class="main-title-div pb-50" [class]="titleClass" [ngClass]="{ 'text-white' : textWhite == true , 'color-6' : textWhite == false, 'align-center': isClassApplied == false}" >
      @if (isLabelAdded) {
        <span class="label label-gradient color-6 mb-15" [ngClass]="tagClass">{{ tag }}</span>
      }
        <h2 class="align-center" [ngClass]="{ 'text-white' : textWhite == true }" >{{ heading }}</h2>
        @if (isLineAdded) {
          <hr class="horizontal-line" >
        }
    </div>
  }
}

@if(type == 'basic'){
  <div [class]="titleClass" [ngClass]="{ 'text-white' : textWhite == true }">
    <h2>{{ heading }}</h2>
    <p [ngClass]="descClass">{{ desc }} </p>
  </div>
}

