import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { happyClients } from '../../../../shared/interface/property';
import { PropertyService } from '../../../../shared/services/property.service';

@Component({
  selector: 'app-slider-filter-happy-client',
  templateUrl: './slider-filter-happy-client.component.html',
  styleUrls: ['./slider-filter-happy-client.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderFilterHappyClientComponent {

  @Input() tagClass: string = '';
  @Input() title: string;
  public tag: string = 'Our';

  public happyClientsData: happyClients[] = [];

  constructor(private propertyService : PropertyService) { }

  ngOnInit(){
  
}
}
