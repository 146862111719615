import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { homeSectionSlider } from '../../../../../shared/interface/property';

@Component({
  selector: 'app-slider-four',
  templateUrl: './slider-four.component.html',
  styleUrls: ['./slider-four.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderFourComponent {

  @Input() homeSectionSliderData: homeSectionSlider[];
  @Input() instituteData: any;


  constructor() {

  }

  ngOnInit(): void {
    console.log("data of INstitute image", this.instituteData)
  }
  public Options = {
    loop: true,
    nav: true,
    dots: false,
    navText: [
      '<i class="fa fa-chevron-left"></i>',
      '<i class="fa fa-chevron-right"></i>'
    ],
    responsive: {
      0: {
        items: 1,
      },
    },
  };
}
