<div class="property-box">

  <div class="property-image">
    @if(isAdmissionsRoute == true){
    <app-image-slider [latestForRentData]="institutesData" [tagClass]="tagClass" [listView]="listView"
      [thumbnail]="thumbnail" [thumbnail_video]="thumbnail_video" [gridImages]="gridImages"></app-image-slider>
    } @else if (isAdmissionsRoute == false) {
    <div class="property-slider" [class]="tagClass">
      <a href="javascript:void(0)" class="bg-size" [style.background-image]="'url(' + institutesData.img.url + ')'">
        <img [src]="institutesData.imgUrl" class="bg-img" alt="property-image" style="display: none;" />
      </a>
    </div>
    }
    @if (isAdmissionsRoute == true) {
    <div class="seen-data">
      <app-feather-icons [icon]="'camera'"></app-feather-icons>
      <span>{{ institutesData.img.length }}</span>
    </div>
    }
  </div>
  <div class="property-details">
    @if (institutesData.moduleType === "books") {
    <span class="font-roboto card-title">
      <app-feather-icons class="color" [icon]="'list'"></app-feather-icons>{{ institutesData.city }}</span>
    }@else {
    <span class="font-roboto card-title">
      <app-feather-icons class="color" [icon]="'map-pin'"></app-feather-icons>{{ institutesData.city + ", " +
      institutesData.country }}</span>
    }

    <a href="javascript:void(0)" (click)="getInstitutebyId(institutesData.id, institutesData.moduleType)">
      <h3 class="line-clamp">{{ institutesData.title }}</h3>
    </a>
    @if (isDescriptionShown === true) {
    <p class="font-roboto description-clamp">{{ institutesData.details }}</p>
    }
    <ul>
      @if (institutesData.moduleType === "books") {
      <li>
        <img src="assets/images/svg/icon/category.png" class="img-fluid ruler-tool" alt="ruler-icon" />{{
        institutesData.contactNumber }}
      </li>
      <li class="border-line"></li>
      <li>
        <img src="assets/images/svg/icon/language.png" class="img-fluid ruler-tool" alt="ruler-icon" />
        {{ institutesData.email }}

      </li>
      }@else {
      <li class="tooltip-container">
        <img src="assets/images/svg/icon/telephone.png" class="img-fluid ruler-tool" alt="ruler-icon" />{{
        institutesData.contactNumber }} <app-feather-icons (mouseover)="Tooltip(true)" (mouseout)="Tooltip(false)"
          (click)="copyToClipBoard(institutesData.contactNumber)" class="color" [icon]="'copy'"></app-feather-icons>
        <div class="tooltip" *ngIf="tooltipVisible">{{toolTipText}}</div>
      </li>
      <li class="border-line"></li>
      @if (institutesData.moduleType === "jobs") {
      <li>
        <img src="assets/images/svg/icon/job-seeker.png" class="img-fluid ruler-tool" alt="ruler-icon" />
        {{ institutesData.email }}
      </li>
      }@else {
      <li class="tooltip-container">
        <img src="assets/images/svg/icon/email.png" class="img-fluid ruler-tool" alt="ruler-icon" />


       
        @if(institutesData.email){
        {{ institutesData.email.substring(0, 15) }}
        @if(institutesData.email.length > 15){ ... }
        }

        <app-feather-icons (mouseover)="Tooltip(true)" (mouseout)="Tooltip(false)"
          (click)="copyToClipBoard(institutesData.email)" class="color" [icon]="'copy'"></app-feather-icons>
        <div class="tooltip" *ngIf="tooltipVisible">{{toolTipText}}</div>
      </li>
      }

      }

    </ul>
    <div class="property-btn d-flex">
      <button aria-label="Button" class="btn btn-link"
        (click)="getInstitutebyId(institutesData.id, institutesData.moduleType)">
        Details<i class="fas fa-arrow-right ms-1"></i>
      </button>
    </div>
  </div>
</div>

<ng-template #readMoreModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ institutesData.title }}</h4>
    <button aria-label="Button" type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
      <app-feather-icons class="close-color" [icon]="'x'"></app-feather-icons>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ institutesData.details }}</p>
  </div>
</ng-template>