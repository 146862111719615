import { Component, inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserCentralizedDataService } from 'src/app/shared/services/user-centralized-data.service';

@Component({
  selector: 'app-cv-data',
  templateUrl: './cv-data.component.html',
  styleUrl: './cv-data.component.scss',
})
export class CvDataComponent {
  public noRecordFound = false;
  public isDataLoading = true;
  isErrorOccurred: boolean = false;
  public isSpinnerShown = false;
  gridType: string = 'grid-3';
  public templateId: number | null; // Allow null values
  cvData: any = {};

  @Input() totalData: number;
  private readonly _toaster = inject(ToastrService);




  constructor(
    private userCentralizedDataService: UserCentralizedDataService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    // Fetch templateId from queryParams
    this.route.queryParams.subscribe(params => {
      this.templateId = params['templateId'] || null;  
      // console.log('Template ID from queryParams:', this.templateId);
    });
    

    // Fetch and subscribe to user details
    this.userCentralizedDataService.userDetails$.subscribe(userDetails => {
      this.cvData.userDetails = userDetails;
    });
  }


  showCv() {
    // Validate each section of CV data
    const isUserDetailsValid = this.cvData.userDetails && Object.keys(this.cvData.userDetails).length > 0;

    if (isUserDetailsValid) {
      const templateId = this.templateId || null;

      // console.log('Navigating to cv-template with Template ID:', templateId);

      // Navigate to the CV template preview page with the templateId in queryParams
      this.router.navigate(['/cv-builder/cv-template/cv', templateId]);
    } else {
      const missingMessage = `Please fill in the sections before proceeding `;
      this._toaster.error(missingMessage);
    }
  }


}
