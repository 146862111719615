<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Edit Profile</h5>
    <button aria-label="Button" type="button" class="btn-close" (click)="modal.dismissAll()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editProfileForm" >
      <div class="row gx-3">
        <div class="form-group col-md-6">
          <label for="first">First Name</label>
          <input type="text" class="form-control" id="first" formControlName="firstName" placeholder="First Name">
        </div>
        <div class="form-group col-md-6">
          <label for="last">Last Name</label>
          <input type="text" class="form-control" id="last" formControlName="lastName" placeholder="Last Name">
        </div>
        <div class="form-group col-md-6">
          <label for="gender">Gender</label>
          <select id="gender" class="form-control" formControlName="gender">
            <option value="" disabled>Select gender...</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label>Birthday</label>
          <input class="form-control" formControlName="dateOfBirth" id="datepicker" type="date" />
        </div>
        <div class="form-group col-md-6">
          <label for="phoneNumber">Phone Number</label>
          <input type="text" class="form-control" formControlName="phoneNumber" placeholder="Phone Number">
        </div>
        
        <div class="form-group col-md-6">
          <!-- <label for="country">Country</label>
          <input type="text" class="form-control" formControlName="country" placeholder="Country"> -->
          <app-common-dropdowns class="app-commondropdown-one space dropspace dropDownCountry"
                          [defaultText]="'Country'" [dataList]="CountryList"
                          (optionSelected)="onCountrySelected($event)" [selectedOption]="Country"  [dataList]="CountryList"
                          [clearThevalue]="Country" (sendTheValue)="onCountryValueChange($event)">
                        </app-common-dropdowns>
        </div>
        <div class="form-group col-md-6">
          <!-- <label for="inputState">State</label>
          <input type="text" class="form-control" formControlName="state" id="inputState" placeholder="State"> -->
          
          <app-common-dropdowns class="app-commondropdown-two space dropspace dropDownState"
                          [defaultText]="'State'" [dataList]="StateList" (optionSelected)="onStateSelected($event)"  [dataList]="StateList"
                          [selectedOption]="State" [clearThevalue]="State" (sendTheValue)="onStateValueChange($event)">
                        </app-common-dropdowns>
        </div>
        <div class="form-group col-md-6">
          <!-- <label for="inputCity">City</label>
          <input type="text" class="form-control" formControlName="city" id="inputCity" placeholder="City"> -->
          <app-common-dropdowns class="app-commondropdown-three space dropspace dropDownCity"
                          [defaultText]="'City'" [dataList]="CityList" (optionSelected)="onCitySelected($event)"  [dataList]="CityList"
                          [selectedOption]="City" [clearThevalue]="City">
                        </app-common-dropdowns>
        </div>
        
        <div class="form-group col-12">
          <label for="inputAddress">Address</label>
          <input type="text" class="form-control" formControlName="address" id="inputAddress" placeholder="1234 Main St">
        </div>
        <div class="form-group col-12">
          <label for="username">Username</label>
          <input type="text" class="form-control" formControlName="username" id="username" placeholder="Username">
        </div>
        <div class="form-group col-12">
          <label for="description">Description</label>
          <textarea class="form-control" formControlName="description" id="description" rows="3"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button aria-label="Button" type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
    <button aria-label="Button" type="submit" class="btn btn-gradient color-2 btn-pill" (click)="onEditDetails()" >Save Changes</button>
  </div>
</div>
