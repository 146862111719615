import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { homeSectionSlider, latestForRent, propertyDetailsData } from '../../../shared/interface/property';
import { PropertyService } from '../../../shared/services/property.service';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { Subject, takeUntil } from 'rxjs';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-sticky-tab',
  templateUrl: './sticky-tab.component.html',
  styleUrls: ['./sticky-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StickyTabComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  @Input() instituteData: any;
  @Input() heading: any;


  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public title = 'sticky_tab';

  public total = 3;
  public homeSectionSliderData: homeSectionSlider[];
  public latestForRentData: latestForRent[] = [];
  public propertyData: propertyDetailsData;
  private apiService = inject(ApiCallService);
  private errorHandlingService = inject(ErrorHandlingService);
  private spinner = inject(NgxSpinnerService)

  public theme_default3 = '#ff5c41';
  public theme_default4 = '#ff8c41';


  constructor(private propertyService: PropertyService, private cdr: ChangeDetectorRef,) {

    // console.log("Books Data: ", this.instituteData)
  }

  ngOnInit() {
    document.documentElement.style.setProperty('--theme-default', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default3', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default4', this.theme_default4);

    // this.propertyService.homeSliderData().subscribe((response) => {
    //   this.homeSectionSliderData = response.homeSection.filter(
    //     (item) => item.type == this.title
    //   );
    // });

    // this.propertyService.propertyDetailsData().subscribe((response) => {
    //   this.propertyData = response;
    // });

    // this.propertyService.latestForRentData().subscribe((response) => {
    //   this.latestForRentData = response.latestForRent.filter((item) =>
    //     item.type.includes('slider_filter_search')
    //   );
    // });
  }


  ngOnDestroy(): void {
    document.documentElement.style.removeProperty('--theme-default');
    document.documentElement.style.removeProperty('--theme-default3');
    document.documentElement.style.removeProperty('--theme-default4');
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async getBookbyId(id: number, type: string,bookName:string): Promise<void> {
    this.spinner.show();

    try {
      const response = await this.apiService.PostCallWithoutToken(null, `Book/GetBookPdf?bookId=${id}`)
        .pipe(takeUntil(this.destroy$))
        .toPromise();
      if (response.responseCode === 200) {
        if (type == 'getBook') {
          const url = this.base64toUrl(response?.data?.pdfBase64);
          window.open(url);
        } else if (type == "downloadpdf") {
          this.downloadPDF(response?.data?.pdfBase64,bookName)
        }
      } else {
        this.errorHandlingService.handleResponseError(response);
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.spinner.hide();
      this.cdr.detectChanges();
    }

  }



  getPDF(bookFile: any) {

  }

  private base64toUrl(bookFile: string): string {
    const byteCharacters = atob(bookFile.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    return window.URL.createObjectURL(blob);
  }


  downloadPDF(bookFile: string, title: string): void {
    const url = this.base64toUrl(bookFile);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${title}.pdf`;
    link.click();
  }

}

