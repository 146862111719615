<div class="row ratio_55 mt-5 advertisement">
  <div class="col-xl-10 ps-2">
    <app-title [isLineAdded]="true" [isLabelAdded]="true" [heading]="'Latest Scholarships'" [tag]="tag"
      [textWhite]="false" [type]="'simple'"></app-title>

    <!-- Show skeleton loader while data is loading -->
    <ng-container *ngIf="isDataLoading; else dataLoaded">
      <div class="item">
        <ngx-skeleton-loader count="8" [theme]="{
          height: '22.5rem',
          width: '23%',
          'border-radius': '10px',
          'margin-right': '0.5rem',
          'margin-left': '0.5rem'
        }"></ngx-skeleton-loader>
      </div>
    </ng-container>

    <!-- Content to show when data is loaded -->

    <ng-template #dataLoaded>
      @defer {
      <ng-container *ngIf="!noRecordFound">
        <app-slider-filter-latest-rent [isDescriptionShown]="isDescriptionShown" [propertyClass]="true"
          [tagClass]="'color-6'" [title]="title" [heading]="'Latest Admissions'" [institutesData]="scholarshipsData">
        </app-slider-filter-latest-rent>
      </ng-container>
      }
      <!-- Show no data message when no records are found -->
      <!-- <ng-template #noData> -->
      @if(noRecordFound){
      <div class="no-record">
        <img src="../../../../assets/images/svg/Nodata.gif" alt="No records found">
        <p>No Scholarships Found</p>
      </div>
      }@else if(isErrorOccurred){
      <div>
        <div class="no-record error-svg">
          <img src="../../../../../assets/images/svg/error.gif" alt="Error Ocuurred">
          <p>An Error Occurred,Try refreshing Page</p>
        </div>
      </div>
      }
      <!-- </ng-template> -->
    </ng-template>


    <!-- Button for all scholarships -->
    <ng-container *ngIf="!isDataLoading && scholarshipsData.length > 0">
      <div class="all-admissions-btn">
        <app-center-button [buttonText]="'All Scholarships'" [route]="'/scholarships/all-scholarships'">
        </app-center-button>
      </div>
    </ng-container>
  </div>

  <!-- Advertisement Section -->
  <div class=" advertisement ads-section" [ngClass]="{
    'col-lg-2': !isScreenSmall, 
    'hideAdvertsment': adImages.length == 0, 
    'col-lg-12': isScreenSmall 
  }">
    @if(!isAdLoading){

    <app-title [heading]="''" [textWhite]="false" [type]="'simple'"></app-title>
    <div class="main-ads-section" (mouseenter)="pauseScroll()" (mouseleave)="startScroll()">
      <div class="ad-images-wrapper">
        <ng-container *ngFor="let ad of adImages">
          <!-- <ng-container *ngIf="ad?.imageUrl?.length > 20">
            <a [href]="ad?.redirectLink" aria-label="clickable-text" target="_blank">
              <img [src]="ad?.imageUrl" class="ad-image" alt="Advertisement" />
            </a>
          </ng-container> -->
          @if(ad?.imageUrl?.length > 20) {
          <a [href]="ad?.redirectLink" aria-label="clickable-text" target="_blank">
            <img [src]="ad?.imageUrl" class="ad-image" alt="Advertisement" />
          </a>
          }
          @for(ad of adImages;track ad){
             @if(ad?.imageUrl?.length > 20){
          <a [href]="ad?.redirectLink" target="_blank" aria-label="clickable-text"><img [src]="ad?.imageUrl"
              class="ad-image" alt="Advertisement" />
          </a>
          }
         }
        </ng-container>
      </div>
    </div>
    }
    @else{
    <div class="item add-container">
      <ngx-skeleton-loader count="1" [theme]="{
            height: addSectionCss.height,
            width: addSectionCss.width,
            'border-radius': '10px',
            'margin-right': '0.5rem',
            'margin-left': '0.5rem'
          }"></ngx-skeleton-loader>
    </div>
    }
  </div>
</div>