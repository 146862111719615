<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="login-wrap">
  <div class="container">
    <div class="row log-in">
      <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 col-12">
        <div class="theme-card">
          <div class="title-3 text-start">
            <h2>Forgot your password</h2>
          </div>
          <form>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <app-feather-icons [icon]="'user'"></app-feather-icons>
                  </div>
                </div>
                <input type="text" class="form-control" placeholder="Enter Email" required>
              </div>
            </div>
            <div>
              <button type="submit" class="btn btn-gradient btn-pill color-2 me-sm-3 me-2" aria-label="Send Request">Send request</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
