<div
  class="coming-soon comingsoon-countdown bg-size"
  [style.background-image]="'url(assets/images/inner-pages/coming-soon.jpg)'"
>
  <img
    src="assets/images/inner-pages/coming-soon.jpg"
    alt="property-image"
    class="img-fluid bg-img"
  />
  <div class="container">
    <div class="row">
      <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
        <div class="coming-soon-detail">
          <div>
            <div class="logo">
              <a href="javascript:void(0)" aria-label="clickable-text">
                <img
                  src="assets/images/logo/footer-logo.png"
                  alt="logo"
                  class="img-fluid"
                />
              </a>
            </div>
            <h2>
              Our website is <br />
              coming soon, follow us for update now !
            </h2>
            <form action="#" class="theme-form w-100">
              <div class="form-group">
                <input
                  type="text"
                  name="password"
                  id="name"
                  class="form-control"
                  placeholder="Enter your email address"
                  required
                />
                <button type="submit" class="btn btn-gradient color-2 btn-flat" aria-label="subscribe">
                  subscribe
                </button>
              </div>
            </form>
            <div class="timer">
              <ul>
                <li>
                  <div class="counter">
                    <div><span id="days"></span>days</div>
                  </div>
                </li>
                <li>
                  <div class="counter">
                    <div><span id="hours"></span>Hour</div>
                  </div>
                </li>
                <li>
                  <div class="counter">
                    <div><span id="minutes"></span>min</div>
                  </div>
                </li>
                <li>
                  <div class="counter">
                    <div><span id="seconds"></span>sec</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="cloud-img bg-size"
    [style.background-image]="'url(assets/images/others/cloud.png)'"
  >
    <img
      src="assets/images/others/cloud.png"
      alt="cloud-image"
      class="img-fluid bg-img"
    />
  </div>
</div>
