<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="provide-service bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 order-lg-1">
        <div class="service-img">
          <img src="assets/images/inner-pages/2.png" class="img-fluid" alt="service-image">
        </div>
      </div>
      <div class="col-lg-5">
        <div class="provide-details">
          <h2>Providing <br/> the best customer service</h2>
          <p class="font-roboto">Residences can be classified by and how they are connected to neighbouring residences and land. Different types of housing tenure can be used for the same physical type. For example, connected residences might be owned by a single entity and leased out, or owned separately with an agreement covering the relationship between units and common areas and concerns. Residences can be classified by and ow they are connected to neighbouring residences and land.</p>
          <button type="button" class="btn btn-gradient color-2 btn-pill" [routerLink]="['/page/other-pages/pricing']" aria-label="Learn more">Learn more <i class="fas fa-arrow-right ms-1"></i></button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-section service-1">
  <div class="container">
    <div class="row">
      <div class="col">
        <app-title [titleClass]="'title-2'" [type]="'basic'" [heading]="'Property Services'" [desc]="serviceDesc"></app-title>
      </div>
      <div class="row property-service column-space">
        @for(data of providedServices; track data){
          <div class="col-xl-4 col-md-6">
            <app-provided-services [providedServices]="data" [type]="'basic'"></app-provided-services>
          </div>
        }
      </div>
    </div>
  </div>
</section>

<section class="bg-light about-people testimonial-style-1">
  <div class="container">
    <div class="row">
      <div class="col">
        <app-title [titleClass]="'title-2'" [type]="'basic'" [heading]="'What People Say'" [desc]="peopleSayDesc" [descClass]="'font-roboto'"></app-title>
        <div class="slick-between">
          <div class="testimonial-1 dot-gradient">
            <app-people-say [peopleSayData]="peopleSayData"></app-people-say>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="ratio2_1 bg-light blog-inner">
  <div class="container">
    <div class="row">
      <div class="col">
        <app-title [titleClass]="'title-2'" [type]="'basic'" [heading]="'Latest Blog'" [desc]="blogDesc" ></app-title>
        <div class="blog-2 blog-grid">
          <app-latest-blog [latestBlogData]="latestBlogData" [type]="'simple'"></app-latest-blog>
        </div>
      </div>
    </div>
  </div>
</section>

