<section class="feature-section bg-comman-2 slick-between">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <app-title [titleClass]="'title-2'" [heading]="'Featured Cities'" [desc]="desc" [textWhite]="true" [type]="'basic'"></app-title>
        <div class="feature-2 dot-gradient">
          <app-property-in-cities [propertyInCityData]="propertyInCity" [type]="'basic'"></app-property-in-cities>
        </div>
      </div>
    </div>
  </div>
</section>
