import { AfterViewInit, ChangeDetectionStrategy, Component, effect, inject, Input, OnInit, signal } from '@angular/core';
import { homeSectionSlider } from '../../../../../shared/interface/property';
import { interval } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';

@Component({
  selector: 'app-slider-two',
  templateUrl: './slider-two.component.html',
  styleUrls: ['./slider-two.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderTwoComponent implements OnInit,AfterViewInit {

  @Input() homeSectionSliderData: homeSectionSlider[] = [];

  private _apiSerice = inject(ApiCallService);

  public Options = {
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 5000,
    navText: [
      '<i class="fa fa-chevron-left"></i>',
      '<i class="fa fa-chevron-right"></i>'
    ],
    responsive: {
      0: {
        items: 1
      }
    }
  }

  wordList = ['Academic Excellence', 'Building Skills', 'Unlocking Opportunities']; // Example word list
  type = signal('');
  idx = signal(0); 
  n = signal(0); 
  isIncreasing = signal(true); 

  constructor() {
    
    // effect is caled every time when the value of the signal is changed
    effect(() => {
      const word = this.wordList[this.idx()];
      this.type.set(word.slice(0, this.n()));
    }, { allowSignalWrites: true }); 
    // signal can't be use inside the effect it will cause infinite loop 
    // in our case we want infinite loop to bypass that we use allowSignalWrites True

    
    interval(100).subscribe(() => {
      const word = this.wordList[this.idx()];
      const wordLength = word.length;

      if (this.isIncreasing()) {
        if (this.n() >= wordLength) {
          this.isIncreasing.set(false);
        } else {
          this.n.set(this.n() + 1);
        }
      } else {
        if (this.n() <= 0) {
          this.isIncreasing.set(true);
          this.idx.set((this.idx() + 1) % this.wordList.length);
        } else {
          this.n.set(this.n() - 1);
        }
      }
    });

  }
  ngAfterViewInit(): void {
    this.startCounter();

    setTimeout(() => {
      this.getTotalRecords();
    }, 5000);
  }

  ngOnInit():void {
  }

  totalRecords = signal({
    institutes: 34,
    admissions: 65,
    jobs: 45,
    scholarships: 32,
    books: 87,
    teacher: 43,
    hostels: 32,
    tutorials: 24
  });

  isDataLoaded = signal(false);
  startCounter() {
    let isIncreasing = true;  
    
    interval(10).subscribe(() => {
      if (!this.isDataLoaded()) {
        const updatedRecords = { ...this.totalRecords() };
        Object.keys(updatedRecords).forEach((key) => {
          const typedKey = key as keyof typeof updatedRecords;  
          if (isIncreasing) {
            if (updatedRecords[typedKey] < 199) {
              updatedRecords[typedKey]++; 
            } else {
              isIncreasing = false;
            }
          } else {
            if (updatedRecords[typedKey] > 0) {
              updatedRecords[typedKey]--; 
            } else {
              isIncreasing = true;
            }
          }
        });
  
        this.totalRecords.set(updatedRecords);
      }
    });
  }
  

  // // Function to animate a counter from 0 to target
  // animateCount(field: string, target: number, duration: number): void {
  //   const increment = target / (duration / 10);
  //   let currentValue = 0;

  //   const interval = setInterval(() => {
  //     currentValue += increment;
  //     if (currentValue >= target) {
  //       currentValue = target;
  //       clearInterval(interval);
  //     }
  //     this.animatedRecords[field] = this.formatCount(Math.floor(currentValue));
  //   }, 10);
  // }

  // formatCount(count: number): string {
  //   if (count >= 1000) {
  //     return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  //     // e.g., "2k" ya "1.5k"
  //   } else {
  //     return count.toString();
  //   }
  // }

  getTotalRecords() {
    this._apiSerice.GetCallWithoutToken("HomePage/GetBannerTotalCounts").subscribe((res: any) => {
      if (res && res.data && res.data.length > 0) {
        const data = res.data[0];
        this.totalRecords.set({
          institutes: data.totalInstitutes || 0,
          admissions: data.totalAdmissions || 0,
          scholarships: data.totalScholarships || 0,
          jobs: data.totalJobs || 0,
          books: data.totalBooks || 0,
          teacher: data.totalTeachers || 0,
          hostels: data.totalHostels || 0,
          tutorials: data.totalTutorials || 0
        });
        this.isDataLoaded.set(true);


        // this.animateCount('institutes', this.totalRecords.institutes, 2000);
        // this.animateCount('admisisons', this.totalRecords.admissions, 2000);
        // this.animateCount('jobs', this.totalRecords.jobs, 2000);
        // this.animateCount('scholarships', this.totalRecords.scholarships, 2000);
        // this.animateCount('books', this.totalRecords.books, 2000);
        // this.animateCount('teachear', this.totalRecords.teachear, 2000);
        // this.animateCount('hostels', this.totalRecords.hostels, 2000);
      }
    });
  }


  // preloadImages(images: string[]) {
  //   images.forEach((image) => {
  //     const img = new Image();
  //     img.src = image;
  //   });
  // }

  lookingForData = [
    {
      title: 'Institutes',
      icon: 'assets/images/icon/Institute-1-2.svg',
      path: '/institutes/all-institutes',
    },
    {
      title: 'Teachers',
      icon: 'assets/images/icon/newlogos/teacher.svg',
      path: '/teacher/teacher-list',
    },
    {
      title: 'Admissions',
      icon: 'assets/images/icon/Admission-1.svg',
      path: '/admissions/all-admissions',
    },
    {
      title: 'Scholarships',
      icon: 'assets/images/icon/newlogos/Scholarship.svg',
      path: '/scholarships/all-scholarships',
    },
    {
      title: 'Jobs',
      icon: 'assets/images/icon/Job-1.svg',
      path: '/jobs/all-jobs',
    },
    {
      title: 'Library',
      icon: 'assets/images/icon/library-2.svg',
      path: '/books/categories',
    },
    {
      title: 'Hostels',
      icon: 'assets/images/icon/library-2.svg',
      path: '/hostels/all-hostels',
    },
    {
      title: 'Tutorials',
      icon: 'assets/images/icon/library-2.svg',
      path: '/tutorials/all-tutorials',
    },
    {
      title: 'Calculator',
      icon: 'assets/images/icon/library-2.svg',
      path: '/gpa-calculator',
    },
    {
      title: 'CV Builder',
      icon: 'assets/images/icon/Job-1.svg',
      path: '/cv-builder/cv-templates',
    },
  ]
}
