import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { banner } from '../../../../../shared/interface/property';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-banner-four',
  templateUrl: './banner-four.component.html',
  styleUrls: ['./banner-four.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerFourComponent {
  subscriber_From: FormGroup;
  @Input() bannerData: banner;
  private _apiService = inject(ApiCallService);
  private _toaster = inject(ToastrService);
  constructor(private fb: FormBuilder) {
    this.subscriber_From = this.fb.group({
      Email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
    })
  }
  subscriber() {
    if (this.subscriber_From.invalid) {
      this._toaster.error("Email Required");
    }
    else {
      const payload = { userEmail: this.subscriber_From.get("Email")?.value }
      this._apiService.PostCallWithoutToken(payload, "AdmissionLeloVisitor/AddSubscribers").subscribe(res => {
        if (res.responseCode == 200) {
          this._toaster.success(res.responseMessage);
          this.subscriber_From.reset();
        }
        else {
          this._toaster.error(res.responseMessage);
        }
      })
    }
  }

}
