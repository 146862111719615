<section class="small-section">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="slide-1 brand-slider">
                    <app-brand [brandData]="brandData" [type]="'simple'"></app-brand>
                </div>
            </div>
        </div>
    </div>
</section>
