import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutenticationRoutingModule } from './autentication-routing.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModalComponent } from './modal/modal.component';
import { LogInUserComponent } from './log-in-user/log-in-user.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoaderTwoComponent } from 'src/app/shared/components/common/loader/loader-two/loader-two.component';





@NgModule({
  declarations: [
    SignInComponent,
    ModalComponent,
    LogInUserComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
  ],
  exports: [
    SignInComponent,
    LogInUserComponent
  ]
})
export class AutenticationModule { }
