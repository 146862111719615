<div class="dashboard-content">
  <div class="my-profile" id="profile">
    <div class="profile-info">
      <!-- <div class="common-card">
        <div class="user-name media">
          <div class="media-body">
            <h5>{{userDetails?.firstName + " " + userDetails?.lastName}}<span class="label label-success">Real estate agent</span></h5>
            <div class="rating">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="far fa-star"></i>
            </div>
          </div>
          <span class="label label-light label-flat color-4" (click)="editDetails()">Edit</span>
        </div>
        <ul class="user-detail">
          <li>
            <app-feather-icons [icon]="'map-pin'"></app-feather-icons>
            <span>{{userDetails?.address + "," + userDetails?.state + "," + userDetails?.country }}</span>
          </li>
          <li>
            <app-feather-icons [icon]="'mail'"></app-feather-icons>
            <span>{{userDetails?.email}}</span>
          </li>
          <li>
            <app-feather-icons [icon]="'check-square'"></app-feather-icons>
            <span>Licensed for 2 years</span>
          </li>
        </ul>
        <p class="font-roboto">{{userDetails?.description}}</p>
      </div> -->
      <div class="common-card">
        <div class="row">
          <div class="col-xxl-6 col-xl-7">
            <!-- <div class="information-detail">
              <div class="common-header">
                <h5>About</h5>
              </div>
              <div class="information">
                <ul>
                  <li>
                    <span>Gender :</span>
                    <p>{{userDetails?.gender}}</p>
                  </li>
                  <li>
                    <span>Birthday :</span>
                    <p>{{userDetails?.dateOfBirth?.substring(0,10)}}</p>
                  </li>
                  <li>
                    <span>Phone number :</span>
                    <a href="javascript:void(0)" aria-label="clickable-text">
                      {{userDetails?.phoneNumber}}
                    </a>
                  </li>
                  <li>
                    <span>Address :</span>
                    <p>{{userDetails?.address + "," + userDetails?.state + "," + userDetails?.country }}</p>
                  </li>
                </ul>
              </div>
            </div> -->
            <div class="information-detail">
              <div class="common-header">
                <h5>Login Details</h5>
              </div>
              <div class="information">
                <ul>
                  @if(email){
                    <li>
                      <span>Email :</span>
                      <a href="javascript:void(0)" aria-label="clickable-text">{{userDetails?.email}}</a>
                      <!-- <span class="label label-light label-flat color-4" (click)="editEmail()">Edit</span> -->
                    </li>
                  }
                  <li>
                    <span>Password :</span>
                    <a href="javascript:void(0)" aria-label="clickable-text">&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</a>
                    <span class="label label-light label-flat color-4" (click)="editPassword()">Edit</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- <div class="col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-0">
            <div class="about-img d-xl-block d-none">
              <img [src]="userDetails?.profileBase64" class="img-fluid" alt="ok-image">
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
