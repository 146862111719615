import { Component, Input, inject } from '@angular/core';
import { propertyInCity } from '../../../../../shared/interface/property';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';

@Component({
  selector: 'app-property-city-one',
  templateUrl: './property-city-one.component.html',
  styleUrls: ['./property-city-one.component.scss']
})
export class PropertyCityOneComponent {

  @Input() propertyInCity: propertyInCity;
  @Input() tagClass: string = '';

  private _utils = inject(CommonUtilsServiceService);

  navigateTospecificComponent(route: string) {
    
    this._utils.navigateTo(route);
  }

}
