<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<div class="mainContainer">
  <div class="headers">
    <h6>Subjects</h6>
    <div class="addHeadings" (click)="addCard(SubjectForm)">
      <app-feather-icons class="color" [icon]="'plus'"></app-feather-icons>

      <span> Subject </span>
    </div>
  </div>
  @defer {
  <div class="tagsContainer">
    @if(isLoading){
    <div class="item">
      <ngx-skeleton-loader count="6" [theme]="{
          height: '40px',
          width: '150px',
          'border-radius': '10px',
          'margin-right': '1rem'
        }" />
    </div>
    } @else if(subjectDeatils?.length > 0){ @for(subject of subjectDeatils;track
    subject){
    <div class="tag">
      <div class="tagContent">
        {{ subject?.subject + " - " + "$" + subject?.fee }}
        <span class="icons">
          <i class="fas fa-edit edit-icon" (click)="editCard(SubjectForm, subject)"></i>
          <i class="fas fa-trash delete-icon" (click)="deleteCard(deleteModal, subject?.id)"></i>
        </span>
      </div>
    </div>
    } }
  </div>
  }
</div>

<ng-template #SubjectForm>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        @if(FormName == "Add"){ Add Subject }@else{ Update Subject }
      </h5>
      <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="row gx-2 gx-sm-3" [formGroup]="subjectForm">
        <div class="form-group col-sm-6">
          <label>Subject<span class="text-danger">*</span></label>
          <app-common-dropdowns [dataList]="subjectList" [defaultText]="'Subject'"
            (optionSelected)="onSubjectSelected($event)" [selectedOption]="subject" [searchTerm]="subject"
            [clearThevalue]="subject" (sendTheValue)="onSubjectValueChange($event)">
          </app-common-dropdowns>
          @if(subject == "" && subjectError){
          <small class="text-danger">Subject is Required</small>
          }
        </div>

        <div class="form-group col-sm-6">
          <label>Duration<span class="text-danger">*</span></label>
          <app-common-dropdowns [dataList]="timeList" [defaultText]="'Time Slot'"
            (optionSelected)="onTimeSelcted($event)" [isDisabled]="true" [selectedOption]="timeSlot"
            [clearThevalue]="timeSlot">
          </app-common-dropdowns>
          @if(timeSlot == "" && durationError){
          <small class="text-danger">Duration is Required</small>
          }
        </div>

        <div class="form-group col-sm-6">
          <label>Fee<span class="text-danger">*</span></label>
          <input type="number" class="form-control" formControlName="fee" placeholder="Enter fee" min="0" />
          @if(subjectForm.get('fee')?.hasError('required') &&
          subjectForm.get('fee')?.touched){
          <small class="text-danger">Fee is Required</small>
          } @if(subjectForm.get('fee')?.hasError('min') &&
          subjectForm.get('fee')?.touched){
          <small class="text-danger">Fee cannot be negative</small>
          }
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()" aria-label="Cancel">
        Cancel
      </button>
      <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="saveCourseDetails()" aria-label="Save">
        @if(FormName == "Add"){ Add }@else{ Update }
      </button>
    </div>
  </div>
</ng-template>

<ng-template #deleteModal>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text">Are You Sure you want to delete this Subject ?</div>
      <div class="deleteButtons">
        <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()" aria-label="Cancel">
          Cancel
        </button>
        <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteSubject()" aria-label="Delete">
          Delete
        </button>
      </div>
    </div>
  </div>
</ng-template>