import { Component } from '@angular/core';

@Component({
  selector: 'app-map-h-search-home-section',
  templateUrl: './map-h-search-home-section.component.html',
  styleUrls: ['./map-h-search-home-section.component.scss']
})
export class MapHSearchHomeSectionComponent {

}
