<div class="row ratio_55 mt-5 advertisement">
  <div class="col-xl-10 ps-2" [ngClass]="{'col-xl-12': adImages.length == 0}">
    <app-title [heading]="'Latest Jobs'" [isLabelAdded]="true" [isLineAdded]="true" [tag]="tag" [textWhite]="false"
      [type]="'simple'"></app-title>

    <!-- Show the slider when there are jobs -->
    @defer {
    <app-slider-filter-latest-rent *ngIf="jobsData.length > 0" [isDescriptionShown]="isDescriptionShown"
      [propertyClass]="true" [tagClass]="'color-6'" [title]="title" [heading]="'Latest Jobs'"
      [institutesData]="jobsData"></app-slider-filter-latest-rent>
    }

    <!-- Show "No Jobs Found" message if no jobs are present -->
     @if(noRecordFound){
       <div class="no-record">
         <img src="../../../../assets/images/svg/Nodata.gif" alt="No Jobs Found" />
         <p>No Jobs Found</p>
       </div>
     }@else if(isErrorOccurred){
      <div>
        <div class="no-record error-svg">
          <img src="../../../../../assets/images/svg/error.gif" alt="Error Ocuurred">
          <p>An Error Occurred,Try refreshing Page</p>
        </div>
      </div>
      }

    <!-- Show the skeleton loader while data is being fetched -->
    <div *ngIf="isDataLoading && !noRecordFound" class="item">
      <ngx-skeleton-loader count="8" [theme]="{
          height: '22.5rem',
          width: '23%',
          'border-radius': '10px',
          'margin-right': '0.5rem',
          'margin-left': '0.5rem'
        }"></ngx-skeleton-loader>
    </div>



    <!-- Show button to view all jobs if there are records -->
    <div class="all-jobs-btn" *ngIf="jobsData.length > 0">
      <app-center-button [buttonText]="'All Jobs'" [route]="'/jobs/all-jobs'"></app-center-button>
    </div>
  </div>

  <!-- Advertisement section -->
  <div class=" advertisement ads-section"
  [ngClass]="{
    'col-lg-2': !isScreenSmall, 
    'hideAdvertsment': adImages.length == 0, 
    'col-lg-12': isScreenSmall 
  }"
  >
  @if(!isAdLoading){
    <app-title [heading]="''" [textWhite]="false" [type]="'simple'"></app-title>
    <div class="main-ads-section" (mouseenter)="pauseScroll()" (mouseleave)="startScroll()">
      <div class="ad-images-wrapper">
        @for(ad of adImages; track ad) {
        @if(ad?.imageUrl?.length > 20) {
        <a [href]="ad?.redirectLink" aria-label="clickable-text" target="_blank">
          <img [src]="ad?.imageUrl" class="ad-image" alt="Advertisement" />
        </a>
        }
        }
        @for(ad of adImages;track ad){ @if(ad?.imageUrl?.length > 20){
        <a [href]="ad?.redirectLink" target="_blank" aria-label="clickable-text"><img [src]="ad?.imageUrl"
            class="ad-image" alt="Advertisement" />
        </a>
        } }
      </div>
    </div>
    }@else{
    <!-- <ng-template #advertismnets> -->
    <div class="item add-container">
      <ngx-skeleton-loader count="1" [theme]="{
            height: addSectionCss.height,
            width: addSectionCss.width,
            'border-radius': '10px',
            'margin-right': '0.5rem',
            'margin-left': '0.5rem'
          }"></ngx-skeleton-loader>
    </div>
    }
  </div>
</div>