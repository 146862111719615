import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';

@Component({
  selector: 'app-center-button',
  templateUrl: './center-button.component.html',
  styleUrl: './center-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CenterButtonComponent {
  @Input() buttonText: string = 'Submit';
  @Input() downloadUrl?: string; 
  @Input() isButtonCenter?: boolean = false; 
  @Input() route?: string; // Optional route input
  @Input() isDownload?: boolean = false; // Flag to determine download action

  @Output() buttonClick = new EventEmitter<void>();

  @Input() icon?: string; // New input for icon
  // @Output() click = new EventEmitter<void>();
  private _utils = inject(CommonUtilsServiceService);

  handleClick() {
    if (this.isDownload) {
      this.handleDownload();
    } else if (this.route) {
      this.navigateToSpecificComponent(this.route);
    }
  }

  handleDownload() {
    if (this.downloadUrl) {
      const link = document.createElement('a');
      link.href = this.downloadUrl;
      link.download = ''; 
      link.click();
    }
  }

  navigateToSpecificComponent(route: string) {
    this._utils.navigateTo(route);
  }

  onButtonClick() {
    this.buttonClick.emit();
  }

}
