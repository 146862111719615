@if(isOnFaqPage){
    <app-header-one></app-header-one>
    <app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>
}@else {
    <div class="mt-90">
        <app-title
          [isLabelAdded]="true"
          [isLineAdded]="true"
          [tag]="'Questions'"
          [titleClass]="'title-1'"
          [heading]="'Frequently ask question'"
          [type]="'simple'"
        ></app-title>
      </div>
}


<section class="faq-section log-in">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 order-lg-1">
                <div class="faq-image text-center">
                    <img src="../../../../../assets/images/Questions.png" class="img-fluid" width="445" height="445" alt="image">
                    <h3>Have Any Questions ?</h3>
                    <p class="font-roboto">You can ask anything you want to know</p>
                </div>
                <form [formGroup]="FAQsForm">
                    <div class="form-group mb-0">
                        <label>Let us know</label>
                        <input type="text" class="form-control glow-input" placeholder="Ask a question" 
                        formControlName="faq_question" required>
                        @if ( FAQsForm.get('faq_question')?.touched &&
              FAQsForm.get('faq_question')?.hasError('required')) {
              <small class="text-danger">Question is required</small>
              }
                    </div>
                    <app-center-button class="place" [buttonText]="'Send'" (click)="sendFaqQuestion()"></app-center-button>

                </form>
            </div>
            <div class="col-lg-6">
                <div class="faq-questions">
                    @if(isOnFaqPage){
                        <div class="title-3 text-start">
                            <h2>Frequently ask question</h2>
                        </div>
                    }
                   
                    <div class="accordion" ngbAccordion [closeOthers]="true" role="region" aria-labelledby="faq-header" aria-expanded="false">
                        <div class="card" ngbAccordionItem [collapsed]="false" role="tabpanel" aria-labelledby="faq-header-1">
                            <div class="card-header" ngbAccordionHeader id="faq-header-1">
                                <a class="card-link" href="javascript:void(0)" ngbAccordionToggle aria-controls="faq-body-1" aria-expanded="false">
                                    Q:How to Register Your Institute on Admission Lylo?

                                </a>
                            </div>
                            <div ngbAccordionCollapse id="faq-body-1" aria-labelledby="faq-header-1">
                                <div class="card-body" ngbAccordionBody>
                                    <ng-template>
                                        To register your institute on Admission Lylo, please contact the Admission Lylo
                                        Support team at <a href="mailto:Support@admissionlylo.com" style="color: #0000FF; text-decoration: none;">Support&#64;admissionlylo.com</a>. They will guide you through the
                                        verification process for both your account and institute. Once verification is
                                        successfully completed, the support team will assist in finalizing your
                                        institute’s official registration on the Admissionlylo.
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="card" ngbAccordionItem [collapsed]="false" role="tabpanel" aria-labelledby="faq-header-2" >
                            <div class="card-header" ngbAccordionHeader id="faq-header-2">
                                <a class="card-link" href="javascript:void(0)" ngbAccordionToggle aria-controls="faq-body-2" aria-expanded="false">
                                    Q:How to post Your Institute admissions Ads on Admission Lylo?

                                </a>
                            </div>
                            <div ngbAccordionCollapse id="faq-body-2" aria-labelledby="faq-header-2">
                                <div class="card-body" ngbAccordionBody>
                                    <ng-template>
                                        To post your institute admission ads on Admission Lylo, please contact the
                                        Admission Lylo Support team at <a href="mailto:Support@admissionlylo.com" style="color: #0000FF; text-decoration: none;">Support&#64;admissionlylo.com</a>. They will guide you
                                        through the verification process for both your account and institute. Once
                                        verification is successfully completed, the support team will assist in
                                        finalizing your institute’s admissions ads on the Admissionlylo.
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="card" ngbAccordionItem [collapsed]="false" role="tabpanel" aria-labelledby="faq-header-3">
                            <div class="card-header" ngbAccordionHeader id="faq-header-3">
                                <a class="card-link" href="javascript:void(0)" ngbAccordionToggle aria-controls="faq-body-3" aria-expanded="false">
                                    Q:How to post scholarships Ads on AdmissionLylo?

                                </a>
                            </div>
                            <div ngbAccordionCollapse id="faq-body-3" aria-labelledby="faq-header-3">
                                <div class="card-body" ngbAccordionBody>
                                    <ng-template>
                                        To post your scholarships ads on Admission Lylo, please contact the Admission
                                        Lylo Support team at<a href="mailto:Support@admissionlylo.com" style="color: #0000FF; text-decoration: none;">Support&#64;admissionlylo.com</a>. They will guide you through the
                                        verification process. Once verification is successfully completed, the support
                                        team will assist in finalizing your scholarships ads on the Admissionlylo.
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="card" ngbAccordionItem [collapsed]="false" role="tabpanel" aria-labelledby="faq-header-4">
                            <div class="card-header" ngbAccordionHeader id="faq-header-4">
                                <a class="card-link" href="javascript:void(0)" ngbAccordionToggle aria-controls="faq-body-4" aria-expanded="false">
                                    Q:How to post Jobs Ads on AdmissionLylo?

                                </a>
                            </div>
                            <div ngbAccordionCollapse id="faq-body-4" aria-labelledby="faq-header-4">
                                <div class="card-body" ngbAccordionBody>
                                    <ng-template>
                                        To post your Jobs ads on AdmissionLylo, please contact the AdmissionLylo Support
                                        team at <a href="mailto:Support@admissionlylo.com" style="color: #0000FF; text-decoration: none;">Support&#64;admissionlylo.com</a>. They will guide you through the verification
                                        process. Once verification is successfully completed, the support team will
                                        assist in finalizing your Jobs ads on the Admissionlylo.
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="card" ngbAccordionItem [collapsed]="false" role="tabpanel" aria-labelledby="faq-header-5">
                            <div class="card-header" ngbAccordionHeader id="faq-header-5">
                                <a class="card-link" href="javascript:void(0)" ngbAccordionToggle aria-controls="faq-body-5" aria-expanded="false">
                                    Q:How to Upload my book on AdmissionLylo?

                                </a>
                            </div>
                            <div ngbAccordionCollapse id="faq-body-5" aria-labelledby="faq-header-5">
                                <div class="card-body" ngbAccordionBody>
                                    <ng-template>
                                        To Upload your book on AdmissionLylo, please contact the AdmissionLylo Support
                                        team at <a href="mailto:Support@admissionlylo.com" style="color: #0000FF; text-decoration: none;">Support&#64;admissionlylo.com</a>. They will guide you through the verification
                                        process. Once verification is successfully completed, the support team will
                                        assist in uploading your book on the Admissionlylo.
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="card" ngbAccordionItem [collapsed]="false" role="tabpanel" aria-labelledby="faq-header-6">
                            <div class="card-header" ngbAccordionHeader id="faq-header-6">
                                <a class="card-link" href="javascript:void(0)" ngbAccordionToggle aria-controls="faq-body-6" aria-expanded="false">
                                    Q: How to post Events on AdmissionLylo?
                                </a>
                            </div>
                            <div ngbAccordionCollapse id="faq-body-6" aria-labelledby="faq-header-6">
                                <div class="card-body" ngbAccordionBody>
                                    <ng-template>
                                        To post your events on AdmissionLylo, please contact the AdmissionLylo Support
                                        team at <a href="mailto:Support@admissionlylo.com" style="color: #0000FF; text-decoration: none;">Support&#64;admissionlylo.com</a>. They will guide you through the verification
                                        process. Once verification is successfully completed, the support team will
                                        assist in finalizing your event listings on the Admissionlylo.
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

@if (isOnFaqPage) {
    <app-adly-footer></app-adly-footer>
}
