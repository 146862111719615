<section class="ratio_40 breadcrumb-section p-0 single-property-images">
  <div class="main-property-slider arrow-image">
    <app-home-section-slider [homeSectionSliderData]="homeSectionSliderData" [type]="'standard'"></app-home-section-slider>
  </div>
  <app-home-details></app-home-details>
</section>

<section class="single-property">
  <div class="container">
    <div class="row ratio_55">
      @if(propertyData){
        <div class="col-xl-3 col-lg-4 order-class">
          <app-advance-filter [propertyFilterSliderData]="propertyData.propertyFilterSlider"></app-advance-filter>
        </div>
      }
      <div class="col-xl-9 col-lg-8">
          <app-property [propertyData]="propertyData" [type]="'simple'"></app-property>
      </div>
    </div>
  </div>
</section>

<app-related-property [type]="'grid-3'" [totalData]="3"></app-related-property>

