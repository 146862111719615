import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InstitutesRoutingModule } from './institutes-routing.module';
import { PropertyRoutingModule } from '../property/property-routing.module';
import { InstitutedetailComponent } from './institutedetail/institutedetail.component';
import { PropertyModule } from '../property/property.module';
import { InstitutesListComponent } from './institutes-list/institutes-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HomeModule } from '../home/home.module';
import { BannerComponent } from './banner/banner.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [
    InstitutesListComponent,
    InstitutedetailComponent,
  ],
  imports: [
    CommonModule,
    InstitutesRoutingModule,
    PropertyModule,
    SharedModule,
    HomeModule,
    NgbPaginationModule,
    NgxSkeletonLoaderModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
  ],
  exports: [
    InstitutesListComponent,
  ]
})
export class InstitutesModule { }
