import { Component } from '@angular/core';

@Component({
  selector: 'app-about-us2-details',
  templateUrl: './about-us2-details.component.html',
  styleUrls: ['./about-us2-details.component.scss']
})
export class AboutUs2DetailsComponent {

}
