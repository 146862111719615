<app-search-tab-home-section></app-search-tab-home-section>

<div class="theme-search-tab">
    <app-slider-filter-latest-sale [tagClass]="'color-4'" [titleType]="'basic'"></app-slider-filter-latest-sale>
</div>

<app-classic-featured-property></app-classic-featured-property>

<app-classic-property-service></app-classic-property-service>

<app-classic-latest-property [latestPropertyData]="latestPropertyData"></app-classic-latest-property>

<app-classic-video></app-classic-video>

<app-classic-happy-client></app-classic-happy-client>

<div class="search-tab-slider">
    <app-classic-banner></app-classic-banner>
</div>

<app-classic-agents></app-classic-agents>

<app-classic-brand></app-classic-brand>
