import { Component } from '@angular/core';

@Component({
  selector: 'app-modern-video-home-section',
  templateUrl: './modern-video-home-section.component.html',
  styleUrls: ['./modern-video-home-section.component.scss']
})
export class ModernVideoHomeSectionComponent {

}
