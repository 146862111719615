@if(showTitle){

<div class="col-xl-12 mt-90">
    <app-title [isLineAdded]="true" [tag]="tag" [isLabelAdded]="true"  [titleClass]="titleClass" [heading]="heading" [textWhite]="false" [type]="type"
        [desc]="desc"></app-title>
</div>
}

<div class="skin-care-review-area box-shadow ptb-100">
    <div class="container">
        <!-- <div class="section-title-warp">
            <h2>Partners List</h2>
        </div> -->
        <div class="skin-care-review-slides">
            <owl-carousel-o [options]="feedbackSlides3">
                @for (partner of partners; track $index) {
                <ng-template carouselSlide>
                    <img [src]="partner.src" [width]="partner.intrinsicWidth" [height]="partner.intrinsicHeight" [alt]="partner.alt" class="partner-img">
                </ng-template>
                }
            </owl-carousel-o>
        </div>
    </div>
</div>