<div class="advance-card">
  <h6>Contact Teacher</h6>
  <div class="category-property">
    <!-- <div class="agent-info">
      <div class="media">
        <img src="assets/images/testimonial/3.png" class="img-50" alt="user-image">
        <div class="media-body ms-2">
          <h6>{{instituteData.email}}</h6>
          <p>{{instituteData.email}}</p>
        </div>
      </div>
    </div> -->
    <!-- <ul>
      <li>
        <a [href]="'mailto:' + instituteData?.email" class="d-flex">
          <app-feather-icons [icon]="'mail'" class="me-2"></app-feather-icons>
          {{instituteData.email}}
        </a>
      </li>
      
      <li>
        <a [href]="'tel:' + instituteData?.contactNumber" class="d-flex">
          <app-feather-icons [icon]="'phone-call'" class="me-2"></app-feather-icons>
          {{instituteData.contactNumber}}
        </a>
      </li>
      
      <li>
        <a [href]="'https://www.google.com/maps/search/?api=1&query=' + (instituteData?.address)"
           target="_blank" class="d-flex">
          <app-feather-icons [icon]="'map-pin'" class="me-2"></app-feather-icons>
          {{instituteData.address}}
        </a>
      </li>
      
    </ul> -->
  </div>
</div>
