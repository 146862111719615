<div [ngClass]="isDarkTheme ? 'light_theme' : 'dark_theme'" class="body " id="top">

    <header class="header" [class.active]="headerActive" data-header>
        <div class="container">
            <h1 class="h1 logo">
                <a [routerLink]="['']">My<span>PortFolio</span></a>
            </h1>
            <div class="navbar-actions">
                <button aria-label="Button" class="theme-btn" aria-label="Change Theme" title="Change Theme" (click)="toggleTheme()"
                    [class.active]="isDarkTheme">
                    <span class="icon"></span>
                </button>
            </div>

            <button aria-label="Button" class="nav-toggle-btn" aria-label="Toggle Menu" title="Toggle Menu" data-nav-toggle-btn
                (click)="toggleNavbar()">
                <span class="one"></span>
                <span class="two"></span>
                <span class="three"></span>
            </button>

            <nav class="navbar" [class.active]="isNavbarActive" data-navbar>
                <ul class="navbar-list">
                    <li>
                        <a (click)="scrollToSection('home')" class="navbar-link">Home.</a>
                    </li>

                    <li>
                        <a (click)="scrollToSection('about')" class="navbar-link">About.</a>
                    </li>

                    <li>
                        <a (click)="scrollToSection('skills')" class="navbar-link">Skills.</a>
                    </li>

                    <li>
                        <a (click)="scrollToSection('portfolio')" class="navbar-link">Portfolio.</a>
                    </li>

                    <li>
                        <a (click)="scrollToSection('contact')" class="navbar-link">Contact.</a>
                    </li>
                </ul>
            </nav>
        </div>
    </header>

    <main>
        @if(founder){
        <article class="container">
            <section class="hero" id="home">
                <div class="main-container">
                    <div class="intro">
                        <h1>
                            <div>Hi</div>
                            I am <span>{{ founder.name }}</span>
                        </h1>
                        <div class="text-3">And I'm a <span class="typing">{{ view.type }}</span></div>
                    </div>
                    <div class="child-2">
                        <source srcset="./assets/images/portfolio/left.png" media="(min-width: 768px)" />
                        <source srcset="./assets/images/portfolio/left.png" media="(min-width: 500px)" />
                        <img src="{{ founder?.img }}"
                            alt="A man in a blue shirt with a happy expression" class="w-100" />
                    </div>
                </div>
                <ul class="hero-social-list">
                    <li>
                        <a class="hero-social-link">
                            <!-- <ion-icon name="logo-facebook"></ion-icon> -->
                            <app-feather-icons [icon]="'facebook'"></app-feather-icons>
                            <div class="tooltip">Facebook</div>
                        </a>
                    </li>

                    <li>
                        <a href="https://github.com/Ahmed8881" target="_blank" class="hero-social-link">
                            <!-- <ion-icon name="logo-github"></ion-icon> -->
                            <app-feather-icons [icon]="'github'"></app-feather-icons>
                            <div class="tooltip">GitHub</div>
                        </a>
                    </li>

                    <li>
                        <a href="https://www.linkedin.com/in/m-ahmed-butt-2aa58a290/" target="_blank"
                            class="hero-social-link">
                            <!-- <ion-icon name="logo-linkedin"></ion-icon> -->
                            <app-feather-icons [icon]="'linkedin'"></app-feather-icons>
                            <div class="tooltip">Linkedin</div>
                        </a>
                    </li>
                </ul>


                <a (click)="scrollToSection('stats')" class="scroll-down">Scroll</a>
            </section>

            <!-- 
              - #STATS
            -->

            <section class="stats" id="stats">
                <ul class="stats-list">
                    <li>
                        <a class="stats-card">
                            <div class="card-icon">
                                <img src="./assets/images/portfolio/stats-card_icon-1.png" alt="Badge icon" />
                            </div>

                            <h2 class="h2 card-title">
                                {{founder?.experience}}+ <strong>Years of Experience</strong>
                                <strong>As a Full Stack Developer</strong>
                            </h2>

                        </a>
                    </li>

                    <li>
                        <a class="stats-card">
                            <div class="card-icon">
                                <img src="./assets/images/portfolio/stats-card_icon-2.png" alt="Checkmark icon" />
                            </div>

                            <h2 class="h2 card-title">
                                {{founder?.projects}}+<strong>Completed Projects</strong>
                            </h2>

                        </a>
                    </li>

                    <li>
                        <a class="stats-card">
                            <div class="card-icon">
                                <img src="./assets/images/portfolio/stats-card_icon-3.png" alt="Peoples rating icon" />
                            </div>

                            <h2 class="h2 card-title">
                                {{founder?.companyExperience}}+<strong>Full Stack developer</strong>
                                <strong>At ATG Systems</strong>
                            </h2>

                            <!-- <ion-icon name="chevron-forward-outline"></ion-icon> -->
                        </a>
                    </li>
                </ul>
            </section>

            <!-- 
              - #ABOUT
            -->

            <section class="about" id="about">
                <figure class="about-banner">
                    <img src="{{ founder?.aboutImg }}"
                        alt="A man in a alice blue shirt with a thinking expression" class="w-100" />
                </figure>

                <div class="about-content section-content">
                    <p class="section-subtitle">About me</p>

                    <h2 class="h3 section-title">
                        Need a Creative Product? I can Help You!
                    </h2>

                    <p class="section-text">
                        {{founder?.desc}}
                    </p>

                    <div class="btn-group">
                        <button aria-label="Button" class="btn btn-secondary">Hire me</button>

                        <button (click)="onDownloadClick()" aria-label="Button" class="btn btn-primary">
                            Download cv
                        </button>
                    </div>
                </div>
            </section>

            <!-- 
              - #SKILLS
            -->

            <section class="skills" id="skills">
                <div class="skills-content section-content">
                    <p class="section-subtitle">My skills</p>

                    <h2 class="h3 section-title">
                        What My Programming Skills Included?
                    </h2>

                    <p class="section-text">
                        I develop simple, intuitive, and responsive user interfaces that
                        help users get things done with less effort and time using those
                        technologies.
                    </p>

                    <div class="skills-toggle" data-toggle-box>
                        <button aria-label="Button" class="toggle-btn" [ngClass]="{'active': showSkills}" (click)="toggleSkills()"
                            data-toggle-btn>Skills</button>
                        <button aria-label="Button" class="toggle-btn" [ngClass]="{'active': showTools}" (click)="toggleTools()"
                            data-toggle-btn>Tools</button>
                    </div>

                </div>

                <div class="skills-box" *ngIf="showSkills" data-skills-box>
                    <ul class="skills-list">
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">HTML5</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/html5.png" alt="HTML5 logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">CSS3</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/css3.png" alt="CSS3 logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">JavaScript</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/javascript.png" alt="JavaScript logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">TypeScript</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/typescript.png" alt="TypeScript logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">jQuery</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/jquery.png" alt="jQuery logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">Bootstrap</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/bootstrap.png" alt="Bootstrap logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">Angular</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/angular.png" alt="Angular logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">Vue</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/vue.png" alt="Vue logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">Firebase</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/firebase.png" alt="Firebase logo" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="skills-box" *ngIf="showTools" data-tools-box>
                    <ul class="skills-list">
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">Webpack</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/webpack.png" alt="Webpack logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">Git</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/git.png" alt="Git logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">Npm</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/npm.png" alt="Npm logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">Command Line</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/command.png" alt="Command Line logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">VS Code</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/vs-code.png" alt="VS Code logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">ClickUp</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/clickup.png" alt="ClickUp logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">Slack</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/slack.png" alt="Slack logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">Photoshop</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/photoshop.png" alt="Photoshop logo" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="skill-card">
                                <div class="tooltip">Adobe XD</div>
                                <div class="card-icon">
                                    <img src="./assets/images/portfolio/adobe-xd.png" alt="Adobe XD logo" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>



            <!-- 
              - #PROJECTS
            -->
            <section class="teams project-content section-content" id="portfolio">
                <div class="max-width">
                    <p class="section-subtitle">My Works</p>
                    <h2 class="h3 section-title">My Projects</h2>
                    <p class="section-text">
                        We develop the best quality website that serves for the long-term.
                        Well-documented, clean, easy and elegant interface helps any
                        non-technical clients.
                    </p>
                    <owl-carousel-o [options]="customOptions" class="carousel">
                        <ng-container *ngFor="let project of projects">
                            <ng-template carouselSlide>
                                <a class="MyProjects" [href]="project.link" target="_blank">
                                    <div class="card">
                                        <div class="box">
                                            <img [src]="project.img" alt="project images" />
                                            <div class="text">{{ project.title }}</div>
                                        </div>
                                    </div>
                                </a>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </section>
            <!-- 
              - #CONTACT
            -->

            <section class="contact" id="contact">
                <div class="contact-content section-content">
                    <p class="section-subtitle">Contact</p>

                    <h2 class="h3 section-title">
                        Have You Any Project? Please Drop a Message
                    </h2>

                    <p class="section-text">
                        Get in touch and let me know how i can help. Fill out the form and
                        i’ll be in touch as soon as possible.
                    </p>

                    <ul class="contact-list">
                        <li class="contact-list-item">
                            <div class="contact-item-icon">
                                <!-- <ion-icon name="location-outline"></ion-icon> -->
                                <app-feather-icons [icon]="'map-pin'"></app-feather-icons>
                            </div>

                            <div class="wrapper">
                                <h3 class="h4 contact-item-title">Address:</h3>

                                <address class="contact-info">{{founder?.address}}</address>
                            </div>
                        </li>

                        <li class="contact-list-item">
                            <div class="contact-item-icon">
                                <!-- <ion-icon name="call-outline"></ion-icon> -->
                                <app-feather-icons [icon]="'phone'"></app-feather-icons>
                            </div>

                            <div class="wrapper">
                                <h3 class="h4 contact-item-title">Phone:</h3>

                                <a href="tel:01234567789" class="contact-info">{{founder?.contactNumber}}</a>
                            </div>
                        </li>

                        <li class="contact-list-item">
                            <div class="contact-item-icon">
                                
                                <app-feather-icons [icon]="'mail'"></app-feather-icons>
                            </div>

                            <div class="wrapper">
                                <h3 class="h4 contact-item-title">Email:</h3>

                                <a href="mailto:info@jack.com" class="contact-info">{{founder?.email}}</a>
                            </div>
                        </li>

                        <li>
                            <ul class="contac-social-list">
                                <li>
                                    <a class="contact-social-link">
                                        <div class="tooltip">Facebook</div>
                                        <app-feather-icons [icon]="'facebook'"></app-feather-icons>
                                       
                                    </a>
                                </li>

                                <li>
                                    <a href="https://github.com/Ahmed8881" target="_blank" class="contact-social-link">
                                        <div class="tooltip">GitHub</div>
                                        <app-feather-icons [icon]="'github'"></app-feather-icons>
                                        
                                    </a>
                                </li>

                                <li>
                                    <a href="https://www.linkedin.com/in/m-ahmed-butt-2aa58a290/" target="_blank"
                                        class="contact-social-link">
                                        <div class="tooltip">Linkedin</div>
                                       
                                        <app-feather-icons [icon]="'linkedin'"></app-feather-icons>
                                    </a>
                                </li>

                                <li>
                                    <a href="#" class="contact-social-link">
                                        <div class="tooltip">Youtube</div>
                                        <app-feather-icons [icon]="'youtube'"></app-feather-icons>
                                        
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" id="contact-form" class="contact-form">
                    <div class="form-wrapper">
                        <label for="name" class="form-label">Name</label>
                        <div class="input-wrapper">
                            <input type="text" formControlName="name" id="name" class="input-field" />
                            <div *ngIf="contactForm.get('name')?.invalid && (contactForm.get('name')?.touched || contactForm.get('name')?.dirty)">
                                <small class="error-message" *ngIf="contactForm.get('name')?.errors?.['required']">Name is required.</small>
                            </div>
                        </div>
                    </div>
                
                    <div class="form-wrapper">
                        <label for="email" class="form-label">Email</label>
                        <div class="input-wrapper">
                            <input type="email" formControlName="email" id="email" placeholder="e.g abc@mail.com" class="input-field" />
                            <div *ngIf="contactForm.get('email')?.invalid && (contactForm.get('email')?.touched || contactForm.get('email')?.dirty)">
                                <small class="error-message" *ngIf="contactForm.get('email')?.errors?.['required']">Email is required.</small>
                                <small class="error-message" *ngIf="contactForm.get('email')?.errors?.['email']">Please enter a valid email.</small>
                            </div>
                        </div>
                    </div>
                
                    <div class="form-wrapper">
                        <label for="phone" class="form-label">Phone</label>
                        <div class="input-wrapper">
                            <input type="tel" formControlName="phone" id="phone" placeholder="Phone Number" class="input-field" />
                            <div *ngIf="contactForm.get('phone')?.invalid && (contactForm.get('phone')?.touched || contactForm.get('phone')?.dirty)">
                                <small class="error-message" *ngIf="contactForm.get('phone')?.errors?.['required']">Phone number is required.</small>
                                <small class="error-message" *ngIf="contactForm.get('phone')?.errors?.['pattern']">Phone number must be 10 digits.</small>
                            </div>
                        </div>
                    </div>
                
                    <div class="form-wrapper">
                        <label for="message" class="form-label">Message</label>
                        <div class="input-wrapper">
                            <textarea formControlName="message" id="message" placeholder="Write message..." class="input-field"></textarea>
                            <div *ngIf="contactForm.get('message')?.invalid && (contactForm.get('message')?.touched || contactForm.get('message')?.dirty)">
                                <small class="error-message" *ngIf="contactForm.get('message')?.errors?.['required']">Message is required.</small>
                            </div>
                        </div>
                    </div>
                
                    <button type="submit" aria-label="Button" class="btn btn-primary">Send</button>
                </form>
                
                

                <script src="https://cdn.emailjs.com/dist/email.min.js"></script>
                <script>
                    (function () {
                        emailjs.init("QYO3DWeUtnc99J_eX");
                    })();

                    document
                        .getElementById("contact-form")
                        .addEventListener("submit", function (event) {
                            event.preventDefault();

                            emailjs
                                .sendForm("service_j6pwmqw", "template_65cyj3j", this)
                                .then(
                                    function (response) {
                                        // console.log("SUCCESS!", response.status, response.text);
                                        alert("Your message has been sent successfully!");
                                    },
                                    function (error) {
                                        console.log("FAILED...", error);
                                        alert(
                                            "Failed to send the message. Please try again later."
                                        );
                                    }
                                );
                        });
                </script>
            </section>
        </article>
        }

    </main>


    <!-- 
    - #FOOTER
  -->

    <footer class="footer">
        <div class="container">
            <p class="h1 logo">
                <a> {{ founder.name }}<span>.</span> </a>
            </p>

            <p class="copyright">
                &copy;. All rights reserved
            </p>
        </div>
    </footer>

    <!-- 
      - #GO TO TOP
    -->

    <a (click)="scrollToSection('top')" class="go-top" [class.active]="goTopBtnActive" data-go-top title="Go to Top">
        <!-- <ion-icon name="arrow-up"></ion-icon> -->
        <app-feather-icons [icon]="'arrow-up'"></app-feather-icons>
    </a>

</div>