  <div class="page-section feature-dec" id="feature">
    <h4 class="content-title">features</h4>
    <div class="single-feature row">
      <!-- @for(allData of featuresData; track allData){ -->
        <div >
          <ul class="row">
            <!-- @for(data of allData.features; track data){
              <li>
                <i class="fas fa-{{data.icon}}"></i> {{data.title}}
              </li>
            } -->
            @for(data of instituteData.additionalFeatures; track data){
                @if(data?.featureName == "Hostels/Dormitories"){
                    <li class="col-lg-6 col-md-6 col-sm-12">
                      <i class="fa-building fas"></i> {{data.featureName}}
                    </li>
                }
                @if(data?.featureName == "Laboratories"){
                  <li class="col-lg-6 col-md-6 col-sm-12">
                    <i class="fa-flask fas"></i> {{data.featureName}}
                  </li>
                }
                @if(data?.featureName == "Scholarships and Financial Aid"){
                  <li class="col-lg-6 col-md-6 col-sm-12">
                    <i class="fa-award fas"></i> {{data.featureName}}
                  </li>
                }
                @if(data?.featureName == "Research Centers"){
                  <li class="col-lg-6 col-md-6 col-sm-12">
                    <i class="fa-microscope fas"></i> {{data.featureName}}
                  </li>
                }
                @if(data?.featureName == "Library Resources and Services"){
                  <li class="col-lg-6 col-md-6 col-sm-12">
                    <i class="fa-book fas"></i> {{data.featureName}}
                  </li>
                }
                @if(data?.featureName == "Health and Wellness Facilities"){
                  <li class="col-lg-6 col-md-6 col-sm-12">
                    <i class="fa-stethoscope fas"></i> {{data.featureName}}
                  </li>
                }
                }
              
            

          </ul>
        </div>
      <!-- } -->
    </div>
  </div>
