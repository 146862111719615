<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section>
  <div class="container">
    <div class="row log-in sign-up">
      <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 col-12">
        <div class="theme-card">
          <div class="title-3 text-start">
            <h2>Sign up</h2>
          </div>
          <app-sign-up-form></app-sign-up-form>
        </div>
      </div>
    </div>
  </div>
</section>
