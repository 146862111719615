import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { MetaService } from 'src/app/shared/services/meta.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-scholarship-list',
  templateUrl: './scholarship-list.component.html',
  styleUrl: './scholarship-list.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScholarshipListComponent {
  public noRecordFound = false;
  public isDataLoading = true;
  isErrorOccurred: boolean = false;
  public isSpinnerShown = false;

  private _dataShare = inject(DataShareService);
  public title = 'slider_filter_search';
  bannerImage: string = 'assets/images/banner/all-institute-banner.jpg';
  data: any;
  receivedData: any;
  scholarshipsData: any[] = [];

  private _spinner = inject(NgxSpinnerService);
  private _utils = inject(CommonUtilsServiceService);

  private readonly metaService: MetaService = inject(MetaService);

  constructor(public propertyService: PropertyService) { }

  ngOnInit(): void {
    // this.CheckAndCallAPI();
    this.fetchScholarshipsData(this.currentPage);

    this.metaService.updateTitle('All Scholarships | AdmissionLylo');
    this.metaService.updateMetaTags([
      { name: 'description', content: 'Your page description here' },
      { name: 'keywords', content: 'angular, seo, meta tags' },
      { name: 'author', content: 'Your Name' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.metaService.setCanonicalURL();
    this.metaService.initializeMetaData();

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  onReset() {
    this.currentPage = 1
    this.isSpinnerShown = true;
    this._spinner.show();
    this.fetchScholarshipsData(this.currentPage);
  }

  handleChildEvent(data: any) {
    this.receivedData = data;
    this.CheckAndCallAPI();
  }

  handleSearchData(event: any) {
    if (event === '') {
      this.isSpinnerShown = true;
      this._spinner.show();
      this.receivedData.search = '';

      this.fetchScholarshipsData(1)
    }
  }


  ///////////////////   Pagination  //////////////////////////////

  pages: (number | string)[] = [];
  currentPage: number = 1;
  totalRecords: number = 0;
  itemsPerPage: number = 8;
  maxVisiblePages: number = 5;
  displayedItems: any[] = [];

  fetchScholarshipsData(pageNumber: number): void {
    this.propertyService.getAllScholarships(this.receivedData || {}, pageNumber, this.itemsPerPage).subscribe(
      (response) => {
        console.log('API Response:', response);
        this.isDataLoading = false;
        this.isErrorOccurred = false;
  
        // Validate response.data
        if (response && Array.isArray(response.data)) {
          this.scholarshipsData = response.data;
        } else {
          console.error('Invalid response data format');
          this.scholarshipsData = [];
          this.totalRecords = 0;
          this.noRecordFound = true;
          return;
        }
  
        // Set total records
        this.totalRecords = this.scholarshipsData[0]?.totalRecords || 0;
        this.calculatePages();
  
        // No records found check
        if (this.scholarshipsData.length === 0) {
          this.noRecordFound = true;
          this.totalRecords = 0;
        } else {
          this.noRecordFound = false;
  
          // Convert scholarship images to Blob URLs
          this.scholarshipsData.forEach((scholarship) => {
            scholarship.blobUrls = []; // Initialize array for Blob URLs
            if (Array.isArray(scholarship.img)) {
              scholarship.img.forEach((image: { url: string }) => {
                if (image.url && image.url.includes('base64,')) {
                  const base64Data = image.url.split('base64,')[1];
                  const blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
                  scholarship.blobUrls.push(blobUrl);
                  console.log('Scholarship Blob URL:', blobUrl);
                } else {
                  scholarship.blobUrls.push('path/to/default/image.png'); // Fallback URL
                }
              });
            }
          });
        }
  
        this.isSpinnerShown = false;
        this._spinner.hide();
      },
      (error) => {
        console.error('Error fetching scholarship data:', error);
        this.isDataLoading = false;
        this.isErrorOccurred = true;
        this.isSpinnerShown = false;
        this._spinner.hide();
      }
    );
  }
  
  

  onPageChanged(event: any): void {
    this.currentPage = event;
    this.fetchScholarshipsData(this.currentPage);
    this.isDataLoading = true;
    this.scrollToContentStart();
  }

  calculatePages(): void {
    const totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
    this.pages = [];
    if (totalPages <= this.maxVisiblePages) {
      this.pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      const startPage = Math.max(
        this.currentPage - Math.floor(this.maxVisiblePages / 2),
        1
      );
      const endPage = Math.min(startPage + this.maxVisiblePages - 1, totalPages);

      if (startPage > 1) {
        this.pages.push(1);
        if (startPage > 2) {
          this.pages.push('...');
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        this.pages.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          this.pages.push('...');
        }
        this.pages.push(totalPages);
      }
    }
  }

  navigateToPage(page: any): void {
    if (page >= 1 && page <= this.pages.length) {
      this.currentPage = page;
      this.fetchScholarshipsData(this.currentPage);
    }
  }

  navigatePage(direction: 'prev' | 'next'): void {
    if (direction === 'prev' && this.currentPage > 1) {
      this.currentPage--;
    } else if (
      direction === 'next' &&
      this.currentPage < Math.ceil(this.totalRecords / this.itemsPerPage)
    ) {
      this.currentPage++;
    }
    this.fetchScholarshipsData(this.currentPage);
  }

  getDisplayRange(): string {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(start + this.itemsPerPage - 1, this.totalRecords);
    return `${start} – ${end}`;
  }

  scrollToContentStart(): void {
    const contentStart = document.getElementById('content-start');
    if (contentStart) {
      contentStart.scrollIntoView({ behavior: 'smooth' });
    }
  }

  resetPagination(): void {
    this.currentPage = 1; // Reset current page to 1
    this.calculatePages(); // Recalculate pages
    this.fetchScholarshipsData(this.currentPage); // Fetch the list of institutes
    this.scrollToContentStart(); // Scroll to content start
  }

  onResetPagination(): void {
    this.resetPagination();
  }

  // TS Code for Pagination End



  CheckAndCallAPI() {
    // const filters = {
    //   country: this.receivedData?.country || '',
    //   state: this.receivedData?.state || '',
    //   city: this.receivedData?.city || '',
    //   search: this.receivedData?.search || ''
    // };

    if (this.receivedData?.country !== '' ||
      this.receivedData?.state !== '' ||
      this.receivedData?.search !== '' ||
      this.receivedData?.scholarshipType !== '') {
      this.currentPage = 1;
      this.isSpinnerShown = true;
      this._spinner.show();
      this.propertyService.getAllScholarships(this.receivedData, this.currentPage, this.itemsPerPage).subscribe(
        (response) => {
          this.scholarshipsData = response.data;
          this.isDataLoading = false;
          this.totalRecords = response.totalRecords;
          this.calculatePages();
          if (this.scholarshipsData == null || this.scholarshipsData?.length <= 0) {
            this.noRecordFound = true;
          }
          else {
            this.noRecordFound = false;
          }
          this.isSpinnerShown = false;
          this._spinner.hide();

          // console.log('Mapped Data: ', this.admissionsData);
        },
        (error) => {
          console.error('Error fetching admissions data', error);
          this.isDataLoading = false;
          this.isSpinnerShown = false;
          this._spinner.show();

        }
      );

    }
  }

}
