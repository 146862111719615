import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss']
})
export class ContactUsFormComponent {

  @Input() captcha:boolean = false;
  @Input() rowClass: string;
  contactForm: FormGroup;
  constructor(private fb:FormBuilder)
  {
    this.contactForm=this.fb.group({
      name:['',Validators.required],
      contact: ['', [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10,18}$")
      ]],
      email: ['', [
        Validators.required, Validators.email, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
      ]],
      message:['',Validators.required]
    })
  }

}
