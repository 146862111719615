import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cv1',
  templateUrl: './cv1.component.html',
  styleUrl: './cv1.component.scss'
})
export class Cv1Component implements OnInit {

  templateId: number | null = null;
  @Input() userData: any = {};

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.templateId = id ? +id : null;
    });

    // console.log('User Data in cv1 :', this.userData);

  }

}
