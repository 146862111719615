<section class="layout-home2 banner-section video-layout p-0">
  <video class="bgvideo" playsinline="" autoplay="" muted="" loop="">
    <source src="assets/video/video.mp4" type="video/mp4">
  </video>
  <div class="container">
    <div class="row overlay-content">
      <div class="col-lg-12">
        <div class="video-details p-0">
          <div class="left-sidebar">
            <span class="label label-light label-flat color-2">#Realestate</span>
            <a href="javascript:void(0)" aria-label="clickable-text">
              <h2> Sick about moving out? </h2>
            </a>
            <app-filter-box-three></app-filter-box-three>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
