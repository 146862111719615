<section class="home-section layout-1 layout-9">
  <div class="container">
    <div class="row">
      <div class="col-xxl-5 col-xl-6 col-lg-7">
        <div class="home-main arrow-light">
          <div class="home-content">
            <div>
              <img
                src="assets/images/signature/3.png"
                class="img-fluid"
                alt="signature"
              />
              <h6>Want to buy or rent Home ?</h6>
              <h1 class="">
                Find Better Places to
                <span class="typed"> {{ view.type }}</span>
              </h1>
              <button
                type="button"
                class="btn btn-gradient btn-pill color-1"
                [routerLink]="['/agent/submit-property']"
                aria-label="submit property"
              >
                submit property
              </button>
              <div class="looking-icons">
                <app-looking-for-icons [text]="true"></app-looking-for-icons>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="layout-right-img">
          <img src="assets/images/layout-9.png" alt="house" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
  <div class="snow-effect"></div>
</section>
