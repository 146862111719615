<div class="cv-container">
    <div class="cv-sidebar">
        <div class="profile-pic">
            <img [src]="userData?.userDetails.profileBase64 || 'https://default-image-url.com/default-profile.png'"
                class="img-fluid bg-img" alt="profile-image" />
        </div><br>
        <div class="contact">
            <h2>Contact Me.</h2>
            <p><strong>Phone:</strong> {{userData?.userDetails.phoneNumber ||"+92 123 4567890"}}
            </p>
            <p><strong>Email:</strong> {{ userData?.userDetails.email||"MYEMAILgmail.com"}}</p>
            <p><strong>Address:</strong> {{ userData?.userDetails.address + ', ' + userData?.userDetails.city + ', ' +
                userData?.userDetails.state + ', ' + userData?.userDetails.country }}
            </p>
        </div><br>
        <div class="about">
            <h2>About Me.</h2>
            <p> {{ userData?.userDetails.description || "lorem ipsum dolor sit amet consectetur adipisicing
                elit. At iure mollitia
                commodi odio ipsa qui, similique perferendis in sequi, eligendi inventore laborum quos numquam
                voluptatibus illo
                delectusamet cumque explicabo Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque
                reiciendis non inventore accusamus
                omnis" }}</p>
        </div>
        <br>

        <div class="languages">
            <h2>Languages</h2>
            <ul>
                @for(language of userData?.languageDetails;track language){
                <li><strong>{{ language?.language}}</strong></li><br>
                }
            </ul>
        </div>
    </div>
    <div class="cv-main">
        <h1>{{userData?.userDetails.firstName + " " +
            userData?.userDetails.lastName}}</h1>
        <h3>{{ userData?.userDetails.gender }}</h3><br>
        <section class="experience">
            <h2>Work Experience</h2>
            <div class="job" *ngFor="let experience of userData?.experienceDetails">
                <h4>{{ experience?.role
                    || "SENIOR UI AND UX DESIGNER"}}</h4>
                <p><strong>{{experience?.company || "UNIVERSITY OF EDUCATION"}}</strong></p>
                <p>{{experience?.startDate?.substring(0,4) +" - "
                    +
                    experience?.endDate?.substring(0,4)}}</p>
                <ul>
                    <li>{{experience?.description || "lorem ipsum dolor sit amet consectetur
                        adipisicing elit. At
                        iure mollitia commodi odio ipsa qui, similique perferendis in sequi,
                        eligendi inventore
                        laborum quos numquam voluptatibus illo delectusamet cumque explicabo"}}</li>

                </ul>
            </div>

        </section>
        <section class="education">
            <h2>Education</h2>
            @for(education of userData?.educationDetails;track education){

            <div class="education-item">
                <h4>{{ education?.degreeTitle || "Bachelor
                    of Science in Computer Science"
                    }}</h4>
                <p> {{education?.startDate?.substring(0,4) +" - " +
                    education?.endDate?.substring(0,4)}}</p>
                <p>{{education?.institute || "UNIVERSITY OF
                    EDUCATION"}}</p>
            </div>
            }
        </section>
        <section class="skills">
            <h2>Skills</h2>
            <p *ngFor="let skill of userData?.skillsDetails">
                {{skill.skillTitle}}
                <span class="bar">
                    <span [style.width.%]="skill?.percentage"></span>
                </span>
            </p>
        </section>
    </div>
</div>