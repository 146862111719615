<section class="banner-section banner-4 parallax-image">
  <div class="container">
    <div class="row">
      <div class="col">
        <app-title [titleClass]="'title-1'" [tag]="'Buy Or Sell'" [type]="'simple'" [tagClass]="tagClass"></app-title>
          @for(data of bannerData; track data){
            <app-banner [bannerData]="data" [type]="'simple'" [tagClass]="tagClass"></app-banner>
          }
      </div>
    </div>
  </div>
</section>
