@if(products.length){
  <nav class="theme-pagination">
    @if(paginate.pages && paginate.pages.length){
      <ul class="pagination">
        <li class="page-item" [ngClass]="{disabled:paginate.currentPage === 1}">
          <a class="page-link" href="javascript:void(0)" aria-label="Previous"  (click)="pageSet(paginate.currentPage - 1)">
            <span aria-hidden="true">«</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        @for(page of paginate.pages; track page){
          <li class="page-item " [ngClass]="{ 'active disabled': paginate.currentPage == page }">
            <a class="page-link" href="javascript:void(0)" (click)="pageSet(page)">{{ page }}</a>
          </li>
        }
        <li class="page-item" [ngClass]="{ disabled: paginate.currentPage == paginate.totalPages }">
          <a class="page-link" href="javascript:void(0)" aria-label="Next" (click)="pageSet(paginate.currentPage + 1)">
            <span aria-hidden="true">»</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    }
  </nav>
}
