import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cv3',
  templateUrl: './cv3.component.html',
  styleUrl: './cv3.component.scss'
})
export class Cv3Component {
  @Input() userData: any = {};

}
