<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="portfolio-section portfolio-grid">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-grid-tab [gridImagesData]="gridImagesData" (imagesData)="receiveChildData($event)"></app-grid-tab>
        <div class="column-sm row grid zoom-gallery-multiple" gallerize>
          <ngx-masonry [options]="masonryOptions" style="width: 100%;" [ordered]="true">
            @for(images of imagesData; track images; let i = $index){
              <div class="col-lg-4 col-sm-6 sale grid-item wow fadeInUp" ngxMasonryItem style="max-width: 430px;">
                <app-common-grid-images [imagesData]="images" [type]="'masonry'" [index]="i"></app-common-grid-images>
              </div>
            }
          </ngx-masonry>
        </div>
      </div>
    </div>
  </div>
</section>



