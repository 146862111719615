import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './shared/components/layouts/layout/layout.component';
import { content } from './shared/routes/routes';
import { HomePageComponent } from './components/home/home-page/home-page.component';
import { AboutUsComponent } from './shared/components/pages/about-us/about-us.component';
import { ContactUsComponent } from './shared/components/pages/contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './shared/components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './shared/components/pages/terms-conditions/terms-conditions.component';
import { FaqsComponent } from './shared/components/pages/faqs/faqs.component';
import { PortfolioComponent } from './shared/components/pages/portfolio/portfolio.component';
import { PagesRoutingModule } from './shared/pages-routing.module';
import { authenticationGuard } from './shared/guard/authentication.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
    component: HomePageComponent,
    title: 'Home Page | Admission Lylo'
  },
  {
    path: 'teacher',
    loadChildren: () => import('./components/teachears/teachear-module.module').then(m => m.TeachearModuleModule)
  },
  {
    path: 'institutes',
    loadChildren: () => import('./components/institutes/institutes.module').then(m => m.InstitutesModule)
  },
  {
    path: 'admissions',
    loadChildren: () => import('./components/admissions/admissions.module').then(m => m.AdmissionsModule)
  },
  {
    path: 'cv-builder',
    loadChildren: () => import('./components/cv-builder/cv-builder.module').then(m => m.CvBuilderModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('./components/jobs/jobs.module').then(m => m.JobsModule)
  },
  {
    path: 'scholarships',
    loadChildren: () => import('./components/scholarships/scholarships.module').then(m => m.ScholarshipsModule)
  },
  // {
  //   path: 'library',
  //   loadChildren: () => import('./components/library/library.module').then(m => m.LibraryModule)
  // },
  {
    path: 'books',
    loadChildren: () => import('./components/modules/library/library.module').then(m => m.LibraryModule)
  },
  {
    path: 'tutorials',
    loadChildren: () => import('./components/tutorials/tutorials.module').then(m => m.TutorialsModule)
  },
  {
    path: 'hostels',
    loadChildren: () => import('./components/hostels/hostels.module').then(m => m.HostelsModule)
  },
  {
    canActivate : [authenticationGuard],
    path: 'user',
    loadChildren: () => import('./components/pages/user-panel/user-panel.module').then(m => m.UserPanelModule)
  },
  {
    path: 'authentication',
    loadChildren: () => import('./components/authentication/autentication-routing.module').then(m => m.AutenticationRoutingModule)
  },

  // {
  //   path: 'about-us',
  //   component: AboutUsComponent,
  // },
  // {
  //   path: 'privacy-policy',
  //   component: PrivacyPolicyComponent,
  // },
  // {
  //   path: 'terms-conditions',
  //   component: TermsConditionsComponent,
  // },
  // {
  //   path: 'portfolio/:id',
  //   component: PortfolioComponent,
  // },
  // {
  //   path: 'contact-us',
  //   component: ContactUsComponent
  // },
  // {
  //   path: 'Faq',
  //   component: FaqsComponent,
  // },
  {
    path: '',
    loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule),
  },

  // This Route should be placed at the last of the routing file under all the routes
  {
    path: '**',
    redirectTo: 'page/other-pages/404',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), PagesRoutingModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
