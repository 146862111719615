import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { currency, homeSectionSlider } from '../../../../shared/interface/property';

@Component({
  selector: 'app-home-section-slider',
  templateUrl: './home-section-slider.component.html',
  styleUrls: ['./home-section-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeSectionSliderComponent {

  @Input() homeSectionSliderData: homeSectionSlider[] = [];
  @Input() type : string = '';
  @Input() instituteData : any;

  constructor(){
   
  }
  ngOnInit(): void {
  }

}
