<section class="blog-section bg-comman-2">
  <div class="container">
    <div class="row">
      <div class="col">
        <app-title [titleClass]="'title-2'" [heading]="'Latest Blog'" [desc]="desc" [textWhite]="true" [type]="'basic'"></app-title>
        <div class="blog-1">
          <app-latest-blog [latestBlogData]="latestBlogData" [type]="'basic'"></app-latest-blog>
        </div>
      </div>
    </div>
  </div>
</section>
