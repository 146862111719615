<section class="offer-section banner-section slick-between" [ngClass]="sectionClass">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-title [titleClass]="'title-1'" [heading]="'Our New Offer'" [textWhite]="true" [type]="'simple'"
                    [tagClass]="tagClass"></app-title>
                <div class="offer-slider">
                    <!-- [tag]="'New Offer'" -->
                    <app-new-offer [title]="title"></app-new-offer>
                </div>
            </div>
        </div>
    </div>
</section>