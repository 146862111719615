import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScholarshipsRoutingModule } from './scholarships-routing.module';
import { ScholarshipListComponent } from './scholarship-list/scholarship-list.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeModule } from '../home/home.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PropertyModule } from '../property/property.module';
import { ScholarshipDetailComponent } from './scholarship-detail/scholarship-detail.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [
    ScholarshipListComponent,
    ScholarshipDetailComponent
  ],
  imports: [
    CommonModule,
    ScholarshipsRoutingModule,
    PropertyModule,
    SharedModule,
    HomeModule,
    NgbPaginationModule,
    NgxSkeletonLoaderModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),

  ],
  exports: [
    ScholarshipListComponent,
  ]
})
export class ScholarshipsModule { }
