import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CvTemplatesComponent } from './cv-templates/cv-templates.component';
import { Cv1Component } from './cv-designs/cv1/cv1.component';
import { Cv2Component } from './cv-designs/cv2/cv2.component';
import { Cv3Component } from './cv-designs/cv3/cv3.component';
import { Cv4Component } from './cv-designs/cv4/cv4.component';
import { Cv5Component } from './cv-designs/cv5/cv5.component';
import { CvEditsComponent } from './cv-edits/cv-edits.component';
import { CvDataComponent } from './cv-data/cv-data.component';

const routes: Routes = [

  {
    path: 'cv-templates',
    component: CvTemplatesComponent
  },
  {
    path: 'cv-builder/cv-data',
    component: CvDataComponent
  },
  {
    path: 'cv-edits',
    component: CvEditsComponent

  },
  {
    path: 'cv-template/cv/:templateId',
    component: CvEditsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CvBuilderRoutingModule { }
