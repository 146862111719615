
<div class="mt-90">
  <app-title [isLabelAdded]="true" [isLineAdded]="true" [tag]="tag" [titleClass]="'title-1'" [heading]="'Subscribe Now'" [type]="'simple'"></app-title>
</div>
<section class="subscribe-section">
  <div class="container">
    <div class="row">
      <div class="col">
        @for(data of bannerData; track data){
        <app-banner [bannerData]="data" [type]="'standard'"></app-banner>
        }
      </div>
    </div>
  </div>
</section>