import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleComponent {

  @Input() titleClass: string = '';
  @Input() tagClass: string = '';
  @Input() tag: string = '';
  @Input() heading: string = '';
  @Input() desc: string = '';
  @Input() textWhite: boolean = false;
  @Input() svgIcon: boolean = false;
  @Input() type: string = '';
  @Input() svgClass: boolean = false;
  @Input() descClass: string;
  @Input() alignClass: string;
  @Input() isLineAdded: boolean = false;
  @Input() isLabelAdded: boolean = false;

  private _router = inject(Router);

  isClassApplied: boolean  = false;

  constructor() { 
    if (this._router.url === '/home-page') {
      this.isClassApplied = true;
    } else {
      this.isClassApplied = false;
    }
   }

}
