import { Component, ElementRef, HostListener, TemplateRef, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import * as faceapi from 'face-api.js';

@Component({
  selector: 'app-question-template',
  templateUrl: './question-template.component.html',
  styleUrl: './question-template.component.scss',
})
export class QuestionTemplateComponent {
  @ViewChild('poupModal') popUp!: TemplateRef<any>;
  @ViewChild('resultModal') resultPopUp!: TemplateRef<any>;
  @ViewChild('videoElement', { static: false }) videoElement!: ElementRef<HTMLVideoElement>;

  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Question';
  public parent = 'Teacher';
  public child = 'Question';

  public isDataLoading = false;
  public isSubmited = false;

  public _toastr = inject(ToastrService);
  public __apiCall = inject(ApiCallService);
  private __utilsService = inject(CommonUtilsServiceService);

  category = "";
  subCategory = "";
  level = "";
  description = "";

  testDetails: any;
  testResult: any;
  questions: any[] = [];
  questionsToShow: any[] = [];
  currentQuestionIndex: number = 0;
  currentQuestion: any;
  timeRemaining: number = 60;
  timer: any;
  selectedOption: string = '';
  selectedOptionIds: number[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public modal: NgbModal
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {

      this.category = params['category'];
      this.subCategory = params['subCategory'];
      this.level = params['level'];
      this.description = params['description'];

      const category = this.category;
      const subCategory = this.subCategory;
      const level = this.level;
      const description = this.description;

      if (this.category && this.subCategory && this.level) {
        this.testDetails = { category, subCategory, level, description };
        this.getQuestions(category, subCategory, level);
      } else {
        console.error('Invalid query parameters.');
      }
    });
    setTimeout(() => {
      if (this.popUp) {
        this.modal.open(this.popUp, { centered: true, size: 'md' });
      };
    }, 1000);
    this.requestCameraAccess();
  }

  getQuestions(category: string, subCategory: string, level: string) {
    this.isDataLoading = true;
    this.__apiCall
      .GetCallWithToken(`Test/GetQuestions?category=${category}&subCategory=${subCategory}&level=${level}`)
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this.questions = response.data;
            this.questionsToShow = this.questions.slice(0, 4);
            this.currentQuestion = this.questionsToShow[this.currentQuestionIndex];
            this.isDataLoading = false;
          } else {
            this.isDataLoading = false;
            console.error('No questions found for the selected test.');
          }
        },
        (error) => {
          this._toastr.error('Error fetching tests data', error);
          this.isDataLoading = false;
        }
      );
  }

  submitTest() {
    const payload = {
      title: `${this.testDetails?.category} - ${this.testDetails?.subCategory}`,
      testDescription: this.testDetails?.description,
      difficultyType: this.testDetails?.level,
      questionOptions: this.questions.map((question) => {
        const selectedOption = question.options.find(
          (option: { id: number }) => option.id === question.selectedOptionId
        );
        return {
          id: question.id,
          option: selectedOption ? selectedOption.option : '',
          questionId: question.id,
          optionId: selectedOption ? selectedOption.id : null,
          isCorrect: selectedOption ? selectedOption.isCorrect : false,
        };
      }),
    };
    this.isDataLoading = true;
    this.__apiCall
      .PostCallWithToken(payload, `UserTest/SaveUserTestDetail`)
      .subscribe(
        (res) => {
          if (res.responseCode === 200) {
            this.testResult = res.data[0];
            this._toastr.success(res.responseMessage);
            this.isDataLoading = false;
            if (this.resultPopUp) {
              this.modal.open(this.resultPopUp, { centered: true, size: 'md' });
            };
          } else {
            this._toastr.error(res.responseMessage);
            this.isDataLoading = false;
          }
        },
        (error) => {
          this._toastr.error('Error saving test results.', error);
          this.isDataLoading = false;
        }
      );
  }

  selectOption(questionIndex: number, option: any) {
    this.questions[questionIndex].selectedOptionId = option.id;
  }


  startTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.timeRemaining = 60;

    this.timer = setInterval(() => {
      if (this.timeRemaining > 0) {
        this.timeRemaining--;
      } else {
        clearInterval(this.timer);
        this._toastr.warning('Time is up for this question!');
        this.nextQuestion();
      }
    }, 1000);
  }

  nextQuestion() {
    if (this.currentQuestionIndex < this.questions.length - 1) {
      this.currentQuestionIndex++;
      this.currentQuestion = this.questions[this.currentQuestionIndex];
      this.startTimer();
    } else {
      clearInterval(this.timer);
    }
  }


  closeTest() {
    clearInterval(this.timer);
    this._toastr.success('Test closed.');
    if (window.opener) {
      window.opener.location.href = '/user/test-summary';
      window.opener.location.reload();
    }
    window.close();
  }

  finishTest() {
    clearInterval(this.timer);
    this._toastr.success('Test completed!');
    this.submitTest();
  }

  navigateToTestSummary(): void {
    this.__utilsService.navigateTo('/user/test-summary');
  }

  enterFullscreen() {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    }
    this.modal.dismissAll();
    this.startTimer();

  }


  async requestCameraAccess() {
    try {
      // Request camera access
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      this._toastr.success('Camera access granted. You can proceed.');

      // Attach the video stream to the video element
      const video = this.videoElement.nativeElement;
      video.srcObject = stream;
      video.play();

      if(video){
        console.log("Video started")
      }

      // Load face detection models
      await faceapi.nets.tinyFaceDetector.loadFromUri('/assets/models');
      await faceapi.nets.faceLandmark68Net.loadFromUri('/assets/models');

      // Start monitoring head position
      video.addEventListener('play', () => {
        const canvas = faceapi.createCanvasFromMedia(video);
        // const canvasContainer = document.querySelector('.canvas-container');
        // canvasContainer?.appendChild(canvas);
        document.body.append(canvas);

        const displaySize = { width: video.videoWidth, height: video.videoHeight };
        faceapi.matchDimensions(canvas, displaySize);

        setInterval(async () => {
          debugger
          const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
            .withFaceLandmarks();
          console.log("Detected faces:", detections);

          const resizedDetections = faceapi.resizeResults(detections, displaySize);
          console.log("Detected", resizedDetections)

          // Clear previous drawings
          canvas.getContext('2d')?.clearRect(0, 0, canvas.width, canvas.height);
          debugger
          // Draw landmarks on the canvas
          faceapi.draw.drawDetections(canvas, resizedDetections);
          faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);

          if (detections.length > 0) {
            const landmarks = detections[0].landmarks;
            debugger
            // Analyze head position (e.g., detect looking away)
            const nose = landmarks.getNose();
            const leftEye = landmarks.getLeftEye();
            const rightEye = landmarks.getRightEye();

            const horizontalMovement = Math.abs(nose[0].x - (leftEye[0].x + rightEye[0].x) / 2);
            const verticalMovement = Math.abs(nose[0].y - (leftEye[0].y + rightEye[0].y) / 2);
            console.log("Horizontal Movement:", horizontalMovement);
            console.log("Vertical Movement:", verticalMovement);
            debugger
            if (horizontalMovement > 5 || verticalMovement > 3) {
              this._toastr.warning('Please focus on the screen!');
            }
          }
        }, 100);
      });
    } catch (error) {
      this._toastr.error('Camera access denied.');
      console.error('Error accessing the camera: ', error);
    }
  }


  // requestCameraAccess() {
  //   navigator.mediaDevices.getUserMedia({ video: true })
  //     .then((stream) => {
  //       this._toastr.success('Camera access granted. You can proceed.');
  //       // Attach the video stream to the video element
  //       const video = this.videoElement.nativeElement;
  //       video.srcObject = stream;
  //       video.play();
  //     })
  // }


  // @HostListener('document:keydown', ['$event'])
  // onKeyDown(event: KeyboardEvent) {
  //   if (event.key === 'Escape') {
  //     event.preventDefault();
  //      //this._toastr.error('Exiting fullscreen is not allowed!');
  //   }

  //   //Block F12 or Ctrl+Shift+I
  //   if (event.key === 'F12' || (event.ctrlKey && (event.key === 'Shift' || event.key === 'I'))) {
  //     event.preventDefault();
  //      //this._toastr.error('Developer tools are disabled!');
  //   }
  // }

  // @HostListener('document:contextmenu', ['$event'])
  // disableRightClick(event: MouseEvent) {
  //   event.preventDefault();
  //    //this._toastr.error('Right-click is disabled during the test.');
  // }

  // @HostListener('document:fullscreenchange', [])
  // onFullscreenChange() {
  //   if (!document.fullscreenElement) {
  //     this._toastr.error('Fullscreen mode is mandatory! Test Ended');
  //     this.closeTest();

  //   }
  // }

  // @HostListener('document:visibilitychange', [])
  // onVisibilityChange() {
  //   if (document.hidden) {
  //     this._toastr.error('Switching tabs is not allowed!');
  //     this.closeTest();
  //   }
  // }







}

