<section class="feature-section ratio_landscape bg-half zoom-gallery">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="feature-4 modern-feature arrow-gradient">
          <app-featured-property [type]="'classic'" [featuredProperty]="featuredProperty" ></app-featured-property>
        </div>
      </div>
    </div>
  </div>
</section>
