import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gpa-calculator',
  templateUrl: './gpa-calculator.component.html',
  styleUrl: './gpa-calculator.component.scss'
})
export class GpaCalculatorComponent implements OnInit {

  gpaForm: FormGroup;
  gpa: number | null = null;
  isOpen = false;
  private toaster = inject(ToastrService);

  constructor(private fb: FormBuilder) {
    this.gpaForm = this.fb.group({
      courses: this.fb.array([this.createCourse()])
    });
  }
  ngOnInit(): void {
    this.addCourse();
    this.addCourse();
  }

  // Getter for accessing the form array
  get courses(): FormArray {
    return this.gpaForm.get('courses') as FormArray;
  }

  // Create a new course group
  createCourse(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      grade: ['', Validators.required],
      credits: [null, [Validators.required, Validators.min(1)]]
    });
  }

  // Add a new course
  addCourse(): void {
    if (this.courses.length >= 10) {
      this.toaster.error("Maximum 10 courses allowed");
    } else {

      this.courses.push(this.createCourse());
    }
  }

  // Remove a course
  removeCourse(index: number): void {
    this.courses.removeAt(index);
  }

  // Calculate GPA
  calculateGPA(): void {
    debugger
    const courses = this.courses.value;
    let totalGradePoints = 0;
    let totalCredits = 0;

    for (const course of courses) {
      debugger
      totalGradePoints += course.grade * course.credits;
      totalCredits += course.credits;
    }

    this.gpa = totalCredits > 0 ? totalGradePoints / totalCredits : null;
  }

  gradeOptions = [
    { label: 'A+', value: 4.0, percentageRange: '90-100%' },
    { label: 'A', value: 4.0, percentageRange: '85-89%' },
    { label: 'A-', value: 3.7, percentageRange: '80-84%' },
    { label: 'B+', value: 3.3, percentageRange: '75-79%' },
    { label: 'B', value: 3.0, percentageRange: '70-74%' },
    { label: 'B-', value: 2.7, percentageRange: '65-69%' },
    { label: 'C+', value: 2.3, percentageRange: '60-64%' },
    { label: 'C', value: 2.0, percentageRange: '55-59%' },
    { label: 'C-', value: 1.7, percentageRange: '50-54%' },
    { label: 'D+', value: 1.3, percentageRange: '45-49%' },
    { label: 'D', value: 1.0, percentageRange: '40-44%' },
    { label: 'F', value: 0.0, percentageRange: 'Below 40%' }
  ];


}
