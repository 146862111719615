<app-image-content-home-section></app-image-content-home-section>

<app-slider-filter-latest-rent [propertyClass]="false" [tagClass]="'color-1'" [title]="title"></app-slider-filter-latest-rent>

<app-slider-filter-featured-property [tagClass]="'color-1'" [title]="title" [sectionClass]="'feature-bg'"></app-slider-filter-featured-property>

<app-slider-filter-latest-rent [propertyClass]="false" [tagClass]="'color-1'" [total]="3" [title]="title"></app-slider-filter-latest-rent>

<div class="image-content-slider">
    <app-slider-filter-banner [tagClass]="'color-1'" [title]="title"></app-slider-filter-banner>
</div>

<app-slider-filter-property-in-cities [tagClass]="'color-1'" [title]="title"></app-slider-filter-property-in-cities>

<div class="image-content-slider">
    <app-slider-filter-happy-client [tagClass]="'color-1'" [title]="title"></app-slider-filter-happy-client>
</div>

<app-slider-filter-agents [tagClass]="'color-1'" [title]="title" [sectionClass]="'ratio_square'"></app-slider-filter-agents>

<app-slider-filter-new-offer [tagClass]="'color-1'" [title]="title" [sectionClass]="'layout1-bg'"></app-slider-filter-new-offer>

<app-image-content-brand></app-image-content-brand>
