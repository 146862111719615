import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { UserCentralizedDataService } from 'src/app/shared/services/user-centralized-data.service';

@Component({
  selector: 'app-cv-language-details',
  templateUrl: './cv-language-details.component.html',
  styleUrl: './cv-language-details.component.scss'
})
export class CvLanguageDetailsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public FormName: string = "Add";
  isLoading: boolean = false;
  public isExpanded: boolean = false;
  hasBeenOpened: boolean = false;
  languageDetails: any = [];
  delId: number;
  editId: number = 0;

  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  private readonly _userCentralizedDataService = inject(UserCentralizedDataService);

  constructor(public modal: NgbModal, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.getLanguageDetails();


    this.getLanguageList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addCard(content: TemplateRef<any>) {
    this.language = "";
    this.languageLevel = "";
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
  }

  editCard(content: TemplateRef<any>, tag: any) {
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.editId = tag?.id;
    this.language = tag?.language;
    this.languageLevel = tag?.languageLevel;
  }

  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }

  // Check if the language already exists
  checkLanguageExists(): boolean {
    const payload = this.createApiPayload();
    const storedLanguages = this._userCentralizedDataService.getLanguageDetails();  // Get stored language details

    if (storedLanguages?.length > 0) {
      if (this.FormName === 'Add') {
        // Check for duplicate language when adding new one
        const isExist = storedLanguages.some((tag: any) => tag.language === payload.language);
        if (isExist) {
          this._toaster.error('This language Already Exists');
          return true;
        }
      } else {
        let newLanguages = storedLanguages.filter((item: any) => item.id !== this.editId);
        // Check for duplicate language when editing existing one
        const isExist = newLanguages.some((tag: any) => tag.language === payload.language && tag.id !== this.editId);
        if (isExist) {
          this._toaster.error('This language Already Exists');
          return true;
        }
      }
    }
    return false;
  }

  // Save language details to sessionStorage and fetch the updated list
  public async saveLanguageDetails(): Promise<void> {
    this.ShowError();
    const isAlreadyExist = this.checkLanguageExists();
    if (!isAlreadyExist) {
      const payload = this.createApiPayload();
      if (this.languageLevel === '' || this.language === '') {
        this._toaster.error('Invalid Form');
      } else {
        this._userCentralizedDataService.saveLanguageDetails(payload); // Save language details
        console.log('Language details saved to sessionStorage', payload);
        this.getLanguageDetails();
        this.modal.dismissAll();

      }
    }
  }

  // Create payload for API
  createApiPayload() {
    this.editId = this.FormName === 'Add' ? 0 : this.editId;
    return {
      id: this.editId,
      languageLevel: this.languageLevel,
      language: this.language
    };
  }

  // Fetch the list of saved language details
  // getLanguageDetails(): void {
  //   this.languageDetails = this._userCentralizedDataService.getLanguageDetails();
  // }


  // public async deletelanguage(): Promise<void> {

  // }

  private async getLanguageDetails(): Promise<void> {
    this.isLoading = true;
    try {
      this.languageDetails = this._userCentralizedDataService.getLanguageDetails();

      if (!this.languageDetails || this.languageDetails.length === 0) {
        this.languageDetails = [];
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this._spinner.hide();
      this.isLoading = false;
      this.cdr.detectChanges();
    }

  }

  /////////////////////////////////////////////////
  /////////////errors of dropdowns //////////////////
  ///////////////////////////////////////////////////

  languageLevelError: boolean = false;
  languageError: boolean = false;

  ShowError() {
    this.languageLevelError = this.languageLevel == "" ? true : false;
    this.languageError = this.language == "" ? true : false;
  }

  ////////////////////////////////////////////////////
  /////////  languageLevel drop down data //////////
  /////////////////////////////////////////////////////

  languageLevel: string = '';
  languageLevelList = [
    { value: 'Fluent' },
    { value: 'Mid Level' },
    { value: 'Conversational' },
    { value: 'Other' }
  ];

  onlanguageLevelSelected(option: any) {
    this.languageLevel = option.value;
  }

  language: string = '';
  languageList = [
    { value: 'English' },
    { value: 'Hindi' },
  ];

  onlanguageSelected(option: any) {
    this.language = option.value;
  }

  getLanguageList() {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetLanguageDropDown")
      .subscribe((response) => {
        this.languageList = response?.data;
      });
  }


  // collapsed the tab
  toggleForm(event: MouseEvent) {
    event.stopPropagation();  // Stop click event propagation to prevent parent click
    this.isExpanded = !this.isExpanded;  // Toggle expanded state for the arrow
  }

}
