<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>

<section class="user-dashboard small-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="sidebar-user sticky-cls">
                    <div class="user-profile">
                        <app-user-info></app-user-info>
                    </div>
                    <div class="dashboard-list">
                        <app-user-panel-side-menu [activeTab]="'add-tutorial'"></app-user-panel-side-menu>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-content">
                    <div class="payment-user" id="payment">
                        <div class="common-card">
                            <div class="common-header">
                                <h5>Tutorial</h5>
                            </div>
                            <div class="row card-payment">
                                @if (isDataLoading) {
                                <div class="item">
                                    <ngx-skeleton-loader count="3" [theme]="{
                                                        height: '22.5rem',
                                                        width: '30%',
                                                        'border-radius': '10px',
                                                        'margin-right': '0.5rem',
                                                        'margin-left': '0.5rem'
                                                        }" />
                                </div>
                                }@else{
                                @for(data of tutorialData; track data){
                                <div class="col-xl-4 col-sm-6">
                                    <!-- //ultra new cards  -->

                                    <div class="property-box payment-card">
                                        <div class="property-image">
                                            <!-- <owl-carousel-o [options]="Options">
                                                @for(images of data?.images; track images){
                                               
                                                <ng-template carouselSlide>
                                                    <div class="property-slider" [class]="tagClass">
                                                        <a href="javascript:void(0)" class="bg-size"
                                                            [style.background-image]="'url('+images?.imageBase64+')'">
                                                            <img [src]="images?.imageBase64" class="bg-img"
                                                                alt="property-image" style="display: none;" />
                                                        </a>
                                                    </div>
                                                </ng-template>
                                                
                                                }
                                            </owl-carousel-o> -->
                                            
                                            <div class="property-slider" >
                                                <div href="javascript:void(0)" class="bg-size"
                                                    [style.background-image]="'url(' + data?.images + ')'">
                                                    <img [src]="data?.images" class="bg-img"
                                                        alt="property-image" style="display: none;" />
                                                </div>
                                            </div>
                                            <!-- <div class="seen-data">
                                                <app-feather-icons [icon]="'camera'"></app-feather-icons>
                                                <span>{{ data?.img?.length }}</span>
                                            </div> -->
                                        </div>
                                        <div class="property-details">
                                            <span class="font-roboto card-title">
                                                <app-feather-icons class="color"
                                                    [icon]="'map-pin'"></app-feather-icons>{{
                                                data?.city + ", "
                                                + data?.country }}</span>
                                            <a href="javascript:void(0)">
                                                <h3 class="line-clamp">{{ data?.courseName }}</h3>
                                            </a>
                                            <p class="font-roboto description-clamp">{{ data.courseDescription }}</p>
                                            <ul>
                                                <li>
                                                    <img src="assets/images/svg/icon/telephone.png"
                                                        class="img-fluid ruler-tool" alt="ruler-icon" />{{
                                                    data?.phoneNumber }}
                                                </li>
                                                <li class="border-line"></li>
                                                <li>
                                                    <img src="assets/images/svg/icon/email.png"
                                                        class="img-fluid ruler-tool" alt="ruler-icon" />
                                                    {{ data?.email }}
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="edit-card">
                                            <a href="javascript:void(0)" (click)="editCard(addTutorial,data?.id)">edit</a>
                                            <a href="javascript:void(0)"
                                                (click)="deleteCard(deleteModal,data?.id)">delete</a>
                                        </div>
                                    </div>

                                </div>
                                }
                                }
                                <div class="col-xl-4 col-sm-6">
                                    <div class="payment-card add-card">
                                        <div class="card-details" (click)="addCard(addTutorial)">
                                            <div>
                                                <i class="fas fa-plus-circle"></i>
                                                <h5>add new Tutorial</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-adly-footer></app-adly-footer>





<ng-template #addTutorial>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "Add"){
                Add New Tutorial
                }@else{
                Update Tutorial
                }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="addTutorialForm">
                <div class="row gx-3">
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Tutorial Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Tutorial Name"
                            formControlName="course_name">
                        @if(addTutorialForm.get('course_name')?.hasError('required') &&
                        addTutorialForm.get('course_name')?.touched){
                        <small class="text-danger">Name is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 ">
                        <label for="a-na">Tutorial Type<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Tutorial Type'"
                            [dataList]="TutorialTypeList" (optionSelected)="ontutorialTypeSelected($event)" [isDisabled]="true"
                            [selectedOption]="tutorialType" [clearThevalue]="tutorialType"></app-common-dropdowns>
                        @if(tutorialType == "" && tutorialTypeError){
                        <small class="text-danger">Tutorial Type is Required</small>
                        }
                    </div>
                                       
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Tutorial Level<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Tutorial Level"
                            formControlName="course_level">
                        @if(addTutorialForm.get('course_level')?.hasError('required') &&
                        addTutorialForm.get('course_level')?.touched){
                        <small class="text-danger">Level is Required</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Tutorial Price<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Tutorial Price"
                            formControlName="course_price">
                        @if(addTutorialForm.get('course_price')?.hasError('required') &&
                        addTutorialForm.get('course_price')?.touched){
                        <small class="text-danger">Price is Required</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Tutorial Category<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Tutorial Category"
                            formControlName="course_category">
                        @if(addTutorialForm.get('course_category')?.hasError('required') &&
                        addTutorialForm.get('course_category')?.touched){
                        <small class="text-danger">Category is Required</small>
                        }
                    </div>    

                    <!-- <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Author Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Author Name"
                            formControlName="authorName">
                        @if(addTutorialForm.get('authorName')?.hasError('required') &&
                        addTutorialForm.get('authorName')?.touched){
                        <small class="text-danger">Name is Required</small>
                        }
                    </div>    -->
                </div>

                <div class="form-group col-12 col-sm-4">
                    <label for="a-na">Keyword<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="a-na" placeholder="Add Keyword"
                        formControlName="keyword_name" (keyup.enter)="onEnterKeyword($event)">
                    @if(keywordTags.length == 0 && addTutorialForm.get('keyword_name')?.touched) {
                    <small class="text-danger">Add at least one Keyword</small>
                    }
                    @for(keyword_tag of keywordTags; track keyword_tag) {
                    <div class="tag" (click)="removeKeywordTags(keyword_tag)">
                        {{ keyword_tag.keywordTitle }}
                        <span class="close-icon">&times;</span>
                    </div>
                    }
                </div>
                <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Tutorial Description<span class="text-danger">*</span></label>
                        <textarea rows="4" name="" id="" class="form-control" placeholder="Enter Description"
                            formControlName="course_description" (input)="updateCharCount()"></textarea>
                        <small class="">{{ charCount }}/400</small>
                        @if(addTutorialForm.get('course_description')?.hasError('required') &&
                        addTutorialForm.get('course_description')?.touched){
                        <small class="text-danger">Description is Required</small>
                        }
                    </div>
                </div>             
                <!-- <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Tutorial Content<span class="text-danger">*</span></label>
                        <quill-editor class="form-control" [(ngModel)]="content"
                         [modules]="editorModules" [placeholder]="'Type your tutorial content here...'"
                         formControlName="course_content" (input)="updateCharCount()"></quill-editor>
                        <small class="">{{ charCount }}/400</small>
                        @if(addTutorialForm.get('course_content')?.hasError('required') &&
                        addTutorialForm.get('course_content')?.touched){
                        <small class="text-danger">Content is Required</small>
                        }
                    </div>
                </div>               -->
                  
                
            </form>
            <div class="dropzone-admin mb-0">
                <label>Media <span class="font-danger">*</span></label>

                <!-- for add case  -->
                @if(FormName == "Add"){
                <form class="dropzone" id="multiFileUpload">
                    <ngx-dropzone (change)="onSelect($event)" accept="image/jpeg,image/jpg,image/png"
                        [multiple]="false">
                        <ngx-dropzone-label>
                            <div class="dz-message needsclick">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <h6 class="f-w-700 mb-0">
                                    Drop files here or click to upload.
                                </h6>
                            </div>
                        </ngx-dropzone-label>
                        @for(f of files; track f){
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true"
                            (removed)="onRemove(f)" [file]="f">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

                        </ngx-dropzone-image-preview>
                        }
                    </ngx-dropzone>
                </form>
                } @else if (FormName == "Edit"){
                <!-- for update case  -->
                <div class="image-gallery">
                    <div *ngFor="let image of images" class="image-container">
                        <app-feather-icons [icon]="'x'" class="closeImageIcon"
                            (click)="removeFile(image)"></app-feather-icons>
                        <img [src]="image.imageBase64" alt="image">
                    </div>
                </div>
                <div class="image-gallery">
                    <div class="upload-container" (click)="fileInput.click()">
                        <app-feather-icons [icon]="'upload'"></app-feather-icons>
                        <input #fileInput type="file" style="display: none;" accept="image/jpeg,image/jpg,image/png"
                            (change)="onFileSelected($event)">
                        Upload more Files
                    </div>
                </div>
                }




            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="onSubmit()">
                @if(FormName == "Add"){
                Add Tutorial
                }@else{
                Update Tutorial
                }
            </button>
        </div>
    </div>

</ng-template>


<ng-template #deleteModal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text">
                Are You Sure you want to delete this Tutorial ?
            </div>
            <div class="deleteButtons">
                <button type="button" class="btn btn-dashed color-2 btn-pill"
                    (click)="modal.dismissAll()">Cancel</button>
                <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteTutorial()">
                    Delete
                </button>
            </div>
        </div>
    </div>
</ng-template>