import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CvServiceService } from 'src/app/shared/services/cv-service.service';
import { agencyAgent } from 'src/app/shared/interface/property';

@Component({
  selector: 'app-cv-edit',
  templateUrl: './cv-edit.component.html',
  styleUrls: ['./cv-edit.component.scss']
})
export class CvEditComponent implements OnInit {
  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Dashboard';
  public parent = 'Cv';
  public child = 'Add Cv';
  gridImages: boolean = false;
  listView: boolean = false;
  public agencyData: agencyAgent[];

  public cvId: number;
  public userData: any = {};
  public isLoading: boolean = true; 

  constructor(
    private route: ActivatedRoute,
    private cvService: CvServiceService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.cvId = +params['templateId'];
    });

    this.fetchUserData();
  }

  // Fetch user data from the CvServiceService
  fetchUserData(): void {
    this.isLoading = true; 
    this.cvService.getAllCvDetails().subscribe({
      next: (response) => {
        this.userData = {
          personalDetails: response.personalDetails,
          languageDetails: response.languageDetails,
          experienceDetails: response.experienceDetails,
          educationDetails: response.educationDetails,
          certificateDetails: response.certificateDetails,
          socialLinksDetails: response.socialLinksDetails,
          awardsDetails: response.awardsDetails,
          subjectDetails: response.subjectDetails,
          skillsDetails: response.skillsDetails
        };
        this.isLoading = false; // Stop loader once data is loaded
        console.log("CV Data", this.userData);
      },
      error: (error) => {
        console.error('Error fetching user data', error);
        this.isLoading = false; // Stop loader in case of error
      }
    });
  }
}
