import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditUserDetailsModalComponent } from '../../../../../shared/components/common/modal/edit-user-details-modal/edit-user-details-modal.component';
import { EditUserEmailModalComponent } from '../../../../../shared/components/common/modal/edit-user-email-modal/edit-user-email-modal.component';
import { EditUserPasswordModalComponent } from '../../../../../shared/components/common/modal/edit-user-password-modal/edit-user-password-modal.component';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailsComponent {

  

  private readonly _userDetailsService = inject(CommonUtilsServiceService);
  private subscription: Subscription;
  private readonly _apiCall = inject(ApiCallService);

  public userDetails: any; 
  public email: string = "";

  ngOnInit(): void {
    this.getLogedInUserDetails();

    this.subscription = this._userDetailsService.userDetailsUpdated$.subscribe(
      (updated) => {
        console.log("User details updated event received:", updated);
        if (updated) {
          this.getLogedInUserDetails();
        }
      }
    );
  }

  constructor(private modal: NgbModal) {}

  editDetails() {
    if (!this.userDetails) {
      this.getLogedInUserDetails();
    }
  
    const modalRef = this.modal.open(EditUserDetailsModalComponent, { size: 'lg', centered: true });
    modalRef.componentInstance.userDetails = this.userDetails;
  }
  
  editEmail() {
    this.modal.open(EditUserEmailModalComponent, {
      centered: true,
    });
  }

  editPassword() {
    this.modal.open(EditUserPasswordModalComponent, {
      centered: true,
    });
  }

  getLogedInUserDetails(){
    const storedUserDetails = localStorage.getItem("userDetails");

    if (storedUserDetails) {
      console.log("User details found in localStorage");
      this.userDetails = JSON.parse(storedUserDetails);
      this.email= this.userDetails?.email;
    } else {
      console.log("No user details found in localStorage");
      this.userDetails = null;
    }
  }


  // getLogedInUserDetails() {
  //   this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetPersonalDetails")
  //     .subscribe(
  //       (response: any) => {
  //         this.userDetails = response.data;
  //         // console.log("User personal details:",this.userDetails)
  //       },
  //       (error: any) => {
  //         // console.error('Error fetching user details', error);
  //       }
  //     );
  // }
}
