@if(homeSectionSliderData.length){
<div class="carousel-container">
  <div class="banner-3">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div>
            <h1>Your Path to</h1>
            <p>{{ type() }}</p>
          </div>

          <app-looking-for-icons [lookingForData]="lookingForData" class="looking-icons"></app-looking-for-icons>
          <!-- <div class="row">
            <div class="col-12"> -->
              <div class="total-data">
                <ul>
                  <li>
                    <p>{{ totalRecords().institutes || 0 }}</p>
                    <p>Institutes</p>
                  </li>
                  <li>
                    <p>{{ totalRecords().teacher || 0 }}</p>
                    <p>Teachers</p>
                  </li>
                  <li>
                    <p>{{ totalRecords().admissions || 0 }}</p>
                    <p>Admissions</p>
                  </li>
                  <li>
                    <p>{{ totalRecords().scholarships || 0 }}</p>
                    <p>Scholarships</p>
                  </li>
                  <li>
                    <p>{{ totalRecords().jobs || 0 }}</p>
                    <p>Jobs</p>
                  </li>
                  <li>
                    <p>{{ totalRecords().books || 0 }}</p>
                    <p>Books</p>
                  </li>
                  <li>
                    <p>{{ totalRecords().hostels || 0 }}</p>
                    <p>Hostels</p>
                  </li>
                  <li>
                    <p>{{ totalRecords().books || 0 }}</p>
                    <p>Tutorials</p>
                  </li>

                </ul>
              </div>
            <!-- </div>
          </div> -->

        </div>
      </div>
    </div>


  </div>
  <owl-carousel-o class="bannerOverview" [options]="Options">
    @for(sliderData of homeSectionSliderData; track sliderData){
    <ng-template carouselSlide>
      <div>
        <div class="bg-layout-3"
          [ngStyle]="{'background-image': 'url(' + sliderData.img + ')' , 'width' : '100%' , 'height' : '105vh'}"
          lazy-load>
          <div class="overlay"></div>
        </div>
      </div>

      <!-- <div class="bg-layout-3" [ngStyle]="{'width': '100%', 'height': '105vh'}">
        <img [ngSrc]="sliderData.images?.default || 'path/to/default-image.webp'" 
             [attr.srcset]="generateSrcSet(sliderData?.images)" 
             sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw" 
             alt="background image" />
        <div class="overlay"></div>
      </div> -->

      <!-- <div>

        <div class="bg-layout-3" style="width: 100%; height: 105vh; position: relative;">
          <picture>
            <source media="(max-width: 200px)" [srcset]="sliderData.images?.w_20">
            <source media="(max-width: 547px)" [srcset]="sliderData.images?.w_547">
            <source media="(max-width: 777px)" [srcset]="sliderData.images?.w_777">
            <source media="(max-width: 979px)" [srcset]="sliderData.images?.w_979">
            <source media="(max-width: 1187px)" [srcset]="sliderData.images?.w_1187">
            <source media="(max-width: 1360px)" [srcset]="sliderData.images?.w_1360">
            <source media="(min-width: 1400px)" [srcset]="sliderData.images?.w_1400">
            <img [src]="sliderData.images?.default" alt="{{ sliderData.title }}"
              style="width: 100%; height: 100%; object-fit: cover;">
          </picture>
         
          <div class="overlay"></div>
        </div>

      </div> -->
    </ng-template>
    }
  </owl-carousel-o>
</div>
}