import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-request-exploration-form',
  templateUrl: './request-exploration-form.component.html',
  styleUrls: ['./request-exploration-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestExplorationFormComponent {

}
