import { Component } from '@angular/core';

@Component({
  selector: 'app-about-us1-details',
  templateUrl: './about-us1-details.component.html',
  styleUrls: ['./about-us1-details.component.scss']
})
export class AboutUs1DetailsComponent {

}
