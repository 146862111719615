<!-- Admission Lylo Loader  -->
@if(isLoading){
<div class="loader-wrapper">
    <div class="row loader-text">
        <div class="col-12">
            <img src="../../../../../../assets/images/icon/loader.gif" class="img-fluid" alt="loader" />
        </div>
        <div class="col-12">
            <div>
                <h3 class="mb-0">Admissions Lylo Loading...</h3>
            </div>
        </div>
    </div>
</div>
}
<!-- Admission Lylo Loader -->
<div class="container border-radius" id="container" [ngClass]="{'active': isActive}"
    [formGroup]="formName === 'Sign-up' ? sign_up_form : sign_in_form">
    <!-- <div class="company-logo"> -->
    <!-- <img src="assets/logos/Admission lylo.svg" alt=""> -->
    <!-- <img src="../../../../assets/images/icon/loginLogo.jpeg" alt=""> -->
    <!-- <img src="../../../../assets/images/icon/newlogos/white-logo.png" alt="">
    </div> -->
    <div class="company-logo">
        @if(!isScreenSmall){
        @if(formName === 'Sign-up'){
        <img src="../../../../assets/images/icon/newlogos/white-logo.png" alt="Sign Up Logo" loading="lazy" />
        }
        @else{
        <img src="../../../../assets/images/icon/newlogos/logo.png" alt="Sign Up Logo" loading="lazy" />
        }
        }@else{
        <img src="../../../../assets/images/icon/newlogos/logo.png" alt="Sign Up Logo" loading="lazy" />
        }
        <div class="cross-icon" [ngClass]="{'black-color': formName == 'Sign-up','whiteColor' : formName != 'Sign-up'}" >
            <i class="fas fa-times" (click)="closeModal()"></i>
        </div>
    </div>
    
    <!-- <div class="company-logo">
        <img [src]="formName === 'Sign-up' 
            ? '../../../../assets/images/icon/loginLogo.jpeg' 
            : '../../../../assets/images/icon/newlogos/white-logo.png'" alt="Company Logo" class="img-fluid" />
    </div> -->

    <div class="form-container sign-up">
        <form [formGroup]="sign_up_form" class="sign-up-form">
            <h2>Create Account</h2>

            <div class="or-log-in">
                <div class="icons">
                    <div class="ico">
                        <img src="../../../../assets/images//svg/socialIcons/google.png" alt="Google logo">
                    </div>
                    <div class="ico">
                        <img src="../../../../assets/images//svg/socialIcons/linkdin.svg" alt="Linkedin logo">
                    </div>

                </div>
                <p>or use your email for registration</p>
            </div>
            <div class="inputFields row-fields">
                <div class="input col-6">
                    <input class="input-type" type="text" placeholder="First Name" formControlName="first_name">
                    @if(sign_up_form.get('first_name')?.hasError('required') &&
                    sign_up_form.get('first_name')?.touched){
                    <div class="error-message">First Name is Required</div>
                    }
                </div>
                <div class="input col-6">
                    <input class="input-type" type="text" placeholder="Last Name" formControlName="last_name">
                    @if(sign_up_form.get('last_name')?.hasError('required') &&
                    sign_up_form.get('last_name')?.touched){
                    <div class="error-message">Last Name is Required</div>
                    }
                </div>
            </div>
            <div class="inputFields row-fields">
                <div class="input col-6">
                    <input class="input-type" type="text" placeholder="Username" formControlName="user_name">
                    @if(sign_up_form.get('user_name')?.hasError('required') &&
                    sign_up_form.get('user_name')?.touched){
                    <div class="error-message">User Name is Required</div>
                    }
                </div>
                <div class="input col-6">
                    <input class="input-type" type="text" placeholder="Contact" formControlName="contact" min="0"
                        minlength="10" maxlength="18">
                    <div *ngIf="sign_up_form.get('contact')?.hasError('required') && sign_up_form.get('contact')?.touched"
                        class="error-message">
                        Contact is Required
                    </div>

                    <div *ngIf="sign_up_form.get('contact')?.hasError('pattern') && sign_up_form.get('contact')?.touched"
                        class="error-message">
                        Invalid Contact Number
                    </div>

                    <!-- <div *ngIf="sign_up_form.get('contact')?.hasError('minlength') && sign_up_form.get('contact')?.touched"
                        class="error-message">
                        Contact Number should be at least 10 digits
                    </div>

                    <div *ngIf="sign_up_form.get('contact')?.hasError('maxlength') && sign_up_form.get('contact')?.touched"
                        class="error-message">
                        Contact Number should not exceed 18 digits
                    </div> -->
                </div>
            </div>
            <div class="inputFields row-fields single-row-fields">
                <div class="input">
                    <input class="input-type" type="email" placeholder="Email" formControlName="sign_up_email">
                    @if(sign_up_form.get('sign_up_email')?.hasError('required') &&
                    sign_up_form.get('sign_up_email')?.touched){
                    <div class="error-message">Email is Required</div>
                    }
                    @if(sign_up_form.get('sign_up_email')?.hasError('pattern') &&
                    sign_up_form.get('sign_up_email')?.touched){
                    <div class="error-message">Invalid Email Format</div>
                    }
                </div>
            </div>
            <div class="inputFields row-fields">
                <div class="input col-6">
                    <div>
                        <i class="fa" [ngClass]="signUpPassword === 'password' ? 'fa-eye-slash' : 'fa-eye'"
                            (click)="showAndHidePassword('signUpPassword')"></i>
                    </div>
                    <input class="input-type" type="text" placeholder="Password" formControlName="sign_up_password"
                        [type]="signUpPassword" #pwd>
                    @if(sign_up_form.get('sign_up_password')?.hasError('required') &&
                    sign_up_form.get('sign_up_password')?.touched){
                    <div class="error-message">Password is Required</div>
                    }
                </div>
                <div class="input col-6">
                    <i class="fa" [ngClass]="signUpConfirmPassword === 'password' ? 'fa-eye-slash' : 'fa-eye'"
                        (click)="showAndHidePassword('signUpConfirmPassword')"></i>
                    <input class="input-type" placeholder="Confirm Password" [type]="signUpConfirmPassword"
                        formControlName="confirm_password" #cpsw (keyup)='checkPasswords(pwd.value, cpsw.value)'>
                    @if(sign_up_form.get('confirm_password')?.hasError('required') &&
                    sign_up_form.get('confirm_password')?.touched){
                    <div class="error-message">Confirm Password is Required</div>
                    }
                    <div class="inValidPassWord" *ngIf='!passwordsMatching && cpsw.value && isConfirmPasswordDirty'>
                        Passwords did not match
                    </div>
                </div>

            </div>
            <div class="password-error-message">
                @if(!(passMinlength && passUpperCase && passLowerCase && passNumber &&
                passSpecialChar) && sign_up_form.get("sign_up_password")?.value?.length > 0){
                <div class="passwordErrorMessages">
                    <div class="message" [ngClass]="passMinlength? 'validPassword' : 'inValidPassWord' ">
                        <i [ngClass]="passMinlength? 'fa fa-check-circle' : 'fa fa-times-circle' "></i>
                        At least 8 characters long.
                    </div>

                    <div class="message" [ngClass]="passUpperCase? 'validPassword' : 'inValidPassWord' ">
                        <i [ngClass]="passUpperCase? 'fa fa-check-circle' : 'fa fa-times-circle' "></i>
                        At least one uppercase letter.
                    </div>
                    <div class="message" [ngClass]="passLowerCase? 'validPassword' : 'inValidPassWord' ">
                        <i [ngClass]="passLowerCase? 'fa fa-check-circle' : 'fa fa-times-circle' "></i>
                        At least one lowercase letter.
                    </div>
                    <div class="message" [ngClass]="passNumber? 'validPassword' : 'inValidPassWord' ">
                        <i [ngClass]="passNumber? 'fa fa-check-circle' : 'fa fa-times-circle' "></i>
                        At least one number
                    </div>
                    <div class="message" [ngClass]="passSpecialChar? 'validPassword' : 'inValidPassWord' ">
                        <i [ngClass]="passSpecialChar? 'fa fa-check-circle' : 'fa fa-times-circle' "></i>
                        At least one special character (&#64;,%,$,#, etc.)
                    </div>
                </div>
                }
            </div>


            <div class="button">
                <div class="col-lg-12 py-4">
                    <div class="text-center">
                        <a class="btn btn-gradient color-2 btn-pill" aria-label="Sign Up" (click)="signUpUser()">Sign Up
                        </a>
                    </div>
                    <div class="SmallNavigationScrrens justify-content-center">
                        <div>Already have Account?<a (click)="deactivate()">Sign In</a></div>
                    </div>
                </div>
            </div>
        </form>



    </div>
    <div class="form-container sign-in">

        @if(!showForgetandSignIn){
        <form [formGroup]="sign_in_form" class="sign-in-form">
            <h2>Sign in to Admission Lylo</h2>

            <div class="or-log-in">
                <div class="icons">
                    <div class="ico">
                        <img src="../../../../assets/images//svg/socialIcons/google.png" alt="Google logo">
                    </div>
                    <div class="ico">
                        <img src="../../../../assets/images//svg/socialIcons/linkdin.svg" alt="Linkedin logo">
                    </div>

                </div>
                <p>or use your email account</p>
            </div>

            <div class="inputFields">
                <div class="input">
                    <input class="input-type" type="text" placeholder="Username or Email"
                        formControlName="sign_in_email">
                    @if(sign_in_form.get('sign_in_email')?.hasError('required') &&
                    sign_in_form.get('sign_in_email')?.touched){
                    <div class="error-message">Email or Username is Required</div>
                    }
                </div>
                <div class="input">
                    <i class="fa" [ngClass]="inputType === 'password' ? 'fa-eye-slash' : 'fa-eye'"
                        (click)="showAndHidePassword('password')"></i>
                    <input class="input-type" type="password" placeholder="Password" formControlName="sign_in_password"
                        [type]="inputType">
                    @if(sign_in_form.get('sign_in_password')?.hasError('required') &&
                    sign_in_form.get('sign_in_password')?.touched){
                    <div class="error-message">Password is Required</div>
                    }
                </div>
                <div class="remember-me">
                    <label>
                        <input class="checkbox_animated color-4" type="checkbox" formControlName="rememberMe"><span> Remember Me</span>
                    </label>
                </div>
            </div>

            <div class="forgotPassword forgotScreen">
                <a aria-label="Forgot Password" (click)="chnageForgetForms()">Forgot Password ?</a>
            </div>
            <div class="button">
                <div class="col-lg-12 py-4">
                    <div class="text-center">
                        <a class="btn btn-gradient color-2 btn-pill" aria-label="clickable-text"
                            (click)="signInUser()">Sign In
                        </a>
                    </div>
                    <div class="SmallNavigationScrrens justify-content-center">
                        <div>Don't have an account?<a (click)="activate()">Sign Up</a></div>
                    </div>
                </div>

            </div>
        </form>
        }@else{
        <form [formGroup]="forget_form" class="sign-in-form">
            <h2>Forget Password</h2>
            <div class="or-log-in">
                <p class="text-center">Enter your email address we will send you instructions to reset your password</p>
            </div>

            <div class="inputFields">
                <div class="input">
                    <input class="input-type" type="text" placeholder="Enter your Email" formControlName="forget_email">
                    @if(forget_form.get('forget_email')?.hasError('required') &&
                    forget_form.get('forget_email')?.touched){
                    <div class="error-message">Email is Required</div>
                    }
                    @if(forget_form.get('forget_email')?.hasError('pattern') &&
                    forget_form.get('forget_email')?.touched){
                    <div class="error-message">Invalid Email Format</div>
                    }
                </div>
            </div>

            <div class="forgotPassword" (click)="chnageForgetForms()">
                Already have an acoount?<a>Sign in</a>
            </div>
            <div class="button">
                <div class="col-lg-12 py-4">
                    <div class="text-center">
                        <a class="btn btn-gradient color-2 btn-pill" aria-label="clickable-text"
                            (click)="forgetPassword()">Continue
                        </a>
                    </div>
                </div>
            </div>
        </form>
        }


    </div>
    <div class="toggle-container">
        <div class="toggle">
            <div class="toggle-panel toggle-left">
                <div class="left-panel-text">
                    <h2>Welcome Back!</h2>
                    <p>To keep connected with us please login with your personal info</p>
                </div>
                <div class="col-lg-6 py-4">
                    <div class="text-center">
                        <a class="btn btn-gradient color-2 btn-pill" (click)="deactivate()">Sign In
                        </a>
                    </div>
                </div>

            </div>
            <div class="toggle-panel toggle-right">

                <div class="right-panel-text">
                    <h2>Hello, Friends</h2>
                    <p>Enter your Personal Details and Start journey with us</p>
                </div>
                <div class="col-lg-6 py-4">
                    <div class="text-center">
                        <a class="btn btn-gradient color-2 btn-pill" aria-label="clickable-text"
                            (click)="activate()">Sign Up
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>