import { ChangeDetectionStrategy, Component, HostListener, inject, Input, OnInit } from '@angular/core';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-latest-jobs',
  templateUrl: './latest-jobs.component.html',
  styleUrl: './latest-jobs.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class LatestJobsComponent implements OnInit {


  public tag = "Jobs";
  @Input() tagClass: string = "";
  public title = 'slider_filter_search';
  private _utils = inject(CommonUtilsServiceService);
  private _apicall = inject(ApiCallService);
  private readonly errorHandlerService = inject(ErrorHandlingService);

  isDescriptionShown: boolean = true;
  public noRecordFound = false;
  public isDataLoading = true;
  isErrorOccurred: boolean = false;
  addSectionCss: any = {
    height: '100%',
    width: '100%',
  };
  isAdLoading: boolean = true;
  jobsData: any[] = [];
  constructor(public propertyService: PropertyService) { }

  ngOnInit(): void {
    this.getLatestJobs();
    this.checkScreenSize();
    // this.getAds();
    setInterval(() => {
      this.isAdLoading = false;
    }, 5000)
  }


  isScreenSmall: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    const width = window.innerWidth;
    this.isScreenSmall = width < 1200;
    if (width > 1200) {
      this.addSectionCss.height = '100%';
    } else {
      this.addSectionCss.height = '280px';
    }
  }

  getLatestJobs(): void {
    this.isDataLoading = true;
    this.isErrorOccurred = false;
    this.jobsData = []; // Clear the existing data

    this.propertyService.getLatestJobs().subscribe(
      (response) => {
        this.jobsData = response;
        this.noRecordFound = !this.jobsData || this.jobsData.length === 0;
        this.isDataLoading = false;

        // Convert each job's image URL to a Blob URL
        this.jobsData.forEach((job) => {
          if (job && job.img && job.img.url && job.img.url.includes('base64,')) {
            const base64Data = job.img.url.split('base64,')[1]; // Extract base64 data
            job.blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            // console.log("Job Blob URL:", job.blobUrl);
          } else {
            job.blobUrl = 'path/to/default/image.png';
            console.warn("Image data is not in the expected base64 format:", job.img ? job.img.url : "No img property");
          }
        });
      },
      (error) => {
        this.isErrorOccurred = true;
        this.isDataLoading = false;
        console.error("Error fetching latest jobs:", error);
      }
    );
  }


  getAds(): void {
    this.propertyService
      .getAds(null, 'Ad/GetAdsAdmissionLelo?moduleName=job')
      .subscribe(
        (res) => {
          if (res.responseCode === 200) {
            this.adImages = res.data;

            this.adImages.forEach((ad) => {
              if (ad.imageUrl && ad.imageUrl.includes('base64,')) {
                const base64Data = ad.imageUrl.split('base64,')[1];
                ad.imageUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
                console.log("Ad Blob URL:", ad.imageUrl);
              }
            });


            this.isAdLoading = false;
          } else {
            this.adImages = [];
            this.isAdLoading = false;
            this.errorHandlerService.handleResponseError(res);
          }
        },
        (error) => {
          this.errorHandlerService.handleHttpError(error);
          this.isAdLoading = false;
          this.adImages = [];
        }
      );
  }






  adImages: any[] = [
    {
      redirectLink: 'https://youtube.com',
      imageUrl: '../../../../../assets/images/advertisements/jobs/1.jpeg'
    },
    {
      redirectLink: 'https://youtube.com',
      imageUrl: '../../../../../assets/images/advertisements/jobs/2.jpeg'
    },
    {
      redirectLink: 'https://youtube.com',
      imageUrl: '../../../../../assets/images/advertisements/jobs/3.jpeg'
    },
    {
      redirectLink: 'https://youtube.com',
      imageUrl: '../../../../../assets/images/advertisements/jobs/4.jpeg'
    },
    {
      redirectLink: 'https://youtube.com',
      imageUrl: '../../../../../assets/images/advertisements/jobs/5.jpeg'
    },
  ];

  pauseScroll() {
    const adsSection = document.querySelector('.ad-images-wrapper') as HTMLElement;
    if (adsSection) {
      adsSection.style.animationPlayState = 'paused';
    }
  }

  startScroll() {
    const adsSection = document.querySelector('.ad-images-wrapper') as HTMLElement;
    if (adsSection) {
      adsSection.style.animationPlayState = 'running';
    }
  }





}
