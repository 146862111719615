import { Component, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { review } from '../../../../shared/interface/property';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent {
//inputs
  @Input() reviewData: review[];


  //services
  private _apiService = inject(ApiCallService);
  private _authService = inject(AuthService);
  private _modal = inject(DataShareService);
  private _toaster = inject(ToastrService);


  //varaibles
  instituteReview = [];
  instituteId: any;

  reviewForm: FormGroup;
  name: any;
  email: any;
  firstName: any;
  lastName: any;
  userImage: any;
  fullName: any;
  selectedRating: number = 0;


  date = new Date();
  isoString = this.date.toISOString();

  constructor(public config: NgbRatingConfig, private fb: FormBuilder, private route: ActivatedRoute) {
    config.max = 5;
    config.readonly = true;
    const token = this._authService.getToken();

    if (token) {
      this.name = localStorage.getItem("username");
      this.email = localStorage.getItem("Email");
      this.userImage = localStorage.getItem("profilePicture");
      this.firstName = localStorage.getItem("firstName");
      this.lastName = localStorage.getItem("lastName");
      this.fullName = this.firstName + " " + this.lastName;
    } else {
      this.fullName = "Your Name";
      this.email = "youremail@gmail.com"
    }
    this.instituteId = this.route.snapshot.queryParamMap.get('instituteid');

    this.reviewForm = this.fb.group({
      name: [this.fullName, [Validators.required]],
      email: [this.email, [Validators.required]],
      review: ['', [Validators.required]],
    });


  }

  ngOnInit(): void {
    this.getReviews();
  }





  reviews: any = [
    //   {
    //     name: "Ali Khan",
    //     comment: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore atque quibusdam iure ipsam ut id deserunt quis, repellat officiis nobis hic laborum mollitia similique deleniti ab quae repellendus veritatis voluptatibus?",
    //     date: this.isoString,
    //     numberOfStars: 5,
    //     showFullComment: false
    //   },
    //   {
    //     name: "Ali Khan",
    //     comment: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore atque quibusdam iure ipsam ut id deserunt quis, repellat officiis nobis hic laborum mollitia similique deleniti ab quae repellendus veritatis voluptatibus?",
    //     date: this.isoString,
    //     numberOfStars: 3,
    //     showFullComment: false
    //   },
    //   {
    //     name: "Ali Khan",
    //     comment: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore atque quibusdam iure ipsam ut id deserunt quis, repellat officiis nobis hic laborum mollitia similique deleniti ab quae repellendus veritatis voluptatibus?",
    //     date: this.isoString,
    //     numberOfStars: 2,
    //     showFullComment: false
    //   },
    //   {
    //     name: "Ali Khan",
    //     comment: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore atque quibusdam iure ipsam ut id deserunt quis, repellat officiis nobis hic laborum mollitia similique deleniti ab quae repellendus veritatis voluptatibus?",
    //     date: this.isoString,
    //     numberOfStars: 2,
    //     showFullComment: false
    //   }
  ]


  onRatingChange(rating: number): void {
    this.selectedRating = rating;
  }


  addReview() {

  }
  SubmitReview(content: TemplateRef<any>) {
    const payload: any = {
      instituteId: this.instituteId,
      name: this.fullName,
      comment: this.reviewForm.get('review')?.value,
      ratingDate: new Date(),
      numberOfStars: this.selectedRating,
      userEmail: this.email
    }

    const reviewVal = this.reviewForm.get('review')?.value;
    const token = this._authService.getToken();
    if (!token) {
      this._toaster.error("Please login first");
      this._modal.openSignInModal(content);
    }
    else if (!reviewVal) {
      this._toaster.error("Please write a review");
    }
    else if (this.selectedRating === 0) {
      this._toaster.error("Please select a rating stars");
    }
    else{
      this.selectedRating = 0;
      const scrollableDiv = document.getElementById('scrollableDiv') as HTMLDivElement;
      scrollableDiv.scrollTop = 0;
      if (token) {
        this._apiService.PostCallWithToken(payload, "Institute/AddRatings").subscribe((res) => {
          if (res.responseCode == 200) {
            this._toaster.success(res.responseMessage);
            this.getReviews();
          } else {
            this._toaster.error(res.responseMessage);
          }
        })
      }
    }
  }

  closePopUp() {
    this._modal.closePopUp();
    this.firstName = localStorage.getItem("firstName");
    this.lastName = localStorage.getItem("lastName");
    this.fullName = this.firstName + " " + this.lastName;
    this.email = localStorage.getItem("Email");
  }

  formatDate(date: Date): string {
    return date.toISOString().substring(0, 10);
  }


  toggleComment(review: any, event: Event): void {
    event.preventDefault();
    review.showFullComment = !review.showFullComment;
  }

  trackReview(index: number, review: any): any {
    return review.name;
  }



  getReviews() {
    
    const payload ={
      instituteId: this.instituteId
    }
    this._apiService.PostCallWithoutToken(payload, "Institute/GetRatingsByInstituteId").subscribe((res) => {
      if (res.responseCode == 200) {
        this.reviews = res.data;
      }else{
        this._toaster.error(res.responseMessage);
      }
    })
  }


}
