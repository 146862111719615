import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-service-cards',
  templateUrl: './service-cards.component.html',
  styleUrl: './service-cards.component.scss'
})
export class ServiceCardsComponent {
  @Input() cardsData: any[] = [];

  educationList = [
    {
      degreeTitle: 'Bachelor of Science in Computer Science',
      institute: 'XYZ University',
      startDate: '2020-01-01',
      endDate: '2024-01-01',
      cgpa: 3.8,
      total: 120,
      obtained: 115,
    },
  ]
  
}
