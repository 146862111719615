<section class="small-section bg-light brand-wrap">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="slide-2 brand-slider">
          <app-brand [brandData]="brandData" [type]="'basic'"></app-brand>
        </div>
      </div>
    </div>
  </div>
</section>
