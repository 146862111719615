import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';


@Component({
  selector: 'app-add-tutorial',
  templateUrl: './add-tutorial.component.html',
  styleUrl: './add-tutorial.component.scss'
})
export class AddTutorialComponent implements OnInit, OnDestroy{
  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Dashboard';
  public parent = 'Tutorials';
  public child = 'Add Tutorial'

  addTutorialForm: FormGroup;
  html! : string;
  tutorialData: any;
  gridImages: boolean = false;
  listView: boolean = false;
  thumbnail: boolean = false;
  isDataLoading: boolean = true;
  thumbnail_video: boolean = false;
  public isSpinnerShown = false;
  tagClass: string;
  total: number;
  public selectedImage: string;  
  public FormName: string = "Add";

  public readonly __apiCall = inject(ApiCallService);
  public readonly _toastr = inject(ToastrService);
  public readonly propertyService = inject(PropertyService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  public readonly _spinner = inject(NgxSpinnerService)

  public theme_default3 = '#ff5c41';
  public theme_default4 = '#ff8c41';

  content: string = '';
  editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], 
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
    ],
  };  

  constructor(public modal: NgbModal, private fb: FormBuilder, private cdr: ChangeDetectorRef){
    this.addTutorialForm = this.fb.group({
      course_name :["", Validators.required],
      course_type :["", Validators.required],
      course_level :["", Validators.required],
      course_description :["", Validators.required, Validators.minLength(400)],
      course_price :["", Validators.required],
      course_category :["", Validators.required],
      keyword_name :["", Validators.required],
      course_content: ["",Validators.required,Validators.minLength(400) ],
    })
  }

  

  ngOnInit() {
    document.documentElement.style.setProperty('--theme-default', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default3', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default4', this.theme_default4);

    
    

    this.getTutorial();    

  }

  getTutorial(){
    this.isDataLoading = true;
    this.__apiCall.GetCallWithToken("Dashboard/GetCourses").subscribe((response) => {
      if (response.responseCode == 200) {
        this.tutorialData = response.data;
        this.isDataLoading = false;
      } else {
        this.tutorialData = [];
        this.isDataLoading = false;
      }
    }, (error) => {
      this._toastr.error('Error fetching Tutorials data', error);
      this.isDataLoading = false;
    }
    )
  }

  ngOnDestroy(): void {
    document.documentElement.style.removeProperty('--theme-default');
    document.documentElement.style.removeProperty('--theme-default3');
    document.documentElement.style.removeProperty('--theme-default4');
    
  }

  chehckedFeatures: { featureName: string }[] = [];



  charCount: number = 0;
  updateCharCount(): void {
    let description = this.addTutorialForm.get("course_description")?.value;
    this.charCount = description?.length || 0;
  }

  updateCheckedValues(feature: any) {
    feature.checked = !feature.checked;
  }

  addCard(content : TemplateRef<any>){
    this.FormName = "Add";
    this.addTutorialForm.reset();
    this.updateTutorialId = 0;    
    this.keywordTags = [];
    //this.authorName = "";
    this.tutorialType = "";
    this.modal.open(content, { centered: true, size: 'lg' })
  }

  editCard(content : TemplateRef<any>, id : number){
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.addTutorialForm.reset();
    //this.authorName = "";
    this.tutorialType = "";
    this.keywordTags = [];
    this.editTutorial(id);
  }

  tutorialByIdData: any
  updateTutorialId: number = 0;
  editTutorial(id : number){
    this.updateTutorialId = id;
    this.isSpinnerShown = true;
    this._spinner.show();

    this.__apiCall.PostCallWithToken(null, `Dashboard/GetCourseById?CourseId=${id}`).subscribe((res) =>{
      if(res.responseCode == 200){
        this.tutorialByIdData = res.data;

        this.addTutorialForm.get('course_name')?.setValue(this.tutorialByIdData?.courseName);
        this.addTutorialForm.get('course_type')?.setValue(this.tutorialByIdData?.courseType);
        this.addTutorialForm.get('course_level')?.setValue(this.tutorialByIdData?.courseLevel);
        this.addTutorialForm.get('course_price')?.setValue(this.tutorialByIdData?.coursePrice);
        this.addTutorialForm.get('course_content')?.setValue(this.tutorialByIdData?.courseUrl);
        this.addTutorialForm.get('course_description')?.setValue(this.tutorialByIdData?.courseDescription);
        this.addTutorialForm.get('course_category')?.setValue(this.tutorialByIdData?.courseCategory);

       // this.authorName = this.tutorialByIdData?.AuthorName,
        this.keywordTags = this.tutorialByIdData?.courseKeyword,
        this.images = this.tutorialByIdData?.tutorialImages
        this.updateCharCount();

      }
      else {
        this._toastr.error(res.responseMessage);
        this.isSpinnerShown = false;
        this._spinner.hide();
      }
    })
    this.addTutorialForm.updateValueAndValidity();
  }

  delId: number;
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }

  deleteTutorial() {
    this.isSpinnerShown = true;
    this._spinner.show();
    this.__apiCall
      .PostCallWithoutToken(null, `Dashboard/DeleteCourse?id=${this.delId}`).subscribe((res) => {
        if (res.responseCode === 200) {
          this._toastr.success(res.responseMessage);
          this.getTutorial();
          this.modal.dismissAll();
          this.isSpinnerShown = false;
          this._spinner.hide();

        }
        else {
          this._toastr.error(res.responseMessage);
          this.modal.dismissAll();
          this.isSpinnerShown = false;
          this._spinner.hide();
        }
      })
  }

  tutorialTypeError: boolean = false;
  imageError: boolean = false;

  ShowError() {
    this.imageError = this.images.length < 1 ? true : false;
    this.tutorialTypeError = this.tutorialType == "" ? true : false;
  }

  public Options = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
  };

  public thumbnailCarouselOptions = {
    items: 4,
    margin: 10,
    center: true,
    loop: true,
    nav: false,
    dots: false,
  };

  changeImage(image: string) {
    this.selectedImage = image;
  }

  TutorialTypeList = [
    { id: 1, value: "Education Blog" },
    { id: 2, value: "Development Blog" },
    { id: 3, value: "Cooking Blog" },
    { id: 4, value: "Gaming Blog" },
    { id: 5, value: "Music Blog" },
  ];
  tutorialType: string = "";

  ontutorialTypeSelected(option: any) {
    this.tutorialType = option.value;
  }



  //authorName: string = "";
  //showKeyword: boolean = false;
  keywordTags: any[] = [];

  addKeywordTags(): void {
    debugger
    console.log("Form Value: ", this.addTutorialForm.value)
    const tagInput = this.addTutorialForm.get("keyword_name")?.value.trim();

    if (tagInput && !this.keywordTags.some(tag => tag.keywordTitle === tagInput)) {
      const newTag = { keywordTitle: tagInput };
      this.keywordTags.push(newTag);
      this.addTutorialForm.get("keyword_name")?.setValue("");
    }
  }

  removeKeywordTags(tag: any): void {
    this.keywordTags = this.keywordTags.filter((t) => t !== tag);
  }

  onEnterKeyword(event: Event): void {
    debugger
    event.preventDefault();

    console.log("Event triggered:", event);
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addKeywordTags();
    }
  }
  

  files: File[] = [];

  images: { imageBase64: string }[] = [];

  onSelect(event: NgxDropzoneChangeEvent) {
    const newFiles = event.addedFiles;
    this.files.push(...newFiles);


    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const sizeInKB = Math.round(file.size / 1024);
        const istoDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!istoDel) {
          const base64String = e.target.result as string;
          this.images.push({ imageBase64: base64String });
        }
      };
      reader.readAsDataURL(file);
    });

  }

  onRemove(event: File) {
    const index = this.files.indexOf(event);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.images.splice(index, 1);
    }
  }
  imageSizeError: boolean = false
  checkImgeSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.imageSizeError = true;
      this.onRemove(file)
      return true;

    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.images.push({ imageBase64: base64String });
        };
      };
    }
  }

  removeFile(file: any): void {

    this.images = this.images.filter((t: any) => t !== file);

  }

  ///////////////////////////////////////////////////////////
  // Function to add Facilities tag ///////////////////////
  //////////////////////////////////////////////////////////////


  checkTagsValidity(): boolean {

    if (this.keywordTags?.length < 1) {
      return true;
    }
    return false;
  }





  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////


  removeUmwantedControl() {

    this.addTutorialForm.removeControl('keyword_name');
    this.addTutorialForm.updateValueAndValidity();
  }
  addControls() {
    if (!this.addTutorialForm.contains('keyword_name')) {
      this.addTutorialForm.addControl('keyword_name', new FormControl("", Validators.required));
    }
    this.addTutorialForm.updateValueAndValidity();
  }


////////////////////////////////////
  // API CALL AND VALIDATIONS CHECK
  ////////////////////////////////////


  vediolink: any;
  public  onSubmit() {
    debugger;
    // const payload = this.createApiPayload();
    this.addTutorialForm.markAllAsTouched();
    const tagsValidity = this.checkTagsValidity();
    this.ShowError();
    this.removeUmwantedControl();

    if (
     // this.authorName === "" ||      
      this.images.length < 1 ||
      tagsValidity ||
      this.addTutorialForm.invalid
    ) {
      const errorMessage = this.getFirstError();
      if (errorMessage) {
        this._toastr.error(errorMessage);
        this.addControls();
        return;
      }
      this.addControls();
    }
    else {
      this.isSpinnerShown = true;
      this._spinner.show();
      try {
        debugger
        const payload = this.createApiPayload();
        this.__apiCall
          .PostCallWithToken(payload, "Dashboard/SaveCourse")
         .subscribe((response: any) => {
         this.addControls();
        if (response.responseCode == 200) {
          this.isSpinnerShown = false;
          this._spinner.hide();
          this._toastr.success(response.responseMessage);
          this.modal.dismissAll();
           this.getTutorial();
        } else {
          this.errorHandlingService.handleResponseError(response);

        }
      });
      } catch (error) {
        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
      } finally {
        this.isSpinnerShown = false;
        this._spinner.hide();
        this.addControls();
        this.cdr.detectChanges();
      }
      // });
    }

  }
  private getFirstError(): string {
    for (const key of Object.keys(this.addTutorialForm.controls)) {
      const control = this.addTutorialForm.get(key);
      if (control && control.invalid) {
        return `${this.getFriendlyFieldName(key)} is invalid.`;
      }
    }
      
    //if (this.authorName === "") return "AuthorName Type is required.";    
    if (this.images.length < 1) return "At least one image is required.";
    if (this.checkTagsValidity()) return "Tags are invalid.";
  
    const description = this.addTutorialForm.get('description')?.value;
    if ((description?.length ?? 0) < 400)
      return "Description must be at least 400 characters long."; 
    
    return "";
  }
  
  private getFriendlyFieldName(field: string): string {
    const fieldNames: { [key: string]: string } = {
      course_name: "Tutorial Name",
      course_level: "Tutorial Level",
      course_type: "Tutorial Type",
      course_price: "Tutorial Price",
      course_category: "Category",
      course_description: "Tutorial Description",
      keyword_tag: "Keyword",
      course_content: "Tutorial Content",
      tags: "Tags",
    };
    return fieldNames[field] || field;    
  }

  createApiPayload() {
    const payload: any = {
      id: this.updateTutorialId,
      courseName: this.addTutorialForm.get("course_name")?.value,
      courseType: this.addTutorialForm.get("course_type")?.value,
      tutorialType: this.tutorialType,
      courseLevel: this.addTutorialForm.get("course_level")?.value,
      price: this.addTutorialForm.get("course_price")?.value,
      courseUrl: this.addTutorialForm.get("course_content")?.value,
     // authorName: this.authorName,
      keyword: this.keywordTags,
      category: this.addTutorialForm.get("course_category")?.value,
      courseDescription: this.addTutorialForm.get("course_description")?.value,
      images: this.images,
    }
    return payload;

  }

  

}



