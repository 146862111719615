import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-admission-form',
  templateUrl: './admission-form.component.html',
  styleUrl: './admission-form.component.scss'
})
export class AdmissionFormComponent {

  addAdmissionForm: FormGroup;
  admissionData: any;
  public isSpinnerShown = false;
  private popUP$ = new Subscription;
  public __apiCall = inject(ApiCallService);
  public _toastr = inject(ToastrService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  @ViewChild('addAdmission') addAdmission!: TemplateRef<any>;

  constructor(public modal: NgbModal, private fb: FormBuilder, private popUp: DataShareService) {
    this.addAdmissionForm = this.fb.group({
      institute_name: ["", Validators.required],
      courses_name: ["", Validators.required],
      classes_name: ["", Validators.required],
      department: ["", Validators.required],
      institute_address: ["", Validators.required],
      institute_addressTitle: ["", Validators.required],
      institute_addressLink: ["", [Validators.required, Validators.pattern('https://maps.google.com/maps.+')]],
      site_link: ["", [Validators.required, Validators.pattern('https?://.+')]],

      email_address: ["", [
        Validators.required,
        Validators.email,
        Validators.pattern(
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
        ),
      ]],
      phone: ["", [Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10,18}$"),
        Validators.minLength(10),
        Validators.maxLength(18),]],
      
      application_fee: ["", Validators.required],
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
      scholarship_tag: ["", Validators.required],
      required_documents: ["", Validators.required],
      description: ["", [Validators.required, Validators.minLength(400)]],

    });


    this.popUP$ = this.popUp.currentPopup$.subscribe((popUpName) => {
      if (popUpName == 'admission') {
        if (this.addAdmission) {
          this.modal.open(this.addAdmission, { centered: true, size: 'xl' });
        } else {
          setTimeout(() => {
            if (this.addAdmission) {
              this.modal.open(this.addAdmission, { centered: true, size: 'xl' });
            }
          }, 100);
        }
        this.addAdmissionForm.reset();
        this.Country = "";
        this.State = "";
        this.InstitueType = "";
        this.City = "";
        this.TestType = "";
        this.coursestags = [];
        this.departmentTags = [];
        this.classesTags = [];
        this.scholarshipTags = [];
        this.requiredDocuments = [];
        this.showDepartment = false;
        this.showcourses = false;
        this.showClasses = false;
        this.showScholarship = false;
        this.showEntranceExam = false;
        this.showDocuments = false;
        this.instituteTypeError = false;
        this.countryTypeError = false;
        this.stateError = false;
        this.cityError = false;
        this.entranceexamError = false;
        this.imageError = false;

      }
    })


  }
  ngOnInit() {
    this.getCountry();
  }




  ngOnDestroy(): void {
    if (this.popUP$) {
      this.popUP$.unsubscribe();
    }
  }



  ///////////////////////////////////////////////////
  // End Date limiter STARTS HERE 
  minmumDate: any
  getMinDate() {
    const maxDate = this.addAdmissionForm.get('start_date')?.value;
    this.addAdmissionForm.get('end_date')?.reset();
    this.minmumDate = maxDate
  }

  getMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }

  minEndDate:any;
  updateEndDateMin(): void {
    const startDate = this.addAdmissionForm.get('start_date')?.value;
    this.minEndDate = startDate ? startDate : null;
  }

  //  End Date limiter Ends HERE 
  ///////////////////////////////////////////

  charCount: number = 0;
  updateCharCount(): void {
    let description = this.addAdmissionForm.get("description")?.value;
    this.charCount = description?.length || 0;
  }









  files: File[] = [];

  images: { imageBase64: string }[] = [];

  onSelect(event: NgxDropzoneChangeEvent) {
    const newFiles = event.addedFiles;
    this.files.push(...newFiles);


    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const sizeInKB = Math.round(file.size / 1024);
        const istoDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!istoDel) {
          const base64String = e.target.result as string;
          this.images.push({ imageBase64: base64String });
        }
      };
      reader.readAsDataURL(file);
    });

  }

  onRemove(event: File) {
    const index = this.files.indexOf(event);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.images.splice(index, 1);
    }
  }
  imageSizeError: boolean = false
  checkImgeSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.imageSizeError = true;
      this.onRemove(file)
      return true;

    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }
  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////

  InstitueTypeList = [
    { id: 1, value: "University" },
    { id: 2, value: "College" },
    { id: 3, value: "School" },
    { id: 4, value: "Academy" }
  ];

  InstitueType: string = "";
  showDepartment: boolean = false;
  showcourses: boolean = false;
  showClasses: boolean = false;
  showScholarship: boolean = false;
  showEntranceExam: boolean = false;
  showDocuments: boolean = false;


  onInstitueTypeSelected(option: any) {
    this.InstitueType = option.value;
    if (option.value == "University") {
      this.showDepartment = true;
      this.showcourses = true;
      this.showScholarship = true;
      this.showEntranceExam = true;
      this.showDocuments = true;
      this.showClasses = false;
      // this.classesValidationRequired = false;
    } else if (option.value == "College") {
      this.showClasses = true;
      this.showScholarship = true;
      this.showDocuments = true;
      this.showEntranceExam = false;
      this.showcourses = false;
      this.showDepartment = false;
      // this.departmentValidationRequired = false;
    } else if (option.value == "School") {
      this.showClasses = true;
      this.showScholarship = true;
      this.showDocuments = true;
      this.showDepartment = false;
      this.showEntranceExam = false;
      this.showcourses = false;

    }
    else if (option.value == "Academy") {
      this.showClasses = true;
      this.showDocuments = true;
      this.showcourses = false;
      this.showScholarship = false;
      this.showEntranceExam = false;
      this.showDepartment = false;

    }

  }


  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for TestType Starts Here
  //////////////////////////////////////////////

  TestType: string = "";

  EntranceExamList = [
    { id: 1, value: "MDCAT" },
    { id: 2, value: "ECAT" },
    { id: 3, value: "NET" },
    { id: 4, value: "NAT" },
    { id: 5, value: "GAT" },
    { id: 6, value: "NONE" },
  ];

  onTestTypeSelected(option: any) {
    this.TestType = option.value;

  }

  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for TestType Ends Here
  //////////////////////////////////////////////


  ////////////////////////////////////////////////////
  /////////  Country drop down  //////////
  ///////////////////////////////////////////////////// 
  CountrysearchTerm: string = "";
  Country: string = "";
  State: string = '';
  City: string = '';
  CountryList = []
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetCountryDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      })

  }
  instituteTypeError: boolean = false;
  countryTypeError: boolean = false;
  stateError: boolean = false;
  cityError: boolean = false;
  entranceexamError: boolean = false;
  imageError: boolean = false;

  ShowError() {
    this.instituteTypeError = this.InstitueType == "" ? true : false;
    this.countryTypeError = this.Country == "" ? true : false;
    this.stateError = this.State == "" ? true : false;
    this.cityError = this.City == "" ? true : false;
    this.entranceexamError = this.TestType == "" ? true : false;
    this.imageError = this.images.length < 1 ? true : false
  }

  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = '';
      this.City = '';
    }
    this.Country = option.value;
    this.getStateList(option.id);

  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue
    if (this.Country === '') {
      this.State = '';
      this.City = ''
    }
  }

  ////////////////////////////////////////////////////
  /////////  State drop down data //////////
  ////////////////////////////////////////////////////

  StatesearchTerm: string = "";

  StateList = []
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }

  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = '';
    }
    this.State = option.value;
    this.getCityList(option.id);

  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue
    if (this.State === '') {
      this.City = ''
    }
  }

  getStateList(countryId: number) {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetStateDropDown?CountryId=' + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }


  ////////////////////////////////////////////////////
  /////////  City drop down data //////////
  ////////////////////////////////////////////////////


  CityList = []
  onCitySelected(option: any): void {
    this.City = option.value;
  }

  getCityList(stateId: number) {
    this.__apiCall.GetCallWithoutToken('DropDown/GetCityDropDown?StateId=' + stateId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      }
    )
  }




  ///////////////////////////////////////////////////////////
  // Function to add SCHOLARSHIPS tag functionality start here
  scholarshipTags: any[] = [];

  addScholarshipTags(): void {
    const tagInput = this.addAdmissionForm.get("scholarship_tag")?.value.trim();

    if (tagInput && !this.scholarshipTags.some((tag) => tag.scholarshipName === tagInput)) {
      const newTag = { scholarshipName: tagInput };
      this.scholarshipTags.push(newTag);
      this.addAdmissionForm.get("scholarship_tag")?.setValue("");
    }
  }

  removeScholarshipTags(tag: any): void {
    this.scholarshipTags = this.scholarshipTags.filter((t) => t !== tag);
  }

  onEnterScholarship(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addScholarshipTags();
    }
  }
  // Function to add SCHOLARSHIPS tag functionality end here
  /////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // Function to add Required Documents tag functionality start here
  requiredDocuments: any[] = [];

  addDocumentsTags(): void {
    const tagInput = this.addAdmissionForm.get("required_documents")?.value.trim();

    if (tagInput && !this.requiredDocuments.some((tag) => tag.documentName === tagInput)) {
      const newTag = { documentName: tagInput };
      this.requiredDocuments.push(newTag);
      this.addAdmissionForm.get("required_documents")?.setValue("");
    }
  }

  removeDocumentsTags(tag: any): void {
    this.requiredDocuments = this.requiredDocuments.filter((t) => t !== tag);
  }

  onEnterDocuments(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addDocumentsTags();
    }
  }
  // Function to add Required Documents tag functionality end here
  /////////////////////////////////////////////////////////////////////////



  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Language
  //////////////////////////////////////////////




  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag //////////////////////////////
  ////////////////////////////////////////////////////////////


  coursestags: any[] = [];

  addCourseTag(): void {

    const tagInput = this.addAdmissionForm.get("courses_name")?.value.trim();

    if (
      tagInput &&
      !this.coursestags.some((tag) => tag.courseName === tagInput)
    ) {
      const newTag = { courseName: tagInput };
      this.coursestags.push(newTag);
      this.addAdmissionForm.get("courses_name")?.setValue("");
    }
  }

  removeCourseTag(tag: any): void {
    this.coursestags = this.coursestags.filter((t) => t !== tag);
  }

  onEnterKeyPress(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addCourseTag();
    }
  }


  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag //////////////////////////////
  ////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag ////////////////////////////
  ////////////////////////////////////////////////////////////


  classesTags: any[] = [];

  addClassTags(): void {
    const tagInput = this.addAdmissionForm.get("classes_name")?.value.trim();

    if (
      tagInput &&
      !this.classesTags.some((tag) => tag.classTitle === tagInput)
    ) {
      const newTag = { classTitle: tagInput };
      this.classesTags.push(newTag);
      this.addAdmissionForm.get("classes_name")?.setValue("");
    }
  }

  removeClassTags(tag: any): void {
    this.classesTags = this.classesTags.filter((t) => t !== tag);
  }

  onEnterKey(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addClassTags();
    }
  }

  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag ////////////////////////////
  ////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // Function to add Department tag ///////////////////////
  //////////////////////////////////////////////////////////////


  departmentTags: any[] = [];

  adddepartmentTags(): void {
    const tagInput = this.addAdmissionForm.get("department")?.value.trim();

    if (tagInput && !this.departmentTags.some((tag) => tag.departmentName === tagInput)) {
      const newTag = { departmentName: tagInput };
      this.departmentTags.push(newTag);
      this.addAdmissionForm.get("department")?.setValue("");
    }
  }

  removeDepartmentTags(tag: any): void {
    this.departmentTags = this.departmentTags.filter((t) => t !== tag);
  }

  onEnterDepartment(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.adddepartmentTags();
    }
  }


  ///////////////////////////////////////////////////////////
  // Function to add Department tag ///////////////////////
  //////////////////////////////////////////////////////////////




  checkTagsValidity(): boolean {
    // ;
    if (this.InstitueType === "University") {
      if (this.departmentTags.length < 1 || this.coursestags.length < 1 || this.scholarshipTags.length < 1 || this.requiredDocuments.length < 1) {
        return true;
      }
    }
    if (this.InstitueType === "College" || this.InstitueType === "School") {
      if (this.classesTags.length < 1 || this.requiredDocuments.length < 1 || this.scholarshipTags.length < 1) {
        return true;
      }
    }
    if (this.InstitueType === "Academy") {
      if (this.classesTags.length < 1 || this.requiredDocuments.length < 1) {
        return true;
      }
    }

    return false;
  }


  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////


  removeUmwantedControl() {

    this.addAdmissionForm.removeControl('classes_name');
    this.addAdmissionForm.removeControl('courses_name');
    this.addAdmissionForm.removeControl('department');
    this.addAdmissionForm.removeControl('scholarship_tag');
    this.addAdmissionForm.removeControl('required_documents');
    this.addAdmissionForm.updateValueAndValidity();

    // console.log("val remove", this.addAdmissionForm.value)
  }
  addControls() {

    if (this.InstitueType === "University") {
      if (!this.addAdmissionForm.contains('courses_name')) {
        this.addAdmissionForm.addControl('courses_name', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('department')) {
        this.addAdmissionForm.addControl('department', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('scholarship_tag')) {
        this.addAdmissionForm.addControl('scholarship_tag', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('required_documents')) {
        this.addAdmissionForm.addControl('required_documents', new FormControl("", Validators.required));
      }

    }
    else if (this.InstitueType === "College") {

      if (!this.addAdmissionForm.contains('classes_name')) {
        this.addAdmissionForm.addControl('classes_name', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('scholarship_tag')) {
        this.addAdmissionForm.addControl('scholarship_tag', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('required_documents')) {
        this.addAdmissionForm.addControl('required_documents', new FormControl("", Validators.required));
      }
    }
    else if (this.InstitueType === "School") {
      if (!this.addAdmissionForm.contains('classes_name')) {
        this.addAdmissionForm.addControl('classes_name', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('scholarship_tag')) {
        this.addAdmissionForm.addControl('scholarship_tag', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('required_documents')) {
        this.addAdmissionForm.addControl('required_documents', new FormControl("", Validators.required));
      }
    }
    this.addAdmissionForm.updateValueAndValidity();
  }


  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////




  ////////////////////////////////////
  // API CALL AND VALIDATIONS CHECK
  ////////////////////////////////////


  public async onSubmit() {
    this.addAdmissionForm.markAllAsTouched();
    const tagsValidity = this.checkTagsValidity();
    this.ShowError();
    this.removeUmwantedControl();

    const description = this.addAdmissionForm.get('description')?.value;
    if (
      this.Country === "" || this.InstitueType === "" || this.City === "" || this.State === "" ||
      this.images.length < 1 ||
      tagsValidity || (this.InstitueType === "University" && this.TestType === "") || description?.length < 400
    ) {
      const errorMessage = this.getFirstError();
      if (errorMessage) {
        this._toastr.error(errorMessage);
        this.addControls();
        return;
      }
      this.addControls();
    }
    else {
      this.isSpinnerShown = true;
      try {
        const payload = this.createApiPayload();

        this.__apiCall
          .PostCallWithToken(payload, "Dashboard/CreateAdmissions")
          .subscribe((response: any) => {
            this.addControls();
            if (response.responseCode == 200) {
              this._toastr.success(response.responseMessage);
              this.modal.dismissAll();
            } else {
              this._toastr.error(response.errorMessage);
            }

          });
      } catch (error) {
        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
      } finally {
        this.isSpinnerShown = false;
      }
    }

  }

  private getFirstError(): string {
    // Check for invalid form controls
    for (const key of Object.keys(this.addAdmissionForm.controls)) {
      const control = this.addAdmissionForm.get(key);
      if (control && control.invalid) {
        return `${this.getFriendlyFieldName(key)} is invalid.`;
      }
    }
    // Check specific fields in priority order
    if (this.Country === "") return "Country is required.";
    if (this.InstitueType === "") return "Institute Type is required.";
    if (this.City === "") return "City is required.";
    if (this.State === "") return "State is required.";
    if (this.TestType === "") return "TestType is required.";
    if (this.images.length < 1) return "At least one image is required.";
    if (this.checkTagsValidity()) return "Tags are invalid.";

    // No errors
    return "";
  }

  private getFriendlyFieldName(field: string): string {
    const fieldNames: { [key: string]: string } = {
      institute_name: "Institute Name",
      courses_name: "Courses Name",
      classes_name: "Classes Name",
      department: "Department",
      site_link: "Website Link",
      institute_address: "Institute Address",
      institute_addressTitle: "Institute Address Title",
      institute_addressLink: "Institute Google Maps Link",
      email_address: "Email Address",
      description: "Description",
      phone: "Phone Number",
      video_link: "Video Link",
      application_fee: "Application Fee",
      start_date: "Start Date",
      end_date: "End Date",
      scholarship_tag: "Scholarship Tag",
      required_documents: "Required Documents",
      Country: "Country",
      InstitueType: "Institute Type",
      TestType: "Test Type",
      City: "City",
      State: "State",
      images: "Images",
      tags: "Tags",
  
    };

    return fieldNames[field] || field;
  }



  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////


  createApiPayload() {
    const payload: any = {
      instituteName: this.addAdmissionForm.get("institute_name")?.value,
      description: this.addAdmissionForm.get("description")?.value,
      instituteType: this.InstitueType,
      contactNumber: this.addAdmissionForm.get("phone")?.value,
      websiteLink: this.addAdmissionForm.get("site_link")?.value,
      email: this.addAdmissionForm.get("email_address")?.value,
      country: this.Country,
      state: this.State,
      city: this.City,
      address: this.addAdmissionForm.get("institute_address")?.value,
      locationTitle: this.addAdmissionForm.get("institute_addressTitle")?.value,
      locationSrc: this.addAdmissionForm.get("institute_addressLink")?.value,
      admissionImages: this.images,
      applicationFee: this.addAdmissionForm.get("application_fee")?.value,
      startDate: this.addAdmissionForm.get("start_date")?.value,
      endDate: this.addAdmissionForm.get("end_date")?.value,
      testType: this.TestType,
      // estimatedPrice: this.addAdmissionForm.get("estimated_Price")?.value,
      // specialization: this.addAdmissionForm.get("Specialization")?.value,
    };


    if (this.InstitueType == "University") {
      // ;
      payload.admissionDepartments = this.departmentTags;
      payload.admissionCourses = this.coursestags;
      payload.admissionScholarships = this.scholarshipTags;
      payload.admissionDocuments = this.requiredDocuments;
    }

    if (this.InstitueType == "College" || this.InstitueType == "School") {
      // ;
      payload.admissionClasses = this.classesTags;
      payload.admissionScholarships = this.scholarshipTags;
      payload.admissionDocuments = this.requiredDocuments;
    }

    if (this.InstitueType == "Academy") {
      // ;
      payload.admissionClasses = this.classesTags;
      payload.admissionDocuments = this.requiredDocuments;
    }

    return payload;

  }

  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////






}
