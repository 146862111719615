<app-loader-two [isLoading]="isLoading"></app-loader-two>
<app-header-one></app-header-one>
@if(hostelData){
<app-sticky-tab [instituteData]="hostelData"></app-sticky-tab>

<!-- @if(relatedhostelData.length !== 0){ -->
<app-related-property [type]="'grid-3'" [heading]="heading" [totalData]="3" [instituteData]="relatedhostelData"
    [isDescriptionShown]="true" [isSkeletonShown]="isRelatedHostelLoading">
</app-related-property>
<!-- } -->

}

<app-adly-footer></app-adly-footer>