import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PropertyBoxGridService {
  listViewOn() {
    throw new Error('Method not implemented.');
  }

  public listView: boolean = false;
  public gridOptions: boolean = true;
  public col_xl_4: boolean = false;
  public col_lg_4: boolean = false;
  public col_md_6: boolean = false;
  public col_md_3: boolean = true;
  public col_6: boolean = false;
  public col_lg_6: boolean = false;
  public col_xl_6: boolean = false;
  public col_xl_12: boolean = false;
  public col_xxl_3: boolean = true;
  public col_md_12: boolean;



  // Grid 2: 2 cards per row
  grid2() {
    this.resetView();
    this.gridOptions = true;
    this.col_md_6 = true;
  }

  // Grid 3: 3 cards per row
  grid3() {
    this.resetView();
    this.col_md_6 = false; // Disable 2 cards per row
    this.col_xl_4 = true;  // 3 cards per row
  }

  // Grid 4: 4 cards per row
  grid4() {
    this.resetView();
    this.col_xxl_3 = true;
  }

  // List view: 1 card per row
  listOpen() {
    this.resetView();
    this.listView = true;
  }

  // Helper method to reset all states to default
  private resetView() {
    this.listView = false;
    this.gridOptions = false;
    this.col_md_6 = false;
    this.col_xl_4 = false;
    this.col_lg_6 = false;
    this.col_xxl_3 = true;
  }


  // gridOpen() {
  //   this.gridOptions = true;
  //   this.col_lg_6 = true;
  //   this.col_md_6 = true;
  //   this.col_xl_4 = true;
  //   this.col_lg_4 = false;
  //   this.col_6 = false;
  //   this.col_xl_6 = false;
  //   this.col_xl_12 = false;
  //   this.col_xxl_3 = false;
  //   this.listView = false;
  // }

  // gridOpen2() {
  //   this.gridOptions = true;
  //   this.col_lg_6 = false;
  //   this.col_md_6 = true;
  //   this.col_xl_4 = false;
  //   this.col_lg_4 = false;
  //   this.col_6 = false;
  //   this.col_xl_6 = false;
  //   this.col_xl_12 = false;
  //   this.col_xxl_3 = false;
  //   this.listView = false;
  // }

  // listOpen() {
  //   this.listView = true;
  //   this.col_xl_12 = true;
  //   this.col_xl_6 = false;
  //   this.gridOptions = false;
  //   this.col_lg_6 = false;
  //   this.col_md_6 = false;
  //   this.col_xl_4 = false;
  //   this.col_lg_4 = false;
  //   this.col_6 = false;
  //   this.col_xxl_3 = false;
  // }

  // list() {
  //   this.listView = true;
  //   this.col_xl_6 = true;
  //   this.col_xl_12 = false;
  //   this.gridOptions = false;
  //   this.col_lg_6 = false;
  //   this.col_md_6 = false;
  //   this.col_xl_4 = false;
  //   this.col_lg_4 = false;
  //   this.col_6 = false;
  //   this.col_xxl_3 = false;
  // }

  // grid2() {
  //   this.col_lg_6 = false;  // Disable col-lg-6
  //   this.col_md_6 = true;   // Enable col-md-6
  //   this.col_lg_4 = false;  // Disable col-lg-4
  //   this.gridOptions = false;
  //   this.col_xl_4 = false;
  //   this.col_6 = false;
  //   this.col_xl_6 = false;
  //   this.col_xl_12 = false;
  //   this.col_xxl_3 = false;
  //   this.listView = false;
  // }
  // grid2d() {
  //   this.col_lg_6 = false;  // Disable col-lg-6
  //   this.col_md_6 = true;   // Enable col-md-6
  //   this.col_lg_4 = false;  // Disable col-lg-4
  //   this.gridOptions = false;
  //   this.col_xl_4 = false;
  //   this.col_6 = false;
  //   this.col_xl_6 = false;
  //   this.col_xl_12 = false;
  //   this.col_xxl_3 = false;
  //   this.listView = false;
  // }

  // grid3() {
  //   this.col_md_6 = true;
  //   this.col_xl_4 = true;
  //   this.col_lg_4 = false;
  //   this.col_6 = false;
  //   this.col_lg_6 = false;
  //   this.gridOptions = false;
  //   this.col_xl_6 = false;
  //   this.col_xl_12 = false;
  //   this.col_xxl_3 = false;
  //   this.listView = false;
  // }

  // grid4() {
  //   this.col_md_12 = true;  // Enable col-md-12
  //   this.col_lg_6 = true;   // Enable col-lg-6
  //   this.gridOptions = false;
  //   this.col_md_6 = true;
  //   this.col_xl_4 = true;
  //   this.col_6 = false;
  //   this.col_lg_4 = false;
  //   this.gridOptions = false;
  //   this.col_xl_6 = false;
  //   this.col_xl_12 = false;
  //   this.listView = false;
  // }
}
