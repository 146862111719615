<ng-template #addScholarship>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                    Add New Scholarship
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="addScholarshipForm">
                <div class="row gx-3">
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Scholarship Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Scholarship Name"
                            formControlName="scholarship_name">
                        @if(addScholarshipForm.get('scholarship_name')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_name')?.touched){

                        <small class="text-danger">Name is Required</small>
                        }

                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-in">Institute Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-in" placeholder="Enter Institute Name"
                            formControlName="institute_name">
                        @if(addScholarshipForm.get('institute_name')?.hasError('required') &&
                        addScholarshipForm.get('institute_name')?.touched) {
                        <small class="text-danger">Name is Required</small>
                        }

                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-st">Scholarship Type<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Scholarship Type'"
                            [dataList]="ScholarshipTypeList" (optionSelected)="onScholarshipTypeSelected($event)"
                            [selectedOption]="ScholarshipType"></app-common-dropdowns>

                        @if(ScholarshipType == "" && scholarshipTypeError){
                        <small class="text-danger">Type is Required</small>
                        }

                    </div>
                    <!-- </div>
                <div class="row gx-3"> -->
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Country<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="second-dropdown" [defaultText]="'Enter Country Name'"
                            [dataList]="CountryList" (optionSelected)="onCountrySelected($event)"
                            [selectedOption]="Country" [clearThevalue]="Country"
                            (sendTheValue)="onCountryValueChange($event)"></app-common-dropdowns>
                        @if(Country == "" && countryTypeError){
                        <small class="text-danger">Country is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">State<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="third-dropdown" [defaultText]="'Enter State Name'"
                            [dataList]="StateList" (optionSelected)="onStateSelected($event)" [selectedOption]="State"
                            [clearThevalue]="State" (sendTheValue)="onStateValueChange($event)"></app-common-dropdowns>
                        @if(State == "" && stateError){
                        <small class="text-danger">State is Required</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">City<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="fourth-dropdown" [defaultText]="'Enter City Name'"
                            [dataList]="CityList" (optionSelected)="onCitySelected($event)" [selectedOption]="City"
                            [clearThevalue]="City"></app-common-dropdowns>
                        @if(City == "" && cityError){
                        <small class="text-danger">City is Required</small>
                        }
                    </div>
                    <!-- </div>

                <div class="row gx-3"> -->
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-ad">Address<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-ad" placeholder="Enter Address"
                            formControlName="scholarship_address">
                        @if(addScholarshipForm.get('scholarship_address')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_address')?.touched) {
                        <small class="text-danger">Address is Required</small>
                        }

                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-at">Address Title<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-at" placeholder="Enter Address Title"
                            formControlName="scholarship_addressTitle">
                        @if(addScholarshipForm.get('scholarship_addressTitle')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_addressTitle')?.touched) {
                        <small class="text-danger">Address is Required</small>
                        }

                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-al">Address Link<span class="text-danger">*</span></label>
                        <input type="url" class="form-control" id="a-al" placeholder="Enter Google Maps Link"
                            formControlName="scholarship_addressLink">
                        @if(addScholarshipForm.get('scholarship_addressLink')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_addressLink')?.touched) {
                        <small class="text-danger">Address Link is Required</small>
                        }@else if(addScholarshipForm.get('scholarship_addressLink')?.touched &&
                        addScholarshipForm.get('scholarship_addressLink')?.hasError('pattern')) {
                        <small class="text-danger">https://maps.google.com/maps link is required</small>
                        }
                    </div>
                    <!-- </div>



                <div class="row gx-3"> -->

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-em">Email Address<span class="text-danger">*</span></label>
                        <input type="email" class="form-control" id="a-em" placeholder="Enter Email Address"
                            formControlName="email_address">
                        @if(addScholarshipForm.get('email_address')?.hasError('required') &&
                        addScholarshipForm.get('email_address')?.touched){
                        <small class="text-danger">Email is Required</small>
                        }@else if(addScholarshipForm.get('email_address')?.touched &&
                        addScholarshipForm.get('email_address')?.hasError('pattern')
                        ){
                        <small class="text-danger">Invalid Email</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-ph">Phone Number<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-ph" placeholder="Enter Phone Number"
                            formControlName="scholarship_phone">

                        @if(addScholarshipForm.get('scholarship_phone')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_phone')?.touched) {
                        <small class="text-danger">Phone number is Required</small>
                        }@else if(addScholarshipForm.get('scholarship_phone')?.touched &&
                        addScholarshipForm.get('scholarship_phone')?.hasError('pattern')) {
                        <small class="text-danger">Phone number must be between 10 and 18 digits</small>
                        }

                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-sl">Application Link<span class="text-danger">*</span></label>
                        <input type="url" class="form-control" id="a-sl" placeholder="Enter Application Link"
                            formControlName="application_Link">


                        @if(addScholarshipForm.get('application_Link')?.hasError('required') &&
                        addScholarshipForm.get('application_Link')?.touched) {
                        <small class="text-danger">Application link is Required</small>
                        }@else if(addScholarshipForm.get('application_Link')?.touched &&
                        addScholarshipForm.get('application_Link')?.hasError('pattern')) {
                        <small class="text-danger">Valid Application starting with https:// is required</small>
                        }
                    </div>

                    <!-- </div>

                <div class="row gx-3"> -->
                    <div class="form-group col-6 col-sm-4">
                        <label for="">Application Fee<span class="text-danger">*</span></label>
                        <input type="number" class="form-control" id="a-sa" placeholder="Enter Scholarship Fee"
                            formControlName="scholarship_fee">
                        @if(addScholarshipForm.get('scholarship_fee')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_fee')?.touched) {
                        <small class="text-danger">Amount Required</small>
                        }

                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-sd">Start Date<span class="text-danger">*</span></label>
                        <input type="date" class="form-control" id="a-sd" placeholder="Select Start Date"
                            formControlName="start_date" (change)="updateEndDateMin()">
                        @if(addScholarshipForm.get('start_date')?.hasError('required') &&
                        addScholarshipForm.get('start_date')?.touched) {
                        <small class="text-danger">Start Date is Required</small>
                        }

                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-ed">End Date<span class="text-danger">*</span></label>
                        <input type="date" class="form-control" id="a-ed" placeholder="Select End Date"
                            formControlName="end_date" [min]="minEndDate">
                        @if(addScholarshipForm.get('end_date')?.hasError('required') &&
                        addScholarshipForm.get('end_date')?.touched) {
                        <small class="text-danger">End Date is Required</small>
                        }

                    </div>
                    <!-- </div>

                <div class="row gx-3"> -->
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-cgpa">Minimum CGPA<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-cgpa" placeholder="Enter Minimum CGPA"
                            formControlName="minimum_cgpa">

                        @if(addScholarshipForm.get('minimum_cgpa')?.hasError('required') &&
                        addScholarshipForm.get('minimum_cgpa')?.touched) {
                        <small class="text-danger">Minimum CGPA is Required</small>
                        }@else if(addScholarshipForm.get('minimum_cgpa')?.hasError('greaterThanFour') &&
                        addScholarshipForm.get('minimum_cgpa')?.touched) {
                        <small class="text-danger">CGPA cannot be greater than 4</small>
                        }

                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-du">Scholarship Duration<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Scholarship Duration'"
                            [dataList]="DurationTimeList" (optionSelected)="onDurationSelected($event)"
                            [isDisabled]="true" [selectedOption]="Duration"
                            [clearThevalue]="Duration"></app-common-dropdowns>
                        @if(addScholarshipForm.get('duration')?.hasError('required') &&
                        addScholarshipForm.get('duration')?.touched) {
                        <small class="text-danger">Duration is Required</small>
                        }

                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-sa">Scholarship Amount<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-sa" placeholder="Enter Scholarship Amount"
                            formControlName="scholarship_amount">
                        @if(addScholarshipForm.get('scholarship_amount')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_amount')?.touched) {
                        <small class="text-danger">Invalid Amount</small>
                        }

                    </div>
                    <!-- </div>

                <div class="row gx-3"> -->
                    <div class="form-group col-12 col-sm-4">
                        <label for="job-education">Eliglbe Courses<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-education" placeholder="Add Courses"
                            formControlName="courses_name" (keyup.enter)="onEnterKeyPress($event)">
                        @if(coursestags.length == 0 && addScholarshipForm.get('courses_name')?.touched) {
                        <small class="text-danger">Add at least one Course</small>
                        }
                        @for(courseTag of coursestags; track courseTag) {
                        <div class="tag" (click)="removeCourseTag(courseTag)">
                            {{ courseTag.courseName }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                    </div>

                    <div class="form-group col-12 col-sm-4">
                        <label for="job-experience">Coverages<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-experience" placeholder="Add Coverages"
                            formControlName="coverage_Tags" (keyup.enter)="onEnterCoverage($event)">
                        @if(coverageTags.length == 0 && addScholarshipForm.get('coverage_Tags')?.touched) {
                        <small class="text-danger">Add at least one Coverage</small>
                        }
                        @for(coverage of coverageTags; track coverageTags){
                        <div class="tag" (click)="removeCoverageTags(coverage)">
                            {{ coverage.coverageName }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                    </div>

                    <div class="form-group col-12 col-sm-4">
                        <label for="job-skills">Required Documents<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-skills" placeholder="Add Required Documents"
                            formControlName="required_documents" (keyup.enter)="onEnterDocuments($event)">
                        @if(requiredDocuments.length == 0 && addScholarshipForm.get('required_documents')?.touched) {
                        <small class="text-danger">Add at least one Skill</small>
                        }
                        @for(requiredDocument of requiredDocuments; track requiredDocument){
                        <div class="tag" (click)="removeDocumentsTags(requiredDocument)">
                            {{ requiredDocument.documentName }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }

                    </div>

                </div>

                <div class="row gx-3">
                    <div class="form-group col-md-12 col-sm-12">
                        <label for="a-desc">Scholarship Description<span class="text-danger">*</span></label>
                        <textarea class="form-control" id="a-desc" rows="4" placeholder="Enter Scholarship Description"
                            formControlName="scholarship_description"
                            (input)="updateScholarshipDescriptionCharCount()"></textarea>

                        <small>{{ scholarshipDescriptionCharCount }}/400</small>

                        @if(addScholarshipForm.get('scholarship_description')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_description')?.touched){
                        <small class="text-danger">Job Description is Required</small>
                        }

                    </div>
                </div>

                <div class="row gx-3">
                    <div class="form-group col-md-12 col-sm-12">
                        <label for="a-apply">How to Apply<span class="text-danger">*</span></label>
                        <textarea class="form-control" id="a-apply" rows="5"
                            placeholder="Enter How to Apply Information" formControlName="how_to_apply"
                            (input)="updateHowToApplyCharCount()"></textarea>

                        <small>{{ howToApplyCharCount }}/400</small>
                        @if(addScholarshipForm.get('how_to_apply')?.hasError('required') &&
                        addScholarshipForm.get('how_to_apply')?.touched){
                        <small class="text-danger">How to Apply is Required</small>
                        }

                    </div>
                </div>

            </form>
            <div class="dropzone-admin mb-0">
                <label>Media <span class="font-danger">*</span></label>
                <form class="dropzone" id="multiFileUpload">
                    <ngx-dropzone (change)="onSelect($event)" accept="image/jpeg,image/jpg,image/png"
                        [multiple]="false">
                        <ngx-dropzone-label>
                            <div class="dz-message needsclick">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <h6 class="f-w-700 mb-0">
                                    Drop files here or click to upload.
                                </h6>
                            </div>
                        </ngx-dropzone-label>
                        @for(f of files; track f){
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true"
                            (removed)="onRemove(f)" [file]="f">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

                        </ngx-dropzone-image-preview>
                        }
                    </ngx-dropzone>
                </form>
            

                @if(imageError){
                    <small class="text-danger">Image is Required</small>
                    }
                    @else if(imageSizeError){
                    <small class="text-danger">Image size should be less than 1MB</small>
                    }


            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="onSubmit()"> 
                Add Scholarship

            </button>
        </div>
    </div>

</ng-template>