import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-propety-location',
  templateUrl: './propety-location.component.html',
  styleUrls: ['./propety-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropetyLocationComponent {

  @Input() location: string;
  @Input() getInstituteData: any;

  constructor() {
   

  }
  ngOnInit() {
  }
}
