<div class="row">
    @for(data of propertyOfDay; track data){
      <div class="col-xl-6 col-lg-5">
        @if(data){
          <owl-carousel-o [options]="Options">
            @for(images of data.images; track images){
              <ng-template carouselSlide>
                  <div class="feature-image property-slider mb-0">
                    <a href="javascript:void(0)" class="bg-size" [style.background-image]="'url('+images.img+')'">
                      <img [src]="images.img" class="bg-img" alt="property-image" style="display: none;">
                    </a>
                  </div>
                </ng-template>
            }
          </owl-carousel-o>
        }
      </div>
      <div class="col-xl-6 col-lg-7">
        <div class="feature-content">
          <div class="details">
            <h3><a href="javascript:void(0)"><span>Home in</span> {{ data.location }} </a></h3>
            <span>{{ data.city }}</span>
            <p class="font-roboto">{{ data.desc }}</p>
          </div>
          <ul class="detail-list">
            <li>
              <div class="d-flex">
                <span class="label label-light color-2 label-lg">
                  <img src="assets/images/icon/bed.png" class="img-fluid img-icon" alt="bed-icon">
                </span>
                <h6>Bedroom</h6>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <span class="label label-light color-2 label-lg">
                  <img src="assets/images/icon/bathroom.png" class="img-fluid img-icon" alt="bathroom-icon">
                </span>
                <h6>Bathroom</h6>
              </div>
            </li>
            <li>
              <span class="label label-light color-2 label-lg">{{ data.sqft }} Sq Ft</span>
            </li>
          </ul>
          <ul class="feature-price">
            <li>
              <h3>{{ data.price | currencySymbol: propertyService.Currency  }}</h3>
              <h6>{{ data.title }}</h6>
            </li>
            <li><a href="javascript:void(0)" class="btn btn-gradient btn-pill color-2 btn-lg" [routerLink]="['/property/image-box']">View property</a></li>
          </ul>
        </div>
      </div>
    }
</div>

