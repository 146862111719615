import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

interface Image {
  img: string;
  icon?: boolean;
  intrinsicWidth?: number; 
  intrinsicHeight?: number; 
}

interface HappyClient {
  id: number;
  type: string;
  images: Image[];
  desc: string;
  title: string;
  name: string;
  email?: string;
}

@Component({
  selector: 'app-happy-client-one',
  templateUrl: './happy-client-one.component.html',
  styleUrls: ['./happy-client-one.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HappyClientOneComponent implements OnInit {

  @Input() happyClientsData: HappyClient[] = [];
  @Input() tagClass: string = '';

  // Owl carousel options
  public Options = {
    loop: true,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayTimeOut: 500,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1,
      },
    }
  };

  // Dummy data for testing
  ngOnInit(): void {
    this.happyClientsData = [
      {
        id: 1,
        type: 'standard',
        images: [
          { img: 'assets/images/about/clients/Choudry-Qasim.jpg', icon: false, intrinsicWidth: 825, intrinsicHeight: 601 },
          { img: 'assets/images/about/clients/shahzaib.jpg', icon: true, intrinsicWidth: 1536, intrinsicHeight: 1053 },
          { img: 'assets/images/about/clients/Mian-Hamza.jpg', icon: false, intrinsicWidth: 1079, intrinsicHeight: 785  }
        ],
        desc: 'A well-organized and responsive website, perfect for those seeking career opportunities or educational admissions. Its clear structure and navigation make it accessible to a wide audience',
        title: 'Happy Customer',
        name: 'Shahzaib Rehman',
        email: 'Shahzaib@gmail.com'
      },
      {
        id: 2,
        type: 'premium',
        images: [
          { img: 'assets/images/about/clients/shahzaib.jpg', icon: false, intrinsicWidth: 1536, intrinsicHeight: 1053 },
          { img: 'assets/images/about/clients/Choudry-Qasim.jpg', icon: true, intrinsicWidth: 825, intrinsicHeight: 601  },
          { img: 'assets/images/about/clients/Mian-Hamza.jpg', icon: false, intrinsicWidth: 1079, intrinsicHeight: 785 }
        ],
        desc: 'An impressive platform that combines simplicity with functionality, providing users with a hassle-free way to explore job listings and educational admissions. The clean layout enhances the user experience',
        title: 'Satisfied Client',
        name: 'Choudry Qasim',
        email: 'qasim@gmail.com'
      },
      {
        id: 3,
        type: 'standard',
        images: [
          { img: 'assets/images/about/clients/Choudry-Qasim.jpg', icon: false, intrinsicWidth: 825, intrinsicHeight: 601  },
          { img: 'assets/images/about/clients/Mian-Hamza.jpg', icon: true, intrinsicWidth: 1079, intrinsicHeight: 785 },
          { img: 'assets/images/about/clients/shahzaib.jpg', icon: false, intrinsicWidth: 1536, intrinsicHeight: 1053 }
        ],
        desc: 'A well-organized and responsive website, perfect for those seeking career opportunities or educational admissions. Its clear structure and navigation make it accessible to a wide audience',
        title: 'Happy Customer',
        name: 'Mian Hamza',
        email: 'hamza@gmail.com'
      }
    ];
  }
}
