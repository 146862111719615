<div class="wrapper sliderWrraper">
    <div class="slider">
      <div class="progress"></div>
      <div class="value min">0</div>
      <div class="range mid">Salary-Range</div>
      <div class="value max">100000</div>
    </div>
    <div class="range-input">
      <input type="range" class="range-min" min="0" max="100000" value="0" step="100">
      <input type="range" class="range-max" min="0" max="100000" value="100000" step="100">
    </div>
  </div>