import { Component, OnInit, inject } from '@angular/core';
import { MetaService } from 'src/app/shared/services/meta.service';

@Component({
  selector: 'app-book-categories',
  templateUrl: './book-categories.component.html',
  styleUrl: './book-categories.component.scss'
})
export class BookCategoriesComponent implements OnInit {

  private readonly metaService: MetaService = inject(MetaService);

  ngOnInit() {
    this.metaService.updateTitle('Book Categories | AdmissionLylo');
    this.metaService.updateMetaTags([
      { name: 'description', content: 'Your page description here' },
      { name: 'keywords', content: 'angular, seo, meta tags' },
      { name: 'author', content: 'Your Name' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }
}
