<div class="coming-soon comingsoon-countdown coming-left bg-size" [style.background-image]="'url(assets/images/inner-pages/coming-soon2.jpg)'">
  <img src="assets/images/inner-pages/coming-soon2.jpg" alt="image" class="img-fluid bg-img">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 offset-xl-3 col-md-8 offset-md-2">
        <div class="coming-soon-detail">
          <div>
            <div class="logo">
              <a href="javascript:void(0)" aria-label="clickable-text">
                <img src="assets/images/logo/3.png" alt="logo" class="img-fluid">
              </a>
            </div>
            <h2 class="font-roboto">
              <span>Counter </span> site is coming soon...
            </h2>
            <div class="timer">
              <ul>
                <li><span id="days"></span>days</li>
                <li><span id="hours"></span>Hour</li>
                <li><span id="minutes"></span>min</li>
                <li><span id="seconds"></span>sec</li>
              </ul>
            </div>
            <form action="#" class="theme-form w-100">
              <div class="form-group">
                <input type="text" name="password" id="name" class="form-control" placeholder="Enter your email address">
                <button type="submit" class="btn btn-solid color-3 btn-flat" aria-label="subscribe">subscribe</button>
              </div>
            </form>
            <div class="social-coming">
              <ul>
                <li><a href="https://www.facebook.com/" aria-label="clickable-text" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="https://twitter.com/" aria-label="clickable-text" target="_blank"><i class="fab fa-twitter"></i></a></li>
                <li><a href="https://account.google.com" aria-label="clickable-text" target="_blank"><i class="fab fa-google-plus-g"></i></a></li>
              </ul>
              <p>Copyright 2023, All Right Reserved Sheltos </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
