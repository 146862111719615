<!-- <ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="var(--mainColor)"
type="ball-spin-clockwise"
[fullScreen]="true"
>
<p style="font: size 20px; color: var(--mainColor)">Loading...</p>
</ngx-spinner> -->

<div class="video-details">
  <span class="label label-light label-flat color-4">Admission Lylo</span>
  <h2>Stay Up to Date</h2>
  <p class="font-roboto">
    Discover exciting career opportunities and educational paths in one
    convenient place. Our platform provides access to a wide range of job
    openings and admissions for top institutes, designed to help you achieve
    your personal and professional goals.
  </p>
  <form [formGroup]="subscriber_From">
    <div class="form-group">
      <input type="email" class="form-control glow-input" placeholder="Enter Your Email Address"
        formControlName="Email" />
    </div>
    <div class="error">
      @if(subscriber_From.get('Email')?.hasError('required') &&
      subscriber_From.get('Email')?.touched){
      <small class="text-danger">Email is Required</small>
      }@else if(subscriber_From.get('Email')?.hasError('pattern') &&
      subscriber_From.get('Email')?.touched){
      <small class="text-danger">Invalid Email</small>
      }
    </div>
    <app-center-button [isButtonCenter]="true" [buttonText]="'Subscribe Now'"
      (buttonClick)="subscriber()"></app-center-button>
  </form>
</div>