<h4 class="content-title">gallery</h4>
<div class="single-gallery">
  @if(requestForm == true){
  <app-request-exploration-form></app-request-exploration-form>
  }
  @if(instituteData.blobUrls && instituteData.blobUrls.length > 0) {
  <div class="gallery-for">
    <owl-carousel-o [options]="Options" #owlCar>
      @for(blobUrl of instituteData.blobUrls; track blobUrl) {
      <ng-template carouselSlide [id]="blobUrl" [width]="430">
        <div>
          <div class="bg-size" [style.background-image]="'url('+blobUrl+')'">
            <img [src]="blobUrl" class="bg-img" alt="property-image" style="display: none;">
          </div>
        </div>
      </ng-template>
      }
    </owl-carousel-o>
  </div>

  <div class="gallery-nav">
    <owl-carousel-o [options]="thumbnailCarouselOptions">
      @for(blobUrl of instituteData.blobUrls; track blobUrl; let i = $index) {
      <ng-template carouselSlide [id]="blobUrl+i">
        <div (click)="owlCar.to(blobUrl); changeImage(blobUrl+i)" class="bg-size"
          [style.background-image]="'url('+blobUrl+')'">
          <img [src]="blobUrl" class="img-fluid" alt="property-image" style="display: none;">
        </div>
      </ng-template>
      }
    </owl-carousel-o>
  </div>
  }

</div>