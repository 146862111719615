import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LibraryRoutingModule } from './library-routing.module';
import { BookCategoriesComponent } from './book-categories/book-categories.component';
import { BooksListComponent } from './books-list/books-list.component';
import { SharedModule } from "../../shared/shared.module";
import { HomeModule } from "../home/home.module";
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AgencyModule } from "../pages/agency/agency.module";


@NgModule({
  declarations: [
    BookCategoriesComponent,
    BooksListComponent
  ],
  imports: [
    CommonModule,
    LibraryRoutingModule,
    SharedModule,
    HomeModule,
    NgbPaginationModule,
    NgxSkeletonLoaderModule,
    AgencyModule
]
})
export class LibraryModule { }
