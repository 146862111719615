import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';

@Component({
  selector: 'app-hostel-form',
  templateUrl: './hostel-form.component.html',
  styleUrl: './hostel-form.component.scss'
})
export class HostelFormComponent implements OnInit {
  addHostelForm: FormGroup;
  hostelsData: any;
  isDataLoading: boolean = true;
  public isSpinnerShown = false;
  private popUP$ = new Subscription;
  private destroy$ = new Subject<void>();
  @ViewChild('addhostel') addhostel!: TemplateRef<any>;
  public __apiCall = inject(ApiCallService);
  public _toastr = inject(ToastrService);
  public _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);


  constructor(public modal: NgbModal, private fb: FormBuilder, private cdr: ChangeDetectorRef,private popUp: DataShareService) {
    this.addHostelForm = this.fb.group({
      hostel_name: ["", Validators.required],
      facilites: [""],
      site_link: ["", [Validators.required, Validators.pattern('https?://.+')]],
      hostel_address: ["", Validators.required],
      hostel_addressTitle: ["", Validators.required],
      hostel_addressLink: ["", [Validators.required, Validators.pattern('https://maps.google.com/maps.+')]],

      email_address: ["", [
        Validators.required,
        Validators.email,
        Validators.pattern(
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
        ),
      ]],
      description: ["", [Validators.required, Validators.minLength(400)]],
      phone: ["", [Validators.required,
      Validators.pattern("^((\\+91-?)|0)?[0-9]{10,18}$"),
      Validators.minLength(10),
      Validators.maxLength(18),]],
      video_link: [""],
      opening_time: ["", Validators.required],
      closing_time: ["", Validators.required],
      number_of_seats: ["", [
        Validators.required,
        Validators.min(1),
        Validators.pattern("^[0-9]*$")
      ]],
      rent_per_seat: ["", [
        Validators.required,
        Validators.min(1),
        Validators.pattern("^[0-9]*$")
      ]]
    }, { validators: this.validateOpeningClosingTime });



    this.popUP$ = this.popUp.currentPopup$.subscribe((popUpName) => {
      if (popUpName == 'hostel') {
        if (this.addhostel) {
          this.modal.open(this.addhostel, { centered: true, size: 'xl' });
        } else {
          setTimeout(() => {
            if (this.addhostel) {
              this.modal.open(this.addhostel, { centered: true, size: 'xl' });
            }
          }, 100);
        }
        this.addHostelForm.reset();
        this.FacilitesTags = [];
        this.HostelType = "";
        this.City = "";
        this.Country = "";
        this.State = "";
        this.hostelTypeError = false;
        this.countryTypeError = false;
        this.stateError = false;
        this.cityError = false;
        this.imageSizeError = false;
        this.updateCharCount();
      }
    })


  }
  ngOnInit() {
    this.getCountry();
  }




  ngOnDestroy(): void {
    if (this.popUP$) {
      this.popUP$.unsubscribe();
    }
  }
  charCount: number = 0;
  updateCharCount(): void {
    let description = this.addHostelForm.get("description")?.value;
    this.charCount = description?.length || 0;
  }
  updateCheckedValues(feature: any) {
    feature.checked = !feature.checked;
  }
  validateOpeningClosingTime(formGroup: FormGroup) {
    const openingTime = formGroup.get('opening_time')?.value;
    const closingTime = formGroup.get('closing_time')?.value;
    if (openingTime && closingTime && openingTime >= closingTime) {
      return { invalidTimeRange: true };
    }
    return null;
  }
  files: File[] = [];
  images: { imageBase64: string }[] = [];

  onSelect(event: NgxDropzoneChangeEvent) {
    const newFiles = event.addedFiles;
    this.files.push(...newFiles);

    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const sizeInKB = Math.round(file.size / 1024);
        const istoDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!istoDel) {
          const base64String = e.target.result as string;
          this.images.push({ imageBase64: base64String });
        }
      };
      reader.readAsDataURL(file);
    });

  }

  onRemove(event: File) {
    const index = this.files.indexOf(event);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.images.splice(index, 1);
    }
  }
  imageSizeError: boolean = false
  checkImgeSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.imageSizeError = true;
      this.onRemove(file)
      return true;

    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }


  /////////////////////////////////////////////////////
  //////////// image update case //////////////////////
  //////////////////////////////////////////////////



  


  HostelType: string = "";
  HostelTypeList = [
    { id: 1, value: "Boys Hostel" },
    { id: 2, value: "Girls Hostel" },

  ];
  onHostelSelected(option: any) {
    this.HostelType = option.value;
  }


  ////////////////////////////////////////////////////
  /////////  Country drop down  //////////
  ///////////////////////////////////////////////////// 
  CountrysearchTerm: string = "";
  Country: string = "";
  State: string = '';
  City: string = '';
  CountryList = []
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }

  getCountry() {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetCountryDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      })

  }
  hostelTypeError: boolean = false;
  countryTypeError: boolean = false;
  stateError: boolean = false;
  cityError: boolean = false;
  ShowError() {
    this.hostelTypeError = this.HostelType == "" ? true : false;
    this.countryTypeError = this.Country == "" ? true : false;
    this.stateError = this.State == "" ? true : false;
    this.cityError = this.City == "" ? true : false;
  }

  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = '';
      this.City = '';
    }
    this.Country = option.value;
    this.getStateList(option.id);

  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue
    if (this.Country === '') {
      this.State = '';
      this.City = ''
    }
  }

  ////////////////////////////////////////////////////
  /////////  State drop down data //////////
  ////////////////////////////////////////////////////

  StatesearchTerm: string = "";

  StateList = []
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }

  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = '';
    }
    this.State = option.value;
    this.getCityList(option.id);

  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue
    if (this.State === '') {
      this.City = ''
    }
  }

  getStateList(countryId: number) {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetStateDropDown?CountryId=' + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }


  ////////////////////////////////////////////////////
  /////////  City drop down data //////////
  ////////////////////////////////////////////////////


  CityList = []
  onCitySelected(option: any): void {
    this.City = option.value;
  }

  getCityList(stateId: number) {
    this.__apiCall.GetCallWithoutToken('DropDown/GetCityDropDown?StateId=' + stateId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      }
    )
  }




  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Language
  //////////////////////////////////////////////






  ///////////////////////////////////////////////////////////
  // Function to add Facilities tag ///////////////////////
  //////////////////////////////////////////////////////////////


  FacilitesTags: any[] = [];

  addFacilitesTags(): void {
    debugger;
    const tagInput = this.addHostelForm?.get("facilites")?.value.trim();
    if (this.FacilitesTags == undefined) {
      this.FacilitesTags = [];
    }
    if (tagInput && !this.FacilitesTags.some((tag) => tag.featureTitle === tagInput)) {
      debugger
      const newTag = { featureTitle: tagInput };
      this.FacilitesTags.push(newTag);
      this.addHostelForm.get("facilites")?.setValue("");
    }
  }

  removeFacilitesTags(tag: any): void {
    this.FacilitesTags = this.FacilitesTags.filter((t) => t !== tag);
  }

  onEnterFacilites(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addFacilitesTags();
    }
  }


  ///////////////////////////////////////////////////////////
  // Function to add Facilities tag ///////////////////////
  //////////////////////////////////////////////////////////////


  checkTagsValidity(): boolean {

    if (this.FacilitesTags?.length < 1) {
      return true;
    }
    return false;
  }





  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////


  removeUmwantedControl() {

    this.addHostelForm.removeControl('facilites');
    this.addHostelForm.updateValueAndValidity();
  }
  addControls() {
    if (!this.addHostelForm.contains('facilites')) {
      this.addHostelForm.addControl('facilites', new FormControl("", Validators.required));
    }
    this.addHostelForm.updateValueAndValidity();
  }


  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////




  ////////////////////////////////////
  // API CALL AND VALIDATIONS CHECK
  ////////////////////////////////////


  vediolink: any;
  public async onSubmit(): Promise<void> {
    debugger;
    // const payload = this.createApiPayload();
    this.addHostelForm.markAllAsTouched();
    const tagsValidity = this.checkTagsValidity();
    this.ShowError();
    this.removeUmwantedControl();

    if (
      this.Country === "" ||
      this.HostelType === "" ||
      this.City === "" ||
      this.State === "" ||
      this.images.length < 1 ||
      tagsValidity ||
      this.addHostelForm.invalid
    ) {
      const errorMessage = this.getFirstError();
      if (errorMessage) {
        this._toastr.error(errorMessage);
        this.addControls();
        return;
      }
      this.addControls();
    }
    else {
      this.isSpinnerShown = true;
      this._spinner.show();
      try {
        debugger
        const payload = this.createApiPayload();
        const response = await this.__apiCall
          .PostCallWithToken(payload, "Dashboard/SaveHostel").pipe()
          .toPromise();
        // .subscribe((response: any) => {
        // this.addControls();
        if (response.responseCode == 200) {
          this.isSpinnerShown = false;
          this._spinner.hide();
          this._toastr.success(response.responseMessage);
          this.modal.dismissAll();
          // this.getHostels();
        } else {
          this.errorHandlingService.handleResponseError(response);

        }
      } catch (error) {
        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
      } finally {
        this.isSpinnerShown = false;
        this._spinner.hide();
        this.addControls();
        this.cdr.detectChanges();
      }
      // });
    }

  }


  private getFirstError(): string {
    for (const key of Object.keys(this.addHostelForm.controls)) {
      const control = this.addHostelForm.get(key);
      if (control && control.invalid) {
        return `${this.getFriendlyFieldName(key)} is invalid.`;
      }
    }
  
    if (this.Country === "") return "Country is required.";
    if (this.HostelType === "") return "Hostel Type is required.";
    if (this.City === "") return "City is required.";
    if (this.State === "") return "State is required.";
    if (this.images.length < 1) return "At least one image is required.";
    if (this.checkTagsValidity()) return "Tags are invalid.";
  
    const description = this.addHostelForm.get('description')?.value;
    if ((description?.length ?? 0) < 400)
      return "Description must be at least 400 characters long.";
  
    if (!this.validateOpeningClosingTime(this.addHostelForm))
      return "Opening time must be before closing time.";
  
    return "";
  }
  
  private getFriendlyFieldName(field: string): string {
    const fieldNames: { [key: string]: string } = {
      hostel_name: "Hostel Name",
      facilites: "Facilities",
      site_link: "Site Link",
      hostel_address: "Hostel Address",
      hostel_addressTitle: "Hostel Address Title",
      hostel_addressLink: "Hostel Address Link",
      email_address: "Email Address",
      description: "Description",
      phone: "Phone Number",
      video_link: "Video Link",
      opening_time: "Opening Time",
      closing_time: "Closing Time",
      number_of_seats: "Number of Seats",
      rent_per_seat: "Rent Per Seat",
      Country: "Country",
      HostelType: "Hostel Type",
      City: "City",
      State: "State",
      images: "Images",
      tags: "Tags",
    };
    return fieldNames[field] || field;    
  }
  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////


  createApiPayload() {
    const payload: any = {
      name: this.addHostelForm.get("hostel_name")?.value,
      description: this.addHostelForm.get("description")?.value,
      type: this.HostelType,
      phoneNumber: this.addHostelForm.get("phone")?.value,
      siteLink: this.addHostelForm.get("site_link")?.value,
      email: this.addHostelForm.get("email_address")?.value,
      country: this.Country,
      state: this.State,
      address: this.addHostelForm.get("hostel_address")?.value,
      locationName: this.addHostelForm.get("hostel_addressTitle")?.value,
      locationUrl: this.addHostelForm.get("hostel_addressLink")?.value,
      openingTime: this.addHostelForm.get("opening_time")?.value,
      closingTime: this.addHostelForm.get("closing_time")?.value,
      noOfSeats: this.addHostelForm.get("number_of_seats")?.value,
      seats: this.addHostelForm.get("rent_per_seat")?.value,
      city: this.City,
      images: this.images,
      features : this.FacilitesTags


    }

    return payload;

  }

  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////




}
