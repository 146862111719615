import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookCategoriesComponent } from './book-categories/book-categories.component';
import { BooksListComponent } from './books-list/books-list.component';

const routes: Routes = [
  {
    path: 'book-categories',
    component: BookCategoriesComponent
  },
  {
    path: 'all-books',
    component: BooksListComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LibraryRoutingModule { }
