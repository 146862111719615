<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>

<div class="mainContainer">
  <div class="headers">
    <h6>Languages</h6>
    <div></div>
    <div class="addHeadings" (click)="addCard(addlanguage)">
      <app-feather-icons class="color" [icon]="'plus'"></app-feather-icons>
      <span> Language </span>
    </div>
  </div>
  @defer {
  <div class="tagsContainer">
    @if(isLoading){
    <div class="item">
      <ngx-skeleton-loader count="6" [theme]="{
          height: '40px',
          width: '150px',
          'border-radius': '10px',
          'margin-right': '1rem'
        }" />
    </div>
    } @else if(languageDetails?.length > 0){ @for(language of
    languageDetails;track language){
    <div class="tag">
      <div class="tagContent">
        {{ language?.language + " - " + language?.languageLevel }}
        <span class="icons">
          <i class="fas fa-edit edit-icon" (click)="editCard(addlanguage, language)"></i>
          <i class="fas fa-trash delete-icon" (click)="deleteCard(deleteModal, language?.id)"></i>
        </span>
      </div>
    </div>
    } }
  </div>
  }
</div>

<ng-template #addlanguage>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        @if(FormName == "Add"){ Add Language }@else{ Update Language }
      </h5>
      <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="row gx-2 gx-sm-3">
        <div class="form-group col-sm-6">
          <label for="experience">Languages<span class="text-danger">*</span></label>
          <app-common-dropdowns class="first-dropdown" [dataList]="languageList" [defaultText]="'Selct Language*'"
            (optionSelected)="onlanguageSelected($event)" [selectedOption]="language" [isDisabled]="false"
            [clearThevalue]="language">
          </app-common-dropdowns>
          @if(language == "" && languageError){
          <small class="text-danger">Langugae is Required</small>
          }
        </div>
        <div class="form-group col-sm-6">
          <label for="experience">Language Level<span class="text-danger">*</span></label>
          <app-common-dropdowns class="second-dropdown" [dataList]="languageLevelList"
            [defaultText]="'Select language Level*'" (optionSelected)="onlanguageLevelSelected($event)"
            [selectedOption]="languageLevel" [isDisabled]="true" [clearThevalue]="languageLevel">
          </app-common-dropdowns>
          @if(languageLevel == "" && languageLevelError){
          <small class="text-danger">Language level is Required</small>
          }
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()" aria-label="Close">
        Cancel
      </button>
      <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="savelanguageDetails()"
        aria-label="Close">
        @if(FormName == "Add"){ Add }@else{ Update }
      </button>
    </div>
  </div>
</ng-template>

<ng-template #deleteModal>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text">Are You Sure you want to delete this language ?</div>
      <div class="deleteButtons">
        <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()" aria-label="Close">
          Cancel
        </button>
        <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deletelanguage()" aria-label="Delete">
          Delete
        </button>
      </div>
    </div>
  </div>
</ng-template>