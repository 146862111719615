<app-header-one></app-header-one>
<!-- <app-navbar></app-navbar> -->
<!-- <app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb> -->

<section class="user-dashboard small-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <div class="sidebar-user sticky-cls">
          <div class="user-profile">
            <app-user-info></app-user-info>
          </div>
          <div class="dashboard-list">
            <app-user-panel-side-menu [activeTab]="'create-property'"></app-user-panel-side-menu>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="dashboard-content">
          <div class="create-tab" id="create-property">
            <div class="property-wizard common-card">
              <div class="common-header">
                <h5>Create Profile</h5>
              </div>
              <div class="create-property-form">
                <div class="form-inputs">
                  <app-basic-information></app-basic-information>
                </div>

              </div>
            </div>
          </div>
          <!-- languages  -->
          <div class="create-tab" id="create-property">
            <div class="property-wizard common-card">
              <div class="create-property-form">
                <div class="form-inputs">
                  <app-languages></app-languages>
                </div>
              </div>
            </div>
          </div>
          <!-- subjects  -->
          <div class="create-tab" id="create-property">
            <div class="property-wizard common-card">
              <div class="create-property-form">
                <div class="form-inputs">
                  <app-subject></app-subject>
                </div>
              </div>
            </div>
          </div>
          <!-- skills  -->
          <div class="create-tab" id="create-property">
            <div class="property-wizard common-card">
              <div class="create-property-form">
                <div class="form-inputs">
                  <app-skills></app-skills>
                </div>
              </div>
            </div>
          </div>
          <!-- education  -->
          <div class="create-tab" id="create-property">
            <div class="property-wizard common-card">
              <div class="create-property-form">
                <div class="form-inputs">
                  <app-education></app-education>
                </div>
              </div>
            </div>
          </div>
          <!-- experience  -->
          <div class="create-tab" id="create-property">
            <div class="property-wizard common-card">
              <div class="create-property-form">
                <div class="form-inputs">
                  <app-experience></app-experience>
                </div>
              </div>
            </div>
          </div>

          <!-- awards  -->
          <div class="create-tab" id="create-property">
            <div class="property-wizard common-card">
              <div class="create-property-form">
                <div class="form-inputs">
                  <app-awards></app-awards>
                </div>
              </div>
            </div>
          </div>
          <!-- certificates  -->
          <div class="create-tab" id="create-property">
            <div class="property-wizard common-card">
              <div class="create-property-form">
                <div class="form-inputs">
                  <app-certificate></app-certificate>
                </div>
              </div>
            </div>
          </div>
          <!-- socail links  -->
          <div class="create-tab" id="create-property">
            <div class="property-wizard common-card">
              <div class="create-property-form">
                <div class="form-inputs">
                  <h6>Social Links</h6>
                  <app-social-links></app-social-links>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</section>

<app-adly-footer></app-adly-footer>