import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { MetaService } from 'src/app/shared/services/meta.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-institutedetail',
  templateUrl: './institutedetail.component.html',
  styleUrl: './institutedetail.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstitutedetailComponent {
  @Input() heading = "Related Institutes";
  InstituteID: any;
  institutesData: any;
  public isLoading = true;
  public isRelatedInstituteLoading = true;
  relatedInstituteData: any[] = [];

  private _utils = inject(CommonUtilsServiceService);
  private _apiCall = inject(ApiCallService);
  private _propertyService = inject(PropertyService);
  private readonly _spinner = inject(NgxSpinnerService);

  private readonly metaService: MetaService = inject(MetaService);
  constructor(
    private route: ActivatedRoute
  ) {
    this.InstituteID = this.route.snapshot.queryParamMap.get('instituteid');

  }


  ngOnInit(): void {

    this.InstituteID = this.route.snapshot.queryParamMap.get('instituteid');

    this.route.queryParamMap.subscribe((params) => {
      this.InstituteID = params.get('instituteid');
      if (this.InstituteID) {
        this.getInstituteDetails();
      }
      window.scrollTo(0, 0);
    });

    this.metaService.updateTitle('Institute Details | AdmissionLylo');
    this.metaService.updateMetaTags([
      { name: 'description', content: 'Your page description here' },
      { name: 'keywords', content: 'angular, seo, meta tags' },
      { name: 'author', content: 'Your Name' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.metaService.setCanonicalURL();
    this.metaService.initializeMetaData();

  }


  getInstituteDetails() {
    this._spinner.show();
    this._apiCall.PostCallWithoutToken(null, `Institute/GetInstituteById?InstituteId=${this.InstituteID}`).subscribe(
      (response) => {
        this.isLoading = false;
        this.institutesData = response.data;

        // Setting up the necessary data structure
        this.institutesData.moduleType = "institute";

        // Initialize Blob URL array
        this.institutesData.blobUrls = [];

        // Convert each image in the `images` array to Blob URL
        if (Array.isArray(this.institutesData.images)) {
          this.institutesData.images.forEach((image: { imageBase64: string }) => {
            if (image.imageBase64 && image.imageBase64.includes('base64,')) {
              const base64Data = image.imageBase64.split('base64,')[1];
              const blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
              this.institutesData.blobUrls.push(blobUrl);
              console.log("Institute Detail Blob URL:", blobUrl);
            } else {
              // Fallback for non-base64 images or missing URLs
              this.institutesData.blobUrls.push('path/to/default/image.png');
            }
          });
        }
        this.isLoading = false;
        this._spinner.hide();
        this.getRelatedInstitutes();
      },
      (error) => {
        this.isLoading = false;
        this._spinner.hide();
        console.error('Error fetching institute details:', error);
      }
    );
  }


  // Get Related Institutes
  getRelatedInstitutes() {
    this.isRelatedInstituteLoading = true;
    this._propertyService.getRelatedInstitutes(this.InstituteID).subscribe(
      (response) => {
        this.relatedInstituteData = response;
        this.isRelatedInstituteLoading = false;

        // Convert each related institute's image URL to a Blob URL
        this.relatedInstituteData.forEach((institute) => {
          if (institute && institute.img && institute.img.url && institute.img.url.includes('base64,')) {
            const base64Data = institute.img.url.split('base64,')[1]; // Extract base64 data
            institute.blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            // console.log("Related Institute Blob URL:", institute.blobUrl);
          } else {
            // Set a default image if the image data is not in the expected format
            institute.blobUrl = 'path/to/default/image.png';
            console.warn("Image data is not in the expected base64 format:", institute.img ? institute.img.url : "No img property");
          }
        });

      },
      (error) => {
        this.isRelatedInstituteLoading = false;
        console.error("Error fetching related institutes:", error);
      }
    );
  }



}
