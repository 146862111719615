<div class="row">
  <div class="col-xl-5 col-lg-7">
    <div class="about-content">
      <h3>We are the <br />expert of communication</h3>
      <p class="font-roboto">Decoration is the furnishing of a space with decorative elements, sometimes complemented by advice and practical assistance. sometimes complemented by advice and practical assistance.</p>
    </div>
    <div class="about-listing">
      <ul>
        <li>
          <h4>15,801</h4>
          <p>Total property</p>
        </li>
        <li>
          <h4>5792</h4>
          <p>Agents</p>
        </li>
        <li>
          <h4>3871</h4>
          <p>Agency</p>
        </li>
        <li>
          <h4>4791+</h4>
          <p>Sold out property</p>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-xl-7 map-image col-lg-5 bg-size" [style.background-image]="'url(assets/images/about/map.png)'" style="width: 55%;">
    <img src="assets/images/about/map.png" class="img-fluid bg-img" alt="map-image">
    <div class="marker-icons">
      <ul>
        <li><img class="img-fluid marker-1" src="assets/images/leaflet/marker-icon.png" alt="home-icon"></li>
        <li><img class="img-fluid marker-2" src="assets/images/leaflet/marker-icon.png" alt="home-icon"></li>
        <li><img class="img-fluid marker-3" src="assets/images/leaflet/marker-icon.png" alt="home-icon"></li>
        <li><img class="img-fluid marker-4" src="assets/images/leaflet/marker-icon.png" alt="home-icon"></li>
      </ul>
    </div>
  </div>
</div>
