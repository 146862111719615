<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<form
  class="row gx-2 gx-sm-3"
  [formGroup]="socialLinksForm"
  (ngSubmit)="saveSocialLinks()"
>
  <div class="form-group col-sm-6">
    <label for="facebook">Facebook</label>
    <input
      type="url"
      class="form-control"
      id="facebook"
      formControlName="facebook"
      placeholder="Enter Facebook URL"
    />
    @if(socialLinksForm.get('facebook')?.hasError('pattern') &&
    socialLinksForm.get('facebook')?.touched){
    <small class="text-danger">Invalid Facebook URL.</small>
    }
  </div>

  <div class="form-group col-sm-6">
    <label for="twitter">Twitter</label>
    <input
      type="url"
      class="form-control"
      id="twitter"
      formControlName="twitter"
      placeholder="Enter Twitter URL"
    />
    @if(socialLinksForm.get('twitter')?.hasError('pattern') &&
    socialLinksForm.get('twitter')?.touched){
    <small class="text-danger">Invalid Twitter URL.</small>
    }
  </div>

  <div class="form-group col-sm-6">
    <label for="linkedin">LinkedIn</label>
    <input
      type="url"
      class="form-control"
      id="linkedin"
      formControlName="linkedin"
      placeholder="Enter LinkedIn URL"
    />
    @if(socialLinksForm.get('linkedin')?.hasError('pattern') &&
    socialLinksForm.get('linkedin')?.touched){
    <small class="text-danger">Invalid LinkedIn URL.</small>
    }
  </div>

  <div class="form-group col-sm-6">
    <label for="instagram">Instagram</label>
    <input
      type="url"
      class="form-control"
      id="instagram"
      formControlName="instagram"
      placeholder="Enter Instagram URL"
    />
    @if(socialLinksForm.get('instagram')?.hasError('pattern') &&
    socialLinksForm.get('instagram')?.touched){
    <small class="text-danger">Invalid Instagram URL.</small>
    }
  </div>

  <div class="form-group col-sm-12 text-end">
    <button type="submit" class="btn btn-gradient color-2 btn-pill" aria-label="Save Social Links">
      Save Social Links
    </button>
  </div>
</form>
