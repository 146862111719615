<app-header-one></app-header-one>
<app-breadcrumb [bgImage]="bgImage" [title]="Title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="single-property">
    <div class="container">
        <div class="row ratio_65">
            <div class="col-xl-9 col-lg-8 ">
                <div class="description-section">
                    <div class="description-details">
                        <div class="desc-box" id="cvContainer">
                            <div *ngIf="cvId === 1">
                                @if(userData){
                                <app-cv1 [userData]="userData"></app-cv1>
                                }

                            </div>
                            <div *ngIf="cvId === 2">

                                @if(userData){

                                <app-cv2 [userData]="userData"></app-cv2>
                                }

                            </div>
                            <div *ngIf="cvId === 3">
                                @if(userData){

                                <app-cv3 [userData]="userData"></app-cv3>
                                }

                            </div>
                            <div *ngIf="cvId === 4">
                                @if(userData){

                                <app-cv4 [userData]="userData"></app-cv4>
                                }

                            </div>
                            <div *ngIf="cvId === 5">
                                @if(userData){

                                <app-cv5 [userData]="userData"></app-cv5>
                                }

                            </div>
                            <div *ngIf="cvId === 6">
                                @if(userData){

                                <app-cv6 [userData]="userData"></app-cv6>
                                }

                            </div>
                            <div *ngIf="cvId === 7">
                                @if(userData){

                                <app-cv7 [userData]="userData"></app-cv7>
                                }

                            </div>
                            <div *ngIf="cvId === 8">
                                @if(userData){

                                <app-cv8 [userData]="userData"></app-cv8>
                                }

                            </div>
                            <div>
                                <app-cv9></app-cv9>
                            </div>
                            <div>
                                <app-cv10></app-cv10>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4">
                <div class="left-sidebar sticky-cls single-sidebar">
                    <div class="filter-cards">
                        <div class="advance-card">
                            <h6>Enjoy Your CV</h6>
                            <div class="category-property">
                                <ul>

                                    <li>
                                        <a class="d-flex" (click)="navigateToCvData()">
                                            <app-feather-icons [icon]="'edit'" class="me-2"></app-feather-icons>
                                            Edit CV Data
                                        </a>
                                    </li>
                                    <li>
                                        <a class="d-flex" (click)="navigateToCvTemplates()">
                                            <app-feather-icons [icon]="'file-text'" class="me-2"></app-feather-icons>
                                            Show more templates
                                        </a>
                                    </li>

                                    <li>
                                        <a class="d-flex" (click)="downloadCV()">
                                            <app-feather-icons [icon]="'download'" class="me-2"></app-feather-icons>
                                            Download CV
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-adly-footer></app-adly-footer>