import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JobsRoutingModule } from './jobs-routing.module';
import { JobListComponent } from './job-list/job-list.component';
import { PropertyModule } from '../property/property.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { HomeModule } from '../home/home.module';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { JobsDetailsComponent } from './jobs-details/jobs-details.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [
    JobListComponent,
    JobsDetailsComponent
  ],
  imports: [
    CommonModule,
    JobsRoutingModule,
    PropertyModule,
    SharedModule,
    HomeModule,
    NgbPaginationModule,
    NgxSkeletonLoaderModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
  ]
})
export class JobsModule { }
