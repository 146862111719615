import { ChangeDetectionStrategy, Component } from '@angular/core';
import { homeSectionSlider } from '../../../../shared/interface/property';
import { PropertyService } from '../../../../shared/services/property.service';

@Component({
  selector: 'app-corporate-home-slider',
  templateUrl: './corporate-home-slider.component.html',
  styleUrls: ['./corporate-home-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorporateHomeSliderComponent {

  public title = 'corporate';

  public homeSectionSliderData: homeSectionSlider[] = [];
  

  constructor(private propertyService: PropertyService) { }

  ngOnInit() {
    // this.propertyService.homeSliderData().subscribe(response => {
    //   this.homeSectionSliderData = response.homeSection.filter(item => item.type == this.title)
    // });
    this.showbannerdata()
  }

  showbannerdata() {
    this.homeSectionSliderData = [
      {
        id: 1,
        type: 'basic',
        img: '../../../../../assets/HomePagebanner/bannerbg1.jpg',
        title: 'Navigate the Path',
        subTitle: 'to Higher Education Excellence',
        buttonText : "View More"
      },
      {
        id: 2,
        type: 'basic',
        img: '../../../../../assets/HomePagebanner/bannerbg2.jpg',
        title: 'Building a Future',
        subTitle: 'Through Education',
        buttonText : "View More"
      },
      {
        id: 3,
        type: 'basic',
        img: '../../../../../assets/HomePagebanner/bannerbg3.jpg',
        title: 'Your Path to',
        subTitle: 'Academic Excellence',
        buttonText : "View More"
      },
    ]
  }


}
