<section class="home-section layout-5">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-8 col-md-11">
        <div class="home-main">
          <div class="home-content">
            <div>
              <img
                src="assets/images/signature/2.png"
                class="img-fluid m-0"
                alt="sign"
              />
              <h6>Want to buy or rent Home ?</h6>
              <h1>How {{ typedText }} <span class="typed"></span></h1>
              <div class="row">
                <div class="col-xl-8">
                  <p>
                    Elegant retreat in a quiet Coral Gables setting. This home
                    provides wonderful entertaining spaces with a chef kitchen
                    opening Elegant retreat in a quiet Coral Gables setting.
                  </p>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-lg btn-gradient btn-pill color-2"
                [routerLink]="['/agent/submit-property']"
                aria-label="submit property"
              >
                submit property
              </button>
              <div class="looking-icons">
                <app-looking-for-icons [text]="false"></app-looking-for-icons>>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="decor-image">
    <img
      src="assets/images/shape.png"
      alt="background-image"
      class="img-fluid"
    />
  </div>
</section>
