<div class="category-property">
  <ul>
    <li>
      <a href="javascript:void(0)" (click)="getCategory('apartment')">
        <i class="fas fa-arrow-right me-2"></i>Apartment 
        <span class="float-end">({{ totalApartment.length }})</span>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="getCategory('villa')" aria-label="clickable-text">
        <i class="fas fa-arrow-right me-2"></i>Villa 
        <span class="float-end">({{ totalVilla.length }})</span>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="getCategory('family_house')" aria-label="clickable-text">
        <i class="fas fa-arrow-right me-2"></i>Family House 
        <span class="float-end">({{ totalFamilyHouse.length }})</span>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="getCategory('town_house')" aria-label="clickable-text">
        <i class="fas fa-arrow-right me-2"></i>Town House 
        <span class="float-end">({{ totalTownHouse.length }})</span>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="getCategory('office')" aria-label="clickable-text">
        <i class="fas fa-arrow-right me-2"></i>Offices 
        <span class="float-end">({{ totalOffice.length }})</span>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="getCategory('duplex')" aria-label="clickable-text">
        <i class="fas fa-arrow-right me-2"></i>Duplexes 
        <span class="float-end">({{ totalDuplex.length }})</span>
      </a>
    </li>
  </ul>
</div>
