<div class="row ratio_55 mt-40 advertisement">
    <div class="col-xl-10 ps-2" [ngClass]="{ 'col-xl-12': adImages.length == 0 }">
      <app-title [isLineAdded]="true" [isLabelAdded]="true" [heading]="'Top Hostels'" [tag]="tag" [textWhite]="false"
        [type]="'simple'"></app-title>
  
      <!-- Filter buttons are always visible -->
      <div class="container filter-btns">
        <div class="toggle-btns">
          <input type="radio" id="All" name="toggle" checked (click)="onHostelFilterClick('All')" />
          <label [ngClass]="{'active': selectedHostelType === 'All'}" for="All">All</label>
  
          <input type="radio" id="Girls Hostel" name="toggle" (click)="onHostelFilterClick('Girls Hostel')" />
          <label [ngClass]="{'active': selectedHostelType === 'Girls Hostel'}" for="Girls Hostel">Girls</label>
  
          <input type="radio" id="Boys Hostel" name="toggle" (click)="onHostelFilterClick('Boys Hostel')" />
          <label [ngClass]="{'active': selectedHostelType === 'Boys Hostel'}" for="Boys Hostel">Boys</label>
        </div>
      </div>
  
  
      <!-- Show the slider when there are institutes -->
      @defer {
      <app-slider-filter-latest-rent *ngIf="HostelsData.length > 0" [isDescriptionShown]="isDescriptionShown"
        [propertyClass]="true" [tagClass]="'color-6'" [title]="title" [heading]="'Top Hostels'" [showFilters]="false"
        [institutesData]="HostelsData" [isTopTeachersModule]="false"></app-slider-filter-latest-rent>
      }
  
      <!-- Show "No Institutes Found" message if no institutes are present -->
      <div *ngIf="noRecordsFound" class="no-record">
        <img src="../../../../assets/images/svg/Nodata.gif" alt="No Institutes Found" />
        <p>No Hostels Found</p>
      </div>
      @if(isErrorOccurred){
      <div>
        <div class="no-record error-svg">
          <img src="../../../../../assets/images/svg/error.gif" alt="Error Ocuurred">
          <p>An Error Occurred,Try refreshing Page</p>
        </div>
      </div>
      }
  
      <!-- Show the skeleton loader while data is being fetched -->
      <div *ngIf="isDataLoading && !noRecordsFound" class="item">
        <ngx-skeleton-loader count="8" [theme]="{
            height: '22.5rem',
            width: '23%',
            'border-radius': '10px',
            'margin-right': '0.5rem',
            'margin-left': '0.5rem'
          }"></ngx-skeleton-loader>
      </div>
  
  
  
  
      <div class="all-institute-btn" *ngIf="!isErrorOccurred">
        <app-center-button [buttonText]="'All Hostels'" [route]="'/hostels/all-hostels'"></app-center-button>
      </div>
    </div>
  
    <!-- Advertisement section -->
    <div class=" advertisement ads-section" [ngClass]="{
      'col-lg-2': !isScreenSmall, 
      'hideAdvertsment': adImages.length == 0, 
      'col-lg-12': isScreenSmall 
    }">
      <!-- <app-title [heading]="''" [textWhite]="false" [type]="'simple'"></app-title> -->
      @if(!isAdLoading){
      <div class="main-ads-section" (mouseenter)="pauseScroll()" (mouseleave)="startScroll()">
        <div class="ad-images-wrapper">
          @for (ad of adImages; track ad) { @if (ad?.imageUrl?.length > 20) {
          <a [href]="ad?.redirectLink" target="_blank" aria-label="clickable-text">
            <img [src]="ad?.imageUrl" class="ad-image" alt="Advertisement" />
          </a>
          } }
          @for(ad of adImages;track ad){ @if(ad?.imageUrl?.length > 20){
          <a [href]="ad?.redirectLink" target="_blank" aria-label="clickable-text"><img [src]="ad?.imageUrl"
              class="ad-image" alt="Advertisement" />
          </a>
          } }
        </div>
      </div>
      }
      @else{
      <!-- <ng-template #advertismnets> -->
      <div class="item add-container">
        <ngx-skeleton-loader count="1" [theme]="{
              height: addSectionCss.height,
              width: addSectionCss.width,
              'border-radius': '10px',
              'margin-right': '0.5rem',
              'margin-left': '0.5rem'
            }"></ngx-skeleton-loader>
      </div>
      }
      <!-- </ng-template> -->
  
    </div>
  </div>