<app-loader-two [isLoading]="isDataLoading"></app-loader-two>


<section class="user-dashboard full-screen-section noselect">

  <div class=" col header">
    <img src="../../../../../../assets/logos/Admission lylo.svg" alt="Logo" class="logo" />

    <div class="timer">
      <span>Time Remaining: <b>{{ timeRemaining }}</b> seconds</span>
      <span>Remaining Questions: <b>{{ questionsToShow.length - currentQuestionIndex }}</b> questions</span>
    </div>

  </div>


  <div class="question-header">
    <h3>Multiple Choice Question</h3>
    <p>Complete this test to upgrade your level. Each question must be attempted to see your result.
    </p>
  </div>

  <div class="row video-responsive ">
    <div class="col-10">
      <div class="quiz-container">
        <div *ngIf="currentQuestionIndex < questions.length" class="question-container">
          <h3>Q{{ currentQuestionIndex + 1 }}: <span> {{ questions[currentQuestionIndex].question }}</span></h3>
          @if (isDataLoading) {
          <div>
            <ngx-skeleton-loader count="4" appearance="circle" [theme]="{
             width: '100%',
             height: '3rem',
             'border-radius': '10px'
           }" />
          </div>
          }@else {
          <div *ngFor="let option of questions[currentQuestionIndex].options" class="option">
            <label>
              <input type="radio" [name]="'question' + currentQuestionIndex" [value]="option.id"
                [checked]="questions[currentQuestionIndex]?.selectedOptionId === option.id"
                (change)="selectOption(currentQuestionIndex, option)" />
              <span class="option-text">{{ option.option }}</span>
            </label>
          </div>
          }
          <div class="actions">
            <button (click)="closeTest()" class="btn btn-gradient color-2 btn-pill">
              End Test
            </button>

            <button (click)="currentQuestionIndex < questionsToShow.length - 1 ? nextQuestion() : finishTest()"
              class="btn btn-gradient color-2 btn-pill" [disabled]="!questions[currentQuestionIndex]?.selectedOptionId">
              {{ currentQuestionIndex === questions.length - 1 ? 'Finish' : 'Next' }}
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="col-2">
      <div class="video-preview">
        <video #videoElement autoplay  width="640" height="480"></video>
      </div>
      <div class="canvas-container">
        <canvas id="overlay"></canvas>
      </div>
    </div>
  </div>

</section>

<ng-template #poupModal>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="closeTest()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text">
        Are You ready to start this test for <strong> {{ category + " - " + subCategory }} </strong> ?? <br><br>
        <strong class="redColor">NOTE: </strong>Don't close fullscreen mode. Otherwise your test will be cancelled
      </div>
      <div class="deleteButtons">
        <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="enterFullscreen()">
          Start
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #resultModal>
  <div class="modal-content" id="modal-content">
    <div class="modal-body" id="modal-body">
      <div class="results-summary-container">
        @if(testResult?.isPassed){
        <div class="confetti">
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
        </div>
        }
        <div class="results-summary-container__result">
          <div class="heading-tertiary">Your Result</div>
          <div class="result-box">
            <div class="heading-primary">{{testResult.obtainedMarks}}</div>
            <p class="result">of {{testResult.totalMarks}}</p>
          </div>
          <div class="result-text-box" *ngIf="testResult?.isPassed; else failureMessage">
            <div class="heading-secondary">Congratulations!</div>
            <p class="paragraph">
              Best wishes for your future. Keep working hard.
            </p>
          </div>

          <div class="summary__cta">
            <button class="btn btn-gradient color-2 btn-pill" (click)="closeTest()">Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #failureMessage>
  <div class="result-text-box">
    <div class="heading-secondary">Better luck next time</div>
    <p class="paragraph">
      Work hard until you achieve your goals.
    </p>
  </div>
</ng-template>