<!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="var(--mainColor)" type="ball-spin-clockwise"
  [fullScreen]="true">
  <p style="font: size 20px; color: var(--mainColor)">Loading...</p>
</ngx-spinner> -->
<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>

<app-header-one></app-header-one>
<app-common-banner-1 [imagePath]="bannerImage" [heading1]="'Scholarships'"
  [heading2]="'for Innovation, Education, and Success'" (childEvent)="handleChildEvent($event)"
  (callAPionEmptyFields)="onReset()" [showCountryDropdown]="true" [showStateDropdown]="true" [showCityDropdown]="false"
  [showRangeSlider]="false" [showDegreeDropdown]="false" [showJobTypeDropdown]="false"
  [showScholarshipTypeDropdown]="true" [totalRecords]="totalRecords"
  (sendTheSearchValue)="handleSearchData($event)"
  [popUpButton]="{ text: 'Add Scholarships', isShown: true, componentName: 'scholarships' }"
  ></app-common-banner-1>
<!-- <app-banner></app-banner> -->
<div class="container-fluid">

  <app-grid-panel [heading]="'Scholarships'" [currentPage]="currentPage" [totalRecords]="totalRecords"
    [itemsPerPage]="itemsPerPage"></app-grid-panel>
  @if(isDataLoading){
  <div class="item">
    <ngx-skeleton-loader count="8" [theme]="{
                height: '300px',
                width: '300px',
                'border-radius': '10px',
                'margin-right': '1rem'
                }" />
  </div>
  }@else if(isErrorOccurred){
  <div>
    <div class="no-record">
      <img src="../../../../../../assets/images/svg/error.gif" alt="Error Ocuurred">
      <p>An Error Occurred,Try refreshing Page</p>
    </div>
  </div>
  }@else if (noRecordFound) {
  <div class="no-record">
    <img src="../../../../assets/images/svg/Nodata.gif" alt="No records found">
    <p>No Scholarships Found</p>
  </div>

  }
  @else{

  <app-slider-filter-latest-rent [propertyClass]="true" [tagClass]="'color-6'" [title]="title"
    [heading]="'All Scholarships'" [institutesData]="scholarshipsData"></app-slider-filter-latest-rent>
  }

  <!-- pagination starts here -->
  @if(scholarshipsData.length !== 0){
  <div class="row pagination-container">
    <div class="mt30 d-flex justify-content-center">
      <ngb-pagination [collectionSize]="totalRecords" [(page)]="currentPage" [pageSize]="itemsPerPage"
        (pageChange)="onPageChanged($event)" [maxSize]="maxVisiblePages" [ellipses]="false" [boundaryLinks]="true">
      </ngb-pagination>
    </div>
    <div class="tRecord text-center">
      {{ getDisplayRange() }} of {{ totalRecords }}
    </div>
  </div>
  }

</div>


<!-- pagination ends here -->

<app-adly-footer></app-adly-footer>