<div class="image_section row zoom-gallery-multiple gx-3">
  <div class="col-sm-8">
    <a style="background-image: url('assets/images/parallax/4.jpg')" class="bg-size" aria-label="View property image 4">
      <img [src]="'assets/images/parallax/4.jpg'" class="img-fluid bg-img" alt="Property image showcasing feature 4" style="display: none;">
    </a>    
  </div>
  <div class="col-sm-4">
    <div class="row">
      @for(images of propertyImageData; track images){
        <div class="col-lg-12">
          <div class="img-property">
            <a [style.background-image]="'url(' + images.url + ')'" 
   class="bg-size" 
   [attr.aria-label]="'View property image: ' + images.description">
  <img [src]="images.url" class="img-fluid bg-img" alt="Property image: {{ images.description }}" style="display: none;">
</a>

          </div>
        </div>
      }
    </div>
  </div>
</div>
