<div class="complete-details">
  <div>
    <img
      src="assets/images/inner-pages/4.svg"
      class="img-fluid"
      alt="Thank you SVG"
    />
    <h3>Thank you !!</h3>
    <h6>Congratulations, your property has been submitted</h6>
    <p class="font-roboto">
      Residences can be classified by and how they are connected to neighbouring
      residences and land. Different types of housing tenure can be used for the
      same physical type.
    </p>
    <button
      type="submit"
      class="btn btn-gradient color-2 step-again btn-pill"
      (click)="submit()"
      aria-label="Add new property"
    >
      Add new property
    </button>
  </div>
</div>
