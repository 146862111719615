<!-- @if(instituteData){ -->
<!-- @for(data of instituteData; track data){ -->
<owl-carousel-o [options]="Options">
  @if(instituteData?.blobUrls?.length){
  <ng-container>
    @for(blobUrl of instituteData.blobUrls; track blobUrl){
    <ng-template carouselSlide>
      <div>
        <div class="bg-size" [style.background-image]="'url(' + blobUrl + ')'">
          <img [src]="blobUrl" class="bg-img" alt="property-image" style="display: none;">
        </div>
      </div>
    </ng-template>
    }
  </ng-container>
  }
</owl-carousel-o>

<!-- } -->
<!-- } -->

