import { ChangeDetectionStrategy, Component, inject, Input, TemplateRef } from '@angular/core';
import { Menu, NavService } from '../../services/nav.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { DataShareService } from '../../services/data-share.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {

  @Input() icon: boolean | undefined;
  @Input() menuRight: boolean = false;
  @Input() userAccount: boolean = false;
  @Input() userDetails: any;

  @Input() public menuItems: Menu[] = [];
  public isOpenMenu: boolean = false;
  public open: boolean = false;
  public home: "";



  private _modal = inject(DataShareService);
  private modalService = inject(NgbModal);
  private _authService = inject(AuthService);

  constructor(public navServices: NavService,public router: Router) {
    this.navServices.items.subscribe(
      (menuItems) => (this.menuItems = menuItems)
    );
    // console.log("menu", this.menuItems)
  }

  isActive(path: any): boolean {
     return this.router.url === path || (path === '' && this.router.url === '/');
  }

  

  openSideMenu() {
    this.isOpenMenu = true;
  }

  closeSideMenu() {
    this.isOpenMenu = false;
  }

  openMenu(item: Menu) {
    if (!item.active) {
      this.menuItems.forEach((a: Menu) => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) {
          return false;
        }
        a.children.forEach((b: Menu) => {
          if (b.section) {
            b.section.forEach((child) => {
              if (b.section?.includes(item)) {
                child.active = false;
              }

            })
          }
          if (a.children?.includes(item)) {
            b.active = false;
          }
          if (b.children) {
            b.children?.forEach((c: Menu) => {
              if (b.children?.includes(item)) {
                c.active = false;
              }
            });
          }
        });
        return;
      });
    }
    item.active = !item.active;
  }
  languageOpen: boolean = false;
  openLanguage() {
    this.languageOpen = !this.languageOpen;
  }
  clickOutside(): void {
    this.languageOpen = false
  }


  openBackDropCustomClass(content: TemplateRef<any>) {

    this._modal.openSignInModal(content);
  }

  onUserLoggedIn() {
    this.modalService.dismissAll();
  }


  logoutUser(): void {
    this._authService.logout();
    this.userAccount = false;
    // this.checkUserStatus();
  }

  closePopUp: boolean = false;
  openLogOutpOpUP() {
    this.closePopUp = !this.closePopUp
  }


}
