import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrl: './certificate.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CertificateComponent  implements OnInit, OnDestroy {
  // private destroy$ = new Subject<void>();
  certificateForm: FormGroup;
  public FormName: string = "Add";
  isLoading : boolean = true;
  public isSpinnerShown = false;
  certificatedDetails: any = [
    // {
    //   title: "Frontend Developer",
    //   institute: "ATG SYSTEM",
    //   year: "2022",
    // },
    // {
    //   title: "Backend Developer",
    //   institute: "ATG SYSTEM",
    //   year: "2022",
    // }
  ];
  delId: number;
  editId: number = 0;

  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  constructor(private fb: FormBuilder, public modal: NgbModal,private cdr: ChangeDetectorRef) {
    this.certificateForm = this.fb.group({
      title: ['', Validators.required],
      institute: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],

    });
  }

  ngOnInit(): void {
    this.getCertificatesDetails();
  }
  ngOnDestroy(): void {
    // this.destroy$.next();
    // this.destroy$.complete();
  }


  addCard(content: TemplateRef<any>) {
    this.certificateForm.reset();
    this.isPresent = false;
    if(!this.isPresent){
      this.certificateForm.get('endDate')?.enable();
    }
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
  }
  editCard(content: TemplateRef<any>, tag: any) {
    this.certificateForm.reset();
    this.isPresent = false;
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.editId = tag?.id;
    this.certificateForm.get("title")?.setValue(tag?.title);
    this.certificateForm.get("institute")?.setValue(tag?.institute);
    this.certificateForm.get("startDate")?.setValue(tag?.startDate?.substring(0, 10));
    this.certificateForm.get("endDate")?.setValue(tag?.endDate?.substring(0, 10));
    // this.year = tag?.experience;
    this.isPresent = tag?.isPresent;
    if(this.isPresent){
      this.certificateForm.get('endDate')?.disable();
    }else{
      this.certificateForm.get('endDate')?.enable();
    }


  }
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }


  checkCertificateExsists(): boolean {
    const payload = this.createApiPayload();
    if (this.certificatedDetails?.length > 0) {
      if (this.FormName == "Add") {
        const isExsist = this.certificatedDetails.some((tag: any) => tag.title == payload.title);
        if (isExsist) {
          this._toaster.error("This Certificate Already Exist");
          return true;
        }
      } else {
        let newCertificates = this.certificatedDetails;
        newCertificates = newCertificates.filter((item: any) => item.id != this.editId);
        const isExsist = newCertificates.some((tag: any) => tag.title == payload.title && tag.id != this.editId);
        if (isExsist) {
          this._toaster.error("This Certificate Already Exist");
          return true;
        }
      }

      return false;
    }
    return false;
  }

  public saveCertificateDetails(): void {
    this.certificateForm.markAllAsTouched();
    const isAlreadyExsists = this.checkCertificateExsists();
    if (!isAlreadyExsists) {
        const payload = this.createApiPayload();
        if (this.certificateForm.invalid) {
            this._toaster.error("Invalid Form");
        } else {
            this.isSpinnerShown = true;
            this._apiCall.PostCallWithToken(payload, 'Teacher/SaveTeacherCertificate')
                .subscribe(
                    (response) => {
                        if (response.responseCode === 200) {
                            this._toaster.success(response.responseMessage);
                            this.certificateForm.reset();
                            this.modal.dismissAll();
                            this.getCertificatesDetails();
                            this.editId = 0;
                        } else {
                            this.errorHandlingService.handleResponseError(response);
                        }
                        this.isSpinnerShown = false;
                    },
                    (error) => {
                        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
                        this.isSpinnerShown = false;
                    }
                );
        }
    }
}

  createApiPayload() {
    const date = new Date().toJSON();
    this.editId = this.FormName == "Add" ? 0 : this.editId
    return {
      id: this.editId,
      title: this.certificateForm.get('title')?.value,
      institute: this.certificateForm.get('institute')?.value,
      startDate: this.certificateForm.get('startDate')?.value || date,
      endDate: this.certificateForm.get('endDate')?.value || date,
      // experience: this.year,
      IsPresent : this.isPresent
    };
  }


  public deleteCertificate(): void {
    this.isSpinnerShown = true;
    this._apiCall.PostCallWithToken(null, `Teacher/DeleteTeacherCertificate?CertificateId=${this.delId}`)
        .subscribe(
            (res) => {
                if (res.responseCode === 200) {
                    this._toaster.success(res.responseMessage);
                    this.getCertificatesDetails();
                    this.modal.dismissAll();
                } else {
                    this.errorHandlingService.handleResponseError(res);
                }
                this.isSpinnerShown = false;
            },
            (error) => {
                this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
                this.isSpinnerShown = false;
            }
        );
}

  // FUNCTION TO GET Experience DETAILS

  public getCertificatesDetails(): void {
    this.isLoading = true;
    this._apiCall.GetCallWithToken("Teacher/GetTeacherCertificate")
        .subscribe(
            (response) => {
                if (response.responseCode === 200) {
                    this.certificatedDetails = response.data;
                } else {
                    this.certificatedDetails = [];
                    this.errorHandlingService.handleResponseError(response);
                }
                this.isLoading = false;
            },
            (error) => {
                this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
                this.isLoading = false;
            }
        );
}


  /////////////////////////////////////////////////
  /////////////errors of dropdowns //////////////////
  ///////////////////////////////////////////////////
  // yearError: boolean = false;


  // ShowError() {
  //   this.yearError = this.year == "" ? true : false;

  // }


  ////////////////////////////////////////////////////
  /////////  Year drop down data //////////
  ///////////////////////////////////////////////////// 


  // year: string = '';
  // YearList = [
  //   { value: '6 months+' },
  //   { value: '1 Year+' },
  //   { value: '2 Year+' },
  //   { value: '3 Year+' },
  //   { value: '4 Year+' },
  //   { value: '5 Year+' },

  // ]

  // onYearSelected(option: any) {
  //   this.year = option.value;

  // }

  getMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }

  minmumDate: string;

  getMinDate() {
    const startDate = this.certificateForm.get('startDate')?.value;
    this.certificateForm.get('endDate')?.reset();
    this.minmumDate = startDate;
  }




  // PRESENT BUTTON LOGISTICS
  isPresent: boolean = false;
  getCheckboxValue() {
    console.log(this.isPresent);
    if (this.isPresent) {
      this.certificateForm.get('endDate')?.disable();
    } else {
      this.certificateForm.get('endDate')?.enable();
    }
  }

}
