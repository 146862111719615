import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxsModule } from '@ngxs/store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AppComponent } from "./app.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule, provideHttpClient, withInterceptors } from "@angular/common/http";
import { wishlistState } from "./shared/store/states/wishlist.state";
import { categoryState } from "./shared/store/states/category.state";
import { compareState } from "./shared/store/states/compare.state";
import { propertyState } from "./shared/store/states/property-detail.state";
import { imageState } from "./shared/store/states/property-images.state";
import { CarouselModule } from "ngx-owl-carousel-o";
import { InstitutesModule } from "./components/institutes/institutes.module";
import { TeachearModuleModule } from './components/teachears/teachear-module.module';
import { SharedModule } from "./shared/shared.module";
import { authInterceptor } from './shared/interceptors/auth.interceptor';
import { AutenticationModule } from './components/authentication/autentication.module';
import { AdmissionsModule } from './components/admissions/admissions.module';
import { JobsModule } from './components/jobs/jobs.module';
import { ScholarshipsModule } from './components/scholarships/scholarships.module';
import { LibraryModule } from './components/library/library.module';
import { TutorialsModule } from './components/tutorials/tutorials.module';
import { HostelsModule } from './components/hostels/hostels.module';
import { UserPanelModule } from './components/pages/user-panel/user-panel.module';
import { CvBuilderModule } from './components/cv-builder/cv-builder.module';
import { QuillModule } from 'ngx-quill'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      tapToDismiss: true,
      closeButton: true,
      preventDuplicates: true,
      newestOnTop: true,
      progressBar: true
    }),
    CarouselModule,
    InstitutesModule,
    AdmissionsModule,
    JobsModule,
    ScholarshipsModule,
    LibraryModule,
    CvBuilderModule,
    TeachearModuleModule,
    TutorialsModule,
    HostelsModule,
    AutenticationModule,
    UserPanelModule,   
    QuillModule.forRoot(),
    NgxsModule.forRoot([
        wishlistState,
        categoryState,
        compareState,
        imageState,
        propertyState,
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
        key: ["wishlist", "compare"],
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
        },
    }),
    
    
    
],
  providers: [provideHttpClient(
    withInterceptors([authInterceptor])
  )
],
  exports: [HttpClientModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
  