<section class="error-section small-section">
  <div class="container">
    <div class="row">
      <div class="col text-center not-found">
        <img src="assets/images/inner-pages/2.svg" class="img-fluid" alt="404-image">
        <h2>Whoops! something went wrong ?</h2>
        <p class="font-roboto">we are sorry but the page you are looking for doesn't exist or has been removed. please check or search again.</p>
        <div class="btns">
          <a [routerLink]="['/theme/classic']" aria-label="clickable-text" class="btn btn-gradient color-4">go to home page</a>
          <a href="javascript:void(0)" aria-label="clickable-text" class="btn btn-dashed color-4 ms-2">Report problem</a>
        </div>
      </div>
    </div>
  </div>
</section>
