<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child" [type]="'effect'"></app-breadcrumb>

<section class="about-main">
  <div class="container">
    <div class="row">
      <div class="col">
        <app-title [titleClass]="'title-2'" [type]="'basic'" [heading]="'About Us'" [desc]="aboutDesc" [descClass]="'font-roboto'"></app-title>
        <div class="user-about">
          <app-about-us2-details></app-about-us2-details>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-section service-2 bg-light">
  <div class="container">
    <div class="row">
      <div class="col">
        <app-title [titleClass]="'title-2'" [type]="'basic'" [heading]="'Provided Services'" [desc]="providedServiceDesc" [descClass]="'font-roboto'"></app-title>
        <div class="row property-service column-space about-service">
          @for(data of providedServices; track data){
            <div class="col-xl-4 col-md-6">
              <app-provided-services [providedServices]="data" [type]="'simple'" [tagClass]="tagClass"></app-provided-services>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</section>

<div class="agents-slider">
  <!-- <app-slider-filter-agents [tagClass]="'color-1'" [title]="title" [sectionClass]="'ratio_square'"></app-slider-filter-agents> -->
</div>

<section class="about-section slick-between about-inner ratio_square">
  <div class="container">
    <div class="row">
      <div class="col">
        <app-title [titleClass]="'title-2'" [type]="'basic'" [heading]="'Meet our Agent'" [desc]="agentsDesc" [descClass]="'font-roboto'"></app-title>
        <div class="about-1 about-wrap arrow-white">
          <app-agents [agentsData]="agentsData" [type]="'simple'" [tagClass]="tagClass"></app-agents>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="bg-light about-people testimonial-style-1">
  <div class="container">
    <div class="row">
      <div class="col">
        <app-title [titleClass]="'title-2'" [type]="'basic'" [heading]="'What People Say'" [desc]="peopleSayDesc" [descClass]="'font-roboto'"></app-title>
        <div class="slick-between">
          <div class="testimonial-1 dot-gradient">
            <app-people-say [peopleSayData]="peopleSayData"></app-people-say>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="ratio2_1 blog-inner">
  <div class="container">
    <div class="row">
      <div class="col">
        <app-title [titleClass]="'title-2'" [type]="'basic'" [heading]="'Latest Blog'" [desc]="blogDesc" [descClass]="'font-roboto'"></app-title>
        <div class="blog-2 blog-grid">
          <app-latest-blog [latestBlogData]="latestBlogData" [type]="'simple'"></app-latest-blog>
        </div>
      </div>
    </div>
  </div>
</section>

