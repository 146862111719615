import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-library-form',
  templateUrl: './library-form.component.html',
  styleUrl: './library-form.component.scss'
})
export class LibraryFormComponent {
  @ViewChild('AddOrEditModal') addBooks!: TemplateRef<any>;

  private popUP$ = new Subscription;
  addBookForm: FormGroup;
  booksData: any;
  public isSpinnerShown = false;
  public fileBase64: any = null;
  public imageBase64: any = null;
  private destroy$ = new Subject<void>();

  public _apiCall = inject(ApiCallService);
  public _toastr = inject(ToastrService);
  private errorHandlingService = inject(ErrorHandlingService);


  constructor(public modal: NgbModal, private fb: FormBuilder, private sanitizer: DomSanitizer, private popUp: DataShareService) {
    this.addBookForm = this.fb.group({
      book_title: ["", Validators.required],
      book_edition: ["", Validators.required],
      publisher: ["", Validators.required],
      publish_date: ["", Validators.required],
      ISBN_Number: ["", Validators.required],
      authors_name: ["", Validators.required],
      keywords: ["", Validators.required],
      description: ["", [Validators.required,]],


    });


    this.popUP$ = this.popUp.currentPopup$.subscribe((popUpName) => {
      if (popUpName == 'books') {
        if (this.addBooks) {
          this.modal.open(this.addBooks, { centered: true, size: 'xl' });
        } else {
          setTimeout(() => {
            if (this.addBooks) {
              this.modal.open(this.addBooks, { centered: true, size: 'xl' });
            }
          }, 100);
        }
        this.addBookForm.reset();
        this.Language = "";
        this.bookCategory = "";
        this.authorTags = [];
        this.keywordTags = [];
        this.coverImageFile = null;
        this.pdfFile = null;
        this.coverImages = [];
        this.coverImageBase64 = null;
        this.pdfFileBase64 = null;
        this.languageError = false;
        this.categoryError = false;

      }
    })


  }

  ngOnInit() {
    this.getCategoryDropDown();
    this.getLaguageDropdown();
  }

  ngOnDestroy() {
    if (this.popUP$) {
      this.popUP$.unsubscribe();
    }
  }



  charCount: number = 0;
  updateCharCount(): void {
    let description = this.addBookForm.get("description")?.value;
    this.charCount = description?.length || 0;
  }



  checkTagsValidity(): boolean {

    if (this.authorTags.length < 1 || this.keywordTags.length < 1) {
      return true;
    }

    return false;
  }

  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////


  removeUmwantedControl() {

    this.addBookForm.removeControl('authors_name');
    this.addBookForm.removeControl('keywords');
    this.addBookForm.updateValueAndValidity();
  }
  addControls() {


    if (!this.addBookForm.contains('authors_name')) {
      this.addBookForm.addControl('authors_name', new FormControl("", Validators.required));
    }
    if (!this.addBookForm.contains('keywords')) {
      this.addBookForm.addControl('keywords', new FormControl("", Validators.required));
    }



    this.addBookForm.updateValueAndValidity();
  }




  ////////////////////////////////////
  // API CALL AND VALIDATIONS CHECK
  ////////////////////////////////////


  onSubmit() {
    this.addBookForm.markAllAsTouched();
    const tagsValidity = this.checkTagsValidity();
    this.ShowError();

    this.removeUmwantedControl();

    const description = this.addBookForm.get("description")?.value;

    if (
      this.Language === "" ||
      this.bookCategory === "" ||
      this.coverImageBase64 == null ||
      this.pdfFileBase64 == null ||
      description?.length < 400 ||
      tagsValidity ||
      this.addBookForm.invalid
    ) {
      const errorMessage = this.getFirstError();
      if (errorMessage) {
        this._toastr.error(errorMessage);
        this.addControls();
        return;
      }
      this.addControls();
    }
    else {
      this.isSpinnerShown = true;
      const payload = this.createApiPayload();
      console.log("Book Data:", payload)
      this._apiCall
        .PostCallWithToken(payload, "Dashboard/SaveBook")
        .subscribe((response: any) => {
          this.addControls();
          if (response.responseCode == 200) {
            this.isSpinnerShown = false;
            this._toastr.success(response.responseMessage);
            this.modal.dismissAll();
          } else {
            this._toastr.error(response.responseMessage);
            this.isSpinnerShown = false;
          }
        });
    }

  }


  private getFirstError(): string {
    for (const key of Object.keys(this.addBookForm.controls)) {
      const control = this.addBookForm.get(key);
      if (control && control.invalid) {
        return `${this.getFriendlyFieldName(key)} is required or invalid.`;
      }
    }
  
    if (this.Language === "") return "Language is required.";
    if (this.bookCategory === "") return "Book Category is required.";
    if (this.coverImageBase64 == null) return "Cover Image is required.";
    if (this.pdfFileBase64 == null) return "PDF File is required.";
    
    const description = this.addBookForm.get('description')?.value;
    if ((description?.length ?? 0) < 400)
      return "Description must be at least 400 characters long.";
  
    if (this.checkTagsValidity()) return "Tags are invalid.";
  
    return "";
  }

  private getFriendlyFieldName(field: string): string {
    const fieldNames: { [key: string]: string } = {
      book_title: "Book Title",
      book_edition: "Book Edition",
      publisher: "Publisher",
      publish_date: "Publish Date",
      ISBN_Number: "ISBN Number",
      authors_name: "Author(s) Name",
      keywords: "Keywords",
      description: "Description",
      Language: "Language",
      bookCategory: "Book Category",
      coverImageBase64: "Cover Image",
      pdfFileBase64: "PDF File",
      tags: "Tags",
    };

    return fieldNames[field] || field;
    
  }
  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////


  createApiPayload() {
    const payload: any = {
      // id: this.updateInstituteId,

      title: this.addBookForm.get("book_title")?.value,
      edition: this.addBookForm.get("book_edition")?.value,
      publisherName: this.addBookForm.get("publisher")?.value,
      isbNumber: this.addBookForm.get("ISBN_Number")?.value,
      publishedDate: this.addBookForm.get("publish_date")?.value,
      bookDescription: this.addBookForm.get("description")?.value,
      language: this.Language,
      category: this.bookCategory,
      bookAuthorVMs: this.authorTags,
      bookKeyWordVMs: this.keywordTags,
      fileBase64: this.pdfFileBase64,
      imageBase64: this.coverImageBase64,

    }


    return payload;

  }




  private base64toUrl(bookFile: string): string {
    const byteCharacters = atob(bookFile.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    return window.URL.createObjectURL(blob);
  }


  getMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }


  ////////////////////////////////////////////
  // code for  DropDown for Language
  //////////////////////////////////////////////

  languageList = [];
  languageType: string = "";
  Language: string = "";
  onLanguageSelected(option: any) {
    this.Language = option.value;

  }
  onLanguageValueChange(searchTerm: string) {

    this.Language = searchTerm;
  }

  languageError: boolean = false;
  categoryError: boolean = false;
  ShowError() {
    this.languageError = this.Language == "" ? true : false;
    this.categoryError = this.Language == "" ? true : false;
  }

  getLaguageDropdown() {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetLanguageDropDown")
      .subscribe((response) => {
        this.languageList = response?.data;
      });
  }


  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Book category
  //////////////////////////////////////////////

  bookCategory: string = ""
  categorySearchTerm: string = '';
  CategoryList = []
  onCategorySelected(option: any) {
    this.bookCategory = option.value

  }
  SearchTermCategory(searchTerm: any) {
    this.categorySearchTerm = searchTerm;
  }

  getCategoryDropDown() {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetBookDropDown")
      .subscribe((response) => {
        this.CategoryList = response?.data;
      });
  }

  ///////////////////////////////////////////////////////////
  // Function to add Author tag ///////////////////////
  //////////////////////////////////////////////////////////////


  authorTags: any[] = [];

  addAuthorTags(): void {
    const tagInput = this.addBookForm.get("authors_name")?.value.trim();

    if (tagInput && !this.authorTags.some((tag) => tag.authorName === tagInput)) {
      const newTag = { author: tagInput };
      this.authorTags.push(newTag);
      this.addBookForm.get("authors_name")?.setValue("");
    }
  }

  removeAuthorTags(tag: any): void {
    this.authorTags = this.authorTags.filter((t) => t !== tag);
  }

  onEnterAuthor(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addAuthorTags();
    }
  }

  ///////////////////////////////////////////////////////////
  // Function to add Keywords ///////////////////////
  //////////////////////////////////////////////////////////////


  keywordTags: any[] = [];

  addKeywordsTags(): void {
    const tagInput = this.addBookForm.get("keywords")?.value.trim();

    if (tagInput && !this.keywordTags.some((tag) => tag.tag === tagInput)) {
      const newTag = { keyWord: tagInput };
      this.keywordTags.push(newTag);
      this.addBookForm.get("keywords")?.setValue("");
    }
  }

  removeKeywordsTags(tag: any): void {
    this.keywordTags = this.keywordTags.filter((t) => t !== tag);
  }

  onEnterKeywords(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addKeywordsTags();
    }
  }

  // Variables to store the selected files
  pdfFile: File | null = null;
  pdfFileBase64: any | null = null;
  coverImageFile: File | null = null;
  coverImageBase64: string | null = null;
  coverImages: { imageBase64: string }[] = [];

  // Method to handle PDF selection
  onPdfSelect(event: NgxDropzoneChangeEvent) {
    const newFile = event.addedFiles[0];
    if (newFile) {
      this.pdfFile = newFile;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.pdfFileBase64 = e.target.result as string;
        console.log('PDF Base64:', this.pdfFileBase64);
      };
      reader.readAsDataURL(newFile);
    }
  }

  // Method to remove the selected PDF
  onRemovePdf() {
    this.pdfFile = null;
    this.pdfFileBase64 = null;
    console.log('PDF removed');
  }

  // Method to handle image (cover photo) selection
  onImageSelect(event: NgxDropzoneChangeEvent) {
    const newFile = event.addedFiles[0];
    if (newFile) {
      this.coverImageFile = newFile;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.coverImageBase64 = e.target.result as string;
        console.log('Image Base64:', this.coverImageBase64);
      };
      reader.readAsDataURL(newFile);
    }
  }

  // Method to remove the selected cover image
  onRemoveImage() {
    this.coverImageFile = null;
    this.coverImageBase64 = null;
    console.log('Image removed');
  }


  onPDFFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result as string;
        this.pdfFileBase64 = base64String;
      };
    }
  }




}

