<div class="row details-row">
  <div class="col-lg-6 ratio_70">
    <div class="details-image grid-box">
      <div class="overlay">
        <div class="portfolio-image bg-size" [style.background-image]="'url(assets/images/parallax/4.jpg)'">
          <img src="assets/images/parallax/4.jpg" alt="property-image" class="bg-img">
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="details-right">
      <div>
        <h3>About Project</h3>
        <p>
          Residences can be classified by and connected to neighbouring residences and land. Residences can be classified by and connected to neighbouring residences and land. Residences can be classified by and connected to neighbouring residences and land.
        </p>
        <ul class="agent-social">
          <li><a href="https://www.facebook.com/" aria-label="clickable-text" class="facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
          <li><a href="https://twitter.com/" aria-label="clickable-text" class="twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
          <li><a href="https://account.google.com" aria-label="clickable-text" class="google" target="_blank"><i class="fab fa-google"></i></a></li>
          <li><a href="https://www.linkedin.com/" aria-label="clickable-text" class="linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
        </ul>
      </div>
      <div class="project-details">
        <h3>Project Detail</h3>
        <div class="detail-container d-flex pt-0">
          <div class="portfolio-left">
            <h6 class="text-start">client :</h6>
          </div>
          <div class="portfolio-right">
            <h6>john doe</h6>
          </div>
        </div>
        <div class="detail-container d-flex">
          <div class="portfolio-left">
            <h6 class="text-start">date :</h6>
          </div>
          <div class="portfolio-right">
            <h6>22 Nov 2023</h6>
          </div>
        </div>
        <div class="detail-container d-flex">
          <div class="portfolio-left">
            <h6 class="text-start">link :</h6>
          </div>
          <div class="portfolio-right">
            <h6>www.abcd.com</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
