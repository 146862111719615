<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>

<div class="mainContainer">
    <div class="headers">
        <h6>
            Certificates
        </h6>
        <div>

        </div>
        <div class="addHeadings" (click)="addCard(addCertificate)">
            <app-feather-icons class="color" [icon]="'plus'"></app-feather-icons>
            <span>
                Certficate
            </span>
        </div>
    </div>
    @defer {
    <div>
        @if(isLoading){
        <div class="item">
            <ngx-skeleton-loader count="6" [theme]="{
                    height: '40px',
                    width: '150px',
                    'border-radius': '10px',
                    'margin-right': '1rem'
                    }" />
        </div>
        }
        @else if(certificatedDetails?.length > 0){
        <!-- @for(certificate of certificatedDetails;track certificate){
        <div class="tag">
            <div class="tagContent">
                {{certificate?.title}}
                <span class="icons">
                    <i class="fas fa-edit edit-icon" (click)="editCard(addCertificate,certificate)"></i>
                    <i class="fas fa-trash delete-icon" (click)="deleteCard(deleteModal,certificate?.id)"></i>
                </span>
            </div>
        </div>
        } -->
        <div class="row">
            <div class="col-md-12">
                <div class="educational-quality">
                    @for(certificate of certificatedDetails;track certificate){
                    <div class="detail-tags">
                        <div>
                            <div class="m-circle clr">C</div>
                            <div class="wrapper mb40">
                                <!-- @if(!certificate?.isPresent){ -->
                                <span class="tag">{{certificate?.startDate?.substring(0,4) +" - " +
                                    certificate?.endDate?.substring(0,4)}}</span>
                                <!-- } -->
                                <!-- @else{
                                <span class="tag">{{certificate?.startDate?.substring(0,4) }} - Present</span>
                                } -->
                                <h5 class="mt15">{{certificate?.title}}</h5>
                                <h4 class="clr2">{{certificate?.institute}}</h4>
                            </div>
                        </div>

                        <div class="control-buttons">
                            <i class="fas fa-edit edit-icon" (click)="editCard(addCertificate,certificate)"></i>
                            <i class="fas fa-trash delete-icon" (click)="deleteCard(deleteModal,certificate?.id)"></i>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
        }
    </div>
    }
</div>

<ng-template #addCertificate>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "Add"){
                Add Certificate
                }@else{
                Update Certificate
                }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="row gx-2 gx-sm-3" [formGroup]="certificateForm" (ngSubmit)="saveCertificateDetails()">
                <div class="form-group col-sm-6">
                    <label for="title">Certificate Title<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="title" formControlName="title"
                        placeholder="Enter certificate title">
                    @if(certificateForm.get('title')?.hasError('required') &&
                    certificateForm.get('title')?.touched){
                    <small class="text-danger"> Certificate Title is Required</small>
                    }
                </div>

                <div class="form-group col-sm-6">
                    <label for="institute">Institute<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="institute" formControlName="institute"
                        placeholder="Enter institute name">
                    @if(certificateForm.get('institute')?.hasError('required') &&
                    certificateForm.get('institute')?.touched){
                    <small class="text-danger"> Institute is Required</small>
                    }
                </div>

                <!-- <div class="form-group col-sm-6">
                    <label for="experience">Experience<span class="text-danger">*</span></label>
                    <app-common-dropdowns [dataList]="YearList" [defaultText]="'Years*'"
                        (optionSelected)="onYearSelected($event)" [selectedOption]="year" [isDisabled]="true"
                        [clearThevalue]="year">
                    </app-common-dropdowns>
                    @if(year == "" && yearError){
                    <small class="text-danger">Experience is Required</small>
                    }
                </div> -->

                <div class="form-group col-sm-6">
                    <label>Start Date<span class="text-danger">*</span></label>
                    <input type="date" class="form-control" formControlName="startDate"  max="{{ getMaxDate() }}" (change)="getMinDate()">
                    @if(certificateForm.get('startDate')?.hasError('required') &&
                    certificateForm.get('startDate')?.touched){
                    <small class="text-danger">Start date is Required</small>
                    }
                </div>

                <div class="form-group col-sm-6">
                    <div class="last-date">
                        <label>End Date* </label>
                        <!-- <label>
                            <input class="checkbox_animated color-4" type="checkbox" [(ngModel)]="isPresent"
                                [ngModelOptions]="{standalone: true}" (change)="getCheckboxValue()" />
                            Present
                        </label> -->
                    </div>
                    <input type="date" class="form-control" formControlName="endDate" [min]="minmumDate">
                    @if(certificateForm.get('endDate')?.hasError('required') &&
                    certificateForm.get('endDate')?.touched){
                    <small class="text-danger">End date is Required</small>
                    }
                </div>







            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()"
                aria-label="Cancel">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="saveCertificateDetails()"
                aria-label="Add">
                @if(FormName == "Add"){
                Add
                }@else{
                Update
                }
            </button>
        </div>
    </div>
</ng-template>

<ng-template #deleteModal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text">
                Are You Sure you want to delete this certificate ?
            </div>
            <div class="deleteButtons">
                <button type="button" class="btn btn-dashed color-2 btn-pill" aria-label="Cancel"
                    (click)="modal.dismissAll()">Cancel</button>
                <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteCertificate()"
                    aria-label="Delete">

                    Delete
                </button>
            </div>
        </div>
    </div>
</ng-template>