<app-enterprise-home-section></app-enterprise-home-section>

<app-enterprise-property-service [title]="'enterprise'"></app-enterprise-property-service>

<app-enterprise-property-of-day [title]="'enterprise'"></app-enterprise-property-of-day>

<app-enterprise-latest-property [latestForRentData]="latestForRentData" [tagClass]="'color-2'"></app-enterprise-latest-property>

<app-enterprise-looking-for [latestForRentData]="latestForRentData" [title]="'enterprise'" [tagClass]="'color-2'"></app-enterprise-looking-for>

<app-enterprise-featured-city [title]="'enterprise'"></app-enterprise-featured-city>

<app-enterprise-banner></app-enterprise-banner>

<app-enterprise-people-say></app-enterprise-people-say>

<app-enterprise-latest-blog></app-enterprise-latest-blog>

<app-enterprise-brand></app-enterprise-brand>
