<section class="banner-section banner-4 parallax-image">
  <div class="container">
    <div class="row">
      <div class="col">
        @for(data of bannerData; track data){
          <div class="banner-2 ratio2_3">
            <app-banner [bannerData]="data" [type]="'classic'"></app-banner>
          </div>
        }
      </div>
    </div>
  </div>
</section>
