import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
    exp: number;
    Type: string;
    ID: number;
}
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly TOKEN_KEY = 'token';
    private isAuthenticatedSubject = new BehaviorSubject<boolean>(
        this.hasValidToken()
    );

    private userRoleSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    public userRole$: Observable<string | null> = this.userRoleSubject.asObservable();

    constructor(private router: Router, private toastr: ToastrService) { }

    saveToken(token: string): void {
        localStorage.setItem(this.TOKEN_KEY, token);
        this.isAuthenticatedSubject.next(true);
    }

    getToken(): string | null {
        return localStorage.getItem(this.TOKEN_KEY);
    }

    private decodeToken(token: string): DecodedToken {
        try {
            return jwtDecode<DecodedToken>(token);
        } catch (error) {
            console.error('Error decoding token:', error);
            return { exp: 0, Type: '', ID: 0 };
        }
    }

    logout(): void {
        // localStorage.clear('');
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        sessionStorage.clear();
        this.isAuthenticatedSubject.next(false);
        this.router.navigate(['/']);
        this.toastr.info('You have been logged out', 'Session Ended');
    }

    isTokenExpired(token: string | null): boolean {
        ;
        if (!token) return true;
        const decodedToken = this.decodeToken(token);
        return Date.now() >= decodedToken.exp * 1000;
    }

    isAuthenticated(): Observable<boolean> {
        return this.isAuthenticatedSubject.asObservable();
    }

    hasValidToken(): boolean {
        const token = this.getToken();
        return !!token && !this.isTokenExpired(token);
    }

    getUserRole(): string | null {
        const token = this.getToken();
        if (!token) {
            this.userRoleSubject.next(null);
            return null;
        }
        const decodedToken = this.decodeToken(token);
        const role = decodedToken.Type;
        this.userRoleSubject.next(role);
        return role;
    }

    getUserId(): number | null {
        const token = this.getToken();
        if (!token) return null;
        const decodedToken = this.decodeToken(token);
        return decodedToken.ID;
    }

    getUserDetails(): any {
        const token = this.getToken();
        if (!token) return null;
        const userDetails = localStorage.getItem('userDetails');
        return userDetails;
    }

    checkTokenExpiryAndLogout(): void {
        if (!this.hasValidToken()) {
            this.logout();
        }
    }

    generateFallbackImage(firstName: string | null): string {
        const initial = firstName?.charAt(0).toUpperCase();
        return `https://via.placeholder.com/96?text=${initial}`;
    }


    canActivate(): boolean {
        debugger
        if (this.hasValidToken()) {
            return true;
        } else {
            this.toastr.warning('Please log in to access this page', 'Unauthorized');
            this.router.navigate(['/authentication']);
            return false;
        }
    }
}
