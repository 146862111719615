import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

enum ErrorCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  REQUEST_TIMEOUT = 408,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  NETWORK_ERROR = 0
}

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  private readonly ERROR_MESSAGES: { [key: number]: string } & { DEFAULT: string } = {
    [ErrorCode.BAD_REQUEST]: 'Bad Request. Please check your input and try again.',
    [ErrorCode.UNAUTHORIZED]: 'Unauthorized. Please log in and try again.',
    [ErrorCode.FORBIDDEN]: 'Forbidden. You do not have permission to access this resource.',
    [ErrorCode.NOT_FOUND]: 'Not Found. The requested resource could not be found.',
    [ErrorCode.REQUEST_TIMEOUT]: 'Request Timeout. Please try again later.',
    [ErrorCode.INTERNAL_SERVER_ERROR]: 'Internal Server Error. Please try again later or contact support.',
    [ErrorCode.BAD_GATEWAY]: 'Bad Gateway. Please try again later.',
    [ErrorCode.SERVICE_UNAVAILABLE]: 'Service Unavailable. Please try again later.',
    [ErrorCode.GATEWAY_TIMEOUT]: 'Gateway Timeout. Please try again later.',
    [ErrorCode.NETWORK_ERROR]: 'Network Error. Please check your internet connection and try again.',
    DEFAULT: 'An unexpected error occurred. Please try again or contact support.'
  };

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  handleResponseError(response: { responseCode: number; responseMessage?: string }): void {
    this.spinner.hide();
    const message = response.responseCode === ErrorCode.BAD_REQUEST && response.responseMessage
      ? response.responseMessage
      : this.getErrorMessage(response.responseCode);
    this.showErrorToast(message);
  }

  handleHttpError(error: HttpErrorResponse): void {
    this.spinner.hide();
    
    if (error.error instanceof ErrorEvent) {
      this.showErrorToast('A client-side error occurred. Please check your connection and try again.');
    } else {
      const message = this.getErrorMessage(error.status);
      this.showErrorToast(message);
    }
  }

  private getErrorMessage(statusCode: number): string {
    return this.ERROR_MESSAGES[statusCode] || this.ERROR_MESSAGES.DEFAULT;
  }

  private showErrorToast(message: string): void {
    this.toastr.error(message, 'Error!');
  }
}
