<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="portfolio-section portfolio-grid">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-grid-tab [gridImagesData]="gridImagesData" (imagesData)="receiveChildData($event)"></app-grid-tab>
        <div class="column-sm row grid ratio2_3  zoom-gallery-multiple" gallerize>
          @for(images of imagesData; track images; let i = $index){
            <div class="col-xl-3 col-lg-4 col-sm-6 sale grid-item wow fadeInUp" >
               <app-common-grid-images [imagesData]="images" [index]="i" [gridTitle]="true"></app-common-grid-images>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</section>
