 <section class="video-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-5 col-lg-8">
          <div class="video-details">
              <span class="label label-light label-flat color-4">#Realestate</span>
              <h2>Are you worried <br/> sick about moving out? </h2>
              <p class="font-roboto">We know how it feels! Elegant retreat in a quiet Coral Gables setting. This home provides wonderful entertaining spaces with a chef
                  kitchen opening Elegant retreat in a quiet Coral Gables setting.</p>
              <button aria-label=" Search now" type="button" class="btn btn-gradient btn-lg color-4" [routerLink]="['/listing/grid-view/2-grid/left-sidebar']">Search now</button>
          </div>
      </div>
      <div class="col-xl-6 col-lg-4">
        <div class="play-icon">
          <div class="icon-video">
            <span class="heart-animation"></span>
            <a aria-label="clickable-text" href="javascript:void(0)" (click)="openModal()">
              <i class="fas fa-play"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
