import { Component, inject, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public title = 'sheltos-front-rest';

  private _auth = inject(AuthService);



  constructor(private router: Router) {
    const token = this._auth.getToken()
    if(token)
    this._auth.checkTokenExpiryAndLogout();
  }

  ngOnInit() {
    const links = document.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('aria-label', 'clickable-text');
    });
  }

 

}
