import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoModalComponent } from '../../../../shared/components/common/modal/video-modal/video-modal.component';
import { img } from '../../../../shared/interface/property';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent {
  @Input() videoData: img[];
  @Input() getInstituteData:any; 
  vedioUrl:any;

  constructor(private modal: NgbModal, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.vedioUrl = this.getInstituteData;  
  }

  openModal(videoUrl: string) {
    const modalRef = this.modal.open(VideoModalComponent, { centered: true });
    modalRef.componentInstance.data = videoUrl;
  }

  isPlaying = false;

  playVideo() {
    this.isPlaying = true;
    this.vedioUrl = `${this.getInstituteData}?autoplay=1`;
  }
}
