<section class="pb-0 testimonial-style-2">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-title [titleClass]="'title-3'" [tag]="'Our'" [heading]="'Client'" [desc]="desc" [svgIcon]="true" [type]="'simple'" [svgClass]="svgClass"></app-title>
                <div class="testimonial-3 modern-dot">
                    <app-happy-clients [happyClientsData]="happyClientsData" [type]="'basic'" [tagClass]="tagClass"></app-happy-clients>
                </div>
            </div>
        </div>
    </div>
</section>
