<div class="our-agent theme-card">
  <div class="row">
    <div class="col-sm-6 ratio_landscape">
      <div class="agent-image bg-size">
        <!-- [style.background-image]="'url('+TeacherData?.profileBase64+')'" -->
        <img
          src="../../../../../assets/images/about/clients/SophiaLee.jpg"
          class="img-fluid bg-img"
          alt="agents-image"
        />
        <!-- [src]="TeacherData?.profileBase64" -->
        <!-- <span class="label label-shadow">{{ TeacherData?.property }} Properties</span> -->
      </div>
    </div>
    <div class="col-sm-6">
      <div class="our-agent-details">
        <!-- <h3>{{ TeacherData?.firstName }}</h3> -->
        <h3>Muhammad</h3>
        <h6>Ali</h6>
        <!-- <h6>{{ TeacherData?.lastName }}</h6> -->
        <ul>
          <li>
            <div class="media">
              <div class="icons-square">
                <app-feather-icons [icon]="'map-pin'"></app-feather-icons>
              </div>
              <div class="media-body">
                <!-- <h6>{{ TeacherData?.address }}</h6> -->
                <h6>Bahria Town, Lahore</h6>
              </div>
            </div>
          </li>
          <li>
            <div class="media">
              <div class="icons-square">
                <app-feather-icons [icon]="'phone-call'"></app-feather-icons>
              </div>
              <div class="media-body">
                <!-- <h6>{{ TeacherData?.phoneNumber }}</h6> -->
                <h6>+92 305 1234567</h6>
              </div>
            </div>
          </li>
          <li>
            <div class="media">
              <div class="icons-square">
                <app-feather-icons [icon]="'mail'"></app-feather-icons>
              </div>
              <div class="media-body">
                <!-- <h6>{{ TeacherData?.email }}</h6> -->
                <h6>kLr6Wexample.com</h6>
              </div>
            </div>
          </li>
          <!-- <li class="with-link">
            <div class="media">
              <div class="icons-square">
                <app-feather-icons [icon]="'link'"></app-feather-icons>
              </div>
              <div class="media-body">
                <h6><a href="javascript:void(0)">{{ TeacherData.link }}</a></h6>
              </div>
            </div>
          </li> -->
        </ul>
      </div>
      <ul class="agent-social">
        <!-- @for(social of TeacherData.socialLinks; track social ){ -->
        <li>
          <a aria-label="Facebook" [href]="TeacherData?.socialLinks?.facebook || 'https://www.facebook.com/'" class="facebook"
            ><i class="fab fa-facebook-f"></i
          ></a>
        </li>
        <li>
          <a [href]="TeacherData?.socialLinks?.twitter || 'https://twitter.com/'" class="twitter" aria-label="twitter"
            ><i class="fab fa-twitter"></i
          ></a>
        </li>
        <li>
          <a [href]="TeacherData?.socialLinks?.instagram || 'https://twitter.com/'" class="google" aria-label="google"
            ><i class="fab fa-instagram"></i
          ></a>
        </li>
        <li>
          <a [href]="TeacherData?.socialLinks?.linkedin || 'https://pk.linkedin.com/' " class="linkedin" aria-label="linkedin"
            ><i class="fab fa-linkedin-in"></i
          ></a>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- '/////////////////Descreption///////////////////' -->
<!-- @if(TeacherData?.description){ -->
<div class="about-agent theme-card">
  <h3>About Teacher</h3>
  <div class="row">
    <!-- @for(details of TeacherData; track details){
    <p class="font-roboto col-xl-4"> {{ details?.description }} </p>
  } -->
    <p class="font-roboto col-xl-4">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima tenetur,
      soluta possimus, nisi, omnis optio aut quos placeat consectetur dolor ab
      accusamus commodi. Eligendi eaque, culpa vero dicta minus quia! Lorem
      ipsum dolor sit amet consectetur adipisicing elit. Pariatur debitis
      facilis, assumenda quidem quos harum, recusandae est eos soluta aut culpa
      voluptatem nihil numquam natus at quia earum. Fuga, voluptatibus?
    </p>
  </div>
</div>
<!-- } -->

<!-- '/////////////////Education///////////////////' -->

<!-- @if(TeacherData?.educations){ -->
<div class="about-agent theme-card deatilsCards">
  <h3>Education</h3>
  <div class="row">
    <!-- @for(edu of TeacherData?.educations; track edu){ -->
    <div class="educational-quality col-md-6 col-sm-12">
      <div class="m-circle text-thm">E</div>
      <div class="wrapper mb40">
        <span class="tag">01-01-2019 to 01-01-2020</span>
        <h5 class="mt15">Computer Science</h5>
        <h6 class="text-thm">Lahore College</h6>
        <h6 class="text-thm">CGPA = 3.5</h6>
        <!-- <span class="tag">{{edu.startDate?.substring(0,10)+" to "+edu.endDate?.substring(0,10)}}</span>
        <h5 class="mt15">{{edu?.degreeTitle}}</h5>
        <h6 class="text-thm">{{edu?.institute}}</h6>
        <h6 class="text-thm">CGPA = {{edu?.cgpa}}</h6> -->
      </div>
      <!-- <button *ngIf="!showAll && teacherDetail.educations.length > 3" (click)="showAll = true">Show
            More...</button>
        <button *ngIf="showAll" (click)="showAll = false">Show Less...</button> -->
    </div>
    <!-- } -->
  </div>
</div>
<!-- } -->

<!-- '/////////////////Subjects///////////////////' -->

<!-- @if(TeacherData?.subjects){ -->
<div class="about-agent theme-card deatilsCards">
  <h3>Subject</h3>
  <div class="row">
    <!-- @for(Subject of TeacherData?.subjects; track Subject){ -->
    <div class="educational-quality col-md-6 col-sm-12">
      <div class="m-circle text-thm">S</div>
      <div class="wrapper mb40">
        <span class="tag">AI</span>
        <h5 class="mt15">5000 / month</h5>
      </div>
    </div>
    <div class="educational-quality col-md-6 col-sm-12">
      <div class="m-circle text-thm">S</div>
      <div class="wrapper mb40">
        <span class="tag">DSA</span>
        <h5 class="mt15">5000 / month</h5>
      </div>
    </div>
    <!-- <div class="educational-quality col-md-6 col-sm-12">
      <div class="m-circle text-thm">S</div>
      <div class="wrapper mb40">
        <span class="tag">{{Subject?.subject}}</span>
        <h5 class="mt15">{{Subject?.fee+" / "+Subject?.duration}} </h5>
      </div>
    </div> -->
    <!-- } -->
  </div>
</div>
<!-- } -->

<!-- '/////////////////Experience///////////////////' -->

<!-- @if(TeacherData?.experiences){ -->
<div class="about-agent theme-card deatilsCards">
  <h3>Work & Experience</h3>
  <div class="row">
    <!-- @for(Exp of TeacherData?.experiences; track Exp){ -->
    <div class="educational-quality col-md-6 col-sm-12">
      <div class="m-circle text-thm">E</div>
      <div class="wrapper mb40">
        <span class="tag">01-01-2019 to 01-01-2020</span>
        <h5 class="mt15">ATG</h5>
        <h5 class="mt15">DEVELOPER</h5>
        <h6 class="text-thm">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis in
          aspernatur reprehenderit possimus temporibus! Beatae dolores sed
          ullam? Hic porro autem possimus inventore dolor, earum sequi
          cupiditate ipsam odio beatae!
        </h6>
      </div>
    </div>
    <div class="educational-quality col-md-6 col-sm-12">
      <div class="m-circle text-thm">E</div>
      <div class="wrapper mb40">
        <span class="tag">01-01-2019 to 01-01-2020</span>
        <h5 class="mt15">ATG</h5>
        <h5 class="mt15">DEVELOPER</h5>
        <h6 class="text-thm">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus
          repellat nisi nemo recusandae est doloribus, aut facere labore illo
          accusantium veritatis modi inventore omnis tempore culpa assumenda
          ullam beatae atque?
        </h6>
      </div>
    </div>
    <!-- <div class="educational-quality col-md-6 col-sm-12">
      <div class="m-circle text-thm">E</div>
      <div class="wrapper mb40">
        <span class="tag">{{Exp?.startDate?.substring(0,10)+" to "+Exp.endDate?.substring(0,10)}}</span>
        <h5 class="mt15">{{Exp?.company}}</h5>
        <h5 class="mt15">{{Exp?.role}}</h5>
        <h6 class="text-thm">{{Exp?.description}}</h6>
      </div>
    </div> -->
    <!-- } -->
  </div>
</div>
<!-- } -->

<!-- '/////////////////Skills///////////////////' -->
<!-- @if(TeacherData?.skills){ -->
<div class="about-agent theme-card deatilsCards">
  <h3>Skills</h3>
  <div class="row">
    <!-- <div *ngFor="let skill of TeacherData?.skills; let i = index" class="col-md-6 col-sm-12"> -->
    <div class="skills-container">
      <div class="skill-item d-flex align-items-center">
        <small class="skill-title mr-2">HTML</small>
        <ngb-progressbar
          class="mb-3 progressbar flex-grow-1"
          [showValue]="true"
          type="success"
          [value]="90"
        ></ngb-progressbar>
      </div>
    </div>
    <div class="skills-container">
      <div class="skill-item d-flex align-items-center">
        <small class="skill-title mr-2">CSS</small>
        <ngb-progressbar
          class="mb-3 progressbar flex-grow-1"
          [showValue]="true"
          type="success"
          [value]="60"
        ></ngb-progressbar>
      </div>
    </div>
    <!-- <div class="skills-container">
        <div class="skill-item d-flex align-items-center">
          <small class="skill-title mr-2">{{ skill?.skillTitle }}</small>
          <ngb-progressbar class="mb-3 progressbar flex-grow-1 " [showValue]="true" type="success"
            [value]="skill?.percentage"></ngb-progressbar>
        </div>
      </div> -->
    <!-- </div> -->
  </div>
</div>
<!-- } -->

<!-- '/////////////////Certificate///////////////////' -->

<!-- @if(TeacherData?.certificates){ -->
<div class="about-agent theme-card deatilsCards">
  <h3>Award & Certificates</h3>
  <div class="row">
    <!-- @for(Certi of TeacherData?.certificates; track Certi){ -->
    <div class="educational-quality col-md-6 col-sm-12">
      <div class="m-circle text-thm">C</div>
      <div class="wrapper mb40">
        <span class="tag">Excelent Working</span>
        <h5 class="mt15">ATG</h5>
        <h6 class="text-thm">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime illum
          suscipit pariatur consequuntur sapiente quas accusamus laborum
          consequatur, eveniet cum rerum! Temporibus, reiciendis? Dolorum alias
          praesentium nemo voluptatibus facere doloribus.
        </h6>
      </div>
    </div>
    <div class="educational-quality col-md-6 col-sm-12">
      <div class="m-circle text-thm">C</div>
      <div class="wrapper mb40">
        <span class="tag">Excelent Working</span>
        <h5 class="mt15">ATG</h5>
        <h6 class="text-thm">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime illum
          suscipit pariatur consequuntur sapiente quas accusamus laborum
          consequatur, eveniet cum rerum! Temporibus, reiciendis? Dolorum alias
          praesentium nemo voluptatibus facere doloribus.
        </h6>
      </div>
    </div>
    <!-- <div class="educational-quality col-md-6 col-sm-12">
      <div class="m-circle text-thm">C</div>
      <div class="wrapper mb40">
        <span class="tag">{{Certi?.title}}</span>
        <h5 class="mt15">{{Certi?.institute}}</h5>
        <h6 class="text-thm">{{Certi?.experience}}</h6>
      </div>
    </div> -->
    <!-- } -->
  </div>
</div>
<!-- } -->

<!-- '/////////////////Map///////////////////' -->

<!-- @if(TeacherData.certificates){ -->
<!-- <div class="about-agent theme-card deatilsCards">
    <h3>Location</h3>
    <div class="row"> -->
<!-- @for(Certi of TeacherData.certificates; track Certi){ -->
<!-- <iframe title="title" src="'https://maps.google.com/maps?q=cheezious+i-8+Islamabad+&t=&z=13&ie=UTF8&iwloc=&output=embed'" allowfullscreen></iframe> -->

<!-- } -->
<!-- </div>
  </div> -->
<!-- } -->
