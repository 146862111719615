<section class="home-section layout-1 layout-6">
    <div class="home-main">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="container">
                        <div class="home-left">
                            <div>
                                <div class="home-slider-1 arrow-light slick-shadow">
                                    <app-home-section-slider [homeSectionSliderData]= "homeSectionSliderData" [type]="'simple'"></app-home-section-slider>
                                </div>
                                <div class="looking-icons">
                                    <app-looking-for-icons [text]=true></app-looking-for-icons>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-5">
                    <div class="home-search-6">
                        <div class="vertical-search">
                            <div class="left-sidebar">
                                <app-filter-box [buttonClass]="'color-6'"></app-filter-box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
