import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeachearListComponent } from './teachear-list/teachear-list.component';

import { TeachearModuleRoutingModule } from './teachear-module-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TeachearDetailsComponent } from './teachear-details/teachear-details.component';
import { AgentModule } from '../agent/agent.module';
import { AgencyModule } from '../pages/agency/agency.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [
    TeachearDetailsComponent,
    TeachearListComponent
  ],
  imports: [
    CommonModule,
    TeachearModuleRoutingModule,
    SharedModule,
    AgencyModule,
    AgentModule,
    NgbModule,
    CarouselModule,
    NgxSkeletonLoaderModule.forRoot(),
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
  ]
})
export class TeachearModuleModule { }
