<!-- <app-loader-two [isLoading]="isSpinnerShown"></app-loader-two> -->
<div class="headers">
  <h6>
    Profile Picture
  </h6>
</div>
<div class="container">
  <div class="row">
    <!-- Profile Image Section -->
    <div class="col-12 text-center mb-4">
      <div class="image-container" (click)="triggerFileInput()">
        <img [src]="profileBase64" alt="" class="profile-image">
        <app-feather-icons [icon]="'camera'" class="camera-icon"></app-feather-icons>
        <input type="file" (change)="onFileSelect($event)" class="file-input" #fileInput accept="image/*">
      </div>
      <small *ngIf="profileBase64 == '' && imageError" class="text-danger text-start d-flex">Image is Required</small>
    </div>
    <div class="headers">
      <h6>
        Personal Details
      </h6>
    </div>
    <!-- Personal Details Form -->
    <form class="row gx-2 gx-sm-3" [formGroup]="userForm">
      <div class="form-group col-sm-4">
        <label>First Name*</label>
        <input type="text" class="form-control" formControlName="firstName" placeholder="Enter first name">
        <small *ngIf="userForm.get('firstName')?.hasError('required') && userForm.get('firstName')?.touched"
          class="text-danger">First Name is Required</small>
      </div>

      <div class="form-group col-sm-4">
        <label>Last Name*</label>
        <input type="text" class="form-control" formControlName="lastName" placeholder="Enter last name">
        <small *ngIf="userForm.get('lastName')?.hasError('required') && userForm.get('lastName')?.touched"
          class="text-danger">Last Name is Required</small>
      </div>

      <div class="form-group col-sm-4">
        <label>Gender*</label>
        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Gender'" [dataList]="genderList"
          (optionSelected)="onGenderSelected($event)" [isDisabled]="true" [selectedOption]="gender"
          [clearThevalue]="gender"></app-common-dropdowns>
        <small *ngIf="gender == '' && genderError" class="text-danger">Gender is Required</small>
      </div>

      <!-- Phone Number -->
      <div class="form-group col-sm-4">
        <label>Phone Number*</label>
        <input type="tel" id="phoneNumber" class="form-control" formControlName="phoneNumber"
          placeholder="Enter phone number" (input)="formatPhoneNumber($event)" />
        <small *ngIf="userForm.get('phoneNumber')?.hasError('required') && userForm.get('phoneNumber')?.touched"
          class="text-danger">
          Phone Number is Required
        </small>
        <small *ngIf="userForm.get('phoneNumber')?.hasError('maxlength') && userForm.get('phoneNumber')?.touched"
          class="text-danger">
          Phone Number must be between 10-18 characters
        </small>
        <small *ngIf="userForm.get('phoneNumber')?.hasError('minlength') && userForm.get('phoneNumber')?.touched"
          class="text-danger">
          Phone Number must be between 10-18 characters
        </small>
        <small *ngIf="userForm.get('phoneNumber')?.hasError('pattern') && userForm.get('phoneNumber')?.touched"
          class="text-danger">
          Invalid Phone Number
        </small>
      </div>


      <!-- Date of Birth -->
      <div class="form-group col-sm-4">
        <label>Date of Birth*</label>
        <input type="date" class="form-control" formControlName="dateOfBirth">
        <small *ngIf="userForm.get('dateOfBirth')?.hasError('required') && userForm.get('dateOfBirth')?.touched"
          class="text-danger">Date of Birth is Required</small>
      </div>

      <!-- Address -->
      <div class="form-group col-sm-4">
        <label>Address*</label>
        <input type="text" class="form-control" formControlName="address" placeholder="Enter address">
        <small *ngIf="userForm.get('address')?.hasError('required') && userForm.get('address')?.touched"
          class="text-danger">Address is Required</small>
      </div>

      <!-- Country Dropdown -->
      <div class="form-group col-sm-4">
        <label>Country*</label>
        <app-common-dropdowns class="second-dropdown" [defaultText]="'Enter Country Name'" [dataList]="CountryList"
          (optionSelected)="onCountrySelected($event)" [selectedOption]="Country"
          [clearThevalue]="Country"></app-common-dropdowns>
        <small *ngIf="Country == '' && countryTypeError" class="text-danger">Country is Required</small>
      </div>

      <!-- State Dropdown -->
      <div class="form-group col-sm-4">
        <label>State*</label>
        <app-common-dropdowns class="third-dropdown" [defaultText]="'Enter State Name'" [dataList]="StateList"
          (optionSelected)="onStateSelected($event)" [selectedOption]="State"
          [clearThevalue]="State"></app-common-dropdowns>
        <small *ngIf="State == '' && stateError" class="text-danger">State is Required</small>
      </div>

      <!-- City Dropdown -->
      <div class="form-group col-sm-4">
        <label>City*</label>
        <app-common-dropdowns class="fourth-dropdown" [defaultText]="'Enter City Name'" [dataList]="CityList"
          (optionSelected)="onCitySelected($event)" [selectedOption]="City"
          [clearThevalue]="City"></app-common-dropdowns>
        <small *ngIf="City == '' && cityError" class="text-danger">City is Required</small>
      </div>

      <!-- Description -->
      <div class="form-group col-sm-12">
        <label>Description*</label>
        <textarea class="form-control" formControlName="description" rows="4"
          placeholder="Enter description"></textarea>
        <small *ngIf="userForm.get('description')?.hasError('required') && userForm.get('description')?.touched"
          class="text-danger">Description is Required</small>
      </div>
      <!-- <div class="form-group col-sm-12 text-end">
        <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="savePersonalDetails()"
          aria-label="Save Changes">
          Save Changes
        </button>
      </div> -->
    </form>
  </div>