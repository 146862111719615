import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HostelsRoutingModule } from './hostels-routing.module';
import { HostelListComponent } from './hostel-list/hostel-list.component';
import { GenralInformationComponent } from './submit-hostel/widgets/genral-information/genral-information.component';
import { AddressDetailsComponent } from './submit-hostel/widgets/address-details/address-details.component';
import { AdditionalDetailsComponent } from './submit-hostel/widgets/additional-details/additional-details.component';
import { ConformationComponent } from './submit-hostel/widgets/conformation/conformation.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeModule } from '../home/home.module';
import { PropertyModule } from '../property/property.module';
import { HostelDetailComponent } from './hostel-detail/hostel-detail.component';
import { AdmissionsRoutingModule } from '../admissions/admissions-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [
    HostelListComponent,
    GenralInformationComponent,
    AddressDetailsComponent,
    AdditionalDetailsComponent,
    ConformationComponent,
    HostelDetailComponent
  ],
  imports: [
    CommonModule,
    HostelsRoutingModule,
    SharedModule,
    NgbPaginationModule,
    NgxSkeletonLoaderModule,
    PropertyModule,
    HomeModule,
    AdmissionsRoutingModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
  ]
})
export class HostelsModule { }
